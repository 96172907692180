import React, { useEffect, useState } from "react";
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { SlideshowLightbox } from 'lightbox.js-react'
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import copy from "copy-to-clipboard";


const KycDetailsPersonal = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { mergedData } = location.state || {};
  const [kycStep, setKycStep] = useState("step1");
  const [userKycDetails, setUserKycDetails] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rejectRemarks, setRejectRemarks] = useState("");
  const [isShowLiveAtt, setIsShowLiveAtt] = useState(false);
  const [isShowFaceAtt, setIsShowFaceAtt] = useState(false);
  const [isShowRejectedHist, setIsShowRejectedHist] = useState(false);
  const [rejectedSteps, setRejectedSteps] = useState([]);
  const [editingStep, setEditingStep] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [mainRejectModel, setMainRejectModel] = useState(false);
  const [reasonMessage, setReasonMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [verificationRemark, setVerificationRemark] = useState("");
  const [approvedRemark, setApprovedremark] = useState([]);
  const [showModelApprove, setShowModelApprove] = useState(false);
  const [copied, setCopied] = useState(false);
  const [activeTabOtherDeclarattion, setActiveTabOtherDeclarattion] = useState(0);
  const [activeTabLivenessRejected, setActiveTabLivenessRejected] = useState(0);
  const [activeTabLiveness, setActiveTabLiveness] = useState(0);
  const [activeTabEducation, setActiveTabEducation] = useState(0);
  const [activeTabFaceMatch, setActiveTabFaceMatch] = useState(0);
  const [userKycStatus, setUserKycStatus] = useState([]);
  const [activeTabPanAttemp, setActiveTabPanAttempt] = useState(0);
  const [activeTabRejectedPan, setActiveTabRejectedPan] = useState(0);
  const [loader, setLoader] = useState(false);
  const [panAttemps, setPanAttemps] = useState(null);
  const [livenessAttemps, setLivenessAttemps] = useState(null);
  const [facematchAttemps, setFacematchAttemps] = useState(null);
  const [rejectedPan, setRejectedPan] = useState();
  const [rejectedAadhaar, setRejectedAadhaar] = useState();
  const [rejectedLiveness, setRejectedLiveness] = useState();
  const [rejectedEducation, setRejectedEducation] = useState();
  const [rejectedDeclaration, setRejectedDeclaration] = useState();
  const [userDetails, setUserDetails] = useState();

  const handleTabClickPanAttemp = (e, index) => {
    setActiveTabPanAttempt(index);
    setPanAttemps(userKycDetails?.otherPan?.[index]);

  };

  const handleTabClickRejectedPan = (e, index) => {
    setActiveTabRejectedPan(index);
    setRejectedAadhaar(userKycDetails?.otherAadhar?.[index]);

  };

  const handleTabClick = (e, index) => {
    setActiveTabLiveness(index);
    setLivenessAttemps(userKycDetails?.livenessAttempts?.[index]);
  };

  const handleTabClickFaceMatch = (e, index) => {
    setActiveTabFaceMatch(index);
    setFacematchAttemps(userKycDetails?.faceMatchAttempts?.[index]);

  };

  const handleTabClickRejectedLiveness = (e, index) => {
    setActiveTabLivenessRejected(index);
    setRejectedLiveness(userKycDetails?.rejectedHistory?.[index]);

  };

  const handleTabClickRejectEduction = (e, index) => {
    setActiveTabEducation(index);
    setRejectedEducation(userKycDetails?.otherEducationDetails?.[index]);

  };

  const handleOtherDeclarattion = (e, index) => {
    setActiveTabOtherDeclarattion(index);
    setRejectedDeclaration(userKycDetails?.otherDeclarationData?.[index]);

  };


  useEffect(() => {
    setPanAttemps(userKycDetails?.otherPan?.[activeTabPanAttemp])
    setRejectedPan(userKycDetails?.otherPan?.[activeTabRejectedPan])
    setRejectedAadhaar(userKycDetails?.otherAadhar?.[activeTabRejectedPan])
    setLivenessAttemps(userKycDetails?.livenessAttempts?.[activeTabLiveness])
    setFacematchAttemps(userKycDetails?.faceMatchAttempts?.[activeTabFaceMatch])
    setRejectedLiveness(userKycDetails?.rejectedHistory?.[activeTabLivenessRejected])
    setRejectedEducation(userKycDetails?.otherEducationDetails?.[activeTabEducation])
    setRejectedDeclaration(userKycDetails?.otherDeclarationData?.[activeTabOtherDeclarattion])
  }, [userKycDetails, activeTabLiveness, activeTabPanAttemp, activeTabRejectedPan, activeTabFaceMatch, activeTabLivenessRejected, activeTabEducation, activeTabOtherDeclarattion])


  const fetchUserDetails = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getStepUserData(mergedData?.row?._id, kycStep).then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setUserKycDetails(result?.data)
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    }
    );
  };


  const handleuserKycStatus = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getCurrentKycStatus(mergedData?.row?._id).then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setUserKycStatus(result?.data)
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    }
    );
  };


  const handleKycApproveRemark = async (verificationRemark) => {
    if (!verificationRemark) {
      setErrorMessage("Please Enter Approval Remark");
      return;
    }
    LoaderHelper.loaderStatus(true);
    await AuthService.kycVerifyRemark(verificationRemark, mergedData?.row?._id).then(
      async (result) => {
        if (result?.success) {
          LoaderHelper.loaderStatus(false);
          try {
            alertSuccessMessage(result?.message);
            setShowModelApprove(false);
            navigate("/personal_kyc", { state: { mergedData: { kycStatus: "rejected" } } });
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(result.message);
        }
      }
    );
  };


  const handleGetApprovedRemark = async () => {
    await AuthService.getVerifyRemark(mergedData?.row?._id).then(
      async (result) => {
        if (result?.success) {
          try {
            setApprovedremark(result?.data);
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const handleRejectKyc = async (selectedOption, reason) => {
    if (selectedOption === "reject_all") {
      if (!reason) {
        setErrorMessage("Please enter a reason");
        return;
      }
    }
    setLoading(true);
    await AuthService.rejectUserKycStep(selectedOption, reason, rejectedSteps, mergedData?.row?._id, reasonMessage).then(async (result) => {
      setLoading(false);
      if (result.success) {
        setShowModel(false);
        try {
          fetchUserDetails();
          setReasonMessage("");
          setRejectedSteps([]);
          setMainRejectModel(false);
          navigate("/personal_kyc", { state: { mergedData: { kycStatus: "rejected" } } });
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result);
      }
    })
      .catch((error) => {
        setLoading(false);
        alertErrorMessage(error);
      });
  };

  useEffect(() => {
    if (rejectedSteps?.length >= 1) {
      setSelectedOption("partial_reject");
    } else {
      setSelectedOption("reject_all");
    }
  }, [rejectedSteps]);



  const handleGetUserKycDetails = async () => {
    await AuthService.getUserPersnolKycDetails(mergedData?.row?._id).then(
      async (result) => {
        if (result?.success) {
          try {
            setUserDetails(result?.data);
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          alertErrorMessage(result.message);
        }
      }
    );
  };

  useEffect(() => {
    handleGetUserKycDetails()
  }, []);


  const handleDownloadAmlDetails = () => {
    if (userKycDetails?.otherAmlData?.length > 0 || userKycDetails?.latestAmlData) {
      const otherAmlData = (userKycDetails?.otherAmlData || []).map(item => ({
        ...item,
        Name: "Other Aml Data"
      }));
      const latestAmlData = userKycDetails?.latestAmlData
        ? [{ ...userKycDetails.latestAmlData, Name: "Latest Aml Data" }]
        : [];
      const amlDetails = {
        LatestAmlData: latestAmlData,
        OtherAmlData: otherAmlData,
      };
      const amlDataString = JSON.stringify(amlDetails, null, 2);
      const blob = new Blob([amlDataString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "aml_details.json";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      alertErrorMessage("No Data Available");
    }
  };


  const hadndleDownloadApiData = async () => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.getMasterKycData(mergedData?.row?._id);
      LoaderHelper.loaderStatus(false);

      if (result?.success) {
        const amlData = result?.data;
        const amlDataString =
          typeof amlData === "string" ? amlData : JSON.stringify(amlData, null, 2);
        const blob = new Blob([amlDataString], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "master_kyc_data.txt";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        alertErrorMessage(result.message);
      }
    } catch (error) {
      LoaderHelper.loaderStatus(false);
      alertErrorMessage(error.message || "An error occurred while downloading AML details.");
    }
  };


  useEffect(() => {
    fetchUserDetails();
    handleuserKycStatus();
  }, [mergedData?.row?._id, kycStep])


  const stepNames = {
    step1: "Proof of Identification",
    step2: "Check Liveness",
    step3: "Education & Occupation",
  };

  const storeStepRejectedReason = (rejectRemarks) => {
    if (!rejectRemarks) {
      setErrorMessage("Please enter a reason")
      return;
    }
    const newRemark = {
      kycStep,
      stepName: stepNames[kycStep],
      reason: rejectRemarks,
    };
    setRejectedSteps((prev) => [...prev, newRemark]);
    setShowModel(false);
    setRejectRemarks("");
  };


  const filterSteps = rejectedSteps?.filter(step => step.kycStep == kycStep)[0];

  const handleEditRemark = (stepData) => {
    setMainRejectModel(false);
    setEditingStep(stepData);
    setShowEditModal(true);
  };

  const saveEditedRemark = (updatedStep) => {
    const updatedSteps = rejectedSteps.map((step) =>
      step.kycStep === updatedStep.kycStep ? updatedStep : step
    );
    setRejectedSteps(updatedSteps);
    setShowEditModal(false);
    setMainRejectModel(true);
  };


  const handleClickStep = (step) => {
    setKycStep(step);
    if (step === "step4") {
      handleGetApprovedRemark();
    }
  };

  const handleCopy = (item) => {
    copy(item);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };


  const latestLivenessDetails = userKycDetails?.latestLivenessData?.apiResponse?.result?.details;
  const latestGeoDetails = userKycDetails?.latestLivenessData?.geoDetails?.apiResponse?.result?.geoDetails;



  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <form className="form-data">
            <header className="page-header ">
              <div className="container-xl px-4">
                <div className="page-header-content pb-2">
                  <div className="row align-items-center justify-content-between">
                    <h6 className="page-header-title mb-5">
                      <span
                        className="page-header-icon cursor-pointer"
                        onClick={() =>
                          navigate(
                            mergedData?.pageName === "userList" ? "/user_details" : "/personal_kyc",
                            {
                              state: {
                                mergedData: {
                                  ...mergedData,
                                  row: mergedData?.row,
                                  activeTab: mergedData?.activeTab,
                                  kycTypeUsers: mergedData?.kycTypeUsers,
                                  usersKycType: mergedData?.usersKycType,
                                  pageName: "userList",
                                },
                              },
                            }
                          )
                        }

                      >
                        <i className="fa fa-arrow-left"></i>
                      </span>

                      <small> {mergedData?.kycStatus?.toUpperCase() || "Kyc Details"}</small>
                    </h6>
                    <div className="col-auto">
                      <h1 className="page-header-title">

                        <div className="hd_u_info" >
                          <img src="assets/img/illustrations/profiles/profile-5.png" className="img-account-profile rounded-circle" />
                          <span>  {mergedData?.row?.panDetails?.name || "-------"}
                            <small>
                              User ID: {userDetails?.user?.uid || "-------"}
                              <i
                                className={`ms-1 link cursor-pointer ${copied ? "ri-check-fill text-success" : "ri-file-copy-fill"
                                  }`}
                                onClick={() => handleCopy(userDetails?.user?.uid)}
                              ></i>
                            </small>
                          </span>
                        </div>
                      </h1>
                    </div>
                    <div className="col-auto">
                      <div className="d-flex">

                        {
                          mergedData?.kycStatus !== "not-submitted" && mergedData?.kycStatus !== "rejected" && mergedData?.kycStatus !== "re-kyc" &&
                          <>
                            {
                              kycStep === "step4" &&
                              <>
                                <button className="btn mx-2 btn-dark btn-block btn-md" type="button" onClick={hadndleDownloadApiData}>
                                  Download API Response <i class="ms-2 ri-download-line"></i>
                                </button>
                                <button className="btn btn-danger btn-block btn-md" type="button" onClick={() => setMainRejectModel(true)}>Reject KYC</button>

                              </>
                            }
                            {
                              mergedData?.kycStatus !== "approved" && kycStep === "step4" &&
                              <button className="btn mx-2 btn-success btn-block btn-md" type="button" onClick={() => setShowModelApprove(true)}>
                                Approve
                              </button>
                            }
                          </>
                        }
                      </div>
                    </div>
                  </div>

                  <div className="custom-tab-2 d-flex justify-content-start ">
                    <ul className="nav nav-pills justify-content-end mt-4 mb-0" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className={`nav-link ${location?.pathname === "/kyc_detail_personal" ? "active" : ""}`} type="button"
                          onClick={() => navigate('/kyc_detail_personal',

                            {
                              state: {
                                mergedData: {
                                  ...mergedData,
                                  row: mergedData?.row,
                                  activeTab: mergedData?.activeTab,
                                  kycTypeUsers: mergedData?.kycTypeUsers,
                                  usersKycType: mergedData?.usersKycType,
                                  kycStatus: mergedData?.kycStatus,
                                  pageName: "userList",
                                },
                              },
                            }
                          )}>  Level 1 KYC </button>
                      </li>

                      {
                        userDetails?.user?.user_upgrade ?
                          <li className="nav-item" role="presentation">
                            <button className={`nav-link ${location?.pathname === "/upgrade_kyc_details" ? "active" : ""}`} type="button" onClick={() => navigate('/upgrade_kyc_details',

                              {
                                state: {
                                  mergedData: {
                                    row: mergedData?.row,
                                    kycStatus: mergedData?.kycStatus,
                                  },
                                },

                              })}>  Level 2 KYC    </button>
                          </li>
                          :
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" type="button" onClick={() => alertErrorMessage('Level 2 KYC Not Enable')}>  Level 2 KYC    </button>
                          </li>
                      }



                    </ul>
                  </div>


                </div>
              </div>
            </header>

            <div className="container-xl px-4">

              <div className="row">
                <div className="col-xl-12 mb-4">
                  <ul id="progressbar" >
                    <li className={`cursor-pointer ${kycStep === "step1" ? "active" : ""}  ${userKycStatus?.[0]?.status === "complete" ? "success" : userKycStatus?.[0]?.status === "pending" ? "warning" : userKycStatus?.[0]?.status === "rejected" ? "danger" : userKycStatus?.[0]?.status === "not-submitted" ? "current" : ""}`} id="step1" onClick={() => handleClickStep("step1")}>
                      <strong>Proof of Identification</strong>
                    </li>
                    <li className={`cursor-pointer ${kycStep === "step2" ? "active" : ""} ${userKycStatus?.[1]?.status === "complete" ? "success" : userKycStatus?.[1]?.status === "pending" ? "warning" : userKycStatus?.[1]?.status === "rejected" ? "danger" : userKycStatus?.[1]?.status === "not-submitted" ? "current" : ""}`} id="step2" onClick={() => handleClickStep("step2")}>
                      <strong>Check Liveness & FaceMatch</strong>
                    </li>
                    <li className={`cursor-pointer ${kycStep === "step3" ? "active" : ""} ${userKycStatus?.[2]?.status === "complete" ? "success" : userKycStatus?.[2]?.status === "pending" ? "warning" : userKycStatus?.[2]?.status === "rejected" ? "danger" : userKycStatus?.[2]?.status === "not-submitted" ? "current" : ""}`} id="step3" onClick={() => handleClickStep("step3")}>
                      <strong>Education &amp; Occupation</strong>
                    </li>
                    <li className={`cursor-pointer ${kycStep === "step4" ? "active" : ""} ${userKycStatus?.[3]?.status === "complete" ? "success" : userKycStatus?.[3]?.status === "pending" ? "warning" : userKycStatus?.[3]?.status === "rejected" ? "danger" : userKycStatus?.[3]?.status === "not-submitted" ? "current" : ""}`} id="step4" onClick={() => handleClickStep("step4")}>
                      <strong>Declaration and Submission</strong>
                    </li>
                  </ul>
                  <div className="card" >
                    <div className="card-body py-4">
                      <div className=" " >
                        <div className="profile_data w-100  ac_body px-4 pt-4 ">
                          {/* step 1 */}

                          <div className={`step_1 ${kycStep === "step1" ? "" : "d-none"}`} >
                            <div className="d-flex align-items-center justify-content-between" >
                              <h5> User Information  </h5>
                            </div>

                            <div className="row g-4">
                              <div className="col-lg-4 col-xl-3">
                                <div className="label-group">
                                  <label>  Registration Date </label>
                                  <p>
                                    {userDetails?.user?.createdAt
                                      ? moment(userDetails?.user?.createdAt).format('Do MMMM YYYY,h:mm:ss a')
                                      : "-------"}
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-4 col-xl-3">
                                <div className="label-group">
                                  <label>  Email Address   </label>
                                  <p> {userDetails?.user?.email || "-------"} <i className="ri-verified-badge-fill text-success"></i> </p>
                                </div>
                              </div>
                              <div className="col-lg-4 col-xl-2">
                                <div className="label-group">
                                  <label> Mobile Number </label>
                                  <p> {userDetails?.user?.phone || "-------"} <i className="ri-verified-badge-fill text-success"></i> </p>
                                </div>
                              </div>
                              <div className="col-lg-4 col-xl-2">
                                <div className="label-group">
                                  <label>  User Id </label>
                                  <p>  {userDetails?.user?.uid || "-------"}</p>
                                </div>
                              </div>
                              <div className="col-lg-4 col-xl-2">
                                <div className="label-group">
                                  <label>  Kyc Status </label>
                                  <p>
                                    {userDetails?.user?.kycVerified === 1 ? "Verification Pending" : userDetails?.user?.kycVerified === 2 ? "Verified" : userDetails?.user?.kycVerified === 3 ? "Verification Failed" : userDetails?.user?.kycVerified === 4 ? "Verification Failed" : "Kyc Pending"}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <hr className="my-4 " />

                            <div className="row gx-4" >
                              <div className="col-lg-9" >
                                <div className="d-flex align-items-center justify-content-between" >
                                  <h5>  Proof of Identification</h5>
                                </div>

                                <div className="fc_details" >
                                  <h5>Pan Card Details</h5>
                                  <div className="row g-4 ">
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label> Pan Card Number  </label>
                                        <p> {userKycDetails?.latestPan?.pan || "-------"} {" "}
                                          {
                                            userKycDetails?.latestPan?.name &&
                                            <i className={userKycDetails?.latestPan?.verified ? "ri-verified-badge-fill text-success" : "ri-error-warning-fill text-danger"}></i>

                                          }
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label> Full Name     </label>
                                        <p>
                                          {userKycDetails?.latestPan?.name || "-------"} {" "}
                                          {
                                            userKycDetails?.latestPan?.name &&
                                            <i className={userKycDetails?.latestPan?.verified ? "ri-verified-badge-fill text-success" : "ri-error-warning-fill text-danger"}></i>

                                          }
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label> Gender  </label>
                                        <p> {userKycDetails?.latestPan?.gender || "-------"}   </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-2">
                                      <div className="label-group">
                                        <label> Date of Birth  </label>
                                        <p> {userKycDetails?.latestPan?.dateOfBirth
                                          ? moment(userKycDetails.latestPan?.dateOfBirth).format('Do MMMM YYYY')
                                          : "-------"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label> Linked Aadhar Number  </label>
                                        <p> {userKycDetails?.latestPan?.maskedAadhaarNumber || "-------"} </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3 me-3">
                                      <div className="label-group">
                                        <label>  Pan Verification date  </label>
                                        <p> {userKycDetails?.latestPan?.createdAt
                                          ? <>
                                            {moment(userKycDetails.latestPan.createdAt).format('Do MMMM YYYY')}<br />
                                            <small> {moment(userKycDetails.latestPan.createdAt).format('h:mm:ss a')}</small>
                                          </>
                                          : "-------"
                                        }</p>


                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label> AML Status  </label>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <p> {userKycDetails?.latestAmlData?.action || "-------"}  </p>
                                          <button className=" btn btn-outline-dark  btn-xs" type="button" onClick={handleDownloadAmlDetails}
                                          >Download AML Deatils</button>
                                        </div>

                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-12">
                                      <div className="label-group">
                                        <label> Address  </label>
                                        <p>
                                          {
                                            userKycDetails?.latestPan?.address
                                              ? [
                                                userKycDetails.latestPan.address.line1,
                                                userKycDetails.latestPan.address.line2,
                                                userKycDetails.latestPan.address.house,
                                                userKycDetails.latestPan.address.street,
                                                userKycDetails.latestPan.address.landmark,
                                                userKycDetails.latestPan.address.post_office,
                                                userKycDetails.latestPan.address.sub_district,
                                                userKycDetails.latestPan.address.loc,
                                                userKycDetails.latestPan.address.city,
                                                userKycDetails.latestPan.address.district,
                                                userKycDetails.latestPan.address.vtc,
                                                userKycDetails.latestPan.address.state,
                                                userKycDetails.latestPan.address.country,
                                              ]
                                                .filter(Boolean)
                                                .join(', ')
                                              : "-------"
                                          }
                                          {" "} {userKycDetails?.latestAadhar?.zip}
                                        </p>

                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="fc_details mt-3" >
                                  <h5> Aadhar Card Details</h5>
                                  <div className="row g-4 ">
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label> Masked Aadhar Number  </label>
                                        <p>
                                          {userKycDetails?.latestPan?.maskedAadhaarNumber || "-------"} {" "}
                                          {
                                            userKycDetails?.latestPan?.name &&
                                            <i className={userKycDetails?.latestPan?.verified ? "ri-verified-badge-fill text-success" : "ri-error-warning-fill text-danger"}></i>
                                          }
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label> Full Name </label>
                                        <p>
                                          {userKycDetails?.latestAadhar?.full_name || "-------"} {" "}
                                          {
                                            userKycDetails?.latestPan?.name &&
                                            <i className={userKycDetails?.latestPan?.verified ? "ri-verified-badge-fill text-success" : "ri-error-warning-fill text-danger"}></i>

                                          }
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label> Care of  </label>
                                        <p>  {userKycDetails?.latestAadhar?.care_of || "-------"}  </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label> Gender  </label>
                                        <p>{userKycDetails?.latestAadhar?.gender || "-------"} </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label> Date of Birth  </label>
                                        <p>{userKycDetails?.latestAadhar?.dob || "-------"}</p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6">
                                      <div className="label-group">
                                        <label> Address  </label>
                                        <p>
                                          {
                                            userKycDetails?.latestAadhar?.address
                                              ? [
                                                userKycDetails.latestAadhar?.address.house,
                                                userKycDetails.latestAadhar?.address.street,
                                                userKycDetails.latestAadhar?.address.landmark,
                                                userKycDetails.latestAadhar?.address.post_office,
                                                userKycDetails.latestAadhar?.address.sub_district,
                                                userKycDetails.latestAadhar?.address.loc,
                                                userKycDetails.latestAadhar?.address.district,
                                                userKycDetails.latestAadhar?.address.vtc,

                                                userKycDetails.latestAadhar?.address.state,

                                                userKycDetails.latestAadhar?.address.country,
                                              ]
                                                .filter(Boolean)
                                                .join(', ')
                                              : "-------"
                                          }
                                          {" "} {userKycDetails?.latestAadhar?.zip}
                                        </p>

                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label>  Aadhar Verification date  </label>
                                        <p>
                                          {userKycDetails?.latestAadhar?.createdAt
                                            ? moment(userKycDetails.latestAadhar?.createdAt).format('Do MMMM YYYY,h:mm:ss a')
                                            : "-------"
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3" >
                                <h5> Rejection Remark </h5>
                                <div className="re_row" >

                                  {
                                    userKycDetails?.stepRejectedData?.length > 0 ? (
                                      userKycDetails?.stepRejectedData?.map((item, index) => (
                                        <div className="rm_col" key={index}>
                                          <div className="row">
                                            <div className="col-6">
                                              <h5>
                                                <span>{item?.admin_name}</span>
                                                <small>{item?.admin_id}</small>
                                              </h5>
                                            </div>
                                            <div className="col-6">
                                              <h5 className="text-end">
                                                <small>Date & Time</small>
                                                {item?.timestamp
                                                  ? (
                                                    <>
                                                      {moment(item?.timestamp).format('Do MMMM YYYY')}
                                                      <br />
                                                      {moment(item?.timestamp).format('h:mm:ss a')}
                                                    </>
                                                  )
                                                  : "-------"}
                                              </h5>
                                            </div>
                                          </div>
                                          <p>
                                            <b>Remark : </b>
                                            {item?.reason?.remarks || "-------"}
                                          </p>
                                        </div>
                                      ))
                                    ) :
                                      <div className="d-flex justify-content-center align-items-center">
                                        <img src="/assets/img/not_found.svg" className="img-fluid mt-5" width="200" alt="" />
                                      </div>
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="att_row mt-5">
                              <h5> Pan Verification Attempts ({userKycDetails?.otherPan?.length}) </h5>
                              <div className="att_card">
                                <div className="nav nav-tabs nav-tabs-custom px-2">
                                  <Tabs
                                    className="nav nav-tabs nav-tabs-custom px-2"
                                    activeTab={activeTabPanAttemp} onTabClick={handleTabClickPanAttemp}
                                  >
                                    {userKycDetails?.otherPan?.map((_, index) => (
                                      <Tab
                                        className="nav-link"
                                      >
                                        Attempt {userKycDetails?.otherPan?.length - index}
                                      </Tab>
                                    ))}
                                  </Tabs>


                                </div>
                                <div className="att_col">
                                  {panAttemps ? (
                                    <div className="row g-4">
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label>Pan Card Number</label>
                                          <p>
                                            {panAttemps.pan || "-------"}{' '}
                                            {panAttemps.verified ? (
                                              <i className="ri-verified-badge-fill text-success"></i>
                                            ) : (
                                              <i className="ri-error-warning-fill text-danger"></i>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label>Full Name</label>
                                          <p>{panAttemps.name || "-------"}</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label>Gender</label>
                                          <p>{panAttemps.gender || "-------"}</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label>Date of Birth</label>
                                          <p>
                                            {new Date(panAttemps.dateOfBirth).toLocaleDateString() || "-------"}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label>Address</label>
                                          <p>
                                            {
                                              rejectedPan?.address
                                                ? [
                                                  rejectedPan?.address.line1,
                                                  rejectedPan?.address.line2,
                                                  rejectedPan?.address.street,
                                                  rejectedPan?.address.pincode,
                                                  rejectedPan?.address.city,
                                                  rejectedPan?.address.state,
                                                  rejectedPan?.address.district,

                                                  rejectedPan?.address.country,
                                                ]
                                                  .filter(Boolean)
                                                  .join(', ')
                                                : "-------"
                                            }
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label>Linked Aadhar Number</label>
                                          <p>{panAttemps.maskedAadhaarNumber || "-------"}</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label>Pan Verification Date</label>
                                          <p>

                                            {panAttemps.createdAt
                                              ? moment(panAttemps.createdAt).format('Do MMMM YYYY,h:mm:ss a')
                                              : "-------"
                                            }

                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label>AML Status</label>
                                          <p>{panAttemps.verified ? 'Verified' : 'Unverified'}</p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="fc_details d-flex justify-content-center align-items-center">
                                      <img src="/assets/img/not_found.svg" className="img-fluid mt-5" width="200" alt="" />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="att_row mt-5">
                              <h5>Rejected History</h5>
                              <div className="att_card">
                                {/* Tabs */}
                                <div className="nav nav-tabs nav-tabs-custom px-2">

                                  <Tabs
                                    className="nav nav-tabs nav-tabs-custom px-2"
                                    activeTab={activeTabRejectedPan} onTabClick={handleTabClickRejectedPan}
                                  >
                                    {userKycDetails?.otherPan?.map((attempt, index) => (
                                      <Tab
                                        className="nav-link"
                                      >
                                        {attempt.admin_rejected_date
                                          ? moment(attempt.admin_rejected_date).format('Do MMMM YYYY, h:mm:ss a')
                                          : "-------"
                                        }
                                      </Tab>
                                    ))}
                                  </Tabs>

                                </div>

                                {/* Selected Tab Details */}
                                <div className="att_col">
                                  {rejectedPan || rejectedAadhaar ? (
                                    <>
                                      <div className="fc_details">
                                        <h5>Pan Card Details</h5>
                                        <div className="row g-4">
                                          <div className="col-lg-6 col-xl-3">
                                            <div className="label-group">
                                              <label>Pan Card Number</label>
                                              <p>
                                                {rejectedPan?.pan || "-------"}{' '}
                                                {rejectedPan?.pan && rejectedPan?.verified ? (
                                                  <i className="ri-verified-badge-fill text-success"></i>
                                                ) : (
                                                  <i className="ri-error-warning-fill text-danger"></i>
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-3">
                                            <div className="label-group">
                                              <label>Full Name</label>
                                              <p>{rejectedPan?.name || "-------"}</p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-3">
                                            <div className="label-group">
                                              <label>Gender</label>
                                              <p>{rejectedPan?.gender || "-------"}</p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-3">
                                            <div className="label-group">
                                              <label>Date of Birth</label>
                                              <p>
                                                {rejectedPan?.dateOfBirth ?
                                                  new Date(rejectedPan?.dateOfBirth).toLocaleDateString()
                                                  : "-------"
                                                }
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-3">
                                            <div className="label-group">
                                              <label>Address</label>
                                              <p>

                                                {
                                                  rejectedPan?.address
                                                    ? [
                                                      rejectedPan?.address.line1,
                                                      rejectedPan?.address.line2,
                                                      rejectedPan?.address.street,
                                                      rejectedPan?.address.pincode,
                                                      rejectedPan?.address.city,
                                                      rejectedPan?.address.state,
                                                      rejectedPan?.address.district,

                                                      rejectedPan?.address.country,
                                                    ]
                                                      .filter(Boolean)
                                                      .join(', ')
                                                    : "-------"
                                                }

                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-3">
                                            <div className="label-group">
                                              <label>Linked Aadhar Number</label>
                                              <p>{rejectedPan?.maskedAadhaarNumber || "-------"}</p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-3">
                                            <div className="label-group">
                                              <label>Pan Verification Date</label>
                                              <p>
                                                {rejectedPan?.createdAt
                                                  ? moment(rejectedPan?.createdAt).format('Do MMMM YYYY, h:mm:ss a')
                                                  : "-------"
                                                }
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-3">
                                            <div className="label-group">
                                              <label>AML Status</label>
                                              <p>{rejectedPan?.verified ? 'Verified' : 'Unverified'}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {/* Aadhar Card Details */}
                                      <div className="fc_details mt-3">
                                        <h5>Aadhar Card Details</h5>
                                        <div className="row g-4">

                                          <div className="col-lg-6 col-xl-3">
                                            <div className="label-group">
                                              <label>Full Name</label>
                                              <p>{rejectedAadhaar?.full_name || "-------"}</p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-3">
                                            <div className="label-group">
                                              <label>Care of</label>
                                              <p>{rejectedAadhaar?.care_of || "-------"}</p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-3">
                                            <div className="label-group">
                                              <label>Gender</label>
                                              <p>{rejectedAadhaar?.gender || "-------"}</p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-3">
                                            <div className="label-group">
                                              <label>Date of Birth</label>
                                              <p>{rejectedAadhaar?.dob || "-------"}</p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-6">
                                            <div className="label-group">
                                              <label>Address</label>
                                              <p>
                                                {
                                                  rejectedAadhaar?.address
                                                    ? [
                                                      rejectedAadhaar?.address.street,
                                                      rejectedAadhaar?.address.landmark,
                                                      rejectedAadhaar?.address.post_office,
                                                      rejectedAadhaar?.address.sub_district,
                                                      rejectedAadhaar?.address.state,
                                                      rejectedAadhaar?.address.country,
                                                    ]
                                                      .filter(Boolean)
                                                      .join(', ')
                                                    : "-------"
                                                }
                                                {" "}{rejectedAadhaar?.zip}

                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-3">
                                            <div className="label-group">
                                              <label>Aadhar Verification Date</label>
                                              <p>
                                                {
                                                  rejectedAadhaar?.createdAt ?
                                                    moment(rejectedAadhaar?.createdAt).format('Do MMMM YYYY, h:mm:ss a')
                                                    : "-------"
                                                }
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="fc_details d-flex justify-content-center align-items-center">
                                      <img src="/assets/img/not_found.svg" className="img-fluid mt-5" width="200" alt="" />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="puk_row puk_row_fixed" >
                              <button className="btn btn-md btn-black px-4" type="button" onClick={() => handleClickStep("step2")}>
                                Next
                              </button>
                              {
                                mergedData?.kycStatus !== "not-submitted" &&
                                mergedData?.kycStatus !== "rejected" &&
                                mergedData?.kycStatus !== "re-kyc" &&
                                filterSteps?.kycStep !== kycStep &&
                                (
                                  <button
                                    className="btn btn-md btn-danger px-4"
                                    type="button"
                                    onClick={() => setShowModel(true)}>
                                    Reject Reamrk
                                  </button>
                                )
                              }
                            </div>
                          </div>




                          {/* step 2 */}
                          <div className={`step_1 ${kycStep === "step2" ? "" : "d-none"}`} >
                            <div className="d-flex align-items-center justify-content-between " >
                              <h4>   Check Liveness / Face Match </h4>
                            </div>

                            <div className="ud_details">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="ud_img">
                                    <label> Aadhar Card Image </label>
                                    <div className="img">
                                      {
                                        !userKycDetails?.latestFaceMatchData?.facematch_image2 ?
                                          < img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                                          :
                                          <SlideshowLightbox className="ratio ratio-16x9">
                                            <img src={userKycDetails?.latestFaceMatchData?.facematch_image2 || 'assets/img/blank_img.png'} className="img-fluid w-100" alt=""
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = 'assets/img/blank_img.png';
                                              }}
                                            />
                                          </SlideshowLightbox>
                                      }
                                      <h6>
                                        <small> Update on:  </small>
                                        {userKycDetails?.latestFaceMatchData?.updatedAt
                                          ? moment(userKycDetails.latestFaceMatchData.updatedAt).format('Do MMMM YYYY, h:mm:ss a')
                                          : "-------"}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="ud_img">
                                    <label> Liveness Selfie </label>
                                    <div className="img">

                                      {
                                        !userKycDetails?.latestLivenessData?.imageURL ?
                                          < img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                                          :
                                          <SlideshowLightbox className="ratio ratio-16x9">
                                            <img src={userKycDetails?.latestLivenessData?.imageURL || 'assets/img/blank_img.png'} className="img-fluid w-100" alt=""
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = 'assets/img/blank_img.png';
                                              }}
                                            />
                                          </SlideshowLightbox>
                                      }
                                      <h6>

                                        <small> Update on:  </small>
                                        {userKycDetails?.latestFaceMatchData?.updatedAt
                                          ? moment(userKycDetails.latestFaceMatchData?.updatedAt).format('Do MMMM YYYY, h:mm:ss a')
                                          : "-------"}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="fc_details mt-4" >
                                <div className="d-flex align-items-center justify-content-between " >
                                  <h5>   Liveness Details  </h5>
                                  <div className="label-group d-flex align-items-center">
                                    <label className="me-2" > IP Address: </label>
                                    <p>
                                      <small className="d-flex align-items-center" >{userKycDetails?.latestLivenessData?.geoDetails?.apiResponse?.result?.ipAddress || "-------"}
                                        {
                                         userKycDetails?.latestLivenessData?.geoDetails?.apiResponse?.result?.ipAddress &&
                                          <i className="ri-file-copy-fill ms-1 link cursor-pointer"
                                            onClick={() => { copy(userKycDetails?.latestLivenessData?.geoDetails?.apiResponse?.result?.ipAddress); }} >

                                          </i>
                                        }
                                      </small>
                                    </p>
                                  </div>
                                </div>
                                <div className="row gx-5 ">
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group d-flex align-items-center justify-content-between my-1">
                                      <label> Live Face Status </label>
                                      <p>
                                        <small className="text-success" >
                                          {latestLivenessDetails?.liveFace?.value || "-------"}
                                          {
                                            latestLivenessDetails?.liveFace?.value === "yes" &&
                                            <i className="ri-checkbox-circle-fill fw-normal"></i>
                                          }
                                        </small>
                                      </p>
                                    </div>
                                    <div className="label-group d-flex align-items-center justify-content-between my-1">
                                      <label> Confidance   </label> <p> <small> {latestLivenessDetails?.liveFace?.confidence || "-------"} </small> </p>
                                    </div>
                                    <div className="label-group d-flex align-items-center justify-content-between my-1">
                                      <label> Score   </label> <p> <small>
                                        {latestLivenessDetails?.liveFace?.score
                                          ? `${latestLivenessDetails?.liveFace?.score}%`
                                          : "-------"}
                                      </small> </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group d-flex align-items-center justify-content-between my-1">
                                      <label> Multiple Face Status </label> <p> <small> {latestLivenessDetails?.qualityChecks?.multipleFaces?.value || "-------"} </small> </p>
                                    </div>
                                    <div className="label-group d-flex align-items-center justify-content-between my-1">
                                      <label>   Confidance   </label> <p> <small> {latestLivenessDetails?.qualityChecks?.multipleFaces?.confidence || "-------"} </small> </p>
                                    </div>
                                    <div className="label-group d-flex align-items-center justify-content-between my-1">
                                      <label> Multiple Score   </label>
                                      <p>
                                        <small>
                                          {latestLivenessDetails?.qualityChecks?.multipleFaces?.score !== undefined &&
                                            latestLivenessDetails?.qualityChecks?.multipleFaces?.score !== null
                                            ? `${latestLivenessDetails?.qualityChecks?.multipleFaces?.score}%`
                                            : "-------"}
                                        </small>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group d-flex align-items-center justify-content-between my-1">
                                      <label> Blur  Status </label> <p> <small> {latestLivenessDetails?.qualityChecks?.blur?.value || "-------"}</small> </p>
                                    </div>
                                    <div className="label-group d-flex align-items-center justify-content-between my-1">
                                      <label> Blur  Confidance   </label> <p> <small>{latestLivenessDetails?.qualityChecks?.blur?.confidence || "-------"}</small> </p>
                                    </div>
                                    <div className="label-group d-flex align-items-center justify-content-between my-1">
                                      <label> Blur Score   </label>
                                      <p>
                                        <small>
                                          {latestLivenessDetails?.qualityChecks?.blur?.score
                                            ? `${latestLivenessDetails?.qualityChecks?.blur?.score}%`
                                            : "-------"}
                                        </small>
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <hr />
                                <h5 className="mb-3" > geo Details</h5>
                                <div className="row g-4 ">
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group  ">
                                      <label> Latitude & Longitude </label>

                                      <p>
                                        <small>
                                          {latestGeoDetails
                                            ? latestGeoDetails?.lat + "/" + latestGeoDetails?.lon
                                            : "-------"}
                                        </small>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-2">
                                    <div className="label-group  ">
                                      <label> Country </label>
                                      <p>  <small>{latestGeoDetails?.country || "-------"}</small>  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-2">
                                    <div className="label-group  ">
                                      <label> State </label>
                                      <p>  <small>{latestGeoDetails?.regionName || "-------"}</small>  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-2">
                                    <div className="label-group  ">
                                      <label>  City</label>
                                      <p>  <small>{latestGeoDetails?.city || "-------"}</small>  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-2">
                                    <div className="label-group  ">
                                      <label>  ISP</label>
                                      <p>  <small>{latestGeoDetails?.isp || "-------"}</small>  </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="fc_details mt-4" >
                                <div className="d-flex align-items-center justify-content-between " >
                                  <h5>   Face Match Details  </h5>
                                </div>
                                <div className="row gx-5 ">
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group d-flex align-items-center justify-content-between">
                                      <label> Face Match Status </label>
                                      <p>
                                        <small className={userKycDetails?.latestFaceMatchData?.action === "pass" ? "text-success" : "text-danger"} >
                                          {userKycDetails?.latestFaceMatchData?.action || "-------"} {" "}
                                          {
                                            userKycDetails?.latestFaceMatchData?.action === "pass" &&
                                            <i className="ri-checkbox-circle-fill fw-normal"></i>
                                          }
                                        </small>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group d-flex align-items-center justify-content-between">
                                      <label>   Confidance   </label>
                                      <p> <small>{userKycDetails?.latestFaceMatchData?.facematch_confidence || "-------"}</small> </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group d-flex align-items-center justify-content-between">
                                      <label>   Score   </label>
                                      <p>
                                        <small>
                                          {userKycDetails?.latestFaceMatchData?.facematch_score
                                            ? `${userKycDetails?.latestFaceMatchData?.facematch_score}%`
                                            : "-------"}
                                        </small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>


                            <div className="row lns_row  mt-5" >
                              <div className="col-lg-9" >

                                {/* Liveness Attempts Start */}

                                <div className="att_row mt-0" >
                                  <div className="d-flex align-items-center justify-content-between manul_accordian cursor-pointer" >
                                    <h5 className="mb-0" > Liveness Attempts ({userKycDetails?.livenessAttempts?.length}) </h5>
                                    <span className="btn btn-icon" onClick={() => setIsShowLiveAtt((prevState) => !prevState)}>
                                      <i className={`ri-arrow-${isShowLiveAtt ? "down-s-line" : "up-s-line"}`}></i>
                                    </span>
                                  </div>
                                  {/* tab */}
                                  <div className={`att_card mt-4 ${isShowLiveAtt ? "d-none" : ""}`}>
                                    <div className="nav nav-tabs nav-tabs-custom px-2">
                                      <Tabs
                                        className="nav nav-tabs nav-tabs-custom px-2"
                                        activeTab={activeTabLiveness} onTabClick={handleTabClick}
                                      >
                                        {userKycDetails?.livenessAttempts?.map((_, index) => (
                                          <Tab
                                            className="nav-link"
                                          >
                                            Attempt {userKycDetails?.livenessAttempts?.length - index}
                                          </Tab>
                                        ))}
                                      </Tabs>
                                    </div>
                                    <div className="att_col">
                                      {livenessAttemps ? (
                                        <div className="fc_details " >
                                          <div className="d-flex align-items-center justify-content-between ">
                                            <h5 className="mb-0" >   Liveness Details  </h5>
                                            <div className="label-group d-flex align-items-center">
                                              <label className="me-2"> IP Address: </label>
                                              <p>
                                                <small className="d-flex align-items-center"> {livenessAttemps?.geoDetails?.apiResponse?.result?.ipAddress}
                                                  <i className="ri-file-copy-fill ms-1 link cursor-pointer" onClick={() => { copy(livenessAttemps?.geoDetails?.apiResponse?.result?.ipAddress); }} ></i>
                                                </small>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="row g-4 align-items-center">
                                            <div className="col-lg-8" >
                                              <div className="row gx-4 ">
                                                <div className="col-lg-6 col-xl-4">
                                                  <div className="label-group my-3">
                                                    <label> Live Face Status </label>

                                                    <p>
                                                      <small className="text-success" >
                                                        {livenessAttemps?.apiResponse?.result?.details?.liveFace?.value}
                                                        {
                                                          livenessAttemps?.apiResponse?.result?.details?.liveFace?.value === "yes" &&
                                                          <i className="ri-checkbox-circle-fill fw-normal"></i>
                                                        }
                                                      </small>
                                                    </p>
                                                  </div>
                                                  <div className="label-group my-3">
                                                    <label> Live Face Confidance   </label> <p> <small>  {livenessAttemps?.apiResponse?.result?.details?.liveFace?.confidence}</small> </p>
                                                  </div>
                                                  <div className="label-group my-3">
                                                    <label> Live Face Score   </label>
                                                    <p>
                                                      <small>
                                                        {livenessAttemps?.apiResponse?.result?.details?.liveFace?.score
                                                          ? `${livenessAttemps?.apiResponse?.result?.details?.liveFace?.score}%`
                                                          : "-------"}
                                                      </small>
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-4">
                                                  <div className="label-group my-3">
                                                    <label> Multiple Face Status </label> <p> <small>{livenessAttemps?.apiResponse?.result?.details?.qualityChecks?.multipleFaces?.value}</small> </p>
                                                  </div>
                                                  <div className="label-group my-3">
                                                    <label>  Multiple Face Confidance   </label> <p> <small>{livenessAttemps?.apiResponse?.result?.details?.qualityChecks?.multipleFaces?.confidence}</small> </p>
                                                  </div>
                                                  <div className="label-group my-3">
                                                    <label> Multiple Face Score   </label>
                                                    <p>
                                                      <small>

                                                        {livenessAttemps?.apiResponse?.result?.details?.qualityChecks?.multipleFaces?.score !== undefined &&
                                                          livenessAttemps?.apiResponse?.result?.details?.qualityChecks?.multipleFaces?.score !== null
                                                          ? `${livenessAttemps?.apiResponse?.result?.details?.qualityChecks?.multipleFaces?.score}%`
                                                          : "-------"}

                                                      </small>
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-4">
                                                  <div className="label-group my-3">
                                                    <label> Blur   Status </label> <p> <small> {livenessAttemps?.apiResponse?.result?.details?.qualityChecks?.blur?.value} </small> </p>
                                                  </div>
                                                  <div className="label-group my-3">
                                                    <label> Blur  Confidance   </label> <p> <small>{livenessAttemps?.apiResponse?.result?.details?.qualityChecks?.blur?.confidence}</small> </p>
                                                  </div>
                                                  <div className="label-group my-3">
                                                    <label> Blur Score   </label>
                                                    <p>
                                                      <small>
                                                        {livenessAttemps?.apiResponse?.result?.details?.qualityChecks?.blur?.score
                                                          ? `${livenessAttemps?.apiResponse?.result?.details?.qualityChecks?.blur?.score}%`
                                                          : "-------"}
                                                      </small>
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-7">
                                                  <div className="label-group my-2">
                                                    <label> Date & Time </label>
                                                    <p>
                                                      <small>{livenessAttemps?.createdAt ?
                                                        moment(livenessAttemps?.createdAt).format('Do MMMM YYYY, h:mm:ss a')
                                                        : "-------"}</small>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-lg-4" >
                                              <div className="ud_img">
                                                <label className="mb-1 text-xs mt-2" > Liveness Selfie </label>
                                                <div className="img p-1">

                                                  {
                                                    (
                                                      loader ? (
                                                        <div className="ratio ratio-16x9" >
                                                          <div className="d-flex justify-content-center align-items-center">
                                                            Loading....?
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <SlideshowLightbox className="ratio ratio-16x9">
                                                          <img
                                                            src={livenessAttemps?.imageURL || 'assets/img/blank_img.png'}
                                                            className="img-fluid w-100"
                                                            alt="Aadhar"
                                                          />
                                                        </SlideshowLightbox>

                                                      )
                                                    )
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                          <h5 className="mb-3" > geo Details</h5>
                                          <div className="row g-4 ">
                                            <div className="col-lg-6 col-xl-4">
                                              <div className="label-group  ">
                                                <label> Latitude & Longitude </label>
                                                <p>
                                                  <small>
                                                    {livenessAttemps?.geoDetails?.apiResponse?.result?.geoDetails
                                                      ? livenessAttemps?.geoDetails?.apiResponse?.result?.geoDetails?.lat + "/" + livenessAttemps?.geoDetails?.apiResponse?.result?.geoDetails?.lon
                                                      : "-------"}
                                                  </small>
                                                </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-2">
                                              <div className="label-group  ">
                                                <label> Country </label>
                                                <p>  <small> {livenessAttemps?.geoDetails?.apiResponse?.result?.geoDetails?.country}</small>  </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-2">
                                              <div className="label-group  ">
                                                <label> State </label>
                                                <p>  <small> {livenessAttemps?.geoDetails?.apiResponse?.result?.geoDetails?.regionName}</small>  </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-2">
                                              <div className="label-group  ">
                                                <label>  City</label>
                                                <p>  <small> {livenessAttemps?.geoDetails?.apiResponse?.result?.geoDetails?.city}</small>  </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-2">
                                              <div className="label-group  ">
                                                <label>  ISP</label>
                                                <p>  <small> {livenessAttemps?.geoDetails?.apiResponse?.result?.geoDetails?.isp}</small>  </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="fc_details d-flex justify-content-center align-items-center">
                                          <img src="/assets/img/not_found.svg" className="img-fluid mt-5" width="200" alt="" />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {/* Facematch Attempts Start */}

                                <div className="att_row mt-4" >
                                  <div className="d-flex align-items-center justify-content-between manul_accordian cursor-pointer" >
                                    <h5 className="mb-0" > Facematch Attempts ({userKycDetails?.faceMatchAttempts?.length}) </h5>
                                    <span className="btn btn-icon" onClick={() => setIsShowFaceAtt((prevState) => !prevState)}>
                                      <i className={`ri-arrow-${isShowFaceAtt ? "down-s-line" : "up-s-line"}`}></i>
                                    </span>
                                  </div>
                                  {/* tab */}
                                  <div className={`att_card mt-4 ${isShowFaceAtt ? "d-none" : ""}`}>
                                    <div className="nav nav-tabs nav-tabs-custom px-2">

                                      <Tabs
                                        className="nav nav-tabs nav-tabs-custom px-2"
                                        activeTab={activeTabFaceMatch} onTabClick={handleTabClickFaceMatch}
                                      >
                                        {userKycDetails?.faceMatchAttempts?.map((_, index) => (
                                          <Tab
                                            className="nav-link"
                                          >
                                            Attempt {userKycDetails?.faceMatchAttempts?.length - index}
                                          </Tab>
                                        ))}
                                      </Tabs>

                                    </div>
                                    <div className="att_col">
                                      {facematchAttemps ? (
                                        <div className="fc_details " >
                                          <div className="d-flex align-items-center justify-content-between ">
                                            <h5 className="mb-0" > Facematch Details  </h5>
                                          </div>
                                          <div className="row g-2 align-items-center">
                                            <div className="col-lg-8" >
                                              <div className="row gx-2 ">
                                                <div className="col-lg-6 col-xl-7">
                                                  <div className="label-group my-2">
                                                    <label> Face Match Status </label>
                                                    <p>
                                                      <span /* className="text-danger" */ >
                                                        {facematchAttemps?.facematch_value}
                                                      </span>
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-5">
                                                  <div className="label-group my-2">
                                                    <label> Confidence </label>
                                                    <p>
                                                      {facematchAttemps?.facematch_confidence || "-------"}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-7">
                                                  <div className="label-group my-2">
                                                    <label> Created At </label>
                                                    <p>
                                                      <small>{facematchAttemps?.createdAt ?
                                                        moment(facematchAttemps?.createdAt).format('Do MMMM YYYY, h:mm:ss a')
                                                        : "-------"}</small>
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-5">
                                                  <div className="label-group my-2">
                                                    <label>Score </label>
                                                    <p>

                                                      {facematchAttemps?.facematch_score
                                                        ? `${facematchAttemps?.facematch_score}%`
                                                        : "-------"}

                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-lg-4" >
                                              <div className="ud_img">
                                                <label className="mb-1 text-xs mt-2" > Aadhar Image</label>
                                                <div className="img p-1">

                                                  {loader ? (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                      Loading....?
                                                    </div>
                                                  ) :
                                                    <SlideshowLightbox className="ratio ratio-16x9">
                                                      <img src={facematchAttemps?.facematch_image1 || 'assets/img/blank_img.png'} className="img-fluid w-100" alt=""
                                                        onError={(e) => {
                                                          e.target.onerror = null;
                                                          e.target.src = 'assets/img/blank_img.png';
                                                        }}
                                                      />
                                                    </SlideshowLightbox>
                                                  }

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                          <div className="mt-2" >
                                            <div className="d-flex align-items-center justify-content-between ">
                                              <h5 className="mb-0" >   Liveness Details  </h5>
                                              <div className="label-group d-flex align-items-center">
                                                <label className="me-2"> IP Address: </label>
                                                <p>
                                                  <small className="d-flex align-items-center"> {facematchAttemps?.livenessId?.apiResponse?.ipAddress}
                                                    <i className="ri-file-copy-fill ms-1 link cursor-pointer" onClick={() => { copy(facematchAttemps?.livenessId?.ipAddress); }} ></i>
                                                  </small>
                                                </p>
                                              </div>
                                            </div>
                                            <div className="row g-4 align-items-center">
                                              <div className="col-lg-8" >
                                                <div className="row gx-4 ">
                                                  <div className="col-lg-6 col-xl-4">
                                                    <div className="label-group my-3">
                                                      <label> Live Face Status </label>

                                                      <p>
                                                        <small className="text-success" >
                                                          {facematchAttemps?.livenessId?.apiResponse?.result?.details?.liveFace?.value}
                                                          {
                                                            facematchAttemps?.livenessId?.apiResponse?.result?.details?.liveFace?.value === "yes" &&
                                                            <i className="ri-checkbox-circle-fill fw-normal"></i>
                                                          }
                                                        </small>
                                                      </p>
                                                    </div>
                                                    <div className="label-group my-3">
                                                      <label> Live Face Confidance   </label> <p> <small>  {facematchAttemps?.livenessId?.apiResponse?.result?.details?.liveFace?.confidence}</small> </p>
                                                    </div>
                                                    <div className="label-group my-3">
                                                      <label> Live Face Score   </label>
                                                      <p>
                                                        <small>
                                                          {facematchAttemps?.livenessId?.apiResponse?.result?.details?.liveFace?.score
                                                            ? `${facematchAttemps?.livenessId?.apiResponse?.result?.details?.liveFace?.score}%`
                                                            : "-------"}
                                                        </small>
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-6 col-xl-4">
                                                    <div className="label-group my-3">
                                                      <label> Multiple Face Status </label> <p> <small>{facematchAttemps?.livenessId?.apiResponse?.result?.details?.qualityChecks?.multipleFaces?.value}</small> </p>
                                                    </div>
                                                    <div className="label-group my-3">
                                                      <label>  Multiple Face Confidance   </label> <p> <small>{facematchAttemps?.livenessId?.apiResponse?.result?.details?.qualityChecks?.multipleFaces?.confidence}</small> </p>
                                                    </div>
                                                    <div className="label-group my-3">
                                                      <label> Multiple Face Score   </label>
                                                      <p>
                                                        <small>
                                                          {facematchAttemps?.livenessId?.apiResponse?.result?.details?.qualityChecks?.multipleFaces?.score !== undefined &&
                                                            facematchAttemps?.livenessId?.apiResponse?.result?.details?.qualityChecks?.multipleFaces?.score !== null
                                                            ? `${facematchAttemps?.livenessId?.apiResponse?.result?.details?.qualityChecks?.multipleFaces?.score}%`
                                                            : "-------"}
                                                        </small>
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-6 col-xl-4">
                                                    <div className="label-group my-3">
                                                      <label> Blur Status </label> <p> <small> {facematchAttemps?.livenessId?.apiResponse?.result?.details?.qualityChecks?.blur?.value} </small> </p>
                                                    </div>
                                                    <div className="label-group my-3">
                                                      <label> Blur Confidance   </label> <p> <small>{facematchAttemps?.livenessId?.apiResponse?.result?.details?.qualityChecks?.blur?.confidence}</small> </p>
                                                    </div>
                                                    <div className="label-group my-3">
                                                      <label> Blur Score   </label>
                                                      <p>
                                                        <small>
                                                          {facematchAttemps?.livenessId?.apiResponse?.result?.details?.qualityChecks?.blur?.score
                                                            ? `${facematchAttemps?.livenessId?.apiResponse?.result?.details?.qualityChecks?.blur?.score}%`
                                                            : "-------"}
                                                        </small>
                                                      </p>
                                                    </div>
                                                  </div>

                                                  <div className="col-lg-6 col-xl-7">
                                                    <div className="label-group my-2">
                                                      <label> Created At </label>
                                                      <p>
                                                        <small>{facematchAttemps?.createdAt ?
                                                          moment(facematchAttemps?.createdAt).format('Do MMMM YYYY, h:mm:ss a')
                                                          : "-------"}</small>
                                                      </p>
                                                    </div>
                                                  </div>

                                                </div>
                                              </div>
                                              <div className="col-lg-4" >
                                                <div className="ud_img">
                                                  <label className="mb-1 text-xs mt-2" > Liveness Selfie </label>
                                                  <div className="img p-1">
                                                    {loader ? (
                                                      <div className="d-flex justify-content-center align-items-center">
                                                        Loading....?
                                                      </div>
                                                    ) :
                                                      <SlideshowLightbox className="ratio ratio-16x9">
                                                        <img src={facematchAttemps?.facematch_image2 || 'assets/img/blank_img.png'} className="img-fluid w-100" alt=""
                                                          onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = 'assets/img/blank_img.png';
                                                          }}
                                                        />
                                                      </SlideshowLightbox>
                                                    }

                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <hr />
                                            <h5 className="mb-3" > geo Details</h5>
                                            <div className="row g-4 ">
                                              <div className="col-lg-6 col-xl-4">
                                                <div className="label-group  ">
                                                  <label> Latitude & Longitude </label>
                                                  <p>
                                                    <small>
                                                      {livenessAttemps?.apiResponse?.result?.details?.geoDetails?.lat
                                                        ? livenessAttemps?.apiResponse?.result?.details?.geoDetails?.lat + "/" + livenessAttemps?.apiResponse?.result?.details?.geoDetails?.lon
                                                        : "-------"}
                                                    </small>
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="col-lg-6 col-xl-2">
                                                <div className="label-group  ">
                                                  <label> Country </label>
                                                  <p>  <small> {livenessAttemps?.apiResponse?.result?.details?.geoDetails?.country}</small>  </p>
                                                </div>
                                              </div>
                                              <div className="col-lg-6 col-xl-2">
                                                <div className="label-group  ">
                                                  <label> State </label>
                                                  <p>  <small> {livenessAttemps?.apiResponse?.result?.details?.geoDetails?.regionName}</small>  </p>
                                                </div>
                                              </div>
                                              <div className="col-lg-6 col-xl-2">
                                                <div className="label-group  ">
                                                  <label>  City</label>
                                                  <p>  <small> {livenessAttemps?.apiResponse?.result?.details?.geoDetails?.city}</small>  </p>
                                                </div>
                                              </div>
                                              <div className="col-lg-6 col-xl-2">
                                                <div className="label-group  ">
                                                  <label>  ISP</label>
                                                  <p>  <small> {livenessAttemps?.apiResponse?.result?.details?.geoDetails?.isp}</small>  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="fc_details d-flex justify-content-center align-items-center">
                                          <img src="/assets/img/not_found.svg" className="img-fluid mt-5" width="200" alt="" />
                                        </div>
                                      )}
                                    </div>
                                  </div>


                                </div>

                                <div className="att_row mt-4" >
                                  <div className="d-flex align-items-center justify-content-between manul_accordian cursor-pointer" >
                                    <h5 className="mb-0" > Rejected History</h5>
                                    <span className="btn btn-icon" onClick={() => setIsShowRejectedHist((prevState) => !prevState)}>
                                      <i className={`ri-arrow-${isShowRejectedHist ? "down-s-line" : "up-s-line"}`}></i>
                                    </span>
                                  </div>
                                  {/* tab */}
                                  <div className={`att_card ${isShowRejectedHist ? "d-none" : ""}`} >

                                    <div className="nav nav-tabs nav-tabs-custom px-2">

                                      <Tabs
                                        className="nav nav-tabs nav-tabs-custom px-2"
                                        activeTab={activeTabLivenessRejected} onTabClick={handleTabClickRejectedLiveness}
                                      >
                                        {userKycDetails?.rejectedHistory?.map((attempt, index) => (
                                          <Tab
                                            className="nav-link"
                                          >
                                            {attempt.admin_rejected_date
                                              ? moment(attempt.admin_rejected_date).format('Do MMMM YYYY, h:mm:ss a')
                                              : "-------"
                                            }
                                          </Tab>
                                        ))}
                                      </Tabs>

                                    </div>

                                    <div className="att_col " >
                                      {rejectedLiveness ? (
                                        <div className="fc_details " >
                                          <div className="d-flex align-items-center justify-content-between ">
                                            <h5 className="mb-0" > Facematch Details  </h5>
                                          </div>
                                          <div className="row g-2 align-items-center">
                                            <div className="col-lg-8" >
                                              <div className="row gx-2 ">
                                                <div className="col-lg-6 col-xl-7">
                                                  <div className="label-group my-2">
                                                    <label> Face Match Status </label>
                                                    <p>
                                                      <span /* className="text-danger" */ >
                                                        {rejectedLiveness?.facematch_value}
                                                      </span>
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-5">
                                                  <div className="label-group my-2">
                                                    <label> Confidence </label>
                                                    <p>
                                                      {rejectedLiveness?.facematch_confidence || "-------"}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-7">
                                                  <div className="label-group my-2">
                                                    <label> Created At </label>
                                                    <p>
                                                      <small>{rejectedLiveness?.createdAt ?
                                                        moment(rejectedLiveness?.createdAt).format('Do MMMM YYYY, h:mm:ss a')
                                                        : "-------"}</small>
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-5">
                                                  <div className="label-group my-2">
                                                    <label>Score </label>
                                                    <p>

                                                      {rejectedLiveness?.facematch_score
                                                        ? `${rejectedLiveness?.facematch_score}%`
                                                        : "-------"}

                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-lg-4" >
                                              <div className="ud_img">
                                                <label className="mb-1 text-xs mt-2" > Aadhar Image</label>
                                                <div className="img p-1">

                                                  {loader ? (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                      Loading....?
                                                    </div>
                                                  ) :
                                                    <SlideshowLightbox className="ratio ratio-16x9">
                                                      <img src={rejectedLiveness?.facematch_image2 || 'assets/img/blank_img.png'} className="img-fluid w-100" alt=""
                                                        onError={(e) => {
                                                          e.target.onerror = null;
                                                          e.target.src = 'assets/img/blank_img.png';
                                                        }}
                                                      />
                                                    </SlideshowLightbox>
                                                  }

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                          <div className="mt-2" >
                                            <div className="d-flex align-items-center justify-content-between ">
                                              <h5 className="mb-0" >   Liveness Details  </h5>
                                              <div className="label-group d-flex align-items-center">
                                                <label className="me-2"> IP Address: </label>
                                                <p>
                                                  <small className="d-flex align-items-center"> {rejectedLiveness?.livenessId?.ipAddress}
                                                    <i className="ri-file-copy-fill ms-1 link cursor-pointer" onClick={() => { copy(rejectedLiveness?.livenessId?.ipAddress); }} ></i>
                                                  </small>
                                                </p>
                                              </div>
                                            </div>
                                            <div className="row g-4 align-items-center">
                                              <div className="col-lg-8" >
                                                <div className="row gx-4 ">
                                                  <div className="col-lg-6 col-xl-4">
                                                    <div className="label-group my-3">
                                                      <label> Live Face Status </label>

                                                      <p>
                                                        <small className="text-success" >
                                                          {rejectedLiveness?.livenessId?.apiResponse?.result?.details?.liveFace?.value}
                                                          {
                                                            rejectedLiveness?.livenessId?.apiResponse?.result?.details?.liveFace?.value === "yes" &&
                                                            <i className="ri-checkbox-circle-fill fw-normal"></i>
                                                          }
                                                        </small>
                                                      </p>
                                                    </div>
                                                    <div className="label-group my-3">
                                                      <label> Live Face Confidance   </label> <p> <small>  {rejectedLiveness?.livenessId?.apiResponse?.result?.details?.liveFace?.confidence}</small> </p>
                                                    </div>
                                                    <div className="label-group my-3">
                                                      <label> Live Face Score   </label>
                                                      <p>
                                                        <small>
                                                          {rejectedLiveness?.livenessId?.apiResponse?.result?.details?.liveFace?.score
                                                            ? `${rejectedLiveness?.livenessId?.apiResponse?.result?.details?.liveFace?.score}%`
                                                            : "-------"}
                                                        </small>
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-6 col-xl-4">
                                                    <div className="label-group my-3">
                                                      <label> Multiple Face Status </label> <p> <small>{rejectedLiveness?.livenessId?.apiResponse?.result?.details?.qualityChecks?.multipleFaces?.value}</small> </p>
                                                    </div>
                                                    <div className="label-group my-3">
                                                      <label>  Multiple Face Confidance   </label> <p> <small>{rejectedLiveness?.livenessId?.apiResponse?.result?.details?.qualityChecks?.multipleFaces?.confidence}</small> </p>
                                                    </div>
                                                    <div className="label-group my-3">
                                                      <label> Multiple Face Score   </label>
                                                      <p>
                                                        <small>
                                                          {rejectedLiveness?.livenessId?.apiResponse?.result?.details?.qualityChecks?.multipleFaces?.score !== undefined &&
                                                            rejectedLiveness?.livenessId?.apiResponse?.result?.details?.qualityChecks?.multipleFaces?.score !== null
                                                            ? `${rejectedLiveness?.livenessId?.apiResponse?.result?.details?.qualityChecks?.multipleFaces?.score}%`
                                                            : "-------"}
                                                        </small>
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-6 col-xl-4">
                                                    <div className="label-group my-3">
                                                      <label> Blur Status </label> <p> <small> {rejectedLiveness?.livenessId?.apiResponse?.result?.details?.qualityChecks?.blur?.value} </small> </p>
                                                    </div>
                                                    <div className="label-group my-3">
                                                      <label> Blur Confidance   </label> <p> <small>{rejectedLiveness?.livenessId?.apiResponse?.result?.details?.qualityChecks?.blur?.confidence}</small> </p>
                                                    </div>
                                                    <div className="label-group my-3">
                                                      <label> Blur Score   </label>
                                                      <p>
                                                        <small>
                                                          {rejectedLiveness?.livenessId?.apiResponse?.result?.details?.qualityChecks?.blur?.score
                                                            ? `${rejectedLiveness?.livenessId?.apiResponse?.result?.details?.qualityChecks?.blur?.score}%`
                                                            : "-------"}
                                                        </small>
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-6 col-xl-7">
                                                    <div className="label-group my-2">
                                                      <label> Created At </label>
                                                      <p>
                                                        <small>{rejectedLiveness?.createdAt ?
                                                          moment(rejectedLiveness?.createdAt).format('Do MMMM YYYY, h:mm:ss a')
                                                          : "-------"}</small>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-4" >
                                                <div className="ud_img">
                                                  <label className="mb-1 text-xs mt-2" > Liveness Selfie </label>
                                                  <div className="img p-1">
                                                    {loader ? (
                                                      <div className="d-flex justify-content-center align-items-center">
                                                        Loading....?
                                                      </div>
                                                    ) :
                                                      <SlideshowLightbox className="ratio ratio-16x9">
                                                        <img src={rejectedLiveness?.facematch_image1 || 'assets/img/blank_img.png'} className="img-fluid w-100" alt=""
                                                          onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = 'assets/img/blank_img.png';
                                                          }}
                                                        />
                                                      </SlideshowLightbox>
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <hr />
                                            <h5 className="mb-3" > geo Details</h5>
                                            <div className="row g-4 ">
                                              <div className="col-lg-6 col-xl-4">
                                                <div className="label-group  ">
                                                  <label> Latitude & Longitude </label>
                                                  <p>
                                                    <small>
                                                      {rejectedLiveness?.livenessId?.geoDetails?.lat
                                                        ? rejectedLiveness?.livenessId?.geoDetails?.lat + "/" + rejectedLiveness?.livenessId?.geoDetails?.lon
                                                        : "-------"}
                                                    </small>
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="col-lg-6 col-xl-2">
                                                <div className="label-group  ">
                                                  <label> Country </label>
                                                  <p>  <small> {rejectedLiveness?.livenessId?.geoDetails?.country}</small>  </p>
                                                </div>
                                              </div>
                                              <div className="col-lg-6 col-xl-2">
                                                <div className="label-group  ">
                                                  <label> State </label>
                                                  <p>  <small> {rejectedLiveness?.livenessId?.geoDetails?.regionName}</small>  </p>
                                                </div>
                                              </div>
                                              <div className="col-lg-6 col-xl-2">
                                                <div className="label-group  ">
                                                  <label>  City</label>
                                                  <p>  <small> {rejectedLiveness?.livenessId?.geoDetails?.city}</small>  </p>
                                                </div>
                                              </div>
                                              <div className="col-lg-6 col-xl-2">
                                                <div className="label-group  ">
                                                  <label>  ISP</label>
                                                  <p>  <small> {rejectedLiveness?.livenessId?.geoDetails?.isp}</small>  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="fc_details d-flex justify-content-center align-items-center">
                                          <img src="/assets/img/not_found.svg" className="img-fluid mt-5" width="200" alt="" />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3" >
                                <h5> Rejection Remark </h5>
                                <div className="re_row" >
                                  {
                                    userKycDetails?.stepRejectedData ? (
                                      userKycDetails?.stepRejectedData?.map((item, index) => (
                                        <div className="rm_col" key={index}>
                                          <div className="row">
                                            <div className="col-6">
                                              <h5>
                                                <span>{item?.admin_name}</span>
                                                <small>{item?.admin_id}</small>
                                              </h5>
                                            </div>
                                            <div className="col-6">
                                              <h5 className="text-end">
                                                <small>Date & Time</small>

                                                {item?.timestamp
                                                  ? (
                                                    <>
                                                      {moment(item?.timestamp).format('Do MMMM YYYY')}
                                                      <br />
                                                      {moment(item?.timestamp).format('h:mm:ss a')}
                                                    </>
                                                  )
                                                  : "-------"}
                                              </h5>
                                            </div>
                                          </div>
                                          <p>
                                            <b>Remark : </b>
                                            {item?.reason?.remarks || "-------"}
                                          </p>
                                        </div>
                                      ))
                                    ) :
                                      <div className="d-flex justify-content-center align-items-center mt-5">
                                        <img src="/assets/img/not_found.svg" className="img-fluid mt-5" width="200" alt="" />
                                      </div>
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="puk_row puk_row_fixed" >
                              <button className="btn btn-md btn-gray px-4" type="button" onClick={() => handleClickStep("step1")}>
                                Prev.
                              </button>
                              <button className="btn btn-md btn-black px-4" type="button" onClick={() => handleClickStep("step3")}>
                                Next
                              </button>
                              {
                                mergedData?.kycStatus !== "not-submitted" &&
                                mergedData?.kycStatus !== "rejected" &&
                                mergedData?.kycStatus !== "re-kyc" &&
                                filterSteps?.kycStep !== kycStep &&
                                (
                                  <button
                                    className="btn btn-md btn-danger px-4"
                                    type="button"
                                    onClick={() => setShowModel(true)}>
                                    Reject Reamrk
                                  </button>
                                )
                              }
                            </div>
                          </div>

                          {/* step 3 */}
                          <div className={`step_1 ${kycStep === "step3" ? "" : "d-none"}`} >
                            <div className="d-flex align-items-center justify-content-between mb-4" >
                              <h4> Education & Occupation </h4>
                            </div>
                            <div className="row g-4 ">
                              <div className="col-lg-6 col-xl-4">
                                <div className="label-group">
                                  <label> Education    </label>
                                  <p>  {userKycDetails?.latestEducationDetails?.education || "-------"} </p>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xl-4">
                                <div className="label-group">
                                  <label> Occupation  </label>
                                  <p>   {userKycDetails?.latestEducationDetails?.occupation || "-------"}  </p>
                                </div>
                              </div>

                              <div className="col-lg-6 col-xl-4">
                                <div className="label-group">
                                  <label>  Marital Status</label>
                                  <p>  {userKycDetails?.latestEducationDetails?.marital_status || "-------"}  </p>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xl-4">
                                <div className="label-group">
                                  <label>  Annual Income   </label>
                                  <p> {userKycDetails?.latestEducationDetails?.annual_income || "-------"} </p>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xl-4">
                                <div className="label-group">
                                  <label>  Annual Turnover   </label>
                                  <p>  {userKycDetails?.latestEducationDetails?.annual_turnover || "-------"} </p>
                                </div>
                              </div>

                              <div className="col-lg-6 col-xl-4">
                                <div className="label-group">
                                  <label> Created Date & Time </label>
                                  <p>
                                    <small>{userKycDetails?.latestEducationDetails?.createdAt ?
                                      moment(userKycDetails?.latestEducationDetails?.createdAt).format('Do MMMM YYYY, h:mm:ss a')
                                      : "-------"}</small>
                                  </p>
                                </div>
                              </div>

                            </div>

                            <div className="row hns_row  mt-5" >
                              <div className="col-lg-9" >
                                <div className="att_row mt-4" >
                                  <h5> Rejected History</h5>
                                  <div className="att_card" >
                                    <Tabs
                                      className="nav nav-tabs nav-tabs-custom px-2"
                                      activeTab={activeTabEducation} onTabClick={handleTabClickRejectEduction}
                                    >
                                      {userKycDetails?.otherEducationDetails?.map((attempt, index) => (
                                        <Tab
                                          className="nav-link"
                                        >
                                          {attempt.admin_rejected_date
                                            ? moment(attempt.admin_rejected_date).format('Do MMMM YYYY, h:mm:ss a')
                                            : "-------"
                                          }
                                        </Tab>
                                      ))}
                                    </Tabs>

                                    <div className="att_col">
                                      {rejectedEducation ? (
                                        <>
                                          <div className="row g-4 align-items-center">
                                            <div className="col-lg-6 col-xl-4">
                                              <div className="label-group">
                                                <label> Education </label> <p> <small> {rejectedEducation?.education || "---------"} </small> </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-4">
                                              <div className="label-group">
                                                <label> Occupation   </label> <p> <small> {rejectedEducation?.occupation || "---------"}  </small> </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-4">
                                              <div className="label-group">
                                                <label> Marital Status   </label> <p> <small> {rejectedEducation?.marital_status || "---------"}  </small> </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-4">
                                              <div className="label-group">
                                                <label> Annual Income </label> <p> <small> {rejectedEducation?.annual_income || "---------"}  </small> </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-4">
                                              <div className="label-group">
                                                <label> Annual Turnover  </label> <p> <small> {rejectedEducation?.annual_turnover || "---------"}  </small> </p>
                                              </div>
                                            </div>


                                            <div className="col-lg-6 col-xl-4">
                                              <div className="label-group">
                                                <label> Created Date & Time </label>
                                                <p>
                                                  <small>{rejectedEducation?.createdAt ?
                                                    moment(rejectedEducation?.createdAt).format('Do MMMM YYYY, h:mm:ss a')
                                                    : "-------"}</small>
                                                </p>
                                              </div>
                                            </div>


                                          </div>
                                        </>
                                      ) : (
                                        <div className="fc_details d-flex justify-content-center align-items-center">
                                          <img src="/assets/img/not_found.svg" className="img-fluid mt-5" width="200" alt="" />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3" >
                                <h5> Rejection Remark </h5>
                                <div className="re_row" >

                                  {
                                    userKycDetails?.stepRejectedData ? (
                                      userKycDetails?.stepRejectedData?.map((item, index) => (
                                        <div className="rm_col" key={index}>
                                          <div className="row">
                                            <div className="col-6">
                                              <h5>
                                                <span>{item?.admin_name}</span>
                                                <small>{item?.admin_id}</small>
                                              </h5>
                                            </div>
                                            <div className="col-6">
                                              <h5 className="text-end">
                                                <small>Date & Time</small>
                                                {item?.timestamp
                                                  ? (
                                                    <>
                                                      {moment(item?.timestamp).format('Do MMMM YYYY')}
                                                      <br />
                                                      {moment(item?.timestamp).format('h:mm:ss a')}
                                                    </>
                                                  )
                                                  : "-------"}
                                              </h5>
                                            </div>
                                          </div>
                                          <p>
                                            <b>Remark : </b>
                                            {item?.reason?.remarks || "-------"}
                                          </p>
                                        </div>
                                      ))
                                    ) : <div className="d-flex justify-content-center align-items-center mt-5">
                                      <img src="/assets/img/not_found.svg" className="img-fluid mt-5" width="200" alt="" />
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="puk_row puk_row_fixed" >
                              <button className="btn btn-md btn-gray px-4" type="button" onClick={() => handleClickStep("step2")}>
                                Prev.
                              </button>
                              <button className="btn btn-md btn-black px-4" type="button" onClick={() => handleClickStep("step4")}>
                                Next
                              </button>
                              {
                                mergedData?.kycStatus !== "not-submitted" &&
                                mergedData?.kycStatus !== "rejected" &&
                                mergedData?.kycStatus !== "re-kyc" &&
                                filterSteps?.kycStep !== kycStep &&
                                (
                                  <button
                                    className="btn btn-md btn-danger px-4"
                                    type="button"
                                    onClick={() => setShowModel(true)}>
                                    Reject Reamrk
                                  </button>
                                )
                              }
                            </div>
                          </div>

                          {/* step 4*/}
                          <div className={`step_1 ${kycStep === "step4" ? "" : "d-none"}`}>
                            <div className=" mb-4" >
                              <h4 className=" " > <small>Declaration of Source of Funds </small>  </h4>
                              <ul className="desc mb-0">
                                <li className="mb-2 d-block">
                                  <b>[{userKycDetails?.latestDeclarationData?.name || "-------"}]</b>, a citizen of India, holding Aadhar Number <b>[{userKycDetails?.latestDeclarationData?.aadhar || "-------"}]</b> and PAN Card number <b>[{userKycDetails?.latestDeclarationData?.pan || "-------"}]</b>,  hereby declare and affirm that all funds used in my financial transactions, with CRYPTO INDIA (Incryp Blockchain Private Limited) in INR and any other crypto currency, are obtained from legitimate and legal sources. The funds are fully compliant with all applicable laws, regulations, and guidelines, including Anti-Money Laundering (AML) and Counter-Terrorist Financing (CTF) regulations.
                                </li>
                                <li className="mb-2">We further declare that:</li>
                                <li className="mb-2 d-block">   1. Legitimate Source: All funds used in our company’s financial operations, including the acquisition of crypto assets, are free from any association with illegal activities such as money laundering, tax evasion, or terrorist financing.</li>
                                <li className="mb-2 d-block"> 2. Compliance with AML Regulations: Our company follows stringent internal controls and compliance measures, ensuring full adherence to AML, CTF, and Know Your Customer (KYC) guidelines as stipulated by regulatory authorities. We have systems in place to detect and prevent suspicious or unlawful financial activities</li>
                                <li className="mb-2 d-block">   3. Due Diligence: Our company undertakes regular due diligence on all counterparties and customers to verify the legitimacy of funds, consistent with legal requirements, before entering any transaction involving crypto assets or traditional financial services.</li>
                                <li className="mb-2 d-block">4. Ethical Standards: Our company upholds the highest standards of ethical conduct in all financial dealings, ensuring that funds used in all transactions are derived from legitimate business activities and are compliant with domestic and international regulations.</li>
                                <li className="mb-2 d-block"> 5. Cooperation with Authorities: In the event of any suspicion, inquiry, or investigation into the source of our company’s funds, we commit to full cooperation with law enforcement agencies, financial institutions, and regulatory authorities. We are prepared to provide all necessary documentation and information to verify the source of funds when required.</li>
                              </ul>

                              <div className="row g-3 mt-3" >
                                <div className="col-lg-6" >
                                  <p className="fc_details p-3 d-flex justify-content-center align-items-center" >
                                    <small> T&C Declared on: &nbsp;
                                      <b className="text-dark" >
                                        {userKycDetails?.latestDeclarationData?.createdAt
                                          ? moment(userKycDetails?.latestDeclarationData?.createdAt).format('Do MMM YYYY,h:mm:ss a')
                                          : "-------"
                                        }
                                      </b>
                                      {userKycDetails?.latestDeclarationData?.createdAt &&
                                        <i className="ri-checkbox-circle-fill text-success ms-2"></i>
                                      }
                                    </small>
                                  </p>
                                </div>
                                <div className="col-lg-6" >
                                  <p className="fc_details p-3 d-flex justify-content-center align-items-center" >
                                    <small>KYC Approved on: &nbsp;
                                      {
                                        userKycDetails?.latestDeclarationData?.auto_approved_date ?
                                          <>
                                            <b className="text-dark" >
                                              {userKycDetails?.latestDeclarationData?.auto_approved_date
                                                ? moment(userKycDetails?.latestDeclarationData?.auto_approved_date).format('Do MMM YYYY,h:mm:ss a')
                                                : "-------"
                                              }
                                            </b>&nbsp;
                                            {userKycDetails?.latestDeclarationData?.auto_approved_date &&
                                              <i className="ri-checkbox-circle-fill text-success"></i>
                                            }
                                          </>
                                          :
                                          <>
                                            <b className="text-dark" >
                                              {userKycDetails?.latestDeclarationData?.declaration_approved_date
                                                ? moment(userKycDetails?.latestDeclarationData?.declaration_approved_date).format('Do MMM YYYY,h:mm:ss a')
                                                : "-------"
                                              }
                                            </b>&nbsp;
                                            {userKycDetails?.latestDeclarationData?.declaration_approved_date &&
                                              <i className="ri-checkbox-circle-fill text-success"></i>
                                            }
                                          </>
                                      }
                                    </small>
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row hns_row_two  mt-5" >
                              <div className="col-lg-6">
                                <div className="att_row mt-0" >
                                  <h5 className="mb-2" > <small>Rejected History</small></h5>
                                  <div className="att_card" >

                                    <Tabs
                                      className="nav nav-tabs nav-tabs-custom px-2"
                                      activeTab={activeTabOtherDeclarattion} onTabClick={handleOtherDeclarattion}
                                    >
                                      {userKycDetails?.otherDeclarationData?.map((attempt, index) => (
                                        <Tab
                                          className="nav-link"
                                        >
                                          {attempt.declaration_rejected_date
                                            ? moment(attempt.declaration_rejected_date).format('Do MMMM YYYY, h:mm:ss a')
                                            : "-------"
                                          }
                                        </Tab>
                                      ))}
                                    </Tabs>

                                    <div className="att_col" >
                                      {rejectedDeclaration ? (
                                        <div className="row g-4 align-items-center">
                                          <div className="col-lg-6 col-xl-6">
                                            <div className="label-group">
                                              <label> Declared Date & Time </label>
                                              <p>
                                                <small>
                                                  {rejectedDeclaration.createdAt
                                                    ? moment(rejectedDeclaration.createdAt).format('Do MMMM YYYY, h:mm:ss a')
                                                    : "-------"
                                                  }
                                                </small>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-6">
                                            <div className="label-group">
                                              <label> Full Name </label> <p> <small> {rejectedDeclaration.name || "-------"}  </small> </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-6">
                                            <div className="label-group">
                                              <label> Aadhar Number </label> <p> <small> {rejectedDeclaration.aadhar || "-------"}  </small> </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-6">
                                            <div className="label-group">
                                              <label> Pan Number </label> <p> <small> {rejectedDeclaration.pan || "-------"}  </small> </p>
                                            </div>
                                          </div>


                                          <small>Approved on: &nbsp;
                                            {
                                              rejectedDeclaration?.auto_approved_date ?
                                                <>
                                                  <b className="text-dark" >
                                                    {rejectedDeclaration?.auto_approved_date
                                                      ? moment(rejectedDeclaration?.auto_approved_date).format('Do MMM YYYY,h:mm:ss a')
                                                      : "-------"
                                                    }
                                                  </b>&nbsp;
                                                  {rejectedDeclaration?.auto_approved_date &&
                                                    <i className="ri-checkbox-circle-fill text-success"></i>
                                                  }
                                                </>
                                                :
                                                <>
                                                  <b className="text-dark" >
                                                    {userKycDetails?.latestDeclarationData?.declaration_approved_date
                                                      ? moment(userKycDetails?.latestDeclarationData?.declaration_approved_date).format('Do MMM YYYY,h:mm:ss a')
                                                      : "-------"
                                                    }
                                                  </b>&nbsp;
                                                  {userKycDetails?.latestDeclarationData?.declaration_approved_date &&
                                                    <i className="ri-checkbox-circle-fill text-success"></i>
                                                  }
                                                </>
                                            }
                                          </small>



                                        </div>
                                      ) :
                                        <div className="fc_details d-flex justify-content-center align-items-center">
                                          <img src="/assets/img/not_found.svg" className="img-fluid mt-5" width="200" alt="" />
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="col-lg-3" >
                                {/* Rejection Remark */}
                                <h5 className="mb-2" > <small>Rejection Remark </small></h5>
                                <div className="re_row" >

                                  {
                                    userKycDetails?.stepRejectedData
                                      ?.filter((item) => item?.step === "reject_all")
                                      ?.map((item, index) => (
                                        <div className="rm_col" key={index}>
                                          <div className="row">
                                            <div className="col-6">
                                              <h5>
                                                <span>{item?.admin_name}</span>
                                                <small>{item?.admin_id}</small>
                                              </h5>
                                            </div>
                                            <div className="col-6">
                                              <h5 className="text-end">
                                                <small>Date & Time</small>

                                                {item?.timestamp
                                                  ? (
                                                    <>
                                                      {moment(item?.timestamp).format('Do MMMM YYYY')}
                                                      <br />
                                                      {moment(item?.timestamp).format('h:mm:ss a')}
                                                    </>
                                                  )
                                                  : "-------"}
                                              </h5>
                                            </div>
                                          </div>
                                          <p>
                                            <b>Remark : </b>
                                            {item?.reason?.remarks || item?.remark}
                                          </p>
                                        </div>
                                      ))
                                  }
                                </div>

                                {/* Re-KYC Remark */}
                                <h5 className="mb-2 mt-2" > <small> Re-KYC Remark </small></h5>
                                <div className="re_row" >
                                  {
                                    userKycDetails?.stepRejectedData
                                      ?.filter((item) => item?.step === "rekyc")?.reverse()
                                      ?.map((item, index) => (
                                        <div className="rm_col rm_col_yellow" key={index}>
                                          <div className="row">
                                            <div className="col-6">
                                              <h5>
                                                <span>{item?.admin_name}</span>
                                                <small>{item?.admin_id}</small>
                                              </h5>
                                            </div>
                                            <div className="col-6">
                                              <h5 className="text-end">
                                                <small>Date & Time</small>

                                                {item?.timestamp
                                                  ? (
                                                    <>
                                                      {moment(item?.timestamp).format('Do MMMM YYYY')}
                                                      <br />
                                                      {moment(item?.timestamp).format('h:mm:ss a')}
                                                    </>
                                                  )
                                                  : "-------"}
                                              </h5>
                                            </div>
                                          </div>
                                          <p>
                                            <b>Remark : </b>
                                            {item?.reason?.remarks || item?.remark}
                                          </p>
                                        </div>
                                      ))
                                  }
                                </div>

                              </div>

                              <div className="col-lg-3" >
                                <h5 className="mb-2" > <small>Approved Remark</small> </h5>
                                <div className="re_row ap_row" >
                                  {
                                    approvedRemark ? (
                                      approvedRemark?.map((item, index) => (
                                        <div className="rm_col" key={index}>
                                          <div className="row" >
                                            <div className="col-6" >
                                              <h5> <span> {item?.admin_name === "null" ? "Crypto India" : item?.admin_name} <small>
                                                {item?.admin_id === "null" ? "Crypto India System" : item?.admin_id}</small> </span> </h5>
                                            </div>
                                            <div className="col-6" >
                                              <h5 className="text-end" ><small>Date & Time</small>
                                                {item?.timestamp
                                                  ? (
                                                    <>
                                                      {moment(item?.timestamp).format('Do MMMM YYYY')}
                                                      <br />
                                                      {moment(item?.timestamp).format('h:mm:ss a')}
                                                    </>
                                                  )
                                                  : "-------"}
                                              </h5>
                                            </div>
                                          </div>
                                          <p>
                                            <b>Remark : </b>
                                            {item?.remark || item?.reason || "-------"}
                                          </p>
                                        </div>
                                      ))
                                    ) : null
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="puk_row puk_row_fixed" >
                              <button className="btn btn-md btn-gray px-4" type="button" onClick={() => handleClickStep("step3")}>
                                Prev.
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </main>
      </div>


      {showModel && (
        <>
          <div className="modal show d-block" >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content pb-3">
                <div className="modal-header">
                  <h5 className="modal-title" > Are you sure you want to reject the {kycStep} details?</h5>
                </div>
                <div className="modal-body ">
                  <div className="form-group mb-4 ">
                    <label className="small mb-1"> Enter Reason/Message </label>
                    <textarea className="form-control py-3" rows="4" placeholder="Enter your message.." name="rejectRemarks" value={rejectRemarks}
                      onChange={(e) => {
                        setRejectRemarks(e.target.value);
                        setErrorMessage("");
                      }}>
                    </textarea>
                    {errorMessage && (
                      <div className="text-danger mt-2 small">{errorMessage}</div>
                    )}
                  </div>
                </div>
                <button className="btn btn-danger  btn-block w-100" type="button" onClick={() => storeStepRejectedReason(rejectRemarks)}>
                  <span> Submit </span>
                </button>
                <button className="btn btn-secondary  btn-block w-100 mt-1" type="button" onClick={() => setShowModel(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}

      {
        mainRejectModel && (
          <>
            <div className="modal show d-block">
              <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
                <div className="modal-content ">
                  <div className="modal-header">
                    <h5 className="modal-title"><>{userDetails?.user?.first_name}</> Are You Sure?</h5>
                    <button className="btn-close" type="button" onClick={() => setMainRejectModel(false)}></button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group mb-4" >
                        <label className="small">
                          Select Steps
                        </label>
                        <select
                          className="form-control form-select"
                          value={selectedOption}
                          onChange={(e) => setSelectedOption(e.target.value)}
                        /* disabled={rejectedSteps?.length >= 1} */
                        >
                          <option value="reject_all">All</option>
                          <option value="partial_reject">Partial Rejection</option>
                          <option value="rekyc">Re-KYC</option>
                        </select>
                      </div>
                      {
                        selectedOption !== "reject_all" && selectedOption !== "rekyc" &&
                        <div className="form-group pc_row" >
                          {
                            rejectedSteps?.length > 0 ? (
                              rejectedSteps?.map((item, index) => (
                                <div className="pc_col" key={index}>
                                  <div className="d-flex aliogn-items-center justify-content-between mb-3" >
                                    <h6 className="mb-0" >  <b>{item?.kycStep} </b>-  {item?.stepName} </h6>
                                    <button className="btn btn-xs btn-danger" type="button" onClick={() => handleEditRemark(item)}> Edit Remark </button>
                                  </div>
                                  <p>
                                    <b>Remark : </b>
                                    {item?.reason}
                                  </p>
                                </div>
                              ))
                            ) : null
                          }
                        </div>
                      }


                      {/*  {
                        selectedOption !== "partial_reject" && */}
                      <div className="form-group mb-4 ">
                        <label className="small mb-1"> Enter Reason/Message </label>
                        <textarea className="form-control py-3" rows="7" placeholder="Enter your message.." value={reasonMessage}
                          onChange={(e) => {
                            setReasonMessage(e.target.value);
                            setErrorMessage("");
                          }}
                        ></textarea>
                        {errorMessage && (
                          <div className="text-danger mt-2 small">{errorMessage}</div>
                        )}
                      </div>
                      {/*   } */}
                      {
                        rejectedSteps?.length == 0 && selectedOption === "partial_reject" ?
                          <button className="btn btn-danger btn-block w-100" type="button" onClick={() => {
                            setMainRejectModel(false); handleClickStep("step1");
                            alertWarningMessage("Please Add Step Remark to Reject Kyc");
                          }}>
                            Add Remark
                          </button>
                          :
                          <button className="btn btn-danger btn-block w-100" type="button" onClick={() => handleRejectKyc(selectedOption, reasonMessage)}>
                            {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              : <span> Continue </span>}
                          </button>
                      }

                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-backdrop fade show"></div>
          </>
        )}

      {showEditModal && editingStep && (
        <>
          <div className="modal show d-block" >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content pb-3">
                <div className="modal-header">
                  <h5 className="modal-title" > Are you sure you ?</h5>
                </div>
                <div className="modal-body ">
                  <div className="form-group mb-4 ">
                    <label className="small mb-1"> Edit Remark for {editingStep.kycStep} </label>
                    <textarea className="form-control py-3" rows="4" placeholder="Enter your message.." name="rejectRemarks" value={editingStep.reason} onChange={(e) => setEditingStep({ ...editingStep, reason: e.target.value })}></textarea>
                  </div>
                </div>
                <div>
                  <button className="btn btn-danger  btn-block w-100" type="button"
                    onClick={() => saveEditedRemark(editingStep)}
                  >
                    Save
                  </button>
                  <button className="btn btn-secondary  btn-block w-100 mt-1" type="button" onClick={() => { setShowEditModal(false); setMainRejectModel(true) }}>
                    Cancel
                  </button>
                </div>

              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}

      {showModelApprove && (
        <>
          <div className="modal show d-block" >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content pb-3">
                <div className="modal-header">
                  <h5 className="modal-title" >You are about to approve the KYC.</h5>
                </div>
                <div className="modal-body ">
                  <div className="form-group mb-4 ">
                    <label className="small mb-1"> Enter Kyc Approval Remark </label>
                    <textarea className="form-control py-3" rows="4" placeholder="Enter your Remark.." name="verificationRemark" value={verificationRemark}
                      onChange={(e) => {
                        setVerificationRemark(e.target.value);
                        setErrorMessage("");
                      }}>
                    </textarea>
                    {errorMessage && (
                      <div className="text-danger mt-2 small">{errorMessage}</div>
                    )}
                  </div>
                </div>
                <button className="btn btn-success  btn-block w-100" type="button" onClick={() => handleKycApproveRemark(verificationRemark)}>
                  {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    : <span> Submit </span>}
                </button>
                <button className="btn btn-secondary  btn-block w-100 mt-1" type="button" onClick={() => setShowModelApprove(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}



    </>

  );
};

export default KycDetailsPersonal;
