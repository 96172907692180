import React, { useState, useEffect } from "react";

const Permissions = ({ userDetails }) => {

    const [permissions, setPermissions] = useState({
        buySell: userDetails?.buySell || false,
        spotTrading: userDetails?.spotTrading || false,
        p2pTrading: userDetails?.p2pTrading || false,
        deposit: userDetails?.deposit || false,
        withdraw: userDetails?.withdraw || false,
        quickPay: userDetails?.quickPay || false,
    });

    useEffect(() => {
        if (userDetails) {
            setPermissions({
                buySell: userDetails?.buySell || false,
                spotTrading: userDetails?.spotTrading || false,
                p2pTrading: userDetails?.p2pTrading || false,
                deposit: userDetails?.deposit || false,
                withdraw: userDetails?.withdraw || false,
                quickPay: userDetails?.quickPay || false
            });
        }
    }, [userDetails]);

    const handleToggle = (key) => {
        setPermissions((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header">
                    <div className="page-header-content">
                        <div className="container-xl px-4">
                            <h1 className="page-header-title">Permissions</h1>
                        </div>
                    </div>
                </header>

                <div className="container-xl px-4">
                    <div className="card">
                        <div className="card-body py-4">
                            <div className="profile_data ac_body">
                                <div className="d-flex justify-content-between">
                                    <h5>Permissions</h5>
                                </div>
                                <div className="row">
                                    {[
                                        { key: "buySell", icon: "ri-arrow-up-down-line", label: "Buy & Sell", description: "Revoke user side buy/sell access." },
                                        { key: "spotTrading", icon: "ri-line-chart-fill", label: "Spot Trading", description: "Revoke user side Spot Trading feature." },
                                        { key: "p2pTrading", icon: "ri-p2p-fill", label: "P2P Trading", description: "Revoke user side P2P Trading feature." },
                                        { key: "deposit", icon: "ri-arrow-up-line", label: "Deposit Funds", description: "Revoke user side Deposit feature." },
                                        { key: "withdraw", icon: "ri-arrow-down-line", label: "Withdraw Funds", description: "Revoke user side Withdraw feature." },
                                        { key: "quickPay", icon: "ri-arrow-down-line", label: "Quick Pay", description: "Revoke user side Quick Pay feature." }
                                    ].map((item) => (
                                        <div className="col-lg-6" key={item.key}>
                                            <div className="per_col mb-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="pr_icon"><i className={item.icon}></i></div>
                                                    <div>
                                                        <h5>{item.label}</h5>
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={permissions[item.key]}
                                                        onChange={() => handleToggle(item.key)}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                            <div className="profile_data ac_body my-4">
                                <div className="d-flex justify-content-between">
                                    <h5>Updated History</h5>
                                </div>
                                <div className="table-responsive m_table">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Date/Time</th>
                                                <th scope="col">Permission Type</th>
                                                <th scope="col">Prev. Status</th>
                                                <th scope="col">Updated Status</th>
                                                <th scope="col">Remark By</th>
                                                <th scope="col">Remark</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><span>11-12-2024</span></td>
                                                <td><span>Unknown Name</span></td>
                                                <td><span>----</span></td>
                                                <td><span>----</span></td>
                                                <td><span>----</span></td>
                                                <td><span>Message here.........</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Permissions;
