import React, { useEffect, useState } from "react";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import Select from 'react-select'

const WalletDetails = ({ activeTab }) => {

    const [walletsData, setWalletsData] = useState([]);
    const [totalDataLength, setTotalDataLength] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedWalletId, setSelectedWalletId] = useState("");
    const [currencyId, setCurrencyId] = useState("");
    const [activeFundTab, setActiveFundTab] = useState("add");
    const [amountType, setAmountType] = useState("");
    const [amount, setAmount] = useState("");
    const [remark, setRemark] = useState("");
    const [activeModelAddFund, setActiveModelAddFund] = useState(false);
    const [activeModelHoldUnhold, setActiveModelHoldUnhold] = useState(false);
    const [activeMasterWalletItem, setActiveMasterWalletItem] = useState("Main");
    const [activeModelSelfTransfer, setActiveModelSelfTransfer] = useState(false);
    const [selectedFromWallet, setSelectedFromWallet] = useState("");
    const [selectedToWallet, setSelectedToWallet] = useState("");
    const [walletBalance, setWalletBalance] = useState(0);
    const [skip, setSkip] = useState(0);
    const limit = 10;

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchKey(searchTerm);
    };

    const handleAdminWallet = async () => {
        const formattedSearchKey = searchKey || "";
        let filters = {};

        if (skip) {
            filters.skip = skip;
        }

        if (limit) {
            filters.limit = limit;
        }


        if (formattedSearchKey) {
            filters.search = formattedSearchKey;
        }
        if (activeMasterWalletItem) {
            filters.wallet_type = activeMasterWalletItem;
        }

        const queryString = new URLSearchParams(filters).toString();
        await AuthService.getAdminWallets(queryString).then(async (result) => {
            if (result.success) {
                try {
                    setWalletsData(result.data);
                    setTotalDataLength(result?.data?.totalCount);
                } catch (error) {
                    alertErrorMessage(error);
                    setWalletsData([])
                }
            } else {
                alertErrorMessage(result.message);
                setWalletsData([])
            }
        });
    };

    const handleFormSubmit = async () => {
        if (!selectedWalletId) {
            setErrorMessage("Please select a coin");
            return;
        }
        if (!amount) {
            setErrorMessage("Please enter an amount");
            return;
        }
        setLoading(true);
        try {
            const result = await AuthService.updateWalletBalance(selectedWalletId, amount, activeFundTab, currencyId, remark);
            if (result.success) {
                alertSuccessMessage(result?.message);
                setActiveModelAddFund(false);
                handleRest();
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error.message || "Something went wrong.");
        } finally {
            setLoading(false);
        }
    };

    const handleFormSubmitHoldUnhold = async () => {
        if (!selectedWalletId) {
            setErrorMessage("Please select a coin");
            return;
        } if (!amountType) {
            setErrorMessage("Please select Type Hold/Unhold");
            return;
        }
        if (!amount) {
            setErrorMessage("Please enter an amount");
            return;
        }
        setLoading(true);
        try {
            const result = await AuthService.adminFundholdUnhold(selectedWalletId, currencyId, amountType, amount, remark);
            if (result.success) {
                alertSuccessMessage(result?.message);
                handleAdminWallet();
                setActiveModelHoldUnhold(false);
                setLoading(false);
                handleRest();
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error.message || "Something went wrong.");
        } finally {
            setLoading(false);
        }
    };

    const handleFundTransfer = async () => {
        if (!selectedFromWallet) {
            setErrorMessage("Please select Transfer From Wallet");
            return;
        }
        if (!selectedWalletId) {
            setErrorMessage("Please select a Coin");
            return;
        }
        if (!selectedToWallet) {
            setErrorMessage("Please select Transfer To Wallet");
            return;
        }
        if (!amount) {
            setErrorMessage("Please enter Amount");
            return;
        }
        if (parseFloat(amount) > walletBalance) {
            setErrorMessage("Amount cannot exceed wallet balance");
            return;
        }

        try {
            setLoading(true);
            const result = await AuthService.adminFundTransfer(
                selectedFromWallet,
                selectedToWallet,
                amount,
                selectedWalletId,
                remark
            );
            if (result?.success) {
                alertSuccessMessage(result?.message);
                setActiveModelSelfTransfer(false);
                setLoading(false);
                handleRest();
            } else {
                setLoading(false);
                setErrorMessage(result || "Fund transfer failed.");
            }
        } catch (error) {
            setLoading(false);
            setErrorMessage(error?.message || "An unexpected error occurred.");
        }
    };


    const handleRest = () => {
        setSearchKey("");
        setErrorMessage("");
        setSelectedWalletId("");
        setCurrencyId("");
        setAmountType("");
        setAmount("");
        setRemark("");
        setSelectedFromWallet("");
        setSelectedToWallet("");
    }

    useEffect(() => {
        if (activeTab === 3) {
            handleAdminWallet();
        }
    }, [activeTab, skip, limit, searchKey, activeMasterWalletItem])

    const handlePagination = (action) => {
        switch (action) {
            case "prev":
                if (skip - limit >= 0) setSkip(skip - limit);
                break;
            case "next":
                if (skip + limit < totalDataLength) setSkip(skip + limit);
                break;
            case "first":
                setSkip(0);
                break;
            case "last":
                setSkip(Math.max(totalDataLength - limit, 0));
                break;
            default:
                break;
        }
    };


    const walletOptions = [
        { value: "Main", label: "Main Wallet" },
        { value: "Hot", label: "Hot Wallet" },
        { value: "Merchant", label: "Merchant Wallet" },
    ];

    const filteredToWalletOptions = walletOptions.filter(
        (option) => option.value !== selectedFromWallet
    );

    const filteredFromWalletOptions = walletOptions.filter(
        (option) => option.value !== selectedToWallet
    );


    const coinOptions = selectedFromWallet ? walletsData?.wallets?.map((wallet) => {
        return {
            value: wallet.currency_id,
            label: (
                <div style={{ display: "flex", alignItems: "center" }} className="cursor-pointer">
                    <img
                        src={wallet.icon_path || "assets/img/nocoin.png"}
                        alt={wallet.short_name}
                        style={{ width: 24, height: 24, marginRight: 10 }}
                    />
                    {wallet.name} ({wallet.short_name})
                    <span style={{ marginLeft: 10, fontWeight: "bold" }}>
                        - Balance: {wallet.balance}
                    </span>
                </div>
            ),
            balance: wallet.balance,
        };
    }) : [];



    return (
        <div className={`tab-pane ${activeTab === 3 ? "active" : ""}`} >
            <div className="card-body py-4">

                <div className="profile_data  ac_body" >
                    <div className="">
                        <div className="custom-tab-2">
                            <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a href="#MasterWallet" id="MasterWallet-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true"> Master Wallet  </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a href="#UserWallets" id="UserWallets-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1">  User Wallets  </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content">

                        {/* Master Wallet Tab  */}
                        <div className="tab-pane active" id="MasterWallet" role="tabpanel" aria-labelledby="MasterWallet-tab">
                            <h5> Master Wallet </h5>
                            <div className="row">
                                <div className="col-12" >
                                    <div className="search-header align-items-start justify-content-between px-0 pt-0">
                                        <div className="ow_row" >
                                            <span>Estimated Balance</span>
                                            <h5 className="" >0000.000 <small>INR</small></h5>
                                            <p className="mb-0"> Locked Balance ≈ 0, &nbsp; Total Hold Balance ≈ 0, &nbsp; Total Available Balance ≈ 0 </p>
                                            <ul className="nav custom-tabs mb-2">
                                                <li className="buysell-tab">
                                                    <span className={activeMasterWalletItem === "Main" && "active"} onClick={() => setActiveMasterWalletItem("Main")}>
                                                        Main
                                                    </span>
                                                </li>
                                                <li className="buysell-tab">
                                                    <span className={activeMasterWalletItem === "Hot" && "active"} onClick={() => setActiveMasterWalletItem("Hot")}>
                                                        Hot
                                                    </span>
                                                </li>
                                                <li className="buysell-tab">
                                                    <span className={activeMasterWalletItem === "Merchant" && "active"} onClick={() => setActiveMasterWalletItem("Merchant")}>
                                                        Merchant
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='d-flex gap-2  align-items-start justify-content-between'>
                                            <div className="dd_search">
                                                <input type="search" placeholder="Search here..." name="searchKey" id="searchKey" value={searchKey} onChange={handleSearch} />
                                                <i className="fas fa-search"></i>
                                            </div>

                                            {
                                                activeMasterWalletItem === "Main" &&
                                                <>
                                                    <button className='btn btn-warning btn-md' onClick={() => { setActiveModelAddFund(true); setActiveFundTab("add") }}>
                                                        <b><i className="ri-add-line me-2"></i></b>  Add Funds
                                                    </button>
                                                    <button className='btn btn-success btn-md' onClick={() => { setActiveModelAddFund(true); setActiveFundTab("deduct") }}>
                                                        <b><i className="ri-arrow-up-long-line me-2"></i></b>  Withdraw
                                                    </button>
                                                </>
                                            }



                                            <button className='btn btn-danger btn-md' onClick={() => setActiveModelHoldUnhold(true)}>
                                                Hold/Unhold
                                            </button>

                                            <button className='btn btn-dark btn-md' onClick={() => setActiveModelSelfTransfer(true)}>
                                                <b><i className="ri-arrow-go-forward-line me-2"></i></b> Self Transfer
                                            </button>


                                        </div>
                                    </div>

                                    <div className="table-responsive m_table">
                                        {walletsData && walletsData?.wallets?.length > 0 ? (
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Coin Name</th>
                                                        <th scope="col" className="text-end">Available Balance</th>
                                                        <th scope="col" className="text-end">Locked Balance</th>
                                                        <th scope="col" className="text-end">Hold Balance</th>
                                                        <th scope="col" className="text-end">Total Balance</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {walletsData.wallets &&
                                                        walletsData.wallets.map((wallet) => (
                                                            <tr key={wallet._id}>
                                                                <td>
                                                                    <div className="c_view">
                                                                        <img
                                                                            src={wallet.icon_path || "assets/img/nocoin.png"}
                                                                            className="img-fluid"
                                                                            alt={wallet.short_name}
                                                                            width="30"
                                                                        />
                                                                        <span>
                                                                            {wallet.short_name} <br />
                                                                            <small>{wallet.currency}</small>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td className="text-end">
                                                                    <div className="c_view justify-content-end">
                                                                        <span>{wallet.balance} <br /></span>
                                                                    </div>
                                                                </td>
                                                                <td className="text-end">
                                                                    <div className="c_view justify-content-end">
                                                                        <span>{wallet.locked_balance} <br /></span>
                                                                    </div>
                                                                </td>
                                                                <td className="text-end">
                                                                    <span>{wallet.hold_balance}</span>
                                                                </td>
                                                                <td className="text-end">
                                                                    <span>{(wallet.balance + wallet.locked_balance + wallet.hold_balance)}</span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <p className="text-center mt-3">No Data Available</p>
                                        )}

                                        {
                                            walletsData.wallets?.length > 0 &&
                                            <div className="hVPalX gap-2" >
                                                <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                                <div className="sc-eAKtBH gVtWSU">
                                                    <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                            <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                            <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                        </svg>
                                                    </button>
                                                    <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                                        </svg>
                                                    </button>

                                                    <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                                        </svg>
                                                    </button>
                                                    <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                            <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>



                        {/* User Wallets Tab  */}
                        <div className="tab-pane" id="UserWallets" role="tabpanel" aria-labelledby="UserWallets-tab">
                            <h5> User Wallets </h5>
                            <div className="mb-4" >
                                <div className="row g-4">
                                    <div className="col-lg-6 col-xl-3">
                                        <div className="label-group ow_row">
                                            <label>  Estimated Balance </label>
                                            <h5 className=""> 0000.000 </h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                        <div className="label-group ow_row">
                                            <label>     Total Locked Balance </label>
                                            <h5 className=""> 0000.000 </h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                        <div className="label-group ow_row">
                                            <label>     Total Hold Balance </label>
                                            <h5 className=""> 0000.000 </h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                        <div className="label-group ow_row">
                                            <label>     Total Available Balance </label>
                                            <h5 className=""> 0000.000 </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ul className="nav custom-tabs mb-2">
                                <li className="buysell-tab">
                                    <a className="active">  Overview</a>
                                </li>
                                <li className="buysell-tab">
                                    <a className="">Funding</a>
                                </li>
                                <li className="buysell-tab">
                                    <a className=""> Spot Wallet </a>
                                </li>
                                <li className="buysell-tab">
                                    <a className=""> Future Wallet </a>
                                </li>
                            </ul>

                            <div>
                                <div className="filter_bar search-header justify-content-end px-0 g-2">
                                    <div className="dd_search">
                                        <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                        <i className="fas fa-search"></i>
                                    </div>

                                </div>
                                <div className="table-responsive m_table">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Coin Name</th>
                                                <th scope="col" className="text-end">Available Balance</th>
                                                <th scope="col" className="text-end">Locked Balance</th>
                                                <th scope="col" className="text-end">Hold Balance</th>
                                                <th scope="col" className="text-end">Total Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="c_view">
                                                        <img src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" className="img-fluid" alt="" />
                                                        <span>USDT <br />
                                                            <small>Tether</small>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="text-end">
                                                    <div className="c_view justify-content-end">
                                                        <span>0.00000 <br />
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="text-end">
                                                    <div className="c_view justify-content-end">
                                                        <span>0.00000 <br />
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="text-end">
                                                    <span> 0.00000 </span>
                                                </td>
                                                <td className="text-end">
                                                    <span> 0.00 </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="c_view">
                                                        <img src="assets/img/nocoin.png" className="img-fluid" alt="" />
                                                        <span>INR <br />
                                                            <small>INR</small>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="text-end">
                                                    <div className="c_view justify-content-end">
                                                        <span>0.00000 <br />
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="text-end">
                                                    <div className="c_view justify-content-end">
                                                        <span>0.00000 <br />
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="text-end">
                                                    <span> 0.00000 </span>
                                                </td>
                                                <td className="text-end">
                                                    <span> 0.00 </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {
                activeModelAddFund && (
                    <div>
                        <div className="modal show d-block">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content pb-3">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{activeFundTab?.toUpperCase()} Fund</h5>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-group mb-2">
                                            <label htmlFor="coin" className="small mb-1">Select Coin</label>
                                            <select
                                                className="form-control py-3"
                                                value={selectedWalletId}
                                                // onChange={(e) => { setSelectedWalletId(e.target.value); setErrorMessage("") }}


                                                onChange={(e) => {
                                                    const selectedId = e.target.value;
                                                    const selectedWallet = walletsData?.wallets?.find(wallet => wallet._id === selectedId);
                                                    setSelectedWalletId(selectedId);
                                                    setCurrencyId(selectedWallet?.currency_id || "");
                                                    setErrorMessage("");
                                                }}



                                            >
                                                <option value="">Select a Coin</option>
                                                {walletsData?.wallets?.map((wallet) => (
                                                    <option key={wallet._id} value={wallet._id}>
                                                        {wallet.short_name} ({wallet.currency})
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label htmlFor="amount" className="small mb-1">Enter Amount</label>
                                            <input
                                                type="number"
                                                name="amount"
                                                className="form-control py-3"
                                                placeholder="Enter Amount"
                                                value={amount}
                                                onChange={(e) => { setAmount(e.target.value); setErrorMessage("") }}
                                                onWheel={(e) => e.target.blur()}
                                                onKeyDown={(e) => {
                                                    if (["+", "-", "e", "E"].includes(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div className="col-lg-12 form-group mb-3">
                                            <label className="small mb-1" htmlFor="remark">Remark<em>*</em></label>
                                            <div className="otp_box">
                                                <textarea
                                                    className="form-control form-control-solid"
                                                    id="remark"
                                                    name="remark"
                                                    placeholder="Enter Remark"
                                                    type="text"
                                                    value={remark}
                                                    onChange={(e) => { setRemark(e.target.value); setErrorMessage("") }}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    {errorMessage && (
                                        <div className="text-danger mt-2 small">{errorMessage}</div>
                                    )}

                                    <button className="btn btn-success btn-block w-100" type="button" onClick={handleFormSubmit}>
                                        {loading ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : (
                                            <span>Submit</span>
                                        )}
                                    </button>

                                    {/* Cancel Button */}
                                    <button className="btn btn-secondary btn-block w-100 mt-1" type="button" onClick={() => { setActiveModelAddFund(false); handleRest(); }}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                )
            }

            {
                activeModelHoldUnhold && (
                    <div>
                        <div className="modal show d-block">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content pb-3">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Hold/Unhold Fund</h5>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-group mb-2">
                                            <label htmlFor="coin" className="small mb-1">Select Coin</label>
                                            <select
                                                className="form-control py-3"
                                                value={selectedWalletId}
                                                onChange={(e) => {
                                                    const selectedId = e.target.value;
                                                    const selectedWallet = walletsData?.wallets?.find(wallet => wallet._id === selectedId);
                                                    setSelectedWalletId(selectedId);
                                                    setCurrencyId(selectedWallet?.currency_id || "");
                                                    setErrorMessage("");
                                                }}
                                            >
                                                <option value="">Select a Coin</option>
                                                {walletsData?.wallets?.map((wallet) => (
                                                    <option key={wallet._id} value={wallet._id}>
                                                        {wallet.short_name} ({wallet.currency})
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label htmlFor="coin" className="small mb-1">Select Type</label>
                                            <select className="form-control py-3" value={amountType}
                                                onChange={(e) => { setAmountType(e.target.value); setErrorMessage("") }}
                                            >
                                                <option value="">Select a type</option>
                                                <option value="hold">Hold</option>
                                                <option value="unhold">UnHold</option>
                                            </select>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label htmlFor="amount" className="small mb-1">Enter Amount</label>
                                            <input
                                                type="number"
                                                name="amount"
                                                className="form-control py-3"
                                                value={amount}
                                                onChange={(e) => { setAmount(e.target.value); setErrorMessage("") }}
                                                onWheel={(e) => e.target.blur()}
                                                onKeyDown={(e) => {
                                                    if (["+", "-", "e", "E"].includes(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div className="form-group mb-2">
                                            <label htmlFor="remark" className="small mb-1">Enter Remark</label>
                                            <input
                                                type="text"
                                                name="remark"
                                                className="form-control py-3"
                                                value={remark}
                                                onChange={(e) => { setRemark(e.target.value); setErrorMessage("") }}

                                            />
                                        </div>
                                    </div>

                                    {errorMessage && (
                                        <div className="text-danger mt-2 small">{errorMessage}</div>
                                    )}

                                    <button className="btn btn-success btn-block w-100" type="button" onClick={handleFormSubmitHoldUnhold}>
                                        {loading ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : (
                                            <span>Submit</span>
                                        )}
                                    </button>

                                    <button className="btn btn-secondary btn-block w-100 mt-2" type="button" onClick={() => { setActiveModelHoldUnhold(false); handleRest("") }}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                )
            }

            {
                activeModelSelfTransfer && (
                    <div>
                        <div className="modal show d-block">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content pb-3">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Add Fund</h5>
                                    </div>
                                    <div className="modal-body p-3 p-lg-4">
                                        <div className="mb-2">
                                            <label className="form-label">Transfer From Wallet</label>
                                            <Select
                                                options={filteredFromWalletOptions}
                                                value={filteredFromWalletOptions.find(option => option.value === selectedFromWallet)}
                                                onChange={(selectedOption) => {
                                                    setSelectedFromWallet(selectedOption.value);
                                                    setErrorMessage("");
                                                    setActiveMasterWalletItem(selectedOption.value);
                                                    setSelectedWalletId(""); // Reset coin selection
                                                }}
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <label className="form-label">Select Coin</label>
                                            <Select
                                                options={coinOptions}
                                                value={coinOptions.find(option => option.value === selectedWalletId)}
                                                onChange={(selectedOption) => {
                                                    setSelectedWalletId(selectedOption.value);
                                                    setErrorMessage("");
                                                    setWalletBalance(selectedOption.balance); // Store balance for validation
                                                }}
                                                isDisabled={!selectedFromWallet} // Disable selection if no wallet selected
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <label className="form-label">Transfer To Wallet</label>
                                            <Select
                                                options={filteredToWalletOptions}
                                                value={filteredToWalletOptions.find(option => option.value === selectedToWallet)}
                                                onChange={(selectedOption) => {
                                                    setSelectedToWallet(selectedOption.value);
                                                    setErrorMessage("");
                                                }}
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <label className="form-label">Enter Amount</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={amount}
                                                onChange={(e) => {
                                                    if (!selectedWalletId) {
                                                        setErrorMessage("Please select a wallet first.");
                                                        return;
                                                    }
                                                    const value = e.target.value;
                                                    if (parseFloat(value) > walletBalance) {
                                                        setErrorMessage("Amount cannot exceed wallet balance");
                                                    } else {
                                                        setErrorMessage("");
                                                    }
                                                    setAmount(value);
                                                }}
                                                onWheel={(e) => e.target.blur()}
                                                onKeyDown={(e) => {
                                                    if (["+", "-", "e", "E"].includes(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div className="form-group mb-2">
                                            <label htmlFor="remark" className="small mb-1">Enter Remark</label>
                                            <input
                                                type="text"
                                                name="remark"
                                                className="form-control py-3"
                                                value={remark}
                                                onChange={(e) => { setRemark(e.target.value); setErrorMessage("") }}

                                            />
                                        </div>

                                        {errorMessage && (
                                            <div className="text-danger">{errorMessage}</div>
                                        )}

                                        <div className="text-center mt-4">
                                            <button className="btn btn-success btn-block w-100 fw-semibold" type="button" onClick={handleFundTransfer} disabled={!!errorMessage}>
                                                {loading ? (
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                ) : (
                                                    <span>Submit</span>
                                                )}
                                            </button>

                                            <button className="btn btn-secondary px-5 mt-3" type="button" onClick={() => { setActiveModelSelfTransfer(false); handleRest("") }}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                )
            }

        </div>
    )
};

export default WalletDetails;