import React from "react";

const ViewDocumentModel = ({modelHeading, documentData,setIsModalOpen}) => {

    const handleFullscreen = (id) => {
     /*    const iframe = document.getElementById(id);
        if (iframe.requestFullscreen) {
          iframe.requestFullscreen();
        } else if (iframe.mozRequestFullScreen) {
          iframe.mozRequestFullScreen();
        } else if (iframe.webkitRequestFullscreen) {
          iframe.webkitRequestFullscreen();
        } else if (iframe.msRequestFullscreen) {
          iframe.msRequestFullscreen();
        } */
      };

    return (
        <>
            <div className="custom_modal modal fade show d-block"  >
                <div className="modal-dialog modal-xl" >
                    <div className="modal-content" >
                        <div className="cs_mod_arrow" >
                            <h5 className="mb-0" >{modelHeading}</h5>
                            <div className="cs_row" >
                            <span className="cursor-pointer"onClick={()=>handleFullscreen("document")} ><i class="ri-fullscreen-line"></i></span>
                                <span className="cursor-pointer" onClick={() => setIsModalOpen(false)}  >
                                    <i class="ri-close-large-line"></i>
                                </span>
                            </div>
                        </div>
                        <iframe src={documentData} width="100%" height="100%" id="document" ></iframe>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </>

    );
};

export default ViewDocumentModel;
