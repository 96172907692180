import React, { useEffect, useState } from "react";
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { $ } from "react-jquery-plugin";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ApiConfig } from "../../../../services/ApiIntegration/ApiConfig";


const KycDetailsEntity = () => {

  const [rejectReason, setRejectReason] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = location.state;

  console.log(userDetails, 'userDetails');

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "rejectReason":
        setRejectReason(event.target.value);
        break;
    }
  };

  const verifyIdentity = async (id, status, reason) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getverifyidentity(id, status, reason).then(
      async (result) => {
        if (result?.success) {
          LoaderHelper.loaderStatus(false);
          try {
            alertSuccessMessage("user Kyc successfully Verified");
            $("#rejectmodal").modal("hide");
            navigate("/pending_personal_kyc");
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(result.message);
        }
      }
    );
  };

  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <form className="form-data">
            <header className="page-header ">
              <div className="container-xl px-4">
                <div className="page-header-content">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto">
                      <h1 className="page-header-title">
                        <Link to="/entity_kyc" className="page-header-icon">
                          <i className="fa fa-arrow-left" ></i>
                        </Link>
                        <div className="hd_u_info" >
                          <img
                            src={!userDetails?.user_selfie ? "assets/img/illustrations/profiles/profile-5.png" : ApiConfig?.appUrl + userDetails?.user_selfie} alt="" className="img-account-profile rounded-circle"
                            onError={(e) => {
                              e.target.src = "assets/img/illustrations/profiles/profile-5.png";
                            }}
                          />
                          <span>  {userDetails?.kyc?.first_name || 'Unknown User'} {userDetails?.kyc?.last_name}
                            <small className=" " > User ID: #32151456</small>
                          </span>
                        </div>
                      </h1>
                    </div>
                    {
                      userDetails?.kycVerified == 1 &&
                      <div className="col-auto">
                        <div className="row">
                          <div className="d-flex">
                            <button className="btn btn-danger btn-md btn-block" data-bs-toggle="modal" data-bs-target="#rejectmodal" type="button" >Reject</button>
                            <button className="btn mx-2 btn-md btn-success btn-block" type="button" onClick={() => verifyIdentity(userDetails?._id, 2)}>
                              Approve
                            </button>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </header>
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-xl-12 mb-4">
                  <div className="card mb-4 mb-xl-0">
                    <div className="card-body py-4">
                      <div className="d-flex align-items-start " >

                        <ul id="progressbar">
                          <li class="cursor-pointer active" id="step1">
                            <strong>Proof of Identification</strong>
                          </li>
                          <li class="cursor-pointer warning" id="step2">
                            <strong> Entity Information </strong>
                          </li>
                          <li class="cursor-pointer" id="step3">
                            <strong> Directors List </strong>
                          </li>
                          <li id="step4">
                            <strong> UBO More Then 10% </strong>
                          </li>
                          <li id="step5" className="">
                            <strong> Main Trader </strong>
                          </li>
                          <li id="step6">
                            <strong> Required Documents </strong>
                          </li>
                          <li id="step7">
                            <strong>Declaration and Submission</strong>
                          </li>
                        </ul>

                        <div class="profile_data w-100  ac_body px-4 pt-4 ">

                          {/* step 1 */}
                          <div className="step_1 d-none " >
                            <div className="d-flex align-items-center justify-content-between mb-4" >
                              <h4> Proof of Identification </h4>
                            </div>
                            <div class="row g-4 ">
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label> Name on Pan Card    </label>
                                  <p> Unknown User  <i class="ri-verified-badge-fill text-success"></i></p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label> Pan Card  </label>
                                  <p> 00000000000  <i class="ri-verified-badge-fill text-success"></i> </p>
                                </div>
                              </div>
                            </div>


                            {/* Step action  */}
                            <div className="puk_row" >
                              {/* <button className="btn btn-md btn-gray px-4" >
                              Prev.
                            </button> */}
                              <button className="btn btn-md btn-black px-4" >
                                Next
                              </button>
                            </div>

                          </div>

                          {/* step 2 */}
                          <div className="step_2 d-none" >
                            <div className="d-flex align-items-center justify-content-between mb-4" >
                              <h4>  Entity Information </h4>
                            </div>
                            <div class="row g-4">
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label> Entity Name   </label>
                                  <p> -------- </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>   Entity Type </label>
                                  <p> -------- </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>   Registerd Country </label>
                                  <p> -------- </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label> Registration Number   </label>
                                  <p> -------- </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>    Date Of Incorporation   </label>
                                  <p> -------- </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>  Website (Optional) </label>
                                  <p> -------- </p>
                                </div>
                              </div>
                              <div className="col-12" ><hr className="my-0" /></div>
                              <div className="col-12" >
                                <p>  <b className="text-dark" > Registerd Address</b> </p>
                                <div className="row" >
                                  <div class="col-lg-6 col-xl-6">
                                    <div class="label-group">
                                      <label>   Address</label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-xl-2">
                                    <div class="label-group">
                                      <label>   City</label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-xl-2">
                                    <div class="label-group">
                                      <label>  State </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-xl-2">
                                    <div class="label-group">
                                      <label>  Pin Code </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12" >
                                <p>  <b className="text-dark" >  Opertational Address </b> </p>
                                <div className="row" >
                                  <div class="col-lg-6 col-xl-6">
                                    <div class="label-group">
                                      <label>   Address</label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-xl-2">
                                    <div class="label-group">
                                      <label>   City</label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-xl-2">
                                    <div class="label-group">
                                      <label>  State </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-xl-2">
                                    <div class="label-group">
                                      <label>  Pin Code </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12" ><hr className="my-0" /></div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>  Email id   </label>
                                  <p> -------- </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>  Mobile Number    </label>
                                  <p> -------- </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>  Purpose of Application    </label>
                                  <p> -------- </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>  Annual Trunover (INR)    </label>
                                  <p> -------- </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>  Source of Capital     </label>
                                  <p> -------- </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>  Total Assets Value (INR)     </label>
                                  <p> -------- </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>  GST Number      </label>
                                  <p> -------- </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>  FIU RE. No     </label>
                                  <p> -------- </p>
                                </div>
                              </div>
                            </div>

                            {/* Step action  */}
                            <div className="puk_row" >
                              <button className="btn btn-md btn-gray px-4" >
                                Prev.
                              </button>
                              <button className="btn btn-md btn-black px-4" >
                                Next
                              </button>
                            </div>

                          </div>

                          {/* step 3 */}
                          <div className="step_3 " >
                            <div className="d-flex align-items-center justify-content-between mb-4" >
                              <h4> Directors List </h4>
                            </div>
                            <div class="row g-4 ">

                              <div className="col-12" >
                                <p>  <b className="text-dark" >  Director 1</b> </p>
                                <div className="row" >
                                  <div class="col-lg-6 col-xl-4">
                                    <div class="label-group">
                                      <label>   Director Name </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-xl-4">
                                    <div class="label-group">
                                      <label>  Aadhar Number  </label>
                                      <p> 000000000000 <i class="ri-verified-badge-fill text-success"></i> </p>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-xl-4">
                                    <div class="label-group">
                                      <label>  Pan Number </label>
                                      <p> 0000000000 <i class="ri-verified-badge-fill text-success"></i> </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12" ><hr className="my-0" /></div>

                              <div className="col-12" >
                                <p>  <b className="text-dark" >  Director 2</b> </p>
                                <div className="row" >
                                  <div class="col-lg-6 col-xl-4">
                                    <div class="label-group">
                                      <label>   Director Name </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-xl-4">
                                    <div class="label-group">
                                      <label>  Aadhar Number  </label>
                                      <p> 000000000000 <i class="ri-verified-badge-fill text-success"></i> </p>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-xl-4">
                                    <div class="label-group">
                                      <label>  Pan Number </label>
                                      <p> 0000000000 <i class="ri-verified-badge-fill text-success"></i> </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12" ><hr className="my-0" /></div>


                            </div>


                            {/* Step action  */}
                            <div className="puk_row" >
                              {/* <button className="btn btn-md btn-gray px-4" >
                              Prev.
                            </button> */}
                              <button className="btn btn-md btn-black px-4" >
                                Next
                              </button>
                            </div>

                          </div>


                        </div>




                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </form>
        </main>
      </div>

      <div className="modal" id="rejectmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered alert_modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">Are You Sure{" "}<>{userDetails?.first_name}</>{" "}?</h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group mb-4 ">
                  <label className="small mb-1"> Enter Reason </label>
                  <textarea className="form-control form-control-solid" rows="7" placeholder="Enter your message.." value={rejectReason} name="rejectReason" onChange={handleInputChange}></textarea>
                </div>
                <button className="btn btn-danger btn-block w-100" type="button" disabled={!rejectReason}
                  onClick={() => verifyIdentity(location.state?._id, 3, rejectReason)}>
                  Continue
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default KycDetailsEntity;
