import React from "react";

const TradeDetails = ({ activeTab }) => {

    return (
        <div className={`tab-pane ${activeTab === 8 ? "active" : ""}`} >

            <div className="card-body py-4" >
                <div className="profile_data  ac_body" >
                    <div className=" " >
                        <div className="custom-tab-2">
                            <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a href="#QuickBuySell" id="QuickBuySell-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true"> Quick Buy Sell  </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a href="#SpotTrades" id="SpotTrades-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> Spot Trades  </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a href="#FutureTrades" id="FutureTrades-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> Future Trades </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a href="#PTwoPTrades" id="PTwoPTrades-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1">  P2P Trades  </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a href="#Invest" id="Invest-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1">  Invest </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content">

                        {/* Quick Buy Sell orders history tab  */}
                        <div className="tab-pane active" id="QuickBuySell" role="tabpanel" aria-labelledby="QuickBuySell-tab">
                            <ul className="nav custom-tabs mb-4">
                                <li className="buysell-tab">
                                    <a className="">  Ongoing Orders   </a>
                                </li>
                                <li className="buysell-tab">
                                    <a className="active"> All Orders </a>
                                </li>
                                <li className="buysell-tab">
                                    <a className="">     Buy Orders   </a>
                                </li>
                                <li className="buysell-tab">
                                    <a className=""> Sell Orders </a>
                                </li>
                            </ul>
                            <div className="filter_bar search-header px-0 g-2">
                                <form className="row align-items-center w-100 gx-2">
                                    <div className="col-2">
                                        <select className="form-control form-select cursor-pointer">
                                            <option value="">  Order Status  </option>
                                            <option value="Completed"> All Status  </option>
                                            <option value="Pending"> Completed </option>
                                            <option value="Pending"> Pending </option>
                                            <option value="Pending"> Rejected </option>
                                        </select>
                                    </div>
                                    <div className="col-2">
                                        <input
                                            type="date"
                                            className="form-control form-control-solid"
                                            data-provide="datepicker"
                                            id="litepickerRangePlugin"
                                            name="dateFrom"
                                        />
                                    </div>
                                    <div className="col-2">
                                        <input
                                            type="date"
                                            className="form-control form-control-solid"
                                            data-provide="datepicker"
                                            id="litepickerRangePlugin"
                                            name="dateTo"

                                        />
                                    </div>
                                    <div className="col-2">
                                        <div className="row align-items-center gx-2">
                                            <div className="col">
                                                <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                                    Apply
                                                </button>
                                            </div>
                                            <div className="col">
                                                <button className="btn btn-light btn-block w-100" type="button" >
                                                    <i className="ri-reset-left-line"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <label className="small text-dark mb-1" >  </label>
                                <div className="dd_search">
                                    <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                    <i className="fas fa-search"></i>
                                </div>

                            </div>
                            <div className="table-responsive m_table">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Coin Name</th>
                                            <th scope="col" className="text-end">Available Balance</th>
                                            <th scope="col" className="text-end">Locked Balance</th>
                                            <th scope="col" className="text-end">Hold Balance</th>
                                            <th scope="col" className="text-end">Total Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="c_view">
                                                    <img src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" className="img-fluid" alt="" />
                                                    <span>USDT <br />
                                                        <small>Tether</small>
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <div className="c_view justify-content-end">
                                                    <span>0.00000 <br />
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <div className="c_view justify-content-end">
                                                    <span>0.00000 <br />
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <span> 0.00000 </span>
                                            </td>
                                            <td className="text-end">
                                                <span> 0.00 </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="c_view">
                                                    <img src="assets/img/nocoin.png" className="img-fluid" alt="" />
                                                    <span>INR <br />
                                                        <small>INR</small>
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <div className="c_view justify-content-end">
                                                    <span>0.00000 <br />
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <div className="c_view justify-content-end">
                                                    <span>0.00000 <br />
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <span> 0.00000 </span>
                                            </td>
                                            <td className="text-end">
                                                <span> 0.00 </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/*  Spot Trades history tab  */}
                        <div className="tab-pane" id="SpotTrades" role="tabpanel" aria-labelledby="SpotTrades-tab">
                            <ul className="nav custom-tabs mb-4">
                                <li className="buysell-tab">
                                    <a className=""> Open Orders   </a>
                                </li>
                                <li className="buysell-tab">
                                    <a className="active"> Order History </a>
                                </li>
                                <li className="buysell-tab">
                                    <a className="">  Trade History   </a>
                                </li>
                            </ul>
                            <div className="filter_bar search-header px-0 g-2">
                                <form className="row align-items-center w-100 gx-2">
                                    <div className="col-2">
                                        <select className="form-control form-select cursor-pointer">
                                            <option value="">  Order Side  </option>
                                            <option value="Completed"> All Status  </option>
                                            <option value="Pending"> Buy Orders </option>
                                            <option value="Pending">  Sell Orders </option>
                                        </select>
                                    </div>
                                    <div className="col-2">
                                        <input
                                            type="date"
                                            className="form-control form-control-solid"
                                            data-provide="datepicker"
                                            id="litepickerRangePlugin"
                                            name="dateFrom"
                                        />
                                    </div>
                                    <div className="col-2">
                                        <input
                                            type="date"
                                            className="form-control form-control-solid"
                                            data-provide="datepicker"
                                            id="litepickerRangePlugin"
                                            name="dateTo"

                                        />
                                    </div>
                                    <div className="col-2">
                                        <div className="row align-items-center gx-2">
                                            <div className="col">
                                                <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                                    Apply
                                                </button>
                                            </div>
                                            <div className="col">
                                                <button className="btn btn-light btn-block w-100" type="button" >
                                                    <i className="ri-reset-left-line"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <label className="small text-dark mb-1" >  </label>
                                <div className="dd_search">
                                    <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                    <i className="fas fa-search"></i>
                                </div>

                            </div>
                            <div className="table-responsive m_table">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Coin Name</th>
                                            <th scope="col" className="text-end">Available Balance</th>
                                            <th scope="col" className="text-end">Locked Balance</th>
                                            <th scope="col" className="text-end">Hold Balance</th>
                                            <th scope="col" className="text-end">Total Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="c_view">
                                                    <img src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" className="img-fluid" alt="" />
                                                    <span>USDT <br />
                                                        <small>Tether</small>
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <div className="c_view justify-content-end">
                                                    <span>0.00000 <br />
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <div className="c_view justify-content-end">
                                                    <span>0.00000 <br />
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <span> 0.00000 </span>
                                            </td>
                                            <td className="text-end">
                                                <span> 0.00 </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="c_view">
                                                    <img src="assets/img/nocoin.png" className="img-fluid" alt="" />
                                                    <span>INR <br />
                                                        <small>INR</small>
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <div className="c_view justify-content-end">
                                                    <span>0.00000 <br />
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <div className="c_view justify-content-end">
                                                    <span>0.00000 <br />
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <span> 0.00000 </span>
                                            </td>
                                            <td className="text-end">
                                                <span> 0.00 </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/*  Sport orders history tab  */}
                        <div className="tab-pane" id="FutureTrades" role="tabpanel" aria-labelledby="FutureTrades-tab">
                            <ul className="nav custom-tabs mb-4">
                                <li className="buysell-tab">
                                    <a className=""> Open Orders   </a>
                                </li>
                                <li className="buysell-tab">
                                    <a className="active"> Order History </a>
                                </li>
                                <li className="buysell-tab">
                                    <a className="">  Trade History   </a>
                                </li>
                            </ul>
                            <div className="filter_bar search-header px-0 g-2">
                                <form className="row align-items-center w-100 gx-2">
                                    <div className="col-2">
                                        <select className="form-control form-select cursor-pointer">
                                            <option value="">  Order Side  </option>
                                            <option value="Completed"> All Status  </option>
                                            <option value="Pending"> Buy Orders </option>
                                            <option value="Pending">  Sell Orders </option>
                                        </select>
                                    </div>
                                    <div className="col-2">
                                        <input
                                            type="date"
                                            className="form-control form-control-solid"
                                            data-provide="datepicker"
                                            id="litepickerRangePlugin"
                                            name="dateFrom"
                                        />
                                    </div>
                                    <div className="col-2">
                                        <input
                                            type="date"
                                            className="form-control form-control-solid"
                                            data-provide="datepicker"
                                            id="litepickerRangePlugin"
                                            name="dateTo"

                                        />
                                    </div>
                                    <div className="col-2">
                                        <div className="row align-items-center gx-2">
                                            <div className="col">
                                                <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                                    Apply
                                                </button>
                                            </div>
                                            <div className="col">
                                                <button className="btn btn-light btn-block w-100" type="button" >
                                                    <i className="ri-reset-left-line"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <label className="small text-dark mb-1" >  </label>
                                <div className="dd_search">
                                    <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                    <i className="fas fa-search"></i>
                                </div>
                            </div>
                            <div className="table-responsive m_table">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Coin Name</th>
                                            <th scope="col" className="text-end">Available Balance</th>
                                            <th scope="col" className="text-end">Locked Balance</th>
                                            <th scope="col" className="text-end">Hold Balance</th>
                                            <th scope="col" className="text-end">Total Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="c_view">
                                                    <img src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" className="img-fluid" alt="" />
                                                    <span>USDT <br />
                                                        <small>Tether</small>
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <div className="c_view justify-content-end">
                                                    <span>0.00000 <br />
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <div className="c_view justify-content-end">
                                                    <span>0.00000 <br />
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <span> 0.00000 </span>
                                            </td>
                                            <td className="text-end">
                                                <span> 0.00 </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="c_view">
                                                    <img src="assets/img/nocoin.png" className="img-fluid" alt="" />
                                                    <span>INR <br />
                                                        <small>INR</small>
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <div className="c_view justify-content-end">
                                                    <span>0.00000 <br />
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <div className="c_view justify-content-end">
                                                    <span>0.00000 <br />
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <span> 0.00000 </span>
                                            </td>
                                            <td className="text-end">
                                                <span> 0.00 </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/*  Spot Trades history tab  */}
                        <div className="tab-pane" id="PTwoPTrades" role="tabpanel" aria-labelledby="PTwoPTrades-tab">
                            <h5> Data Not Available</h5>
                        </div>

                        {/*  Spot Trades history tab  */}
                        <div className="tab-pane" id="Invest" role="tabpanel" aria-labelledby="Invest-tab">
                            <h5 className="mx-auto w-100 text-denter d-block" > Data Not Available</h5>
                        </div>

                    </div>
                </div>


            </div>

        </div>
    )
};

export default TradeDetails;