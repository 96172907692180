import axios from "axios";

export const ApiCallPost = async (url, parameters, headers) => {
  try {
    const response = await axios.post(url, parameters, { headers: headers });
    return response?.data;
  }catch (error) {
    if (handleTokenExpiration(error)) return;
    return error?.response?.data?.message || 'An error occurred during the request.';
  }
};

export const ApiCallDelete = async (url, headers) => {
  try {
    const response = await axios.delete(url, { headers: headers });
    return response.data;
  }catch (error) {
    if (handleTokenExpiration(error)) return;
    return error?.response?.data?.message || 'An error occurred during the request.';
  }
};

export const ApiCallGet = async (url, headers) => {
  try {
    const response = await axios.get(url, { headers: headers });
    return response.data;
  }catch (error) {
    if (handleTokenExpiration(error)) return;
    return error?.response?.data?.message || 'An error occurred during the request.';
  }
};

export const ApiCallPut = async (url, parameters, headers) => {
  try {
    const response = await axios.put(url, parameters, { headers: headers });
    return response.data;
  } catch (error) {
    if (handleTokenExpiration(error)) return;
    return error?.response?.data?.message || 'An error occurred during the request.';
  }
};


const handleTokenExpiration = (error) => {
  const errorMessage = error?.response?.data?.message;
  if (errorMessage === 'Token is expired with message: jwt expired' || errorMessage === 'Token is expired with message: invalid signature') {
    sessionStorage.clear();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    window.location.href = '/login';
  }
};

