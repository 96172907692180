import React, { useState } from 'react'
import AuthService from '../../../../services/ApiIntegration/AuthService';
import { alertErrorMessage, alertSuccessMessage } from '../../../CustomComponent/CustomAlert';
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { useNavigate } from 'react-router-dom';
import { MultiSelect } from "react-multi-select-component";

const AddSubadmin = () => {

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    email: '',
    password: '',
    confirmPassword: '',
    permissions: [],
    adminType: 0
  })


  const handleAddSubAdmin = async () => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.AddsubAdmin(formData);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        handleSubadmin();
        resetEditInput();
        navigate('/sub_admin_list');
      } else {
        alertErrorMessage(result);
      }
    } catch (error) {
      alertErrorMessage(error?.message || "An unexpected error occurred.");
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };

  const handleSubadmin = async () => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.getSubAdminList();
      LoaderHelper.loaderStatus(false);
      if (!result.success) {
        alertErrorMessage(result?.message);
      };
    } catch (error) {
      alertErrorMessage(error?.message);
    }
    finally { LoaderHelper.loaderStatus(false) };
  }

  const resetEditInput = () => {
    setFormData({ first_name: '', mobile_number: '', gender: '', email_or_phone: '', password: '', confirm_password: '', permissions: [], admin_type: 0 });
  };

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, permissions: e || [] });
    }
  };

  const options = [
    { value: 1, label: 'Users' },
    { value: 2, label: 'KYC Manager' },
    { value: 3, label: 'Currency Management' },
    { value: 4, label: 'Currency Pair Management' },
    { value: 5, label: 'Fund Deposit Management' },
    { value: 6, label: 'Fund Withdrawal Management' },
    { value: 7, label: "Exchange Profit" },
    { value: 8, label: "Trading Report" },
    { value: 9, label: 'OrderBook' },
    { value: 10, label: 'Support' },
    { value: 11, label: 'Notification Management' },
    { value: 12, label: 'Banner Management' },
  ];

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header ">
          <div className="container-xl px-4">
            <div className="page-header-content">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <h1 className="page-header-title"> 
                    Add new sub admin
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4">
          <div className="card mb-4">
            <div className="card-header">Enter Sub Admin Details</div>
            <div className="card-body pt-4">
              <form>
                <div className="row gx-3 mb-3">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="inputFirstName"> Full Name <em>*</em></label>
                    <input className="form-control  form-control-solid" id="inputFirstName" name='firstName' type="text" placeholder="Enter your First Name " value={formData.firstName} onChange={handleChange} />
                  </div>

                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="inputNumber">Mobile Number  <em>*</em></label>
                    <input className="form-control  form-control-solid" id="inputNumber" name='lastName' type="text" placeholder="Enter your Mobile Number " value={formData.lastName} onChange={handleChange} />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="inputEmailAddress">Email <em>*</em></label>
                    <input className="form-control form-control-solid" id="inputEmailAddress" type="email" name='email' placeholder="Enter your email address" value={formData.email} onChange={handleChange} />
                  </div>
                </div>

                <div className="row gx-3 mb-3">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="inputLocation">Password <em>*</em></label>
                    <input className="form-control form-control-solid" id="inputLocation" type="text" placeholder="Enter your Password" name="password" value={formData.password} onChange={handleChange} />
                  </div>

                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="inputLocation"> Confirm Password</label>
                    <input className="form-control form-control-solid" id="inputLocation" type="text" placeholder="Enter your Password Again" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} />
                  </div>

                  <div className="col-md-4" >
                    <label className="small mb-1" htmlFor="inputLocation">Permissions <em>*</em></label>
                    <MultiSelect
                      options={options}
                      value={formData.permissions}
                      onChange={handleChange}
                      labelledBy="Select"
                    />
                  </div>

                </div>
                <div className="d-flex justify-content-center w-100 mt-5 mb-4">
                  <button className="btn btn-indigo w-25 fs-6" type="button" onClick={handleAddSubAdmin}> Submit Details </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default AddSubadmin