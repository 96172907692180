import React, { useEffect, useState } from "react";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";

const QuickPayLimit = ({ activeTab }) => {

    const [userLimitData, setUserLimitData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState("");
    const [isActiveModel, setIsActiveModel] = useState(false);
    const [activeButton, setActiveButton] = useState("Limits");


    const [formData, setFormData] = useState({
        inrTotalWithdrawal: "",
        inrTotalDailyWithdrawalLimit: "",
        inrMinWithdrawalLimit: "",
        inrMaxWithdrawalLimit: "",
        cryptoTotalWithdrawal: "",
        cryptoTotalDailyWithdrawalLimit: "",
        cryptoMinWithdrawalLimit: "",
        cryptoMaxWithdrawalLimit: "",
        inrFee: "",
        cryptoFee: "",
        dailyPay: "",
        remark: "",
    });


    useEffect(() => {
        if (userLimitData) {
            setFormData({
                inrTotalWithdrawal: userLimitData.freeInrLimit || "",
                inrTotalDailyWithdrawalLimit: userLimitData.dailyInrLimit || "",
                inrMinWithdrawalLimit: userLimitData.minAmountInr || "",
                inrMaxWithdrawalLimit: userLimitData.maxAmountInr || "",
                cryptoTotalWithdrawal: userLimitData.freeUsdLimit || "",
                cryptoTotalDailyWithdrawalLimit: userLimitData.dailyUsdLimit || "",
                cryptoMinWithdrawalLimit: userLimitData.minAmountUsd || "",
                cryptoMaxWithdrawalLimit: userLimitData.maxAmountUsd || "",
                inrFee: userLimitData.inrFee || "",
                cryptoFee: userLimitData.cryptoFee || "",
                dailyPay: userLimitData.totalOrders || "",
                remark: userLimitData.remark || "",
            });
        }
    }, [userLimitData]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };


    const handleLimitData = async () => {
        await AuthService.adminQuickPayLimiDetails().then(async (result) => {
            if (result.success) {
                try {
                    setUserLimitData(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result);
            }
        });
    };

    useEffect(() => {
        if (activeTab === "quickPayLimit") {
            handleLimitData();
        }
    }, [activeTab])


    const handleUpdateUserLimit = async () => {
        setApiResponse("");
        try {
            setIsLoading(true);
            const result = await AuthService.updateExchangeQuickyPayLimit(formData);
            if (result.success) {
                setIsActiveModel(false);
                handleLimitData();
                setFormData({});
                setIsLoading(false);
                alertSuccessMessage("User Quick Pay Limit updated successfully.");
            } else {
                setApiResponse(result);
                setIsLoading(false);
            }
        } catch (error) {
            setApiResponse(error);
            setIsLoading(false);
        }
    };

    return (
        <div className={`tab-pane ${activeTab === "quickPayLimit" ? "active" : ""}`} >
            <div className="d-flex align-items-center justify-content-between mt-0 " >
                <div class="custom-tab-2 mb-3 mt-4">
                    <ul class="nav nav-pills justify-content-start " role="tablist">
                        <li class="nav-item" role="presentation">
                            <button type="button" class={`nav-link ${activeButton === "Limits" ? "active" : ""}`} onClick={() => setActiveButton("Limits")}>
                                Limits
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button type="button" class={`nav-link ${activeButton === "Fees" ? "active" : ""}`} onClick={() => setActiveButton("Fees")} >
                                Fees
                            </button>
                        </li>
                    </ul>
                </div>
                <button className="btn btn-warning btn-sm  " onClick={() => setIsActiveModel(true)}> Update Limits  </button>
            </div>

            {
                activeButton === "Limits" &&
                <div className="profile_data  ac_body" >
                    <div className="row g-3 mt-0">
                        <div className="col-lg-6" >
                            <div className="card h-100">
                                <div className="card-body py-4">
                                    <div className="d-flex justify-content-between" >
                                        <h5> INR Withdrawal Limits  </h5>
                                    </div>
                                    <div className="row g-4" >
                                        <div className=" col-lg-12 " >
                                            <div className="profile_data fc_details  ac_body " >
                                                <div className="d-flex align-items-center justify-content-between mb-4" >
                                                    <h5 className="mb-0" > Overall free Withdrawal Limit (1m)  </h5>
                                                </div>
                                                <div className="row g-4">
                                                    <div className="col-lg-4 col-xl-4">
                                                        <div className="label-group"><label>Total</label>
                                                            <p>  {userLimitData?.freeInrLimit} ₹  </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-lg-12" >
                                            <div className="profile_data fc_details  ac_body " >
                                                <div className="d-flex align-items-center justify-content-between mb-4" >
                                                    <h5 className="mb-0" >  Daily Withdrawal Limit (24H)  </h5>
                                                </div>
                                                <div className="row g-4">
                                                    <div className="col-lg-4 col-xl-4">
                                                        <div className="label-group"><label>  Total     </label><p>  {userLimitData?.dailyInrLimit} ₹  </p></div>
                                                    </div>
                                                    <div className="col-lg-4 col-xl-4">
                                                        <div className="label-group"><label>  Min Amount     </label><p>  {userLimitData?.minAmountInr} ₹  </p></div>
                                                    </div>
                                                    <div className="col-lg-4 col-xl-4">
                                                        <div className="label-group"><label>   Max      </label><p>   {userLimitData?.maxAmountInr} ₹  </p></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" >
                            <div className="card h-100">
                                <div className="card-body py-4">
                                    <div className="d-flex justify-content-between" >
                                        <h5> Crypto Withdrawal  Limits  </h5>
                                    </div>
                                    <div className="row g-4" >
                                        <div className=" col-lg-12" >
                                            <div className="profile_data fc_details  ac_body " >
                                                <div className="d-flex align-items-center justify-content-between mb-4" >
                                                    <h5 className="mb-0" > Overall Withdrawal Limit (1M)  </h5>
                                                </div>
                                                <div className="row g-4">
                                                    <div className="col-lg-4 col-xl-4">
                                                        <div className="label-group"><label>  Total     </label><p>  $ {userLimitData?.freeUsdLimit} </p></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className=" col-lg-12" >
                                            <div className="profile_data fc_details  ac_body " >
                                                <div className="d-flex align-items-center justify-content-between mb-4" >
                                                    <h5 className="mb-0" >  Daily Withdrawal Limit (24H)  </h5>
                                                </div>
                                                <div className="row g-4">
                                                    <div className="col-lg-4 col-xl-4">
                                                        <div className="label-group"><label>  Total     </label><p>  $ {userLimitData?.dailyUsdLimit} </p></div>
                                                    </div>
                                                    <div className="col-lg-4 col-xl-4">
                                                        <div className="label-group"><label>  Min Amount     </label><p>  $ {userLimitData?.minAmountUsd}  </p></div>
                                                    </div>
                                                    <div className="col-lg-4 col-xl-4">
                                                        <div className="label-group"><label>   Max      </label><p>   $ {userLimitData?.maxAmountUsd}  </p></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="card mt-3">
                        <div className="card-body py-4">
                            <div className=" col-lg-12 " >
                                <div className="profile_data fc_details  ac_body " >
                                    <div className="d-flex align-items-center justify-content-between mb-4" >
                                        <h5 className="mb-0" > Daily Pay Number of Orders </h5>
                                    </div>
                                    <div className="row g-4">
                                        <div className="col-lg-4 col-xl-4">
                                            <div className="label-group"><label>  Total     </label><p>   {userLimitData?.totalOrders || "----"}   </p></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }



            {
                activeButton === "Fees" &&
                <div className="profile_data  ac_body" >
                    <div className="row g-3 mt-0">
                        <div className="col-lg-6" >
                            <div className="card h-100">
                                <div className="card-body py-4">
                                    <div className="d-flex justify-content-between" >
                                        <h5> INR Fees  </h5>
                                    </div>
                                    <div className="row g-4" >
                                        <div className=" col-lg-12 " >
                                            <div className="profile_data fc_details  ac_body " >

                                                <div className="row g-4">
                                                    <div className="col-lg-4 col-xl-4">
                                                        <div className="label-group"><label>Total</label>
                                                            <p>  {userLimitData?.inrFee} ₹  </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" >
                            <div className="card h-100">
                                <div className="card-body py-4">
                                    <div className="d-flex justify-content-between" >
                                        <h5> Crypto Fees  </h5>
                                    </div>
                                    <div className="profile_data fc_details ac_body">
                                        <div className="row g-4">
                                            <div className="col-lg-4 col-xl-4">
                                                <div className="label-group"><label>  Total     </label><p>  $ {userLimitData?.cryptoFee} </p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }


            <div className="card mt-3">
                <div className="card-body py-4">

                    <div className="profile_data  ac_body   " >
                        <div className="d-flex justify-content-between" >
                            <h5> Updated History </h5>
                        </div>
                        <div className="table-responsive m_table">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col" className=""> Date/Time </th>
                                        <th scope="col" className=""> Limit Type </th>
                                        <th scope="col" className=""> Remark By </th>
                                        <th scope="col" className=""> Remark </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="">
                                            <span> 11-12-2024, 12:11 AM </span>
                                        </td>
                                        <td className="">
                                            <span>    INR/Crypto </span>
                                        </td>
                                        <td className="">
                                            <span> ----  </span>
                                        </td>
                                        <td className="">
                                            <span> Message here......... </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


            {
                isActiveModel && (
                    <>
                        <div className="modal show d-block" >
                            <div className="modal-dialog modal-dialog-centered modal-xl">
                                <div className="modal-content  ">
                                    <div className="modal-header">
                                        <h5 className="modal-title" >Quick Pay Limits </h5>
                                    </div>
                                    <div className="modal-body p-0 row">
                                        <div className=" col-lg-4 " >
                                            <h6 className="mb-3" > INR Withdrawal Limits </h6>

                                            <div className="form-group mb-3">
                                                <label className="small mb-1" htmlFor="inrTotalWithdrawal">Free INR Withdrawal Limit (1m)<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="inrTotalWithdrawal"
                                                    name="inrTotalWithdrawal"
                                                    placeholder="Enter Amount"
                                                    type="text"
                                                    value={formData.inrTotalWithdrawal}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="small mb-1" htmlFor="inrTotalDailyWithdrawalLimit">Daily INR Withdrawal Limit<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="inrTotalDailyWithdrawalLimit"
                                                    name="inrTotalDailyWithdrawalLimit"
                                                    placeholder="Enter Amount"
                                                    type="text"
                                                    value={formData.inrTotalDailyWithdrawalLimit}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="small mb-1" htmlFor="inrMinWithdrawalLimit">MIN INR Transactions Limit<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="inrMinWithdrawalLimit"
                                                    name="inrMinWithdrawalLimit"
                                                    placeholder="Enter Amount"
                                                    type="text"
                                                    value={formData.inrMinWithdrawalLimit}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group ">
                                                <label className="small mb-1" htmlFor="inrMaxWithdrawalLimit">MAX INR Transactions Limit<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="inrMaxWithdrawalLimit"
                                                    name="inrMaxWithdrawalLimit"
                                                    type="text"
                                                    value={formData.inrMaxWithdrawalLimit}
                                                    onChange={handleChange}
                                                />
                                            </div>


                                        </div>

                                        <div className=" col-lg-4 " >
                                            <h6 className="mb-3" > INR Crypto Limits </h6>
                                            <div className="form-group mb-3">
                                                <label className="small mb-1" htmlFor="cryptoTotalWithdrawal">Crypto free Withdrawal Limit (1m)<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="cryptoTotalWithdrawal"
                                                    name="cryptoTotalWithdrawal"
                                                    type="text"
                                                    value={formData.cryptoTotalWithdrawal}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="small mb-1" htmlFor="cryptoTotalDailyWithdrawalLimit"> Daily Crypto Withdrawal Limit <em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="cryptoTotalDailyWithdrawalLimit"
                                                    name="cryptoTotalDailyWithdrawalLimit"
                                                    type="text"
                                                    value={formData.cryptoTotalDailyWithdrawalLimit}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="small mb-1" htmlFor="cryptoMinWithdrawalLimit">MIN Crypto Transactions Limit<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="cryptoMinWithdrawalLimit"
                                                    name="cryptoMinWithdrawalLimit"
                                                    type="text"
                                                    value={formData.cryptoMinWithdrawalLimit}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group  ">
                                                <label className="small mb-1" htmlFor="cryptoMaxWithdrawalLimit">MAX Crypto Transactions Limit<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="cryptoMaxWithdrawalLimit"
                                                    name="cryptoMaxWithdrawalLimit"
                                                    type="text"
                                                    value={formData.cryptoMaxWithdrawalLimit}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className=" col-lg-4 " >
                                            <h6 className="mb-3" > INR Crypto Limits </h6>
                                            <div className="form-group mb-3">
                                                <label className="small mb-1" htmlFor="inrFee">INR fee<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="inrFee"
                                                    name="inrFee"
                                                    type="text"
                                                    value={formData.inrFee}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="small mb-1" htmlFor="cryptoFee">Crypto fee<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="cryptoFee"
                                                    name="cryptoFee"
                                                    type="text"
                                                    value={formData.cryptoFee}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group ">
                                                <label className="small mb-1" htmlFor="dailyPay">Daily Pay Number of Orders<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="dailyPay"
                                                    name="dailyPay"
                                                    type="text"
                                                    value={formData.dailyPay}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 mt-3  ">
                                            <div className="form-group ">
                                                <label className="small mb-1" htmlFor="remark">Remark<em>*</em></label>
                                                <textarea
                                                    className="form-control py-3"
                                                    rows="3"
                                                    placeholder="Enter your Remark.."
                                                    name="remark"
                                                    value={formData.remark}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className=" d-flex justify-content-center gap-3 flex-between w-100 mt-4">
                                            <button className="btn btn-primary btn-block px-5 btn-md "
                                                type="button"
                                                onClick={handleUpdateUserLimit}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                                                    <span>Submit Details</span>}
                                            </button>
                                            <button className="btn btn-secondary btn-block px-5  btn-md " type="button"
                                                onClick={() => {
                                                    setIsActiveModel(false);
                                                    setApiResponse("");
                                                }}>
                                                Cancel
                                            </button> 
                                        </div> 
                                        {apiResponse && <div className="text-danger">{apiResponse}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </>
                )
            }


        </div >
    )
};

export default QuickPayLimit;