import React, { useEffect, useState } from "react";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import Swal from "sweetalert2";

const WalletDetails = ({ activeTab, row }) => {


    const [walletsData, setWalletsData] = useState([]);
    const [totalDataLength, setTotalDataLength] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [walletType, setWalletType] = useState("");

    const [skip, setSkip] = useState(0);
    const limit = 10;

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchKey(searchTerm);
    };

    const handleUserWallets = async () => {
        const formattedSearchKey = searchKey || "";
        let filters = {};

        if (formattedSearchKey) {
            filters.search = formattedSearchKey;
        }

        if (row?._id) {
            filters.user_id = row?._id;
        }

        if (walletType) {
            filters.wallet_type = walletType;
        }
        const queryString = new URLSearchParams(filters).toString();
        await AuthService.getUserWallets(skip, limit, queryString).then(async (result) => {
            if (result.success) {
                try {
                    setWalletsData(result.data);
                    setTotalDataLength(result?.data?.totalCount);
                } catch (error) {
                    alertErrorMessage(error);
                    setWalletsData([])
                }
            } else {
                alertErrorMessage(result.message);
                setWalletsData([])
            }
        });
    };

    useEffect(() => {
        if (activeTab == 6) {
            handleUserWallets();
        }
    }, [activeTab, skip, limit, searchKey, walletType])

    const handlePagination = (action) => {
        switch (action) {
            case "prev":
                if (skip - limit >= 0) setSkip(skip - limit);
                break;
            case "next":
                if (skip + limit < totalDataLength) setSkip(skip + limit);
                break;
            case "first":
                setSkip(0);
                break;
            case "last":
                setSkip(Math.max(totalDataLength - limit, 0));
                break;
            default:
                break;
        }
    };

    
    const handleAddFunds = async (walletId) => {
        try {
            const { isConfirmed, value } = await Swal.fire({
                title: "Are you sure You Want to Add Fund ?",
                html: `
                      <div style="text-align: left;">
                                               
                          <label for="amount">Enter Amount:</label>
                          <input id="amount" class="form-control mt-2" placeholder="Enter Amount"></input>                          
                         
                        <label for="remark" class="mt-3">Enter Remark:</label>
                         <textarea id="remark" class="form-control mt-2" rows="3" placeholder="Enter Remark"></textarea>
    
                      </div>
                    `,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#bdae3c",
                cancelButtonColor: "#e64942",
                confirmButtonText: "Yes, Update!",
                preConfirm: () => {
                    const remark = document.getElementById("remark").value.trim();
                    const amount = document.getElementById("amount").value.trim();

                    if (!amount) return Swal.showValidationMessage("You need to enter a Amount!");
                    if (!remark) return Swal.showValidationMessage("You need to enter a Remark!");

                    return { remark, amount };
                }
            });

            if (isConfirmed && value) {
                const { remark, amount } = value;

                const response = await AuthService.fundTransferUsers(remark, amount, walletId, row?._id);

                if (response?.success) {
                    Swal.fire({
                        title: "Updated!",
                        text: response?.message || "updated successfully.",
                        icon: "success",
                    });

                    handleUserWallets();
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response || "Failed to update Amount.",
                        icon: "error",
                    });
                }
            }
        } catch (error) {
            Swal.fire("Error!", "Something went wrong. Please try again.", "error");
        }
    };



    const handleHoldUnholdFunds = async (currecncyId) => {
        try {
            const { isConfirmed, value } = await Swal.fire({
                title: "Hold/Unhold Amount",
                html: `
                      <div style="text-align: left;">
                                               
                        <label for="mode" class="mt-3">Select Type:</label>
                            <select id="mode" class="form-control mt-2">
                                <option value="">Select Amount Type</option>
                                <option value="hold">hold</option>
                                <option value="unhold">unhold</option>
                             </select>

                          <label for="amount"  class="mt-3">Enter Amount:</label>
                          <input id="amount" class="form-control mt-2" placeholder="Enter Amount"></input>                          
                         
                        <label for="remark" class="mt-3">Enter Remark:</label>
                         <textarea id="remark" class="form-control mt-2" rows="3" placeholder="Enter Remark"></textarea>
    
                      </div>
                    `,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#bdae3c",
                cancelButtonColor: "#e64942",
                confirmButtonText: "Yes, Update!",
                preConfirm: () => {

                    const mode = document.getElementById("mode").value.trim();
                    const amount = document.getElementById("amount").value.trim();
                    const remark = document.getElementById("remark").value.trim();

                    if (!mode) return Swal.showValidationMessage("You need to select a type!");
                    if (!amount) return Swal.showValidationMessage("You need to enter a Amount!");
                    if (!remark) return Swal.showValidationMessage("You need to enter a Remark!");

                    return { remark, amount, mode };
                }
            });

            if (isConfirmed && value) {
                const { remark, amount, mode } = value;

                const response = await AuthService.userFundholdUnhold(remark, amount, mode, currecncyId, row?._id);

                if (response?.success) {
                    Swal.fire({
                        title: "Updated!",
                        text: response?.message || "updated successfully.",
                        icon: "success",
                    });

                    handleUserWallets();
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response || "Failed to update Amount.",
                        icon: "error",
                    });
                }
            }
        } catch (error) {
            Swal.fire("Error!", "Something went wrong. Please try again.", "error");
        }
    };


    return (
        <div className={`tab-pane ${activeTab === 6 ? "active" : ""}`} >
            <div className="card-body py-4">
                <div className="profile_data  ac_body" >
                    <h5>User Wallet</h5>
                    <br />
                    <ul className="nav custom-tabs mb-0">
                        <li className="buysell-tab">
                            <span className={walletType === "" && 'active'} onClick={() => setWalletType("")}> Overview   </span>
                        </li>
                        <li className="buysell-tab">
                            <span className={walletType === "Funding" && 'active'} onClick={() => setWalletType("Funding")}>  Funding </span>
                        </li>
                        <li className="buysell-tab">
                            <span className={walletType === "SPOT" && 'active'} onClick={() => setWalletType("SPOT")}>  Spot Wallet   </span>
                        </li>
                        <li className="buysell-tab">
                            <span className={walletType === "Futures" && 'active'} onClick={() => setWalletType("Futures")}>   Future Wallet   </span>
                        </li>
                        <li className="buysell-tab">
                            <span className={walletType === "Invest" && 'active'} onClick={() => setWalletType("Invest")}>   Invest Wallet   </span>
                        </li>
                    </ul>
                </div>
                <form className="row">
                    <div className="col-12" >
                        <div className="search-header align-items-start justify-content-between px-0">
                            <div className="ow_row" >
                                <span>Estimated Balance</span>
                                <h5 className="" >0000.000 <small>INR</small></h5>
                                <p className="mb-0"> Locked Balance ≈ 0, &nbsp; Total Hold Balance ≈ 0, &nbsp; Total Available Balance ≈ 0 </p>
                            </div>
                            <div className="dd_search">
                                <input type="search" placeholder="Search here..." name="searchKey" id="searchKey" value={searchKey} onChange={handleSearch} />
                                <i className="fas fa-search"></i>
                            </div>
                        </div>
                        <div className="table-responsive m_table">
                            {walletsData && walletsData?.wallets?.length > 0 ? (
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Coin Name</th>
                                            <th scope="col" className="text-end">Available Balance</th>
                                            <th scope="col" className="text-end">Locked Balance</th>
                                            <th scope="col" className="text-end">Hold Balance</th>
                                            <th scope="col" className="text-end">Total Balance</th>
                                            <th scope="col" className="text-end">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {walletsData.wallets &&
                                            walletsData.wallets.map((wallet) => (
                                                <tr key={wallet._id}>
                                                    <td>
                                                        <div className="c_view">
                                                            <img
                                                                src={wallet.icon_path || "assets/img/nocoin.png"}
                                                                className="img-fluid"
                                                                alt={wallet.short_name}
                                                                width="30"
                                                            />
                                                            <span>
                                                                {wallet.short_name} <br />
                                                                <small>{wallet.currency}</small>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                        <div className="c_view justify-content-end">
                                                            <span>{wallet.balance} <br /></span>
                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                        <div className="c_view justify-content-end">
                                                            <span>{wallet.locked_balance} <br /></span>
                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                        <span>{wallet.hold_balance}</span>
                                                    </td>
                                                    <td className="text-end">
                                                        <span>{(wallet.balance + wallet.locked_balance + wallet.hold_balance)}</span>
                                                    </td>

                                                    <td className="text-end">
                                                        <button className='btn btn-warning btn-md me-2' type="button" onClick={() => handleAddFunds(wallet?.currency_id)}>
                                                            <b><i className="ri-add-line"></i></b>  Add Funds
                                                        </button>
                                                        <button className='btn btn-warning btn-md' type="button" onClick={() => handleHoldUnholdFunds(wallet?.currency_id)}>
                                                            Hold/Unhold
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="text-center mt-3">No Data Available</p>
                            )}

                            {
                                walletsData.wallets?.length > 0 &&
                                <div className="hVPalX gap-2" >
                                    <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                    <div className="sc-eAKtBH gVtWSU">
                                        <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                <path fill="none" d="M24 24H0V0h24v24z"></path>
                                            </svg>
                                        </button>
                                        <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                            </svg>
                                        </button>

                                        <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                            </svg>
                                        </button>
                                        <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                <path fill="none" d="M0 0h24v24H0V0z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
};

export default WalletDetails;