import React from "react";

const AccountDetails = ({ activeTab }) => {

    return (
        <div className={`tab-pane ${activeTab === 0 ? "active" : ""}`} >
            <div className="card-body py-4" >
                <div className="profile_data  ac_body " >
                    <h5>  Account  Details </h5>
                    <div className="row g-4">
                        <div className="col-lg-6 col-xl-3">
                            <div className="label-group"><label> User ID   </label><p>   ------ </p></div>
                        </div>
                        <div className="col-lg-6 col-xl-3">
                            <div className="label-group"><label>   Full Name   </label><p>  ------ </p></div>
                        </div>
                        <div className="col-lg-6 col-xl-3">
                            <div className="label-group"><label>    Email Address </label><p>  ------ </p></div>
                        </div>
                        <div className="col-lg-6 col-xl-3">
                            <div className="label-group"><label>    Mobile Number     </label><p>  ------ </p></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
};

export default AccountDetails;