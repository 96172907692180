import React, { useEffect, useState } from "react";  

const CryptoDeposit = () => {
    

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header ">
                    <div className="container-xl px-4">
                        <div className="page-header-content">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto">
                                    <h1 className="page-header-title">
                                        Crypto Deposits
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-xl px-4">

                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <ul className="nav nav-tabs nav-tabs-custom" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" type="button" > All Deposits </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link " type="button"  > Pending </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link " type="button"  > Completed </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link " type="button"  > Rejected </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link " type="button"  > Cancelled </button>
                                </li>
                            </ul>
                            <div className="dropdown">
                                <a class="btn btn-dark btn-sm " target="_self" href="#">
                                    <i class="fa fa-download me-3"></i>  Export
                                </a>
                            </div>
                        </div>

                        {/* filter bar  */}
                        <div className="filter_bar search-header">
                            <div className="dd_search">
                                <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                <i className="fas fa-search"></i>
                            </div>
                            <form className="row align-items-center">
                                <div className="col-4">
                                    <input
                                        type="date"
                                        className="form-control form-control-solid"
                                        data-provide="datepicker"
                                        id="litepickerRangePlugin"
                                        name="dateFrom"

                                    />
                                </div>
                                <div className="col-4">
                                    <input
                                        type="date"
                                        className="form-control form-control-solid"
                                        data-provide="datepicker"
                                        id="litepickerRangePlugin"
                                        name="dateTo"

                                    />
                                </div>
                                <div className="col-4">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <button className="btn btn-black btn-block w-100 px-4" type="button"  >
                                                Apply
                                            </button>
                                        </div>
                                        <div className="col">
                                            <button className="btn btn-light btn-block w-100" type="button" >
                                                <i className="ri-reset-left-line"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="card-body pt-0 pb-4">
                            <div class="table-responsive m_table">
                                <table class="table table-hover">
                                    <tbody>
                                        <tr>
                                            <td >
                                                <div class="spotName gap-2">
                                                    <img class="img-fluid" alt="" width="20" height="20" src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" />
                                                    <div class="symblecurrency"> 00.474 USDT </div>
                                                    <span class="badge badge-sm bg-warning-lite"> Pending </span>
                                                </div>
                                                <div class="progress w-auto">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100" style={{ width: '45%' }}></div>
                                                </div>
                                                <div class="list_span pt-2">
                                                    <span>Date:</span> 25-03-01 11:11
                                                </div>
                                            </td>
                                            <td>
                                                <div class="spotName gap-2">
                                                    <img class="img-fluid" alt="" width="20" height="20" src="assets/img/illustrations/profiles/profile-5.png" className="user_img" />
                                                    <div class="symblecurrency"> SAMYAK RASTOGI </div>
                                                </div>
                                                <div class="list_span pt-1">
                                                    <span>User ID: </span> CIU1387968411
                                                </div>
                                            </td>
                                            <td>
                                                <div class="list_span pt-1">
                                                    <span>Network:</span> ETH
                                                </div>
                                                <div class="list_span pt-1">
                                                    <span>Address:</span>
                                                    <div> 2123321....231231
                                                        <i class="ri-links-fill cursor-pointer mx-2 text-dark"></i>
                                                        <i class="ri-file-copy-line cursor-pointer text-dark"></i>
                                                    </div>
                                                </div>
                                                <div class="list_span pt-1">
                                                    <span>Txn ID:</span>
                                                    <div> 2123321....231231
                                                        <i class="ri-links-fill cursor-pointer mx-2 text-dark"></i>
                                                        <i class="ri-file-copy-line cursor-pointer text-dark"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="list_span pt-1">
                                                    <span>Wallet:</span> Funding Wallet
                                                </div>
                                                <div class="list_span pt-1">
                                                    <span>Avl Bal:</span> 000.00
                                                </div>
                                                <div class="list_span pt-1">
                                                    <span>Locked Bal:</span> 000.00
                                                </div>
                                            </td>
                                            <td>
                                                <div class="list_span pt-1">
                                                    <span>Travel Rule ID</span> #23165132132
                                                </div>
                                                <div class="list_span pt-1">
                                                    <span>Travel Rule Date</span> 25-03-01 11:11
                                                </div>
                                                <div class="list_span pt-1">
                                                    <span>Status</span> Sumitted
                                                </div>
                                            </td>
                                            <td>
                                                <div className="btn-row d-flex align-items-center gap-2 justify-content-end">
                                                    <button className="btn btn-sm btn-success"> Confirm </button>
                                                    <button className="btn btn-sm btn-danger"> Decline </button>
                                                    <button className="btn btn-sm btn-primary"> Details </button>
                                                    <i class="ri-arrow-down-s-line"></i>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="wall_row wall_row_a">
                                            <td>Funding Wallet</td>
                                            <td class="text-end">000.000</td>
                                            <td class="text-end">000.000</td>
                                            <td class="text-end">000.000</td>
                                            <td class="text-end">000.000</td>
                                            <td class="text-end">000.000</td>
                                        </tr>
                                        <tr class="wall_row wall_row_d">
                                            <td>Funding Wallet</td>
                                            <td class="text-end">000.000</td>
                                            <td class="text-end">000.000</td>
                                            <td class="text-end">000.000</td>
                                            <td class="text-end">000.000</td>
                                            <td class="text-end">000.000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <div class="modal-open-custom  d-none ">
                <div class="modal show d-block">
                    <div class="modal-dialog modal-dialog-centered ">
                        <div class="modal-content p-4 ">
                            <div class="modal-header no-border pb-3">
                                <h5 class="modal-title"> Deposit Details</h5>
                                <button type="button" class="btn-close"></button>
                            </div>
                            <div class="modal-body ">
                                <div class="row justify-content-center ">
                                    <div class="col-xl-12">
                                        <form class="pp_success">
                                            <div class="tt_data tt_data-small mb-0">
                                                <div class="profile_data fc_details  ac_body  p-3 ">
                                                    <div class="tt_item">
                                                        <span class="text-muted"> Order No.</span>
                                                        <span class="tt_normal">
                                                            <small>2123321....231231 <i class="ri-file-copy-line cursor-pointer"></i>
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted"> Status </span>
                                                        <span class="tt_normal text-success"> Completed </span>
                                                    </div>
                                                    <div class="tt_item  ">
                                                        <span class="text-muted"> Date </span>
                                                        <span class="tt_normal"> "DD MMM YYYY <br />HH:mm:ss </span>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted"> Coin </span>
                                                        <div class="spotName gap-1 py-1">
                                                            <img class="img-fluid" alt="" width="20" height="20" src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" />
                                                            <div class="symblecurrency">USDT/INR</div>
                                                        </div>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted"> Deposit amount </span>
                                                        <span class="tt_normal">0000 00</span>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted"> From/To </span>
                                                        <span class="tt_normal"> Self/Other </span>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted"> Address</span>
                                                        <span class="tt_normal">
                                                            <small>2123321....231231 <i class="ri-links-fill cursor-pointer"></i>
                                                                <i class="ri-file-copy-line cursor-pointer"></i>
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted"> TxID </span>
                                                        <span class="tt_normal">
                                                            <small>2123321....231231 <i class="ri-links-fill cursor-pointer"></i>
                                                                <i class="ri-file-copy-line cursor-pointer"></i>
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <div class="tt_item no-border">
                                                        <span class="text-muted"> Deposit Wallet </span>
                                                        <span class="tt_normal"> Future Wallet </span>
                                                    </div>
                                                    <div class="tt_item no-border">
                                                        <span class="text-muted"> Available Balance </span>
                                                        <span class="tt_normal"> 00.00 </span>
                                                    </div>
                                                    <div class="tt_item no-border">
                                                        <span class="text-muted"> Locked Balance </span>
                                                        <span class="tt_normal"> 00.00 </span>
                                                    </div>
                                                    <div class="tt_item no-border">
                                                        <span class="text-muted">Travel Rule DateID </span>
                                                        <span class="tt_normal"> Spot Wallet </span>
                                                    </div>
                                                    <div class="tt_item no-border">
                                                        <span class="text-muted"> Status </span>
                                                        <span class="tt_normal"> Spot Wallet </span>
                                                    </div>
                                                     
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-backdrop fade show"></div>
            </div>


        </div>
    )
}

export default CryptoDeposit;