import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import AuthService from "../../../services/ApiIntegration/AuthService";
import { alertErrorMessage } from "../../CustomComponent/CustomAlert";
import { ApiConfig } from "../../../services/ApiIntegration/ApiConfig";
import LoaderHelper from "../../CustomComponent/LoaderHelper/LoaderHelper";
import Supportmessage from "../Supportmessage";

const Support = () => {
    const [issueList, setIssueList] = useState([]);
    const [emailId, setEmailID] = useState([]);
    const [description, setDescription] = useState([]);
    const [ticketId, setTicketId] = useState('');
    const [activeScreen, setActiveScreen] = useState('support');


    const linkEmail = (row) => {
        return (
            <div onClick={() => nextPage(row)}>
                <span className={row?.seen === 1 || row?.status === "Open" ? "fw-bolder" : ""}>{row?.email} </span>
            </div>
        );
    };

    const linkMobile = (row) => {
        return (
            <div onClick={() => nextPage(row)}>
                <span className={row?.seen === 1 || row?.status === "Open" ? "fw-bolder" : ""}>{row?.phone} </span>
            </div>
        );
    };


    const linkSubject = (row) => {
        return (
            <div onClick={() => nextPage(row)} >
                <span className={row?.seen === 1  || row?.status === "Open" ? "fw-bolder" : ""}>{row?.subject} </span>
            </div>
        );
    };

    const linkUserId = (row) => {
        return (
            <div onClick={() => nextPage(row)} >
                <span className={row?.seen === 1 || row?.status === "Open" ? "fw-bolder" : ""}>{row?.userId} </span>
            </div>
        );
    };

    const linkDescription = (row) => {
        return (
            <div onClick={() => nextPage(row)}>
                <span className={row?.status === "Pending" ? "text-warning" : row?.status === "Open" ? "text-danger" : "text-success"}>
                    {row?.status} <small><i className={row?.seen === 1  ? "fa fa-circle ms-2 text-success" : ""}></i></small>
                </span>
            </div>
        );
    };

    const statuslinkFollow = (row) => {
        return (
            row?.status === "Pending" ?
                <button className="btn btn-sm btn-warning mx-1" onClick={() => handleStatus(row?._id, "Resolved")}>Pending</button>
                :
                <button className="btn btn-sm btn-success mx-1" onClick={() => handleStatus(row?._id, "Pending")}>Resolved</button>
        );
    };

    const handleStatus = async (Id, status) => {
        await AuthService.updateTicketStatus(Id, status).then(async result => {
            if (result?.success) {
                handleIssueList();
            } else {
                alertErrorMessage(result.message)
            }
        })
    }

    function imageFormatter(row) {
        return <a href={ApiConfig?.appUrl + row?.ticket_image} target="_blank" rel="noreferrer" >
            <img alt="image" className="table-img" src={ApiConfig?.appUrl + row?.ticket_image} /></a>;
    }

    const columns = [
        { name: 'IssueImage', sort: true, wrap: true, selector: imageFormatter },
        { name: 'User Id', sort: true, wrap: true, selector: linkUserId },
        { name: 'Email Id', sort: true, wrap: true, selector: linkEmail },
        { name: 'Mobile Number', sort: true, wrap: true, selector: linkMobile },
        { name: 'Subject', sort: true, wrap: true, selector: linkSubject },
        { name: 'Status', sort: true, wrap: true, selector: linkDescription },
        { name: 'Change Status', width: '300px', wrap: true, selector: statuslinkFollow },
    ];
    const customStyles = {
        cells: {
            style: {
                cursor: 'pointer',
            },
        },
    };



    const nextPage = (row) => {
        setActiveScreen('supportmessage');
        setTicketId(row?._id)
        setEmailID(row?.email);
        setDescription(row?.description);
    }

    useEffect(() => {
        handleIssueList()
    }, []);

    const handleIssueList = async () => {
        LoaderHelper.loaderStatus(false);
        await AuthService.getAllTickets().then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setIssueList(result?.data?.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage("Something Went Wrong");
            }
        });
    };


    return (

        activeScreen === 'support' ?
            <>
                <div id="layoutSidenav_content">
                    <main>
                        <header className="page-header ">
                            <div className="container-xl px-4">
                                <div className="page-header-content">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto">
                                            <h1 className="page-header-title">
                                                <div className="page-header-icon"><i className="fa fa-question"></i></div>
                                                Support Page
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="container-xl px-4">
                            <div className="card mb-4">
                                <div className="card-header">Issue List
                                    <div className="dropdown">
                                        <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export </button>
                                        <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                            <CSVLink data={issueList} className="dropdown-item">Export as CSV</CSVLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body mt-3">
                                    <table className="" width="100%" >
                                        <DataTable columns={columns} data={issueList} pagination direction="auto" responsive subHeaderAlign="right" subHeaderWrap striped highlightOnHover fixedHeader onRowClicked={(row) => { nextPage(row) }} customStyles={customStyles} />
                                    </table>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </>
            :
            <Supportmessage id={ticketId} email={emailId} description={description} />
    )
}

export default Support;


