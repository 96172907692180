import moment from "moment";
import { alertErrorMessage } from "../../../Components/CustomComponent/CustomAlert";
import AuthService from "../../ApiIntegration/AuthService";

export const fetchUserBanknList = async (bankStatus, skip, limit, searchKey) => {
  try {
    const result = await AuthService.getBankList(bankStatus, skip, limit, searchKey);
    if (result?.success) {
      return result?.data;
    } else {
      alertErrorMessage(result?.message);
      return [];
    }
  } catch (error) {
    alertErrorMessage(error?.message);
    return [];
  }
};

export const handleData = async (id, userId, setUserBankDetails) => {
  await AuthService.getBankUserList(id, userId).then(async (result) => {
    if (result.success) {
      setUserBankDetails(result.data);
    }
  });
};


export const handleChangeBankStatusApprove = async (status, id, userId, setLoadingStatus, handleData, setUserBankDetails, remark) => {
  setLoadingStatus(true);
  await AuthService.changeBankStatus(status, id, userId, remark).then(async (result) => {
    if (result.success) {
      setLoadingStatus(false);
      try {
        handleData(id, userId, setUserBankDetails);
      } catch (error) {
        alertErrorMessage(error);
      }
    } else {
      setLoadingStatus(false);
      alertErrorMessage(result);

    }
  });
};

export const fatchAccountDetails = async (accountNumber, ifsc, kycName, userId, id, setLoading, handleData, setUserBankDetails) => {
  setLoading(true);
  await AuthService.verifyBankDetails(accountNumber, ifsc, kycName, userId).then(async (result) => {
    if (result.success) {
      setLoading(false);
      try {
        handleData(id, userId, setUserBankDetails);
      } catch (error) {
        alertErrorMessage(error);
      }
    } else {
      setLoading(false);
      alertErrorMessage(result);
    }
  });
};



export const getUserBankColumns = (navigate, bankStatus, skip) => [


  {

    name: "#",
    wrap: true,
    selector: (row, index) => index + 1 + skip
  },


  {
    name: "Date", selector: row => bankStatus === "pending" ? row?.createdAt : bankStatus === "verified" ? row?.approved_reason?.timestamp : bankStatus === "rejected" ? row?.reject_reason?.timestamp : bankStatus === "deleted" ? row?.deletedAt : row?.createdAt
      ? moment(bankStatus === "pending" ? row?.createdAt : bankStatus === "verified" ? row?.approved_reason?.timestamp : bankStatus === "rejected" ? row?.reject_reason?.timestamp : bankStatus === "deleted" ? row?.deletedAt : row?.createdAt).format("MM DDD YYYY, h:mm:ss a")
      : "", wrap: true
  },

  
  { name: "User Id", wrap: true, selector: row => row.userId, },
  { name: "Name", wrap: true, selector: row => row.kycName, },
  { name: "Bank", selector: row => row.bankName, },
  { name: "Account Number", selector: row => row.accountNumber, },

  {
    name: "Action",
    cell: (row) => (
      <button className="btn btn-dark btn-sm me-2" onClick={() => navigate('/users_bank_details', { state: { row, bankStatus } })}>
        View
      </button>
    ),
  },
];

export const handlePaginationBank = (action, setSkip, skip, limit, totalDataBank) => {
  switch (action) {
    case "prev":
      if (skip - limit >= 0) setSkip(skip - limit);
      break;
    case "next":
      if (skip + limit < totalDataBank) setSkip(skip + limit);
      break;
    case "first":
      setSkip(0);
      break;
    case "last":
      setSkip(Math.max(totalDataBank - limit, 0));
      break;
    default:
      break;
  }
};

// UPI Functions



export const fetchUseUpiList = async (upiStatus, skip, limit, searchKey) => {
  try {
    const result = await AuthService.getUpiList(upiStatus, skip, limit, searchKey);
    if (result?.success) {
      return result?.data;
    } else {
      alertErrorMessage(result?.message);
      return [];
    }
  } catch (error) {
    alertErrorMessage(error?.message);
    return [];
  }
};


export const upiColums = [
  {
    name: "Date", selector: row => row?.createdAt
      ? moment(row.createdAt).format("Do MMM YYYY, h:mm:ss a")
      : "----", wrap: true
  },
  { name: "User Id", wrap: true, selector: row => row.user_id, },
  { name: "UPI Holder Name", wrap: true, selector: row => row.UPIHolderName, },
  { name: "UPI ID", selector: row => row.vpaId, },
  { name: "Kyc Name", selector: row => row.savedName, },
  { name: "Name Match", selector: row => row.nameMatch === true ? "YES" : "NO", },
  {
    name: "Status",
    selector: row => row.status,
    cell: row => (
      <span className={row.status === "verified" ? "text-success" : "text-danger"}>
        {row.status}
      </span>
    ),
  }

];


export const handlePaginationUpi = (action, setSkip, skip, limit, totalUpiData) => {
  switch (action) {
    case "prev":
      if (skip - limit >= 0) setSkip(skip - limit);
      break;
    case "next":
      if (skip + limit < totalUpiData) setSkip(skip + limit);
      break;
    case "first":
      setSkip(0);
      break;
    case "last":
      setSkip(Math.max(totalUpiData - limit, 0));
      break;
    default:
      break;
  }
};