import React, { useContext, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import AccountDetails from '../../SessionComponent/MasterManagement/AccountDetails';
import PaymentDetails from '../../SessionComponent/MasterManagement/PaymentDetails';
import WalletDetails from '../../SessionComponent/MasterManagement/WalletDetails';
import SupportTickets from '../../SessionComponent/MasterManagement/SupportTickets';
import ActivityLogs from '../../SessionComponent/MasterManagement/ActivityLogs';
import FeaturesManagement from '../../SessionComponent/MasterManagement/FeaturesManagement';
import { ProfileContext } from '../../CustomComponent/ProfileContext';
import Swal from 'sweetalert2';
import { alertErrorMessage } from '../../CustomComponent/CustomAlert';
import TransactionsComponent from '../../SessionComponent/MasterManagement/MasterTransactions/TransactionsComponent';


const MasterAdmin = () => {

  const [activeTab, setActiveTab] = useState(0);
  const [, updateProfileState] = useContext(ProfileContext);
  const navigate = useNavigate();

  const handleTabClick = (e, index) => {
    setActiveTab(index);
  };


  const showAlert = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to log out`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#e64942",
      cancelButtonColor: "#e0bc28",
      confirmButtonText: "Yes Logout!"
    }).then((result) => {
      if (result.isConfirmed) {
        handleLogout()
      }
    });
  };

  const handleLogout = () => {
    alertErrorMessage('You are logged out..!!');
    updateProfileState({});
    sessionStorage.clear();
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header ">
          <div className="container-xl px-4">
            <div className="page-header-content">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <h1 className="page-header-title">
                    <Link to="/users_list" className="page-header-icon">
                      <i className="fa fa-arrow-left" ></i>
                    </Link>
                    <div className="hd_u_info" >
                      <img
                        src="assets/img/illustrations/profiles/profile-5.png"
                        alt=""
                        className="img-account-profile rounded-circle "
                      />
                      <span> Pardeep Yadav <small className="text-dark" > Master Admin </small> </span>
                    </div>
                  </h1>
                </div>
                <div className="col-auto">
                  <button className="btn btn-danger px-4 btn-md" type='button' onClick={showAlert}>  Logout </button>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="container-xl px-4" >
          <div className="card" >
            <div className="card-header pb-0 ps-0">
              <Tabs className="nav nav-tabs nav-tabs-custom px-2" activeTab={activeTab} onTabClick={handleTabClick}>
                <Tab className='nav-link'>Account Details</Tab>
                <Tab className='nav-link'>Payment Details</Tab>
                <Tab className='nav-link'> Features Management </Tab>
                <Tab className='nav-link'>Wallets</Tab>
                <Tab className='nav-link'>Transaction History </Tab>
                <Tab className='nav-link'>Support Tickets (L3)</Tab>
                <Tab className='nav-link'>Activity Logs</Tab>
              </Tabs>
            </div>

            <div className="tab-content tab-content-card">

              <AccountDetails activeTab={activeTab} />

              <PaymentDetails activeTab={activeTab} />

              <FeaturesManagement activeTab={activeTab} />

              <WalletDetails activeTab={activeTab} />

              <TransactionsComponent activeTab={activeTab} />

              <SupportTickets activeTab={activeTab} />

              <ActivityLogs activeTab={activeTab} />

            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default MasterAdmin