import React, { useEffect, useState } from "react";
import { handleUpdateValue } from "../../../../services/Context/LimitContext";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import Swal from "sweetalert2";
import { alertErrorMessage } from "../../../CustomComponent/CustomAlert";

const WithdrawalLimit = ({ activeTab, userLimitData, handleLimitData }) => {

    const [isActiveModel, setIsActiveModel] = useState(false);
    const [apiResponse, setApiResponse] = useState("");
    const [activatedModelName, setActivatedModelName] = useState("");
    const [updatedValue, setUpdatedValue] = useState();


    const [formData, setFormData] = useState({
        totalWithdrawalYear: "",
        totalCashWithdrawalYear: "",
        dailyWithdrawalLimit: "",
        dailyCashWithdrawalLimit: "",
        totalCryptoWithdrawal: "",
        dailyCryptoWithdrawal: "",
    });;
        

    useEffect(() => {
        if (updatedValue || userLimitData) {
            setFormData({
                totalWithdrawalYear: updatedValue?.totalInrWithdrawal || userLimitData?.totalInrWithdrawal || "",
                totalCashWithdrawalYear: updatedValue?.totalInrCashWithdrawal || userLimitData?.totalInrCashWithdrawal || "",
                dailyWithdrawalLimit: updatedValue?.dailyInrWithdrawal || userLimitData?.dailyInrWithdrawal || "",
                dailyCashWithdrawalLimit: updatedValue?.dailyInrCashWithdrawal || userLimitData?.dailyInrCashWithdrawal || "",
                totalCryptoWithdrawal: updatedValue?.totalCryptoWithdrawal || userLimitData?.totalCryptoWithdrawal || "",
                dailyCryptoWithdrawal: updatedValue?.dailyCryptoWithdrawal || userLimitData?.dailyCryptoWithdrawal || "",
            });
        }
    }, [updatedValue,userLimitData]);





    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
        setApiResponse("");
    };

    const handleActivatedModel = (name) => {
        setIsActiveModel(true);
        setActivatedModelName(name);
        handleGetUpdatedValue();
    };


    const handleUpdateLimitRemark = async () => {
        try {
            const { isConfirmed } = await Swal.fire({
                title: "Are you sure ?",
                html: `
              <label for="remark">Enter Remark:</label>
              <textarea id="remark" class="form-control mt-2" rows="3" placeholder="Enter Remark"></textarea>
            `,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#e64942",
                cancelButtonColor: "#e7e7e7",
                confirmButtonText: "Yes, Update!",
                preConfirm: () => {
                    const remark = document.getElementById("remark").value.trim();
                    if (!remark) {
                        Swal.showValidationMessage("You need to enter a Remark!");
                    }
                    return remark;
                }
            });
            if (isConfirmed) {
                const remark = document.getElementById("remark").value.trim();
                const response = await AuthService.updateLimitRemark(remark);
                if (response.success) {
                    handleLimitData();
                    setIsActiveModel(false);
                    Swal.fire("Updated!", "User Limit Updated Successfully.", "success");
                } else {
                    Swal.fire("Error!", response || "Failed to Update User Limit.", "error");
                }
            }
        } catch (error) {
            Swal.fire("Error!", "Something went wrong. Please try again.", "error");
        }
    };

    const handleGetUpdatedValue = async () => {
        await AuthService.exchangeLimitSession().then(async (result) => {
            if (result.success) {
                try {
                    setUpdatedValue(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result);
            }
        });
    };

    return (
        <div className={`tab-pane ${activeTab === "withdrawalLimit" ? "active" : ""}`} >
            <div className="profile_data  ac_body" >
                <div className="d-flex align-items-center justify-content-end mt-0 " >
                    <button className="btn btn-warning btn-sm  " type="button"
                        onClick={() => handleActivatedModel("Overall INR Withdrawal Limit")}> Update Limits
                    </button>
                </div>
                <div className="row g-4 mt-0">
                    <div className="col-lg-6" >
                        <div className="card h-100">
                            <div className="card-body py-4">
                                <div className="d-flex align-items-center justify-content-between mb-2" >
                                    <h5> INR Withdrawal Limits  </h5>
                                    {/* <button className="btn btn-warning btn-sm mb-2" type="button"
                                        onClick={() => handleActivatedModel("Overall INR Withdrawal Limit")}> Update Limits
                                    </button> */}
                                </div>
                                <div className="row g-4" >
                                    <div className="col-lg-12 " >
                                        <div className="profile_data fc_details  ac_body " >
                                            <div className="d-flex align-items-center justify-content-between mb-4" >
                                                <h5 className="mb-0" > Overall Withdrawal Limit (1FY)  </h5>
                                            </div>
                                            <div className="row g-4">
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="label-group">
                                                        <label>Total</label>
                                                        <p>  {userLimitData?.totalInrWithdrawal} ₹</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="label-group">
                                                        <label>Total Cash</label>
                                                        <p>   {userLimitData?.totalInrCashWithdrawal} ₹</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12" >
                                        <div className="profile_data fc_details  ac_body " >
                                            <div className="d-flex align-items-center justify-content-between mb-4" >
                                                <h5 className="mb-0" >  Daily Withdrawal Limit (24H)  </h5>
                                            </div>
                                            <div className="row g-4">
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="label-group">
                                                        <label>Total</label>
                                                        <p>{userLimitData?.dailyInrWithdrawal} ₹</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="label-group">
                                                        <label>Total Cash</label>
                                                        <p> {userLimitData?.dailyInrCashWithdrawal} ₹ </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6" >
                        <div className="card h-100">
                            <div className="card-body py-4">
                                <div className="d-flex align-items-center justify-content-between mb-2" >
                                    <h5> Crypto Withdrawal Limits  </h5>
                                    {/* <button className="btn btn-warning btn-sm mb-2" disabled type="button"
                                    > Update Limits
                                    </button> */}
                                </div>
                                <div className="row g-4" >
                                    <div className=" col-lg-12" >
                                        <div className="profile_data fc_details  ac_body " >
                                            <div className="d-flex align-items-center justify-content-between mb-4" >
                                                <h5 className="mb-0" > Overall Withdrawal Limit (1FY)  </h5>
                                            </div>
                                            <div className="row g-4">
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="label-group">
                                                        <label>Total</label>
                                                        <p> $ {userLimitData?.totalCryptoWithdrawal || "--"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-lg-12" >
                                        <div className="profile_data fc_details  ac_body " >
                                            <div className="d-flex align-items-center justify-content-between mb-4" >
                                                <h5 className="mb-0" >  Daily Withdrawal Limit (24H)  </h5>
                                            </div>
                                            <div className="row g-4">
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="label-group">
                                                        <label>Total</label>
                                                        <p>$  {userLimitData?.dailyCryptoWithdrawal || "--"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isActiveModel && (
                <>
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-dialog-centered modal-xl">
                            <div className="modal-content  ">
                                {/* <div className="modal-header">
                                    <h5 className="modal-title" >{activatedModelName}</h5>
                                </div> */}
                                <div className="modal-body p-0 row gx-5">
                                    <div className=" col-lg-4   " >
                                        <h6 className="mb-3" > INR Withdrawal Limits  </h6>
                                        <div className="form-group mb-3">
                                            <label className="small mb-1" htmlFor="totalWithdrawalYear">Total INR Withdrawal Limit (1FY) <em>*</em></label>
                                            <div className="otp_box">
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="totalWithdrawalYear"
                                                    name="totalWithdrawalYear"
                                                    placeholder="Enter Amount"
                                                    type="text"
                                                    value={formData.totalWithdrawalYear}
                                                    onChange={handleChange}
                                                />
                                                <button className="btn btn-warning btn-xs" type="button" disabled={!formData.totalWithdrawalYear}
                                                    onClick={() => handleUpdateValue(formData.totalWithdrawalYear, "totalInrWithdrawal", handleGetUpdatedValue)}>
                                                    <span>Update</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <label className="small mb-1" htmlFor="dailyWithdrawalLimit">Daily INR Withdrawal Limit (24H)<em>*</em></label>
                                            <div className="otp_box">

                                                <input
                                                    className="form-control form-control-solid"
                                                    id="dailyWithdrawalLimit"
                                                    name="dailyWithdrawalLimit"
                                                    placeholder="Enter Amount"
                                                    type="text"
                                                    value={formData.dailyWithdrawalLimit}
                                                    onChange={handleChange}
                                                />
                                                <button className="btn btn-warning btn-xs" type="button" disabled={!formData.dailyWithdrawalLimit}
                                                    onClick={() => handleUpdateValue(formData.dailyWithdrawalLimit, "dailyInrWithdrawal", handleGetUpdatedValue)}>
                                                    <span>Update</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>



                                    <div className=" col-lg-4   " >
                                        <h6 className="mb-3" >   Cash Withdrawal Limits    </h6>
                                        <div className="form-group mb-3">
                                            <label className="small mb-1" htmlFor="totalCashWithdrawalYear">Total Cash Withdrawal Limit (1FY)<em>*</em></label>
                                            <div className="otp_box">
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="totalCashWithdrawalYear"
                                                    name="totalCashWithdrawalYear"
                                                    placeholder="Enter Amount"
                                                    type="text"
                                                    value={formData.totalCashWithdrawalYear}
                                                    onChange={handleChange}
                                                />
                                                <button className="btn btn-warning btn-xs" type="button" disabled={!formData.totalCashWithdrawalYear}
                                                    onClick={() => handleUpdateValue(formData.totalCashWithdrawalYear, "totalInrCashWithdrawal", handleGetUpdatedValue)}>
                                                    <span>Update</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-group  ">
                                            <label className="small mb-1" htmlFor="dailyCashWithdrawalLimit">Daily Cash Withdrawal Limit (24H)<em>*</em></label>
                                            <div className="otp_box">

                                                <input
                                                    className="form-control form-control-solid"
                                                    id="dailyCashWithdrawalLimit"
                                                    name="dailyCashWithdrawalLimit"
                                                    placeholder="Enter Amount"
                                                    type="number"
                                                    value={formData.dailyCashWithdrawalLimit}
                                                    onChange={handleChange}
                                                />
                                                <button className="btn btn-warning btn-xs" type="button" disabled={!formData.dailyCashWithdrawalLimit}
                                                    onClick={() => handleUpdateValue(formData.dailyCashWithdrawalLimit, "dailyInrCashWithdrawal", handleGetUpdatedValue)}>
                                                    <span>Update</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" col-lg-4  " >
                                        <h6 className="mb-3" > Crypto  Withdrawal Limits  </h6>
                                        <div className="form-group mb-3">
                                            <label className="small mb-1" htmlFor="totalCryptoWithdrawal">Total Crypto Withdrawal Limit (1FY)<em>*</em></label>
                                            <div className="otp_box">

                                                <input
                                                    className="form-control form-control-solid"
                                                    id="totalCryptoWithdrawal"
                                                    name="totalCryptoWithdrawal"
                                                    placeholder="Enter Amount"
                                                    type="number"
                                                    value={formData.totalCryptoWithdrawal}
                                                    onChange={handleChange}
                                                />
                                                <button className="btn btn-warning btn-xs" type="button" disabled={!formData.totalCryptoWithdrawal}
                                                    onClick={() => handleUpdateValue(formData.totalCryptoWithdrawal, "totalCryptoWithdrawal", handleGetUpdatedValue)}>
                                                    <span>Update</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <label className="small mb-1" htmlFor="dailyCryptoWithdrawal"> Daily Crypto Withdrawal Limit (24H)<em>*</em></label>
                                            <div className="otp_box">

                                                <input
                                                    className="form-control form-control-solid"
                                                    id="dailyCryptoWithdrawal"
                                                    name="dailyCryptoWithdrawal"
                                                    placeholder="Enter Amount"
                                                    type="number"
                                                    value={formData.dailyCryptoWithdrawal}
                                                    onChange={handleChange}
                                                />
                                                <button className="btn btn-warning btn-xs" type="button" disabled={!formData.dailyCryptoWithdrawal}
                                                    onClick={() => handleUpdateValue(formData.dailyCryptoWithdrawal, "dailyCryptoWithdrawal", handleGetUpdatedValue)}>
                                                    <span>Update</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" d-flex justify-content-center gap-3 flex-between w-100 mt-5">
                                        <button className="btn btn-primary btn-block px-5 btn-md " type="button" onClick={handleUpdateLimitRemark}>
                                            APPLY TO USER
                                        </button>
                                        <button className="btn btn-secondary btn-block px-5  btn-md " type="button"
                                            onClick={() => { setIsActiveModel(false); setApiResponse("") }}>
                                            Cancel
                                        </button>

                                    </div>
                                    {apiResponse && <div className="text-danger">{apiResponse}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            )
            }

        </div >

    )
};

export default WithdrawalLimit;