import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import { MultiSelect } from "react-multi-select-component";
import { handleUpdateSubAdmin,handleChangeEditSubAdmin, options  } from '../../../../services/Context/SubadminContext';

const EditSubadmin = () => {

  const location = useLocation()
  const navigate = useNavigate();
  const [apiResponse, setApiResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const subadminData = location.state;

  const [formData, setFormData] = useState({
    userName: subadminData.full_name,
    mobileNumber: subadminData.phone,
    email: subadminData.email,
    permissions: subadminData.permissions,
    password: "",
    userId: subadminData._id
  });

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header ">
          <div className="container-xl px-4">
            <div className="page-header-content">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <h1 className="page-header-title">
                    <Link to="/sub_admin_list" className="page-header-icon">
                      <i className="fa fa-arrow-left"></i>
                    </Link>
                    Edit Subadmin Details
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4">
          <div className="card mb-4">
            <div className="card-header">Sub Admin Details</div>
            <div className="card-body pt-4">
              <form>
                <div className="row gx-3 mb-3 d-flex justify-content-evenly">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="userName">User Name</label>
                    <input className="form-control  form-control-solid" id="userName" type="text" name='userName' placeholder="Enter your first name" value={formData.userName} onChange={(e) => handleChangeEditSubAdmin(e, formData, setFormData, setApiResponse)} />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="mobileNumber">Mobile Number</label>
                    <input className="form-control form-control-solid" id="mobileNumber" type="text" name='mobileNumber' placeholder="Enter your Mobile Number" value={formData.mobileNumber} maxLength={10} onChange={(e) => handleChangeEditSubAdmin(e, formData, setFormData, setApiResponse)} />
                  </div>
                </div>
                <div className="row gx-3 mb-3 d-flex justify-content-evenly">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="inputEmailAddress">Email</label>
                    <input className="form-control form-control-solid" id="inputEmailAddress" name='email' type="email" placeholder="Enter your email address" value={formData.email} onChange={(e) => handleChangeEditSubAdmin(e, formData, setFormData, setApiResponse)} />
                  </div>

                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="password">password</label>
                    <input className="form-control  form-control-solid" id="password" type="text" name='password' placeholder="Enter Password to change" value={formData.password} onChange={(e) => handleChangeEditSubAdmin(e, formData, setFormData, setApiResponse)} />
                  </div>

                </div>
                <div className="row gx-3 mb-3 d-flex justify-content-evenly">
                  <div className="col-md-4" ></div>
                  <div className="col-md-4" >
                    <label className="small mb-1" htmlFor="inputLocation">Permissions</label>
                 {/*    <MultiSelect
                      options={options}
                      value={formData.permissions}
                      onChange={(e) => handleChangeEditSubAdmin(e, formData, setFormData, setApiResponse)}
                      labelledBy="Select"
                    /> */}
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <div className="row gx-3 mt-4 mb-3">
                    <button className="btn btn-indigo" type="button" onClick={() => handleUpdateSubAdmin(navigate, formData, setApiResponse, setIsLoading)}>
                      {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        : <span>  Update Details  </span>}
                    </button>
                  </div>
                </div>
              </form>
              {apiResponse && <div className="text-danger">{apiResponse}</div>}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default EditSubadmin