import React, { useEffect, useState } from "react";
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const CreateBuySellOrder = () => {

    const [addType, setAddType] = useState();
    const [buySellSide, setBuySellSide] = useState("BUY");
    const [baseCurrency, setBaseCurrency] = useState('');
    const [quoteCurrency, setQuoteCurrency] = useState('');
    const [price, setPrice] = useState('');
    const [minimumPrice, setMinimumPrice] = useState('');
    const [maximumPrice, setMaximumPrice] = useState('');
    const [totalQuantity, setTotalQuantity] = useState('');
    const [coinList, setCoinList] = useState([]);
    const [adminWalletBalance, setAdminWalletBalance] = useState();
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    const handleCoinList = async () => {
        await AuthService.getCoinList().then(async (result) => {
            if (result.success) {
                try {
                    setCoinList(result.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };


    const handleAdminWalletBalance = async (currency_id) => {
        await AuthService.getAdminWalletBalance(currency_id).then(async (result) => {
            if (result.success) {
                try {
                    setAdminWalletBalance(result.data?.wallets?.[0]);
                    setTotalQuantity("")
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };



    const handleAddBuySell = async () => {
        if (!addType || undefined) return setErrorMessage("Select Buy Sell Add Type.");
        if (!baseCurrency?.label) return setErrorMessage("Select Base Currency.");
        if (!quoteCurrency?.label) return setErrorMessage("Select Quote Currency.");
        if (!price) return setErrorMessage("Price must be required.");
        if (!minimumPrice) return setErrorMessage("Minimum Value must be required.");
        if (!maximumPrice) return setErrorMessage("Maximum Value must be required.");
        if (!totalQuantity) return setErrorMessage("Target Quantity Value must be required.");
        setLoading(true);
        await AuthService.AddQuickyBuySell(buySellSide, baseCurrency?.label, quoteCurrency?.label, baseCurrency?.id, quoteCurrency?.id, price, minimumPrice, maximumPrice, addType, totalQuantity, baseCurrency?.image, quoteCurrency?.image).then(async (result) => {
            if (result.success) {
                try {
                    alertSuccessMessage(result?.message);
                    setLoading(false);
                    handleReset();
                    navigate('/buy_sell_list');
                } catch (error) {
                    alertErrorMessage(error);
                    setLoading(false);
                }
            } else {
                alertErrorMessage(result);
                setLoading(false);
            }
        });
    };


    useEffect(() => {
        handleCoinList();
    }, []);


    const optionsBaseCurrency = coinList.map(item => ({
        label: item.short_name,
        value: item.short_name,
        image: item.icon_path,
        id: item._id,
    }));

    const optionsQuoteCurrency = coinList.map(item => ({
        label: item.short_name,
        value: item.short_name,
        image: item.icon_path,
        id: item._id,
    }));

    const handleReset = () => {
        setAdminWalletBalance()
        setTotalQuantity("")
        setAddType("")
        setBaseCurrency("")
        setQuoteCurrency("")
        setPrice("")
        setMinimumPrice("")
        setMaximumPrice("")
        setErrorMessage("")
    }

    return (
        <div id="layoutSidenav_content">
            <main>

                <div className="container-xl px-4">
                    <div className="row justify-content-center" >
                        <div className="col-xl-8">
                            <header className="page-header ">
                                <div className=" ">
                                    <div className="page-header-content">
                                        <div className="row align-items-center justify-content-between mt-4">
                                            <div className="col-auto ">
                                                <h1 className="page-header-title mb-0">
                                                    <span class="page-header-icon cursor-pointer">   <i class="fa fa-arrow-left"></i> </span>
                                                    Create Orders
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className='custom-tab-2' >
                                <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button type="button" className={`nav-link ${buySellSide === "BUY" && "active"}`} onClick={() => { setBuySellSide("BUY"); handleReset() }}> Buy </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button type="button" className={`nav-link ${buySellSide === "SELL" && "active"}`} onClick={() => { setBuySellSide("SELL"); handleReset() }}>Sell</button>
                                    </li>
                                </ul>
                            </div>

                            <div className="card mb-4 mb-xl-0">
                                <div className="card-body d-flex justify-content-center flex-column p-5">
                                    <div class="d-flex align-items-center justify-content-between mb-3"><h5> Create Buy Order  </h5></div>
                                    <form className="row">
                                        <div className="col-md-6 mb-3">
                                            <label className="small mb-1" htmlFor="addType"> Ad Type </label>
                                            <select className="form-control form-control-solid form-select" id="addType" name="addType" value={addType} onChange={(e) => { setAddType(e.target.value); setErrorMessage("") }}>
                                                <option value="">Select  Ad Type</option>
                                                <option value={false}>Normal Ad</option>
                                                <option value={true}>Bulk Ad </option>
                                            </select>

                                            {errorMessage === "Select Buy Sell Add Type." && (
                                                <div className="invalid-feedback mb-0 d-block">
                                                    <small>{errorMessage}</small>
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <label className="small mb-1" htmlFor="baseCurrency"> Choose Crypto Currency </label>
                                            <Select
                                                options={optionsBaseCurrency}
                                                onChange={(selectedOption) => {
                                                    setBaseCurrency(selectedOption); setErrorMessage("");
                                                    buySellSide === "SELL" && handleAdminWalletBalance(selectedOption?.id)
                                                }}
                                                value={baseCurrency}
                                                getOptionLabel={(option) => (
                                                    <div className="cc_row">
                                                        <img
                                                            src={option.image || "/assets/img/icon/nocoin.png"}
                                                            alt={option.label}
                                                            onError={(e) => { e.target.src = "/assets/img/icon/nocoin.png"; }}
                                                            style={{ width: '20px', marginRight: '8px' }}
                                                        />
                                                        {option.label}
                                                    </div>
                                                )}
                                            />
                                            {errorMessage === "Select Base Currency." && (
                                                <div className="invalid-feedback mb-0 d-block">
                                                    <small>{errorMessage}</small>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="small mb-1" htmlFor="quoteCurrency"> Fiat Currency </label>

                                            <Select
                                                options={optionsQuoteCurrency}
                                                onChange={(selectedOption) => {
                                                    setQuoteCurrency(selectedOption); setErrorMessage("");
                                                    buySellSide === "BUY" && handleAdminWalletBalance(selectedOption?.id)
                                                }}
                                                value={quoteCurrency}
                                                getOptionLabel={(option) => (
                                                    <div className="cc_row">
                                                        <img
                                                            src={option.image || "/assets/img/icon/nocoin.png"}
                                                            alt={option.label}
                                                            onError={(e) => { e.target.src = "/assets/img/icon/nocoin.png"; }}
                                                            style={{ width: '20px', marginRight: '8px' }}
                                                        />
                                                        {option.label}
                                                    </div>
                                                )}
                                            />
                                            {errorMessage === "Select Quote Currency." && (
                                                <div className="invalid-feedback mb-0 d-block">
                                                    <small>{errorMessage}</small>
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-md-6 mb-3" >
                                            <label className="small mb-1">Price (INR)</label>
                                            <input className="form-control form-control-solid" type="number" placeholder="Enter Price" name="price" value={price} onChange={(e) => { setPrice(e.target.value); setErrorMessage("") }} />
                                            {errorMessage === "Price must be required." && (
                                                <div className="invalid-feedback mb-0 d-block">
                                                    <small>{errorMessage}</small>
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-md-6 mb-3" >
                                            <label className="small mb-1">Minimum Value</label>
                                            <input className="form-control form-control-solid" type="number" placeholder="Enter Minimum Value" name="minimumPrice" value={minimumPrice} onChange={(e) => { setMinimumPrice(e.target.value); setErrorMessage("") }} />
                                            {errorMessage === "Minimum Value must be required." && (
                                                <div className="invalid-feedback mb-0 d-block">
                                                    <small>{errorMessage}</small>
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-md-6 mb-3" >
                                            <label className="small mb-1">Maximum Value</label>
                                            <input className="form-control form-control-solid" type="number" placeholder="Enter Maximum Value" name="maximumPrice" value={maximumPrice} onChange={(e) => { setMaximumPrice(e.target.value); setErrorMessage("") }} />
                                            {errorMessage === "Maximum Value must be required." && (
                                                <div className="invalid-feedback mb-0 d-block">
                                                    <small>{errorMessage}</small>
                                                </div>
                                            )}
                                        </div>



                                        <div className="form-group mb-3 col-lg-6">
                                            <label className="small mb-1"> Target Quantity  </label>
                                            <div className="otp_box">
                                                <input className="form-control form-control-solid" type="number" placeholder="Enter Target Quantity" name="totalQuantity" value={totalQuantity} onChange={(e) => { setTotalQuantity(e.target.value); setErrorMessage("") }} />
                                                {
                                                    adminWalletBalance &&
                                                    <button className="btn btn-warning btn-xs" type="button" onClick={() => setTotalQuantity(adminWalletBalance?.balance)}> Max  </button>
                                                }
                                                {errorMessage === "Target Quantity Value must be required." && (
                                                    <div className="invalid-feedback mb-0 d-block">
                                                        <small>{errorMessage}</small>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {
                                            adminWalletBalance &&
                                            <div className="form-group mb-3 col-lg-6">
                                                <label className="small mb-1"> Wallet Balance  </label>
                                                <div className="otp_box">
                                                    <input className="form-control form-control-solid" value={adminWalletBalance?.balance} disabled />
                                                </div>
                                            </div>

                                        }

                                        <div className="col-md-12 mb-3 d-flex justify-content-center" >
                                            <button className="btn btn-indigo btn-block w-50 mt-2" type="button" onClick={() => handleAddBuySell()}>
                                                {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    : <span> Submit </span>}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default CreateBuySellOrder;