import React from "react";
import { Link } from "react-router-dom";

const SupportTickets = ({ activeTab }) => {

    return (
        <div className={`tab-pane ${activeTab === 5 ? "active" : ""}`} >
            <div className="card-body py-4" >
                <div className="profile_data  ac_body  " >
                    <div className=" " >
                        <div className="custom-tab-2">
                            <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a href="#OpenTickets" id="OpenTickets-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true">   Open Tickets </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a href="#ClosedTickets" id="ClosedTickets-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1">  Closed Tickets </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content">
                        {/* crypto Tab  */}
                        <div className="tab-pane active" id="OpenTickets" role="tabpanel" aria-labelledby="OpenTickets-tab">


                            <div className="ticket_list" >
                                <div className="d-flex justify-content-between" >
                                    <h5> Support Tickets (12) </h5>
                                </div>
                                <div className="table-responsive m_table">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" className=""> Date/Time </th>
                                                <th scope="col" className=""> Ticket ID </th>
                                                <th scope="col" className=""> Subject </th>
                                                <th scope="col" className=""> Message  </th>
                                                <th scope="col" className=""> Image </th>
                                                <th scope="col" className=""> Status  </th>
                                                <th scope="col" className=""> Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="">
                                                    <span> 11-12-2024 </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <div className="d-flex">
                                                        <a href="/support" className="btn btn-dark shadow btn-xs sharp me-1"  >
                                                            <i className="ri-eye-line"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
};

export default SupportTickets;