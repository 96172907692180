const appUrl = "https://dev.api.cryptoindia.in";

export const ApiConfig = {
  // =========EndPoints==========
  login: "login",
  newPassword: "newPassword",
  dashboard: "dashboard",
  getSubAdminList: "sub-admin-list",
  subadmindelete: "delete-sub-admin",
  updateSubadmin: "edit-sub-admin",
  AddsubAdmin: "add-new-admin",
  traderreport: "user-list",
  updateStatus: 'update-user-status',
  transferhistory: "user-trade-history",
  updateTicketStatus: "update-ticket-status",
  transactionstatus: "update-withdrawal-status",
  getverifyData: "approve-kyc-user",
  getrejectedData: "rejected-kyc-user",
  verifyIdentity: "update-kyc-status",
  coinlist: "coinList",
  addNotify: "add-notification",
  deleteNotify: "delete-notification",
  notificationList: "notification-list",
  userWallet: "user-wallet",
  admintransfer: "admintransfer",
  getmasterbal: "getmasterbal",
  setcoinwithdrawal: "edit-currency",
  AddCoinPair: "create-pair",
  createCategory: "create-coin-category",
  getCurrencyPairList: "pair-list",
  completeWithdrawalRequest: "completed-withdrawals",
  PendingWithdrwal: "pending-withdrawals",
  CancelledWithdrwal: "rejected-withdrawals",
  completeDepositRequest: "completed-deposits",
  pendingDeposits: "pending-deposits",
  verifiedtrader: "verifiedtrader",
  addNewCoins: "add-currency",
  Addbanner: "add_banner",
  bannerList: "banner_list",
  updateBanner: "edit_banner",
  bannerdelete: "delete-banner",
  BannerStatus: "set_banner_status",
  deleteCoinPair: "delete-pair",
  getCoinList: "currency-list",
  CoinCategory: "coin-category-list",
  tradingCommission: "trading-commission",
  tradeHistory: 'trade-history',
  OrderBook: 'orderbook',
  tradeById: 'find-trades-by-orderid',
  miscellaneousRequest: 'miscellaneous_withdrawal_request',
  botStatus: 'bot-status',
  cancelOrder: 'cancel-order',
  getAllTickets: 'all-tickets',
  replyTicket: "ticket-admin-reply",
  single_ticket: "single-ticket",
  add_qbs: "add-admin-qbs-limits",
  addedqbsHistoy: "get-admin-qbs-limits",
  deleteQbsList: "delete-admin-qbs-limit",
  allqbsHistoy:"get-all-qbs",
  bank_list:"bank-list",
  // ============URLs================
  baseHelp: `${appUrl}/help/`,
  baseTran: `${appUrl}/v1/transaction/`,
  baseUrl: `${appUrl}/v1/admin/`,
  uploadUrl: `${appUrl}/uploads/`,
  basenotify: `${appUrl}/acc/`,
  baseSecure: `${appUrl}/v1/coin/`,
  baseWallet: `${appUrl}/v1/wallet/`,
  baseCoin: `${appUrl}/v1/coin/`,
  baseReport: `${appUrl}/report/`,
  baseExchange: `${appUrl}/v1/exchange/`,
  uploadcurrency: `${appUrl}/`,
  appUrl: `${appUrl}/`,
};
