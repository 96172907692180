import React, { useEffect, useState } from "react";
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { CSVLink } from "react-csv";
import moment from "moment";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";


const TradeList = () => {

  const [exportData, setExportData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const navigate = useNavigate()

  useEffect(() => {
    handleExportData();
  }, []);


  const handleExportData = async () => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.getExportList();
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        const filteredData = result?.data.reverse().map((item, index) => ({ ...item, index: index + 1, id: index + 1 }))
        setExportData(filteredData);
        setFilterData(filteredData);
      } else {
        alertErrorMessage(result?.message);
      };
    } catch (error) {
      alertErrorMessage(error?.message);
    }
    finally { LoaderHelper.loaderStatus(false) };
  };

  const handleStatus = async (_id, status) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.updateTraderStatus(_id, status);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        handleExportData();
      } else {
        alertErrorMessage(result?.message);
      };
    } catch (error) {
      alertErrorMessage(error?.message);
    }
    finally { LoaderHelper.loaderStatus(false) };
  };


  const columns = [
    { name: 'S.No.', selector: (row, index) => row?.index, sort: true, wrap: true },
    { name: "User ID", selector: (row) => row._id, wrap: true },
    { name: "Name", sort: true, wrap: true, selector: (row) => row?.firstName || '---' },
    // { name: "Last Name", sort: true, wrap: true, selector: (row) => row?.lastName || '---' },
    { name: "Email", sort: true, wrap: true, selector: (row) => row?.email || '---' },
    { name: "Mobile No.", sort: true, selector: (row) => row?.phone || '---' },
    { name: "Joined Date", sort: true, selector: (row) => row?.createdAt ? moment(row?.createdAt).format("Do MMM YYYY") : '---' },
    {
      name: "Action", selector: (row) =>
        <div>
          <button className="btn btn-dark btn-sm me-2" title="View" onClick={() => { navigate('/user_details', { state: row }) }}>
            {/* <i className="fa-solid fa-eye"></i> */}
            View
          </button>

          {row?.status === "Active" ? (
            <button className="btn btn-success btn-sm me-2"
              onClick={() => handleStatus(row?._id, "Inactive")}>
              Active
            </button>
          ) : (
            <button className="btn btn-danger btn-sm me-2"
              onClick={() => handleStatus(row?._id, "Active")}>
              Inactive
            </button>
          )}
        </div>
    },
  ];


  const resetFilterDate = () => {
    setFromDate("");
    setToDate("");
    setExportData(filterData);
  };

  const filterDate = () => {
    const filteredData = filterData.filter((item) => {
      const createdAtDate = new Date(item.createdAt);
      return (!fromDate || createdAtDate >= new Date(fromDate)) &&
        (!toDate || createdAtDate <= new Date(toDate));
    });
    setExportData(filteredData?.reverse())
  };



  const searchObjects = (e) => {
    const keysToSearch = ["firstName", "lastName", "email", "_id", "phone"];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = filterData?.filter((obj) =>
      keysToSearch.some((key) => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm))
    );
    setExportData(matchingObjects);
  }

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header ">
          <div className="container-xl px-4">
            <div className="page-header-content pt-0">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  {/* <h1 className="page-header-title">
                    All Users
                  </h1> */}
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4">

          <div className='custom-tab-2' >
            <ul class="nav nav-pills justify-content-start mb-4" role="tablist">
              <li class="nav-item" role="presentation">
                <a href="#navpills2-1" class="nav-link active" data-bs-toggle="tab" aria-expanded="false" aria-selected="true" role="tab">  Individual Users  </a>
              </li>
              <li class="nav-item" role="presentation">
                <a href="#navpills2-2" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1"> Corporate Users </a>
              </li>
            </ul>
          </div>


          <div className="card mb-4">
            <div className="card-header pb-0">
              <ul className="nav nav-tabs nav-tabs-custom" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-Pending-tab" data-bs-toggle="pill" data-bs-target="#pills-Pending" type="button" role="tab" aria-controls="pills-Pending" aria-selected="false" > All Users  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-Rejected-tab" data-bs-toggle="pill" data-bs-target="#pills-Rejected" type="button" role="tab" aria-controls="pills-Rejected" aria-selected="false" > Verified  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-Rejected-tab" data-bs-toggle="pill" data-bs-target="#pills-Rejected" type="button" role="tab" aria-controls="pills-Rejected" aria-selected="false" > Unverified  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-Rejected-tab" data-bs-toggle="pill" data-bs-target="#pills-Rejected" type="button" role="tab" aria-controls="pills-Rejected" aria-selected="false" > Blocked Users  </button>
                </li>
              </ul>
              <div className="dropdown">
                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  <i className="fa fa-download me-3"></i>Export
                </button>
                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp" >
                  <CSVLink data={exportData} className="dropdown-item">
                    Export as CSV
                  </CSVLink>
                </div>
              </div>
            </div>

            <div className="filter_bar search-header">
              <div className="dd_search">
                <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" onChange={searchObjects} />
                <i class="fas fa-search"></i>
              </div>
              <form className="row align-items-center">
                <div className="col-4">
                  <input
                    type="date"
                    className="form-control form-control-solid"
                    data-provide="datepicker"
                    id="litepickerRangePlugin"
                    name="dateFrom"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>
                <div className="col-4">
                  <input
                    type="date"
                    className="form-control form-control-solid"
                    data-provide="datepicker"
                    id="litepickerRangePlugin"
                    name="dateTo"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
                <div className="col-4">
                  <div className="row align-items-center">
                    <div className="col">
                      <button className="btn btn-black btn-block w-100 px-4" type="button" onClick={filterDate}>
                        Apply
                      </button>
                    </div>
                    <div className="col">
                      <button className="btn btn-light btn-block w-100" type="button" onClick={resetFilterDate}>
                        <i class="ri-reset-left-line"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTable
                  className="table table-hover"
                  columns={columns}
                  data={exportData}
                  pagination
                  noDataComponent={
                    <p> <img src="/assets/img/not_found.svg" width="300" className='img-fluid mt-5' alt='' /> </p>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default TradeList;
