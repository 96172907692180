import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUserBanknList, getUserBankColumns, handlePaginationBank } from "../../../../services/Context/PaymentContext";

const UsersBankList = () => {

  const location = useLocation();
  const navigate = useNavigate()
  const [bankStatus, setBankStatus] = useState(location.state?.status || "pending");
  const [userBankList, setUserBankList] = useState([]);
  const [totalDataBank, setTotalDataBank] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [skip, setSkip] = useState(0);
  const limit = 10;

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchKey(searchTerm);
  };

  const fetchBankData = async (bankStatus, skip, limit, searchKey = "",) => {
    setBankStatus(bankStatus);
    navigate(location.pathname, { state: {} });
    const data = await fetchUserBanknList(bankStatus, skip, limit, searchKey);
    setUserBankList(data?.list || []);
    setTotalDataBank(data?.totalCount || 0);
  };

  const columns = getUserBankColumns(navigate, bankStatus,totalDataBank,skip);

  useEffect(() => {
    fetchBankData(bankStatus, skip, limit, searchKey);
  }, [bankStatus, skip, limit, searchKey]);


  
  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header ">
          <div className="container-xl px-4">
            <div className="page-header-content">
              <div className="row align-items-center justify-content-between">
                <div className="col-12">
                  <div className="d-flex justify-content-between" >
                    <h1 className="page-header-title">
                      Bank Verification
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4">
          <div className="card mb-4">
            <div className="card-header pb-0">
              <ul className="nav nav-tabs nav-tabs-custom" id="pills-tab" role="tablist">
              
                <li className="nav-item" role="presentation">
                  <button className={`nav-link ${bankStatus === "pending" ? "active" : ""}`} type="button" onClick={() => fetchBankData("pending", skip, limit, searchKey)}> Pending  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={`nav-link ${bankStatus === "verified" ? "active" : ""}`} type="button" onClick={() => fetchBankData("verified", skip, limit, searchKey)}> Approved  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={`nav-link ${bankStatus === "rejected" ? "active" : ""}`} type="button" onClick={() => fetchBankData("rejected", skip, limit, searchKey)}> Rejected  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={`nav-link ${bankStatus === "deleted" ? "active" : ""}`} type="button" onClick={() => fetchBankData("deleted", skip, limit, searchKey)}> Deleted  </button>
                </li>
              </ul>

              <div className="dropdown">
                <button className="btn btn-dark btn-sm dropdown-toggle" type="button" >
                  <i className="fa fa-download me-2"></i>
                  <CSVLink data={userBankList} className="text-white">
                    Export as CSV
                  </CSVLink>
                </button>
              </div>

            </div>
            <div className="search-header">
              <div className="dd_search">
                <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" onChange={handleSearch} />
                <i className="fas fa-search"></i>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTable
                  className="table table-hover"
                  columns={columns}
                  data={userBankList}
                  noDataComponent={
                    <p> <img src="/assets/img/not_found.svg" width="300" className='img-fluid mt-5' alt='' /> </p>
                  } />

                {
                  userBankList?.length > 0 &&
                  <div className="hVPalX gap-2" >
                    <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataBank)} of {totalDataBank}</span>
                    <div className="sc-eAKtBH gVtWSU">
                      <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePaginationBank('first', setSkip, skip, limit, totalDataBank)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                          <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                          <path fill="none" d="M24 24H0V0h24v24z"></path>
                        </svg>
                      </button>
                      <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePaginationBank('prev', setSkip, skip, limit, totalDataBank)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                      </button>

                      <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePaginationBank('next', setSkip, skip, limit, totalDataBank)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                          <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                      </button>
                      <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePaginationBank('last', setSkip, skip, limit, totalDataBank)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                          <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                          <path fill="none" d="M0 0h24v24H0V0z"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
};
export default UsersBankList;
