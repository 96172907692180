import React, { useEffect, useState } from "react";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import moment from "moment";

const DirectWithdrawalLimit = ({ activeTab }) => {

    const [userLimitData, setUserLimitData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState("");
    const [isActiveModel, setIsActiveModel] = useState(false);


    const [formData, setFormData] = useState({
        total_orders: "",
        min_order: "",
        max_order: "",
        fee: "",
        tds: "",
        remark: ""
    });


    useEffect(() => {
        if (userLimitData) {
            setFormData({
                total_orders: userLimitData.total_orders || "",
                min_order: userLimitData.min_order || "",
                max_order: userLimitData.max_order || "",
                fee: userLimitData.fee || "",
                tds: userLimitData.tds || ""
            });
        }
    }, [userLimitData]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };


    const handleLimitData = async () => {
        await AuthService.getDirectWithdrawalLimit().then(async (result) => {
            if (result.success) {
                try {
                    setUserLimitData(result?.data);
                    setIsLoading(false);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result);
            }
        });
    };

    useEffect(() => {
        if (activeTab === "DirectWithdrawal") {
            handleLimitData();
        }
    }, [activeTab])


    const handleUpdateUserLimit = async () => {
        setApiResponse("");
        try {
            setIsLoading(true);
            const result = await AuthService.updateDirectWithdrawalLimit(formData);
            if (result.success) {
                setIsActiveModel(false);
                handleLimitData();
                setIsLoading(false);
                alertSuccessMessage(result?.messaeg);
            } else {
                setApiResponse(result);
                setIsLoading(false);
            }
        } catch (error) {
            setApiResponse(error);
            setIsLoading(false);
        }
    };



    return (
        <div className={`tab-pane ${activeTab === "DirectWithdrawal" ? "active" : ""}`} >
            <div className="profile_data  ac_body" >
                <div className="row g-3 mt-0">
                    <div className="col-lg-12" >
                        <div className="d-flex align-items-center justify-content-between mt-2 mb-2" >
                            <h5> Direct Withdrawal  </h5>
                            <button className="btn btn-warning btn-sm mb-2" type="button" onClick={() => setIsActiveModel(true)}> Update Limits  </button>
                        </div>
                        <div className="card  ">
                            <div className="card-body py-4">

                                <div className="row g-4" >
                                    <div className=" col-lg-12" >
                                        <div className="profile_data fc_details  ac_body " >
                                            <div className="d-flex align-items-center justify-content-between mb-4" >
                                                <h5 className="mb-0" >  Direct Withdrawal Limit  </h5>

                                            </div>
                                            <div className="row g-4">


                                                <div className="col-lg-3 col-xl-3">
                                                    <div className="label-group">
                                                        <label>Total Orders</label>
                                                        <p>    {userLimitData?.total_orders}  </p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-xl-3">
                                                    <div className="label-group">
                                                        <label>  Fees  </label>
                                                        <p>    {userLimitData?.fee}  </p>
                                                    </div>
                                                </div>


                                                <div className="col-lg-3 col-xl-3">
                                                    <div className="label-group">
                                                        <label> Min Order </label>
                                                        <p>    {userLimitData?.min_order}  </p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-xl-3">
                                                    <div className="label-group">
                                                        <label>  Max Order    </label>
                                                        <p>  {userLimitData?.max_order} </p>
                                                    </div>
                                                </div>



                                            </div>
                                            <div className="row g-4 mt-0">

                                                <div className="col-lg-3 col-xl-3">
                                                    <div className="label-group">
                                                        <label> TDS    </label>
                                                        <p>   {userLimitData?.tds}   </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-xl-3">
                                                    <div className="label-group">
                                                        <label> Currency    </label>
                                                        <p>  {userLimitData?.currency}   </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-xl-3">
                                                    <div className="label-group">
                                                        <label> Created At  </label>
                                                        <p>   {moment(userLimitData?.createdAt).format('DD MMM YYYY, h:mm:ss a')}   </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-xl-3">
                                                    <div className="label-group">
                                                        <label> Updated At  </label>
                                                        <p>   {moment(userLimitData?.updatedAt).format('DD MMM YYYY, h:mm:ss a')}   </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="profile_data  ac_body my-4 " >
                <div className="card  ">
                    <div className="card-body py-4">
                        <div className="d-flex justify-content-between" >
                            <h5> Updated History </h5>
                        </div>
                        <div className="oul_scroll" >
                            <div className="table-responsive m_table">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date/Time</th>
                                            <th scope="col">Limit</th>
                                            <th scope="col">Remark</th>
                                            <th scope="col">Admin Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       {/*  {userLimitData?.remarks?.map((item, index) => { */}
                                       {userLimitData?.remarks?.slice().reverse().map((item, index) => {  
                                            const limitEntries = item.limit
                                                ? Object.entries(item.limit).filter(([key]) => key !== "_id" && key !== "__v" && key !== "createdAt")
                                                : [];

                                            return (
                                                <tr key={item._id || index}>
                                                    <td>{moment(item.timestamp).format('Do MMM YYYY, h:mm:ss a')}</td>
                                                    <td>
                                                        {limitEntries.length > 0 ? (
                                                            <ul>
                                                                {limitEntries.map(([indexNew, value]) => (
                                                                    <li key={indexNew}> {indexNew} :  {" "}  {value}</li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            "----"
                                                        )}
                                                    </td>
                                                    <td>{item.remark || "----"}</td>
                                                    <td>{item.admin_name || "----"}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {isActiveModel && (
                <>
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-dialog-centered ">
                            <div className="modal-content  ">
                                <div className="modal-body p-0 row gx-5">
                                    <div className="col-lg-12" >
                                        <h6 className="mb-3" >Update Order Limits </h6>
                                        <div className="row">


                                            <div className="col-lg-6 form-group mb-3">
                                                <label className="small mb-1" htmlFor="total_orders">Total Orders<em>*</em></label>
                                                <div className="otp_box">
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="total_orders"
                                                        name="total_orders"
                                                        placeholder="Enter Amount"
                                                        type="number"
                                                        value={formData.total_orders}
                                                        onChange={handleChange}
                                                    />

                                                </div>
                                            </div>



                                            <div className="col-lg-6 form-group mb-3">
                                                <label className="small mb-1" htmlFor="min_order">Min Orders<em>*</em></label>
                                                <div className="otp_box">
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="min_order"
                                                        name="min_order"
                                                        placeholder="Enter Amount"
                                                        type="number"
                                                        value={formData.min_order}
                                                        onChange={handleChange}
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-lg-6 form-group mb-3">
                                                <label className="small mb-1" htmlFor="max_order">Max Orders<em>*</em></label>
                                                <div className="otp_box">
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="max_order"
                                                        name="max_order"
                                                        placeholder="Enter Amount"
                                                        type="number"
                                                        value={formData.max_order}
                                                        onChange={handleChange}
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-lg-6 form-group mb-3">
                                                <label className="small mb-1" htmlFor="fee">Fee Limit<em>*</em></label>
                                                <div className="otp_box">

                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="fee"
                                                        name="fee"
                                                        placeholder="Enter Amount"
                                                        type="number"
                                                        value={formData.fee}
                                                        onChange={handleChange}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-lg-6 form-group mb-3 ">
                                                <label className="small mb-1" htmlFor="tds">Tds<em>*</em></label>
                                                <div className="otp_box">

                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="tds"
                                                        name="tds"
                                                        placeholder="Enter Amount"
                                                        type="number"
                                                        value={formData.tds}
                                                        onChange={handleChange}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-lg-12 form-group mb-3">
                                                <label className="small mb-1" htmlFor="remark">Remark<em>*</em></label>
                                                <div className="otp_box">
                                                    <textarea
                                                        className="form-control form-control-solid"
                                                        id="remark"
                                                        name="remark"
                                                        placeholder="Enter Remark"
                                                        type="number"
                                                        value={formData.remark}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-center gap-3 flex-between w-100 mt-5">
                                        <button className="btn btn-primary btn-block px-5 btn-md " type="button" onClick={handleUpdateUserLimit}>
                                            {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                                                <span>Update{/*  APPLY TO USER */}</span>}
                                        </button>
                                        <button className="btn btn-secondary btn-block px-5  btn-md " type="button"
                                            onClick={() => { setIsActiveModel(false); setApiResponse("") }}>
                                            Cancel
                                        </button>

                                    </div>
                                    {apiResponse && <div className="text-danger">{apiResponse}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            )
            }
        </div>
    )
};

export default DirectWithdrawalLimit;