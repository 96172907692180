import React from "react";

const SecurityDetails = ({ activeTab }) => {

    return (
        <div className={`tab-pane ${activeTab === 3 ? "active" : ""}`} >

        <div className="card-body py-4" >

            <div className="profile_data " >
                <h4 className="mb-4" >  Two Factor Authentication   </h4>

                <div className="  row justify-content-center" >

                    <div className="col-lg-7" >
                        <div className="per_col mb-3" >
                            <div className="d-flex align-items-center " >
                                <div className="pr_icon" ><i className="ri-arrow-up-down-line"></i></div>
                                <div>
                                    <h5>Authenticator App :</h5>
                                </div>
                            </div>
                            <label className="switch">
                                <input type="checkbox" name="" id="forBuySell" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-7" >
                        <div className="per_col mb-3" >
                            <div className="d-flex align-items-center " >
                                <div className="pr_icon" ><i className="ri-line-chart-fill"></i></div>
                                <div>
                                    <h5>  Email OTP Verification </h5>
                                </div>
                            </div>
                            <label className="switch">
                                <input type="checkbox" name="" id="forBuySell" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-7" >
                        <div className="per_col mb-3" >
                            <div className="d-flex align-items-center " >
                                <div className="pr_icon" ><i className="ri-p2p-fill"></i></div>
                                <div>
                                    <h5>   Mobile OTP Verification  </h5>
                                </div>
                            </div>
                            <label className="switch">
                                <input type="checkbox" name="" id="forBuySell" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-7" >
                        <div className="per_col mb-3" >
                            <div className="d-flex align-items-center " >
                                <div className="pr_icon" ><i className="ri-arrow-up-line"></i></div>
                                <div>
                                    <h5> None  </h5>
                                </div>
                            </div>
                            <label className="switch">
                                <input type="checkbox" name="" id="forBuySell" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>


                </div>


            </div>
        </div>
    </div>
    )
};

export default SecurityDetails;