import React from "react";
import { Link } from "react-router-dom";

const ActivityLogs = ({ activeTab }) => {

    return (
        <div className={`tab-pane ${activeTab === 6 ? "active" : ""}`} >
        <div className="card-body py-4" >

            <div className="profile_data  ac_body  " >
                <div className="d-flex justify-content-between" >
                    <h5> User Logs </h5>

                </div>
            </div>
        </div>
    </div>
    )
};

export default ActivityLogs;