import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

const EntityKyc = () => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [kycStatus, setKycStatus] = useState("entity-pending-kyc-user");

  const navigate = useNavigate()
  const linkFollow = (row) => {
    return (
      <button className="verifybtn" onClick={() => { navigate('/kyc_detail_entity', { state: row }) }}>
        View
      </button>
    );
  };

  const columns = [
    { name: "Date", selector: row => moment(row?.createdAt).format("Do MMMM YYYY"), },
    { name: "ID", wrap: true, selector: row => row.userId, },
    { name: "FirstName", selector: row => row.firstName, },
    { name: "LastName", selector: row => row.lastName, },
    { name: "EmailId", wrap: true, selector: row => row.email, },
    { name: "Mobile No.", selector: row => row.phone, },
    { name: "Action", selector: linkFollow, },
  ];

  const handleData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getKycdataEntity(kycStatus).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setData(result.data.reverse());
          setAllData(result.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
      }
    });
  };


  function searchObjects(e) {
    const keysToSearch = ["firstName", "lastName", "email", "pan", "phone", "userId"];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = allData.filter(obj => {
      return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
    });
    setData(matchingObjects);
  }

  useEffect(() => {
    handleData(kycStatus);
  }, [kycStatus]);

  return (
    <div id="layoutSidenav_content">
    <main>
      <header className="page-header ">
        <div className="container-xl px-4">
          <div className="page-header-content">
            <div className="row align-items-center justify-content-between">
              <div className="col-12">
                <div className="d-flex justify-content-between" >
                  <h1 className="page-header-title">
                    <div className="page-header-icon"><i className=" fa fa-user-slash"></i></div>
                   {kycStatus?.toUpperCase()}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="container-xl px-4">
        <div className="card mb-4">
          <div className="card-header pb-0">
            <ul className="nav nav-tabs nav-tabs-custom" id="pills-tab" role="tablist">

            <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-Not-Submited-tab" data-bs-toggle="pill" data-bs-target="#pills-Not-Submited" type="button" role="tab" aria-controls="pills-Not-Submited" aria-selected="false" onClick={() => setKycStatus("entity-not-submitted-kyc-user")}> Not Submited  </button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="pills-Pending-tab" data-bs-toggle="pill" data-bs-target="#pills-Pending" type="button" role="tab" aria-controls="pills-Pending" aria-selected="false" onClick={() => setKycStatus("entity-pending-kyc-user")}> Pending  </button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-Rejected-tab" data-bs-toggle="pill" data-bs-target="#pills-Rejected" type="button" role="tab" aria-controls="pills-Rejected" aria-selected="false" onClick={() => setKycStatus("entity-rejected-kyc-user")}> Rejected  </button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-Approved-tab" data-bs-toggle="pill" data-bs-target="#pills-Approved" type="button" role="tab" aria-controls="pills-Approved" aria-selected="false" onClick={() => setKycStatus("entity-approve-kyc-user")}> Approved  </button>
              </li>
            </ul>
            <div className="dropdown">
              <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                <i className="fa fa-download me-3"></i>Export
              </button>
              <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp" >
                <CSVLink data={data} className="dropdown-item">
                  Export as CSV
                </CSVLink>
              </div>
            </div>
          </div>
          <div className="search-header">
            <div className="dd_search">
              <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" onChange={searchObjects} />
              <i class="fas fa-search"></i>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive" width="100%">
              <DataTable columns={columns} data={data} pagination />
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  )
};
export default EntityKyc;
