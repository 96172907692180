import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useContext } from 'react';
import Sidebar from "../../Components/CustomComponent/Sidebar";
import Dashboard from "../../Components/SidebarPages/Dashboard";
import SubAdminList from "../../Components/SidebarPages/Subadmin/SubadminList";
import UserList from "../../Components/SidebarPages/Users/UserList";
import CurrencyManagement from "../../Components/SidebarPages/Currency/CurrencyManagement";
import CurrencypairManagement from "../../Components/SidebarPages/Currency/CurrencyPairManagement";
import CompletedDeposit from "../../Components/SidebarPages/FundsDepositManagement/CompletedDeposit";
import PendingDeposit from "../../Components/SidebarPages/FundsDepositManagement/PendingDeposit";
import CancelledWithdrawal from "../../Components/SidebarPages/FundsWithdrawalManagement/CancelledWithdrawal";
import PendingWithdrawal from "../../Components/SidebarPages/FundsWithdrawalManagement/PendingWithdrawal";
import CompletedWithdrawal from "../../Components/SidebarPages/FundsWithdrawalManagement/CompletedWithdrawal";
import Notification from "../../Components/SidebarPages/Notifications";
import BannerManagement from "../../Components/SidebarPages/BannerManagement";
import OrderBook from "../../Components/SidebarPages/OrderBook";
import LoginPage from "../../Components/AuthComponent/Login";
import ForgetpasswordPage from "../../Components/AuthComponent/ForgetPassword";
import AddSubadmin from "../../Components/SidebarPages/Subadmin/AddSubadmin";
import EditSubadmin from "../../Components/SidebarPages/Subadmin/EditSubadmin";
import { ProfileContext } from "../../Components/CustomComponent/ProfileContext";
import UserDetails from "../../Components/SidebarPages/Users/UserDetails";
import TradingReport from "../../Components/SidebarPages/Trading Report";
import Support from "../../Components/SidebarPages/Support";
import Header from '../../Components/CustomComponent/Header';
import KycDetailsPersonal from '../../Components/SidebarPages/KYCManager/KycDetailsPersonal';
import BuySellList from '../../Components/SidebarPages/BuySellPage/BuySellList';
import BuySellHistory from '../../Components/SidebarPages/BuySellPage/BuySellHistory';
import WithdrawalMultisig from '../../Components/SidebarPages/WithdrawalMultiSig';
import KycDetailsEntity from '../../Components/SidebarPages/KYCManager/KycDetailsEntity';

import PersonalKyc from '../../Components/SidebarPages/KYCManager/PersonalKyc';
import EntityKyc from '../../Components/SidebarPages/KYCManager/EntityKyc';
import ViewSubadmin from '../../Components/SidebarPages/Subadmin/ViewSubadmin';
import InvestEarnHistory from '../../Components/SidebarPages/InvestEarn/InvestEarnHistory';
import DepositRequest from '../../Components/SidebarPages/InvestEarn/DepositRequest';
import WithdrawalRequest from '../../Components/SidebarPages/InvestEarn/WithdrawalRequest';
import CreateBuySellOrder from '../../Components/SidebarPages/BuySellPage/CreateBuySellOrder';
import MasterAdmin from '../../Components/SidebarPages/MasterAdmin';
import JobApplications from '../../Components/SidebarPages/FooterPages/JobApplications';
import CoinListing from '../../Components/SidebarPages/FooterPages/CoinListing';
import MarketMakerProgram from '../../Components/SidebarPages/FooterPages/MarketMakerProgram';
import PartnershipInquiry from '../../Components/SidebarPages/FooterPages/PartnershipInquiry';
import Permissions from '../../Components/SidebarPages/Permissions';
import ExchangeLimits from '../../Components/SidebarPages/ExchangeLimits';
import UpgradeKycDetails from '../../Components/SidebarPages/KYCManager/UpgradeKycDetails';
import UpgradeKycList from '../../Components/SidebarPages/KYCManager/UpgradeKycList';
import CoinListingDetails from '../../Components/SidebarPages/FooterPages/CoinListingDetails';
import ProductFeedback from '../../Components/SidebarPages/FooterPages/ProductFeedback';
import FeestructureQbs from '../../Components/SidebarPages/FeeStractureQbs/FeestructureQbs';
import FeestructureSpot from '../../Components/SidebarPages/FeeStractureSpot/FeestructureSpotList';
import AddFeeStractureQbs from '../../Components/SidebarPages/FeeStractureQbs/AddFeeStractureQbs';
import AddFeeStractueSpot from '../../Components/SidebarPages/FeeStractureSpot/AddFeeStractueSpot';
import JobOepningList from '../../Components/SidebarPages/FooterPages/JobOpening/JobOepningList';
import AddJobOpening from '../../Components/SidebarPages/FooterPages/JobOpening/AddJobOpening';
import ViewUserJobApplications from '../../Components/SidebarPages/FooterPages/ViewUserJobApplications';
import SupportTicketList from '../../Components/SidebarPages/FooterPages/UserSupport/SupportTicketList';
import SupportTicketDetails from '../../Components/SidebarPages/FooterPages/UserSupport/SupportTicketDetails';
import CreateTicket from '../../Components/SidebarPages/FooterPages/UserSupport/CreateTicket';
import ExchangePayments from '../../Components/SidebarPages/ExchangePayments';
import UsersBankList from '../../Components/SidebarPages/UserBankingManagement/UsersBankList';
import UsersBankDetails from '../../Components/SidebarPages/UserBankingManagement/UsersBankDetails';
import UsersUpiVerification from '../../Components/SidebarPages/UserBankingManagement/UsersUpiVerification';
import QuickPayHistory from '../../Components/SidebarPages/QuickPayHistory';
import CryptoDeposit from '../../Components/SidebarPages/FundsDepositManagement/CryptoDeposit';  
import INRDeposit from '../../Components/SidebarPages/FundsDepositManagement/INRDeposit';



const Routing = () => {

    const [profileState] = useContext(ProfileContext)
    const token = sessionStorage.getItem('token');
    const isAuthenticated = profileState.token || token;

    return (
        <Router>
            {isAuthenticated ? (
                <>
                    <Header />
                    <div id="layoutSidenav" >
                        <Sidebar />
                        <Routes>
                            <Route exact path="/dashboard" element={<Dashboard />} />
                            <Route exact path="/sub_admin_list" element={<SubAdminList />} />
                            <Route exact path="/add_sub_admin" element={<AddSubadmin />} />
                            <Route exact path="/edit_sub_admin" element={<EditSubadmin />} />
                            <Route exact path="/view_sub_admin" element={<ViewSubadmin />} />
                            <Route exact path="/users_list" element={<UserList />} />
                            <Route exact path="/user_details" element={<UserDetails />} />
                            <Route exact path="/permissions" element={<Permissions />} />
                            <Route exact path="/exchange_payments" element={<ExchangePayments />} />
                            <Route exact path="/exchange_limits" element={<ExchangeLimits />} />


                            <Route exact path="/master_admin" element={<MasterAdmin />} />



                            <Route exact path="/personal_kyc" element={<PersonalKyc />} />
                            <Route exact path="/upgrade_kyc" element={<UpgradeKycList />} />
                            <Route exact path="/kyc_detail_personal" element={<KycDetailsPersonal />} />
                            <Route exact path="/upgrade_kyc_details" element={<UpgradeKycDetails />} />
                            <Route exact path="/entity_kyc" element={<EntityKyc />} />
                            <Route exact path="/kyc_detail_entity" element={<KycDetailsEntity />} />

                            <Route exact path="/withdrwalmultisig" element={<WithdrawalMultisig />} />


                            <Route exact path="/users_bank_list" element={<UsersBankList />} />
                            <Route exact path="/users_bank_details" element={<UsersBankDetails />} />
                            <Route exact path="/users_upi_verification" element={<UsersUpiVerification />} />


                            <Route exact path="/deposit_request" element={<DepositRequest />} />
                            <Route exact path="/withdrawal_request" element={<WithdrawalRequest />} />
                            <Route exact path="/invest_earn_history" element={<InvestEarnHistory />} />


                            <Route exact path="/currency_management" element={<CurrencyManagement />} />
                            <Route exact path="/currencypair" element={<CurrencypairManagement />} />
                            <Route exact path="/crypto_deposit" element={<CryptoDeposit />} /> 
                            <Route exact path="inr_deposit" element={<INRDeposit />} />  
                            <Route exact path="/complete_deposit" element={<CompletedDeposit />} />
                            <Route exact path="/pending_deposit" element={<PendingDeposit />} />
                            <Route exact path="/completed_withdrawal" element={<CompletedWithdrawal />} />
                            <Route exact path="/pending_withdrawal" element={<PendingWithdrawal />} />
                            <Route exact path="/cancelled_withdrawal" element={<CancelledWithdrawal />} />
                            <Route exact path="/trading_report" element={<TradingReport />} />
                            <Route exact path="/order_book" element={<OrderBook />} />
                            <Route exact path="/notification" element={<Notification />} />
                            <Route exact path="/support" element={<Support />} />
                            <Route exact path="/banner_management" element={<BannerManagement />} />
                            <Route exact path="/buy_sell_list" element={<BuySellList />} />
                            <Route exact path="/buysell_ad" element={<CreateBuySellOrder />} />
                            <Route exact path="/buysell-history" element={<BuySellHistory />} />
                            <Route exact path="/quick_pay_history" element={<QuickPayHistory />} /> 

                            <Route exact path="/market_maker_program" element={<MarketMakerProgram />} />
                            <Route exact path="/coin_listing" element={<CoinListing />} />
                            <Route exact path="/partnership_inquiry" element={<PartnershipInquiry />} />
                            <Route exact path="/listing_coin_details" element={<CoinListingDetails />} />
                            <Route exact path="/product_feedback" element={<ProductFeedback />} />

                            <Route exact path="/fee_structure_qbs" element={<FeestructureQbs />} />
                            <Route exact path="/add_structure_qbs" element={<AddFeeStractureQbs />} />

                            <Route exact path="/fee_structure_spot" element={<FeestructureSpot />} />
                            <Route exact path="/add_structure_spot" element={<AddFeeStractueSpot />} />

                            <Route exact path="/add_job_opening" element={<AddJobOpening />} />
                            <Route exact path="/job_opening" element={<JobOepningList />} />
                            <Route exact path="/job_applications" element={<JobApplications />} />
                            <Route exact path="/view_job_applications" element={<ViewUserJobApplications />} />

                            <Route exact path="/create_user_support" element={<CreateTicket />} />
                            <Route exact path="/support_ticket_list" element={<SupportTicketList />} />
                            <Route exact path="/support_ticket_details" element={<SupportTicketDetails />} />

                            <Route exact path="*" element={<Navigate to="/dashboard" />} />
                        </Routes>
                    </div>


                </>
            ) : (
                <Routes>
                    <Route exact path="/" element={<LoginPage />} />
                    <Route exact path="/*" element={<LoginPage />} />
                    <Route exact path="/forgotpassword" element={<ForgetpasswordPage />} />
                    <Route exact path="*" element={<Navigate to="/" />} />
                </Routes>
            )}
        </Router>
    );
};

export default Routing;
