import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import DataTable from "react-data-table-component";
import AuthService from "../../../services/ApiIntegration/AuthService";
import LoaderHelper from "../../CustomComponent/LoaderHelper/LoaderHelper";
import { alertErrorMessage } from "../../CustomComponent/CustomAlert";

const QuickPayHistory = () => {

    const [buySellHistory, setBuySellHistory] = useState([]);
    const [allData, setAllData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [totalDataLength, setTotalDataLength] = useState([]);

    const limit = 10;

    const columns = [

        {
            name: 'Order No.',
            selector: row => row._id,
            sortable: true,
            wrap: true,

            format: row =>
                <div className="c_view">{row._id} </div>,
        },

        {
            name: "Date",
            cell: (row) => <div className="justify-content-start" > <span>{moment(row.createdAt).format("DD/MM/YYYY  ")} <br /> <small>{moment(row.createdAt).format("HH:mm:ss")}</small> </span> </div>,
            sortable: false,
        },

        {
            name: 'Currency',
            selector: row => row,
            sortable: true,
            format: row => <div className="text-end">{row.base_currency + "/" + row.quote_currency}</div>,
        },
        {

            name: 'Side',
            selector: row => row.side,
            wrap: true,
            sortable: true,
            format: row =>
                <span className={row.side === 'BUY' ? " text-success" : "text-danger"}>
                    {row?.side}
                </span>,
        },
        {
            name: 'Total Paid',
            selector: row => row.total_pay_amount,
            wrap: true,
            sortable: true,
            format: row => <div className="text-end">{row.total_pay_amount?.$numberDecimal}
                &nbsp;{row?.side === "BUY" ? row.quote_currency : row.base_currency}</div>,

        },
        {
            name: 'Fee',
            selector: row => row.transaction_fees_amount?.$numberDecimal || '0',
            wrap: true,
            sortable: true,

        },
        {
            name: 'TDS',
            selector: row => row.side === "SELL" && row.tds_amount?.$numberDecimal || '0.00',
            wrap: true,
            sortable: true,

        },

        {
            name: 'Total Recived',
            selector: row => row.total_get_amount,
            wrap: true,
            sortable: true,
            format: row => <div className="text-end">{row.total_get_amount?.$numberDecimal}
                &nbsp;{row?.side === "BUY" ? row.base_currency : row.quote_currency}</div>,

        },

        {

            name: 'Status',
            selector: row => row.status,
            wrap: true,
            sortable: true,
            format: row =>
                <span className={row.status === 'Completed' ? " text-success" : row.status === 'Cancelled' ? "text-danger" : row.status === 'Rejected' ? "text-danger" : "text-warning"}>
                    {row?.status}
                </span>,
        },

    ];



    const handlePagination = (action) => {
        if (action === 'prev') {
            if (skip - limit >= 0) {
                setSkip(skip - limit);
            }
        } else if (action === 'next') {
            if (skip + limit < totalDataLength) {
                setSkip(skip + limit);
            }
        } else if (action === 'first') {
            setSkip(0);
        } else if (action === 'last') {
            const lastPageSkip = Math.floor(totalDataLength);
            const data = lastPageSkip - 10
            setSkip(data);
        }
    };


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header ">
                    <div className="container-xl px-4">
                        <div className="page-header-content">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="far fa-user"></i></div>
                                        Buy Sell History
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4">
                    <div className="card mb-4">
                        <div className="card-header">
                            <div className="dropdown">
                                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Export{" "}
                                </button>
                                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                    <CSVLink data={buySellHistory} className="dropdown-item">Export as CSV</CSVLink>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive" width="100%">
                            <DataTable columns={columns} data={buySellHistory} />

                            {
                                buySellHistory?.length > 0 &&
                                <div className="hVPalX gap-2" >
                                    <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                    <div className="sc-eAKtBH gVtWSU">
                                        <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                <path fill="none" d="M24 24H0V0h24v24z"></path>
                                            </svg>
                                        </button>
                                        <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                            </svg>
                                        </button>

                                        <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                            </svg>
                                        </button>
                                        <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                <path fill="none" d="M0 0h24v24H0V0z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default QuickPayHistory;