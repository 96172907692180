import React from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
    const location = useLocation();
    const handleRemoveClass = () => {
        document.body.classList.remove('sidenav-toggled')
    }

    return (
        <div id="layoutSidenav_nav">
            <nav className="sidenav shadow-right sidenav-light">
                <div className="sidenav-menu">
                    <div className="nav accordion" id="accordionSidenav">
                        <div className="sidenav-menu-heading">pages</div>
                        <Link to="/dashboard" className={`nav-link collapsed ${location?.pathname === "/dashboard" && "active"}`} onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i class="ri-apps-2-line"></i></div>
                            Dashboard
                        </Link>

                        {/* sub Admin */}
                        <div>
                            <div className={`nav-link collapsed ${['/sub_admin_list', '/add_sub_admin', '/edit_sub_admin'].includes(location?.pathname) ? 'active' : ''}`}
                                data-bs-toggle="collapse" data-bs-target="#collapseSubadmin" aria-expanded="false" aria-controls="collapseSubadmin">
                                <div className="nav-link-icon"><i class="ri-user-settings-line"></i></div>
                                Admin
                                <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </div>

                            <div className={`collapse ${['/sub_admin_list', '/add_sub_admin', '/edit_sub_admin'].includes(location?.pathname) ? 'show' : ''}`}
                                id="collapseSubadmin" data-bs-parent="#accordionSidenav">
                                <nav className="sidenav-menu-nested nav">
                                    <Link className={`nav-link ${location?.pathname === '/sub_admin_list' ? 'active' : ''}`} to="/sub_admin_list"
                                        onClick={handleRemoveClass}
                                    >
                                        Admin's List
                                    </Link>

                                    <Link className={`nav-link ${location?.pathname === '/add_sub_admin' ? 'active' : ''}`} to="/add_sub_admin"
                                        onClick={handleRemoveClass}>
                                        Add New Admin
                                    </Link>
                                </nav>
                            </div>
                        </div>



                        {/* bbbb b */}


                        <div>
                            <div className={`nav-link collapsed ${['/bank_verification', '/user_bank_details', '/upi_verification'].includes(location?.pathname) ? 'active' : ''}`}
                                data-bs-toggle="collapse" data-bs-target="#collapseBanking" aria-expanded="false" aria-controls="collapseBanking">
                                <div className="nav-link-icon"><i class="ri-money-rupee-circle-line"></i></div>
                                Payments
                                <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </div>

                            <div className={`collapse ${['/bank_verification', '/user_bank_details', '/upi_verification'].includes(location?.pathname) ? 'show' : ''}`}
                                id="collapseBanking" data-bs-parent="#accordionSidenav">
                                <nav className="sidenav-menu-nested nav">
                                    <Link className={`nav-link ${location?.pathname === '/bank_verification' ? 'active' : ''}`} to="/bank_verification"
                                        onClick={handleRemoveClass}
                                    >
                                        Bank Verification
                                    </Link>

                                    <Link className={`nav-link ${location?.pathname === '/upi_verification' ? 'active' : ''}`} to="/upi_verification"
                                        onClick={handleRemoveClass}>
                                        UPI Verification
                                    </Link>

                                </nav>
                            </div>
                        </div>



                        {/*  Users */}

                        <Link to="/users_list" className={`nav-link collapsed ${['/users_list', '/user_details'].includes(location?.pathname) ? 'active' : ''}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i class="ri-group-line"></i></div>
                            Users
                            {/* <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div> */}
                        </Link>


                        {/* Personal KYC Manager */}

                        <Link to="/personal_kyc" className={`nav-link collapsed ${['/personal_kyc', '/kyc_detail_personal'].includes(location?.pathname) ? 'active' : ''}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i class="ri-shield-user-line"></i></div>
                            Personal KYC
                            {/* <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div> */}
                        </Link>

                        {/* Entity KYC Manager */}

                        <Link to="/entity_kyc" className={`nav-link collapsed ${['/entity_kyc', '/kyc_detail_entity'].includes(location?.pathname) ? 'active' : ''}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i class="ri-briefcase-4-fill"></i></div>
                            Entity KYC
                            {/* <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div> */}
                        </Link>




                        {/*  Buy Sell */}
                        <div>
                            <div className={`nav-link collapsed ${['/buysell_ad', '/buysell',  '/buysell-history'].includes(location?.pathname) ? 'active' : ''}`}
                                data-bs-toggle="collapse" data-bs-target="#collapseBuySell" aria-expanded="false" aria-controls="collapseBuySell">
                                <div className="nav-link-icon"><i class="ri-arrow-up-down-fill"></i></div>
                                Buy & Sell
                                <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </div>

                            <div className={`collapse ${['/buysell_ad', '/buysell', '/buysell-history'].includes(location?.pathname) ? 'show' : ''}`}
                                id="collapseBuySell" data-bs-parent="#accordionSidenav">
                                <nav className="sidenav-menu-nested nav">
                                    <Link className={`nav-link ${location?.pathname === '/buysell_ad' ? 'active' : ''}`} to="/buysell_ad"
                                        onClick={handleRemoveClass}>
                                        Create Advertisement  
                                    </Link>
                                    <Link className={`nav-link ${location?.pathname === '/buysell' ? 'active' : ''}`} to="/buysell"
                                        onClick={handleRemoveClass}>
                                        Advertisement List
                                    </Link>
                                    <Link className={`nav-link ${location?.pathname === '/buysell-history' ? 'active' : ''}`} to="/buysell-history"
                                        onClick={handleRemoveClass}>
                                        Order History
                                    </Link>
                                </nav>
                            </div>
                        </div>

                        {/* iNVEST AND eARN */}
                        <div>
                            <div className={`nav-link collapsed ${['/deposit_request', '/withdrawal_request', '/invest_earn_history'].includes(location?.pathname) ? 'active' : ''}`}
                                data-bs-toggle="collapse" data-bs-target="#collapseInvestEarn" aria-expanded="false" aria-controls="collapseInvestEarn">
                                <div className="nav-link-icon"><i class="ri-wallet-3-line"></i></div>
                                Invest & Earn
                                <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </div>

                            <div className={`collapse ${['/deposit_request','/withdrawal_request', '/invest_earn_history'].includes(location?.pathname) ? 'show' : ''}`}
                                id="collapseInvestEarn" data-bs-parent="#accordionSidenav">
                                <nav className="sidenav-menu-nested nav">
                                    <Link className={`nav-link ${location?.pathname === '/deposit_request' ? 'active' : ''}`} to="/deposit_request"
                                        onClick={handleRemoveClass}>
                                        Deposit Request
                                    </Link>
                                    <Link className={`nav-link ${location?.pathname === '/withdrawal_request' ? 'active' : ''}`} to="/withdrawal_request"
                                        onClick={handleRemoveClass}>
                                        Withdrawal Request
                                    </Link>
                                    <Link className={`nav-link ${location?.pathname === '/invest_earn_history' ? 'active' : ''}`} to="/invest_earn_history"
                                        onClick={handleRemoveClass}>
                                        History
                                    </Link>
                                </nav>
                            </div>
                        </div>

                        {/* Funds Deposit Management */}
                        <div>
                            <div className={`nav-link collapsed ${['/complete_deposit', '/pending_deposit'].includes(location?.pathname) ? 'active' : ''}`}
                                data-bs-toggle="collapse" data-bs-target="#collapseFundDeposit" aria-expanded="false" aria-controls="collapseFundDeposit">
                                <div className="nav-link-icon"><i class="ri-arrow-up-line"></i></div>
                                Funds Deposit
                                <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </div>

                            <div className={`collapse ${['/complete_deposit', '/pending_deposit'].includes(location?.pathname) ? 'show' : ''}`}
                                id="collapseFundDeposit" data-bs-parent="#accordionSidenav">
                                <nav className="sidenav-menu-nested nav">
                                    <Link className={`nav-link ${location?.pathname === '/complete_deposit' ? 'active' : ''}`} to="/complete_deposit"
                                        onClick={handleRemoveClass}>
                                        Completed Deposit
                                    </Link>
                                    <Link className={`nav-link ${location?.pathname === '/pending_deposit' ? 'active' : ''}`} to="/pending_deposit"
                                        onClick={handleRemoveClass}>
                                        Pending Deposit
                                    </Link>
                                </nav>
                            </div>
                        </div>


                        {/* Funds Withdrawal Management */}
                        <div>
                            <div className={`nav-link collapsed ${['/completed_withdrawal', '/pending_withdrawal', '/cancelled_withdrawal'].includes(location?.pathname) ? 'active' : ''}`}
                                data-bs-toggle="collapse" data-bs-target="#collapseFundWithdrawal" aria-expanded="false" aria-controls="collapseFundWithdrawal">
                                <div className="nav-link-icon"><i class="ri-arrow-down-line"></i></div>
                                Funds Withdrawal
                                <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </div>

                            <div className={`collapse ${['/completed_withdrawal', '/pending_withdrawal', '/cancelled_withdrawal'].includes(location?.pathname) ? 'show' : ''}`}
                                id="collapseFundWithdrawal" data-bs-parent="#accordionSidenav">
                                <nav className="sidenav-menu-nested nav">
                                    <Link className={`nav-link ${location?.pathname === '/completed_withdrawal' ? 'active' : ''}`} to="/completed_withdrawal"
                                        onClick={handleRemoveClass}>
                                        Completed Withdrawal
                                    </Link>
                                    <Link className={`nav-link ${location?.pathname === '/pending_withdrawal' ? 'active' : ''}`} to="/pending_withdrawal"
                                        onClick={handleRemoveClass}>
                                        Pending Withdrawal
                                    </Link>
                                    <Link className={`nav-link ${location?.pathname === '/cancelled_withdrawal' ? 'active' : ''}`} to="/cancelled_withdrawal"
                                        onClick={handleRemoveClass}>
                                        Cancelled Withdrawal
                                    </Link>
                                </nav>
                            </div>
                        </div>


                        {/* Withdrawal Multi Sig */}
                        <Link to="/withdrwalmultisig" className={`nav-link collapsed ${location?.pathname === "/withdrwalmultisig" && "active"}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i class="ri-page-separator"></i></div>
                            Withdrawal Multi Sig
                        </Link>

                        {/* Currency Management */}
                        <Link to="/currency_management" className={`nav-link collapsed ${location?.pathname === "/currency_management" && "active"}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i class="ri-currency-line"></i></div>
                            Currency Management
                        </Link>


                        {/* Currency Management */}
                        <Link to="/currencypair" className={`nav-link collapsed ${location?.pathname === "/currencypair" && "active"}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i class="ri-bar-chart-box-ai-line"></i></div>
                            Currency Pair Management
                        </Link>



                        {/*  Trading Report */}
                        <Link to="/trading_report" className={`nav-link collapsed ${location?.pathname === "/trading_report" && "active"}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i class="ri-line-chart-fill"></i></div>
                            Trading Report
                        </Link>


                        {/*  OrderBook */}
                        <Link to="/order_book" className={`nav-link collapsed ${location?.pathname === "/order_book" && "active"}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i class="ri-contacts-book-upload-line"></i></div>
                            OrderBook
                        </Link>


                        {/*  Support */}
                        <Link to="/support" className={`nav-link collapsed ${location?.pathname === "/support" && "active"}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i class="ri-customer-service-2-fill"></i></div>
                            Support
                        </Link>


                        {/*  Notifications */}
                        <Link to="/notification" className={`nav-link collapsed ${location?.pathname === "/notification" && "active"}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i class="ri-notification-badge-line"></i></div>
                            Notifications
                        </Link>

                        {/*  Banner Management */}
                        <Link to="/banner_management" className={`nav-link collapsed ${location?.pathname === "/banner_management" && "active"}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i class="ri-image-ai-line"></i></div>
                            Banners
                        </Link>

                    </div>
                </div>
            </nav >
        </div >
    )
}

export default Sidebar;