import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../../../services/ApiIntegration/AuthService';

const AddFeeStractureQbs = () => {


    const navigate = useNavigate();
    const [apiResponse, setApiResponse] = useState("");
    const [error, setError] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        currency_name: '',
        short_name: '',
        trading_fee: '',
        min_deposit: '',
        min_withdrawal: '',
        deposit_fee: '',
        withdrawal_fee: '',
    });

    const handleChange = (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
            setError((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }
        setApiResponse("");
    };


    const validationAddFeeStracture = (formData) => {
        if (!formData.currency_name) {
            return { field: 'currency_name', message: 'Currency Name is required' };
        }
        if (!formData.short_name) {
            return { field: 'short_name', message: 'Short Name is required' };
        }
        if (!formData.trading_fee) {
            return { field: 'trading_fee', message: 'Trading Fee is required' };
        }
        if (!formData.min_deposit) {
            return { field: 'min_deposit', message: 'Min Deposit is required' };
        }
        if (!formData.min_withdrawal) {
            return { field: 'min_withdrawal', message: 'Min Withdrawal is required' };
        }
        if (!formData.deposit_fee) {
            return { field: 'deposit_fee', message: 'Deposit Fee is required' };
        }
        if (!formData.withdrawal_fee) {
            return { field: 'withdrawal_fee', message: 'Withdrawal Fee is required' };
        }

        return null;
    };

    const handleAddFeeStracture = async () => {
        const validationError = validationAddFeeStracture(formData);
        if (validationError) {
            setError({ [validationError.field]: validationError.message });
            return;
        }
        try {
            setIsLoading(true);
            const result = await AuthService.addFeeStractureQbs(formData);
            if (result?.success) {
                navigate('/fee_structure_qbs');
            } else {
                setApiResponse(result || 'An unexpected error occurred.');
                setIsLoading(false);
            }
        } catch (error) {
            setApiResponse(error?.message || 'An unexpected error occurred.');
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };



    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header">
                    <div className="container-xl px-4">
                        <div className="page-header-content">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto">
                                    <h1 className="page-header-title">Add Fee structure QBS</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4">
                    <div className="card mb-4">
                        <div className="card-header">Enter Fee structure QBS Details</div>
                        <div className="card-body pt-4">
                            <form>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-4 mt-2">
                                        <label className="small mb-1" htmlFor="currency_name">Currency Name<em>*</em></label>
                                        <input
                                            className="form-control form-control-solid"
                                            id="currency_name"
                                            name="currency_name"
                                            type="text"
                                            value={formData.currency_name}
                                            onChange={handleChange}
                                        />
                                        {error.currency_name && <span className="text-danger">{error.currency_name}</span>}
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="small mb-1" htmlFor="short_name">Short Name<em>*</em></label>
                                        <input
                                            className="form-control form-control-solid"
                                            id="short_name"
                                            name="short_name"
                                            type="text"
                                            value={formData.short_name}
                                            onChange={handleChange}

                                        />
                                        {error.short_name && <span className="text-danger">{error.short_name}</span>}
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="small mb-1" htmlFor="trading_fee">Trading Fee<em>*</em></label>
                                        <input
                                            className="form-control form-control-solid"
                                            id="trading_fee"
                                            name="trading_fee"
                                            type="number"
                                            value={formData.trading_fee}
                                            onChange={handleChange}

                                        />
                                        {error.trading_fee && <span className="text-danger">{error.trading_fee}</span>}
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="small mb-1" htmlFor="min_deposit">Min Deposit<em>*</em></label>
                                        <input
                                            className="form-control form-control-solid"
                                            id="min_deposit"
                                            name="min_deposit"
                                            type="number"
                                            value={formData.min_deposit}
                                            onChange={handleChange}

                                        />
                                        {error.min_deposit && <span className="text-danger">{error.min_deposit}</span>}
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="small mb-1" htmlFor="min_withdrawal">Min Withdrawal<em>*</em></label>
                                        <input
                                            className="form-control form-control-solid"
                                            id="min_withdrawal"
                                            name="min_withdrawal"
                                            type="number"
                                            value={formData.min_withdrawal}
                                            onChange={handleChange}

                                        />
                                        {error.min_withdrawal && <span className="text-danger">{error.min_withdrawal}</span>}
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="small mb-1" htmlFor="deposit_fee">Deposit Fee<em>*</em></label>
                                        <input
                                            className="form-control form-control-solid"
                                            id="deposit_fee"
                                            name="deposit_fee"
                                            type="number"
                                            value={formData.deposit_fee}
                                            onChange={handleChange}

                                        />
                                        {error.deposit_fee && <span className="text-danger">{error.deposit_fee}</span>}
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="small mb-1" htmlFor="withdrawal_fee">Withdrawal Fee<em>*</em></label>
                                        <input
                                            className="form-control form-control-solid"
                                            id="withdrawal_fee"
                                            name="withdrawal_fee"
                                            type="number"
                                            value={formData.withdrawal_fee}
                                            onChange={handleChange}

                                        />
                                        {error.withdrawal_fee && <span className="text-danger">{error.withdrawal_fee}</span>}
                                    </div>

                                </div>

                                <div className="d-flex justify-content-center w-100 mt-5 mb-4">
                                    <button
                                        className="btn btn-indigo w-25 fs-6"
                                        type="button"
                                        onClick={handleAddFeeStracture}
                                    >
                                        {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <span> Submit Details </span>}
                                    </button>

                                </div>

                            </form>
                            {apiResponse && <div className="text-danger">{apiResponse}</div>}

                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default AddFeeStractureQbs;
