import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { alertErrorMessage } from "../../../CustomComponent/CustomAlert";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import PersonalDetails from "../../../SessionComponent/UserManagement/PersonalDetails";
import PaymentDetails from "../../../SessionComponent/UserManagement/PaymentDetails";
import SecurityDetails from "../../../SessionComponent/UserManagement/SecurityDetails";
import PermissionDetails from "../../../SessionComponent/UserManagement/PermissionDetails";
import LimitDetails from "../../../SessionComponent/UserManagement/LimitDetails";
import TradeDetails from "../../../SessionComponent/UserManagement/TradeDetails";
import TransactionHistory from "../../../SessionComponent/UserManagement/TransactionHistory";
import UserLogs from "../../../SessionComponent/UserManagement/UserLogs";
import UserSupport from "../../../SessionComponent/UserManagement/UserSupport";
import WalletDetails from "../../../SessionComponent/UserManagement/WalletDetails";

const UserDetails = () => {

    const location = useLocation();
    const { mergedData } = location.state || {};
    const { row, kycTypeUsers, usersKycType } = mergedData || {};
    const [userDetail, setUserDetail] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();

    const handleUserData = async () => {
        try {
            const result = await AuthService.getuserDetails(row?._id);
            if (result?.success) {
                setUserDetail(result.data);
            } else {
                alertErrorMessage(result.message);
            }
        } catch (error) {
            alertErrorMessage("An error occurred while fetching user details.");
        }
    };

    useEffect(() => {
        handleUserData();
    }, [])

    const handleTabClick = (e, index) => {
        e.preventDefault();
        setActiveTab(index);
        if (index === 1) {
            navigate('/kyc_detail_personal', {
                state: {
                    mergedData: {
                        row,
                        activeTab: index,
                        kycTypeUsers,
                        usersKycType,
                        pageName: "userList"
                    }
                }
            });
        }
    };

    return (
        <div id="layoutSidenav_content">
            <main>
                <form className="form-data" >
                    <header className="page-header ">
                        <div className="container-xl px-4">
                            <div className="page-header-content">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto">
                                        <h1 className="page-header-title">
                                            <span
                                                className="page-header-icon cursor-pointer"
                                                onClick={() =>
                                                    navigate("/users_list", {
                                                        state: {
                                                            mergedData: {
                                                                row,
                                                                kycTypeUsers,
                                                                usersKycType
                                                            }
                                                        }
                                                    })
                                                }
                                            >
                                                <i className="fa fa-arrow-left" ></i>
                                            </span>
                                            <div className="hd_u_info" >
                                                <img src="assets/img/illustrations/profiles/profile-5.png" className="img-account-profile rounded-circle" />
                                                <span>
                                                    {row?.panDetails?.name}
                                                    <small >
                                                        {row?.email}
                                                        <i className="ri-verified-badge-fill text-success"></i>
                                                    </small>
                                                </span>
                                            </div>
                                        </h1>
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-danger px-4 btn-md" type="button"> Block User</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4" >
                        <div className="card" >
                            <div className="card-header pb-0 ps-0">
                                <Tabs className="nav nav-tabs nav-tabs-custom px-2" activeTab={activeTab} onTabClick={handleTabClick}>
                                    <Tab className="nav-link">Account Details</Tab>
                                    <Tab className="nav-link">KYC Details</Tab>
                                    <Tab className="nav-link">Payment Details</Tab>
                                    <Tab className="nav-link">Security Details</Tab>
                                    <Tab className="nav-link">Permission Details</Tab>
                                    <Tab className="nav-link">Limits Details</Tab>
                                    <Tab className="nav-link">Wallet Details</Tab>
                                    <Tab className="nav-link">Transaction History</Tab>
                                    <Tab className="nav-link">Trade History</Tab>
                                    <Tab className="nav-link">Support</Tab>
                                    <Tab className="nav-link">Activity Logs</Tab>
                                </Tabs>
                                <div className="dropdown">
                                    <button className="btn btn-dark btn-sm dropdown-toggle" type="button" >
                                        <i className="fa fa-download me-3"></i>Export
                                    </button>
                                </div>
                            </div>
                            <div className="tab-content tab-content-card">
                                <PersonalDetails
                                    activeTab={activeTab}
                                    row={row}
                                    userDetail={userDetail}
                                    kycTypeUsers={kycTypeUsers}
                                    usersKycType={usersKycType}
                                />
                                <PaymentDetails activeTab={activeTab} />
                                <SecurityDetails activeTab={activeTab} />
                                <PermissionDetails activeTab={activeTab} />
                                <LimitDetails activeTab={activeTab} userId={row?._id} />
                                <WalletDetails activeTab={activeTab}  row={row}/>
                                <TransactionHistory activeTab={activeTab} />
                                <TradeDetails activeTab={activeTab} />
                                <UserSupport activeTab={activeTab} />
                                <UserLogs activeTab={activeTab} />
                            </div>
                        </div>
                    </div>
                </form>
            </main>
        </div>
    )
}

export default UserDetails;