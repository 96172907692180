import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from '../../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage } from "../../../../CustomComponent/CustomAlert";
import AuthService from "../../../../../services/ApiIntegration/AuthService";
import DataTable from "react-data-table-component";
import { Link, useLocation, useNavigate } from "react-router-dom";

const SupportTicketList = () => {

    const [supportData, setSupportData] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [skip, setSkip] = useState(0);
    const [totalDataLength, setTotalDataLength] = useState(0);
    const limit = 10;
    const navigate = useNavigate()
    const location = useLocation();
    
    const [ticketLevel, setTicketLevel] = useState(location.state?.ticketLevel || "1");
    const [ticketStatus, setTicketStatus] = useState("");

    const columns = [
        {
            name: "DateTime",
            wrap: true,
            selector: row => {
                const createdAt = row?.ticket_raised_at ? moment(row.ticket_raised_at) : null;
                return createdAt ? (
                    <span>
                        {createdAt.format("DD MMM YYYY")}
                        <br />
                        <small>{createdAt.format("h:mm:ss a")}</small>
                    </span>
                ) : (
                    "-------"
                );
            },
        },
        { name: "Ticket Id", selector: row => row.support_ticket_id, wrap: true, sortable: true, },
        { name: "Full Name", selector: row => row.full_name || "-------", sortable: true, wrap: true, },
        { name: "Email", selector: row => row.email, sortable: true, wrap: true, },
        { name: "Mobile No.", selector: row => row.phone, sortable: true, wrap: true, },
        { name: "Status", selector: row => row.status, wrap: true, sortable: true, },
        {
            name: "Action", selector: (row) =>
                <button className="btn btn-dark btn-sm me-2" title="View" onClick={() => { navigate('/support_ticket_details', { state: { row, ticketLevel } }) }}>
                    View
                </button>
        },
    ];

    const handleData = async (skip, limit, searchKey, ticketStatus, ticketLevel) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.getUserSupport(skip, limit, searchKey, ticketStatus, ticketLevel);
            if (result.success) {
                setSupportData(result.data?.ticket || []);
                setTotalDataLength(result?.data?.count || 0);
            } else {
                setSupportData([]);
            }
        } catch (error) {
            alertErrorMessage(error);
            setSupportData([]);
        } finally {
            LoaderHelper.loaderStatus(false);
        }
    };

    useEffect(() => {
        handleData(skip, limit, searchKey, ticketStatus, ticketLevel);
    }, [skip, limit, searchKey, ticketStatus, ticketLevel]);


    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchKey(searchTerm);
    };

    const handlePagination = (action, setSkip, skip, limit, totalDataLength) => {
        switch (action) {
            case "prev":
                if (skip - limit >= 0) setSkip(skip - limit);
                break;
            case "next":
                if (skip + limit < totalDataLength) setSkip(skip + limit);
                break;
            case "first":
                setSkip(0);
                break;
            case "last":
                setSkip(Math.max(totalDataLength - limit, 0));
                break;
            default:
                break;
        }
    };


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header ">
                    <div className="page-header-content pt-0"></div>
                </header>
                <div className="container-xl px-4">

                    <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                            <div className="d-flex justify-content-between" >
                                <div className='custom-tab-2' >
                                    <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button type="button" className={`nav-link ${ticketLevel === "1" ? "active" : ""}`} onClick={() => setTicketLevel("1")}>  Level 1 </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button type="button" className={`nav-link ${ticketLevel === "2" ? "active" : ""}`} onClick={() => setTicketLevel("2")}> Level 2  </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button type="button" className={`nav-link ${ticketLevel === "3" ? "active" : ""}`} onClick={() => setTicketLevel("3")}> Level 3 </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="d-flex align-items-center gap-3" >
                                    <Link to="/create_user_support" class="btn btn-warning btn-md"><b><i class="ri-add-line me-2"></i></b> Create New Ticket </Link>
                                    {
                                        supportData?.length > 0 &&
                                        <div className="dropdown">
                                            <button className="btn btn-dark btn-sm dropdown-toggle" type="button" >
                                                <i className="fa fa-download me-2"></i>
                                                <CSVLink data={supportData} className="text-white" filename={`userSupport.csv`}>
                                                    Export as CSV
                                                </CSVLink>
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-xl px-4">
                    <div className="tab-content" id="nav-tabContent">
                        <div className="card mb-4">
                            <div className="card-header pb-0">
                                <ul className="nav nav-tabs nav-tabs-custom" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button type="button" className={`nav-link ${ticketStatus === "" ? "active" : ""}`} onClick={() => setTicketStatus("")}> All Tickets </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button type="button" className={`nav-link ${ticketStatus === "Open" ? "active" : ""}`} onClick={() => setTicketStatus("Open")}> Active Tickets  </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button type="button" className={`nav-link ${ticketStatus === "Closed" ? "active" : ""}`} onClick={() => setTicketStatus("Closed")}> Closed Tickets  </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="search-header">
                                <div className="dd_search">
                                    <input type="search" placeholder="Search here..." name="searchKey" id="searchKey" value={searchKey} onChange={handleSearch} />
                                    <i className="fas fa-search"></i>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive" width="100%">
                                    <DataTable columns={columns} data={supportData}
                                        noDataComponent={
                                            <p> <img src="/assets/img/not_found.svg" width="300" className='img-fluid mt-5' alt='' /> </p>
                                        }
                                    />
                                    {
                                        supportData?.length > 0 &&
                                        <div className="hVPalX gap-2" >
                                            <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                            <div className="sc-eAKtBH gVtWSU">
                                                <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first', setSkip, skip, limit, totalDataLength)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                        <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                    </svg>
                                                </button>
                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev', setSkip, skip, limit, totalDataLength)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>
                                                </button>

                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next', setSkip, skip, limit, totalDataLength)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>
                                                </button>
                                                <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last', setSkip, skip, limit, totalDataLength)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                        <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
};
export default SupportTicketList;