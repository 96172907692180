import React, { useState } from "react";
import DepositWithdrawal from "../TransactionsHistory/DepositWithdrawal";
import HoldUnhold from "../TransactionsHistory/HoldUnhold";
import AdminTransferHistory from "../TransactionsHistory/AdminTransferHistory";
import UserTransferHistory from "../TransactionsHistory/UserTransferHistory";


const TransactionsComponent = ({ activeTab }) => {

    const [isActiveTab, setIsActiveTab] = useState("deposit_withdrawal");

    return (
        <div className={`tab-pane ${activeTab === 4 ? "active" : ""}`} >
            <div className="card-body py-4">
                <div className="profile_data  ac_body" >
                    <div className=" " >
                        <div className="custom-tab-2">
                            <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${isActiveTab === "deposit_withdrawal" && 'active'}`} onClick={() => setIsActiveTab("deposit_withdrawal")}> Deposit & Withdrawals

                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${isActiveTab === "adminHoldUnhold" && 'active'}`} onClick={() => setIsActiveTab("adminHoldUnhold")}>
                                        Hold/Unhold
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${isActiveTab === "adminInternalFundTransfer" && 'active'}`} onClick={() => setIsActiveTab("adminInternalFundTransfer")}> Admin Transfer History
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${isActiveTab === "fundTransferToUser" && 'active'}`} onClick={() => setIsActiveTab("fundTransferToUser")}> User Transfer History
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <DepositWithdrawal isActiveTab={isActiveTab} />
                    <HoldUnhold isActiveTab={isActiveTab} />
                    <AdminTransferHistory isActiveTab={isActiveTab} />
                    <UserTransferHistory isActiveTab={isActiveTab} />
                </div>
            </div>
        </div>
    )
};

export default TransactionsComponent;