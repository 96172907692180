import React, { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ApiConfig } from "../../../../services/ApiIntegration/ApiConfig";
import { SlideshowLightbox } from 'lightbox.js-react'
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import copy from "copy-to-clipboard";


const CoinListingDetails = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { row } = location.state || {};
  const [copied, setCopied] = useState(false);

  const handleCopy = (item) => {
    copy(item);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <form className="form-data">
          <header className="page-header ">
            <div className="container-xl px-4">
              <div className="page-header-content pb-2">
                <div className="row align-items-center justify-content-between">
                  <h6 className="page-header-title mb-5">
                    <span className="page-header-icon cursor-pointer" onClick={() => navigate('/coin_listing')}>
                      <i className="fa fa-arrow-left" ></i>
                    </span>
                    <small>Listing Coin Details</small>
                  </h6>
                  <div className="col-auto">
                    <h1 className="page-header-title">
                      <div className="hd_u_info" >
                        <img src="assets/img/illustrations/profiles/profile-5.png" className="img-account-profile rounded-circle" />
                        <span>  {row?.full_name || "-------"}
                          <small>
                            User ID: {row?._id || "-------"}
                            <i
                              className={`ms-1 link cursor-pointer ${copied ? "ri-check-fill text-success" : "ri-file-copy-fill"
                                }`}
                              onClick={() => handleCopy(row?._id)}
                            ></i>
                          </small>
                        </span>
                      </div>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div className="container-xl px-4 mt-2">
            <div className="row">
              <div className="col-xl-12 mb-4">
                <div className="card" >
                  <div className="card-body py-4">
                    <div className="profile_data w-100  ac_body px-4 pt-4 ">

                      <div className="d-flex align-items-center justify-content-between mt-4" >
                        <h4>Token Image </h4>
                      </div>
                      <div className="row">
                        {row?.token_image ? (
                          <div className="col-lg-4" >
                            <div className="ud_img">
                              <div className="img">
                                <SlideshowLightbox className="ratio ratio-16x9">
                                  <img
                                    src={row?.token_image || "assets/img/blank_img.png"}
                                    className="img-fluid w-100"
                                    alt="Face Match"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = "assets/img/blank_img.png";
                                    }}
                                  />
                                </SlideshowLightbox>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="col-lg-6" >
                            <div className="ud_img">
                              <div className="img">
                                <img
                                  src="assets/img/blank_img.png"
                                  className="img-fluid w-100"
                                  alt="No Data"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <hr />
                      <div className="d-flex align-items-center justify-content-between " >
                        <h4>Attched Files</h4>
                      </div>
                      <div className="row">
                        {row?.attachments?.length > 0 ? (
                          row.attachments.map((image, index) => (
                            <div className="col-lg-3" key={index}>
                              <div className="ud_img">
                                <div className="img">
                                  <SlideshowLightbox className="ratio ratio-16x9">
                                    <img
                                      src={image || "assets/img/blank_img.png"}
                                      className="img-fluid w-100"
                                      alt="Face Match"
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = "assets/img/blank_img.png";
                                      }}
                                    />
                                  </SlideshowLightbox>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col-lg-3">
                            <div className="ud_img">
                              <div className="img">
                                <img
                                  src="assets/img/blank_img.png"
                                  className="img-fluid w-100"
                                  alt="No Data"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <hr />

                      <div className="fc_details mt-4" >
                        <div className="d-flex align-items-center justify-content-between " >
                          <h5>Blockchain Details</h5>
                        </div>
                        {row?.blockchain?.length > 0 ? (
                          row.blockchain.map((item, index) => (
                            <div className="row gx-5" key={index}>
                              <div className="col-lg-6 col-xl-4">
                                <div className="label-group d-flex align-items-center justify-content-between">
                                  <label>   Chain Name   </label>
                                  <p> <small>{item?.chain_name || "-------"}</small> </p>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xl-4">
                                <div className="label-group d-flex align-items-center justify-content-between">
                                  <label> Contract Address </label>
                                  <p>
                                    <small>
                                      {item?.contract_address || "-------"}
                                    </small>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="d-flex justify-content-center align-items-center">
                            <span>No Data Available</span>
                          </div>
                        )}
                      </div>

                      <div className="fc_details mt-4" >
                        <div className="d-flex align-items-center justify-content-between " >
                          <h5> Coin Details  </h5>
                        </div>
                        <div className="row gx-5 ">
                          <div className="col-lg-6 col-xl-6">
                            <div className="label-group d-flex align-items-center justify-content-between my-1">
                              <label> Launch Date </label> <p> <small> {moment(row?.launch_date).format('Do MMMM YYYY, h:mm:ss a')} </small> </p>
                            </div>
                            <div className="label-group d-flex align-items-center justify-content-between my-1">
                              <label> Designation  </label> <p> <small> {row?.designation || "-------"} </small> </p>
                            </div>
                            <div className="label-group d-flex align-items-center justify-content-between my-1">
                              <label> Company Reg. Number   </label> <p> <small> {row?.company_reg_no || "-------"} </small> </p>
                            </div>
                            <div className="label-group d-flex align-items-center justify-content-between my-1">
                              <label> Commmunity Engagement   </label> <p> <small> {row?.commmunity_engagement || "-------"} </small> </p>
                            </div>
                            <div className="label-group d-flex align-items-center justify-content-between my-1">
                              <label> Security Measures   </label> <p> <small> {row?.security_measures || "-------"} </small> </p>
                            </div>
                            <div className="label-group d-flex align-items-center justify-content-between my-1">
                              <label> Website   </label> <p> <small> {row?.website || "-------"} </small> </p>
                            </div>
                            <div className="label-group d-flex align-items-center justify-content-between my-1">
                              <label> Whitepaper Link   </label> <p> <small> {row?.whitepaper_link || "-------"} </small> </p>
                            </div>
                            <div className="label-group d-flex align-items-center justify-content-between my-1">
                              <label> Project Details   </label> <p> <small> {row?.project_details || "-------"} </small> </p>
                            </div>

                          </div>
                          <div className="col-lg-6 col-xl-6">
                            <div className="label-group d-flex align-items-center justify-content-between my-1">
                              <label> Listed Exchanges </label> <p> <small> {row?.listed_exchanges} </small> </p>
                            </div>
                            <div className="label-group d-flex align-items-center justify-content-between my-1">
                              <label> Price Per Usdt </label> <p> <small> {row?.price_per_usdt || "-------"} </small> </p>
                            </div>
                            <div className="label-group d-flex align-items-center justify-content-between my-1">
                              <label> Registered Company </label> <p> <small> {row?.registered_company || "-------"} </small> </p>
                            </div>
                            <div className="label-group d-flex align-items-center justify-content-between my-1">
                              <label> Token Name </label> <p> <small> {row?.token_name || "-------"} </small> </p>
                            </div>
                            <div className="label-group d-flex align-items-center justify-content-between my-1">
                              <label> Token Symbol </label> <p> <small> {row?.token_symbol || "-------"} </small> </p>
                            </div>
                            <div className="label-group d-flex align-items-center justify-content-between my-1">
                              <label> Tokenomics </label> <p> <small> {row?.tokenomics || "-------"} </small> </p>
                            </div>
                            <div className="label-group d-flex align-items-center justify-content-between my-1">
                              <label> Total Supply </label> <p> <small> {row?.total_supply || "-------"} </small> </p>
                            </div>
                            <div className="label-group d-flex align-items-center justify-content-between my-1">
                              <label> Use Case </label> <p> <small> {row?.use_case || "-------"} </small> </p>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="fc_details mt-4" >
                        <div className="d-flex align-items-center justify-content-between " >
                          <h5>Description</h5>
                        </div>
                        <div className="row gx-5">
                          <div className="col-lg-6 col-xl-4">
                            <div className=" d-flex align-items-center justify-content-between">
                              <p> <small>{row?.description}</small> </p>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
};

export default CoinListingDetails;
