import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import { alertErrorMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import moment from "moment";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const BuySellList = () => {


    const [orderSide, setOrderSide] = useState('BUY');
    const [status, setStatus] = useState('Active');
    const [adsType, setAdsType] = useState(false);
    const [buySellData, setBuySellData] = useState([]);
    const [adminBalance, setAdminBalance] = useState();


    const handleBuysellList = async (orderSide, status, adsType) => {
        await AuthService.getQbsList(orderSide, status, adsType).then(async (result) => {
            if (result.success) {
                try {
                    setBuySellData(result?.data?.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                    setBuySellData([]);
                }
            } else {
                alertErrorMessage(result.message);
                setBuySellData([]);
            }
        });
    };


    useEffect(() => {
        handleBuysellList(orderSide, status, adsType);
    }, [orderSide, status, adsType]);


    const columns = [
        { name: "Order Id", selector: (row) => row?._id, wrap: true, },
        {
            name: "Currency", selector: (row) =>
                <span>
                    <div class="c_view c_view_sm  ">
                        <div class="d-flex align-items-center">
                            <img src={row?.base_currency_image || "assets/img/nocoin.png"} alt=""
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "assets/img/nocoin.png";
                                }}
                            />
                            <span> {row?.base_currency + "/" + row?.quote_currency} </span>
                        </div>
                    </div>
                </span>
        },
        {
            name: "Price", sort: true, wrap: true, selector: (row) => row?.price || '---'
        },
        {
            name: "Order Amount", selector: (row) =>
                <span>
                    <small>Min:{row?.quote_min}</small><br />
                    <small>Max:{row?.quote_max}</small>
                </span>
        },
        {
            name: "Quantity", selector: (row) =>
                <span>
                    <small>Target : {row?.total_quantity}</small><br />
                    <small>filled : {row?.total_quantity - row?.remaining_quantity}</small><br />
                    <small>Remaning : {row?.remaining_quantity}</small>
                </span>
        },
        {
            name: "  Date", selector: (row) =>
                <span>
                    <small>Created:{row?.createdAt ? moment(row?.createdAt).format("Do MMM YYYY") : '---'}</small><br />
                    <small>Updated:{row?.createdAt ? moment(row?.updatedAt).format("Do MMM YYYY") : '---'}</small>
                </span>
        },
        {
            name: "Action",
            selector: (row) => {
                if (status === "Closed") return "-----"
                return (
                    <div className="d-flex align-items-end gap-2" >
                        <div class="checkbox_switch">
                            <input
                                className="tgl"
                                type="checkbox"
                                id={`toggle-${row._id}`}
                                checked={row.status === "Active"}
                                onChange={() => handleUpdateStatus(row._id, row.status === "Active" ? "Inactive" : "Active")}
                            />

                            <label class="tgl-btn" htmlFor={`toggle-${row._id}`}></label>
                        </div>
                        {
                            status === "Inactive" &&
                            <span className="sm_icon sm_danger" title="Delete" onClick={() => showAlert(row._id, "Closed")}> <i class="ri-delete-bin-fill"></i> </span>
                        }

                        {
                            status !== "Closed" &&
                            <button type="button" onClick={() => handleEditTarget(row)} className="btn btn-xs btn-success m-1">
                                <i class="ri-edit-box-line"></i>
                            </button>
                        }
                    </div>
                )
            }
        },
    ];


    /* const handleEditTarget = (row) => {




        handleAdminWalletBalance(row?.quantity_currency === row?.base_currency ? row?.base_currency_id : row?.quote_currency_id )
        
        Swal.fire({
            title: "Edit Target",
            html: `
            <div style="text-align: left;">
                <div class="mb-2">
                    <label for="swal-price" style="display: block; font-weight: bold;">Price:</label>
                    <input id="swal-price" class="swal2-input" type="number" value="${row?.price || ""}" min="0" step="0.01">     
                </div>

                <div class="mb-2">                
                    <label for="swal-quantity" style="display: block; font-weight: bold;">Quantity:</label>
                    <input id="swal-quantity" class="swal2-input" type="number" value="${row?.total_quantity || ""}" min="0" step="1">    
                </div>  
                <h4>${adminBalance}</h4>
            </div>
                    `,
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonText: "Cancel",
            preConfirm: async () => {
                const newPrice = document.getElementById("swal-price").value;
                const newQuantity = document.getElementById("swal-quantity").value;

                try {
                    const result = await AuthService.updateQbsSettingTargetValue(row._id, newPrice, newQuantity);
                    if (result?.success) {
                        handleBuysellList(orderSide, status, adsType);
                        Swal.fire("Success", result?.message || "Updated successfully!", "success");
                        return true;
                    } else {
                        Swal.showValidationMessage(result || "Failed to update target!");
                        return false;
                    }
                } catch (error) {
                    Swal.showValidationMessage(error?.response?.data?.message || "An error occurred!");
                    return false;
                }
            }
        });
    };
 */


    const handleEditTarget = async (row) => {
        const walletId = row?.quantity_currency === row?.base_currency ? row?.base_currency_id : row?.quote_currency_id;
        let adminBalance = "Loading...";
        try {
            const balanceResult = await handleAdminWalletBalance(walletId);
            adminBalance = balanceResult || "0.00";
        } catch (error) {
            adminBalance = "Error fetching balance!";
        }

        Swal.fire({
            title: "Edit Target",
            html: `
                <div style="text-align: left;">
                    <div class="mb-2">
                        <label for="swal-price" style="display: block; font-weight: bold;">Price:</label>
                        <input id="swal-price" class="swal2-input" type="number" value="${row?.price || ""}" min="0" step="0.01">     
                    </div>
    
                    <div class="mb-2">                
                        <label for="swal-quantity" style="display: block; font-weight: bold;">Quantity:</label>
                        <input id="swal-quantity" class="swal2-input" type="number" value="${row?.total_quantity || ""}" min="0" step="1">    
                    </div>  
                    <h4>Wallet Balance: <span id="wallet-balance">${adminBalance}</span></h4>
                </div>
            `,
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonText: "Cancel",
            preConfirm: async () => {
                const newPrice = document.getElementById("swal-price").value;
                const newQuantity = document.getElementById("swal-quantity").value;

                try {
                    const result = await AuthService.updateQbsSettingTargetValue(row._id, newPrice, newQuantity);
                    if (result?.success) {
                        handleBuysellList(orderSide, status, adsType);
                        Swal.fire("Success", result?.message || "Updated successfully!", "success");
                        return true;
                    } else {
                        Swal.showValidationMessage(result || "Failed to update target!");
                        return false;
                    }
                } catch (error) {
                    Swal.showValidationMessage(error?.response?.data?.message || "An error occurred!");
                    return false;
                }
            }
        });
    };

    const handleAdminWalletBalance = async (id) => {
        let filters = {
            wallet_type: "Main",
            currency_id: id,
        };

        const queryString = new URLSearchParams(filters).toString();

        const result = await AuthService.getAdminWallets(queryString);
        if (result.success) {
            return result.data?.wallets?.[0]?.balance || "0.00";
        }
    };

    const handleUpdateStatus = async (id, newStatus) => {
        try {
            const result = await AuthService.updateQbsSettingStatus(id, newStatus);
            if (result?.data?.status) {
                handleBuysellList(orderSide, status, adsType);
            } else {
                alertErrorMessage(result)
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const showAlert = (id, newStatus) => {
        Swal.fire({
            title: "Are you sure?",
            html: `Are you sure you want to delete`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#e64942",
            cancelButtonColor: "#e7e7e7",
            confirmButtonText: "Yes Delete!"
        }).then((result) => {
            if (result.isConfirmed) {
                handleUpdateStatus(id, newStatus)
            }
        });
    };







    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header ">
                    <div className="container-xl px-4">
                        <div className="page-header-content pb-4">
                            <div className="row align-items-center justify-content-between ">
                                <div className="col-auto ">
                                    <h1 className="page-header-title mb-0">
                                        Advertisements List
                                    </h1>
                                </div>
                                <div className="col-auto" >
                                    <div className="header_search form-group mb-0" >
                                        <Link to="/buysell_ad" className="btn btn-warning  btn-md "> <i className="ri-add-line me-2 fw-bold"></i> Create New Ad</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4">
                    <div className='custom-tab-2' >
                        <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button type="button" className={`nav-link ${status === "Active" && "active"}`} onClick={() => setStatus("Active")}> Active  </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button type="button" className={`nav-link ${status === "Inactive" && "active"}`} onClick={() => setStatus("Inactive")}> Inactive </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button type="button" className={`nav-link ${status === "Closed" && "active"}`} onClick={() => setStatus("Closed")}> Deleted </button>
                            </li>
                        </ul>
                    </div>
                    <div className="row" >
                        <div className="col-xl-12" >
                            <div className="card">
                                <div className="card-header pb-0">
                                    <ul className="nav nav-tabs nav-tabs-custom" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${adsType === false && "active"}`} onClick={() => setAdsType(false)} > Normal Ads  </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${adsType === true && "active"}`} onClick={() => setAdsType(true)} type="button"  >  Bulk Ads </button>
                                        </li>
                                    </ul>
                                    <div className="dropdown">
                                        <button className="btn btn-dark btn-sm dropdown-toggle" type="button" >
                                            Export <CSVLink data={buySellData} />
                                        </button>
                                    </div>
                                </div>

                                <div className="custom-tab-2 p-3 pb-0">
                                    <ul className="nav nav-pills justify-content-start mb-1 link_status" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button type="button" className={`nav-link link-success px-4 ${orderSide === "BUY" && "active"}`} onClick={() => setOrderSide('BUY')} >
                                                Buy
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button type="button" className={`nav-link link-danger px-4 ${orderSide === "SELL" && "active"}`} onClick={() => setOrderSide('SELL')} >
                                                Sell
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <form className="row" >
                                        <div className="col-12" >
                                            <div className="table-responsive" >
                                                <DataTable columns={columns} data={buySellData} pagination />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default BuySellList;