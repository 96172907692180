import React, { useEffect, useState } from "react";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import moment from "moment";
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";

const PersonalDetails = ({ activeTab, userDetail, row }) => {

    const [showModel, setShowModel] = useState(false);
    const [showModelChangeMob, setShowModelChangeMob] = useState(false);
    const [activeModelNomini, setActiveModelNomini] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorMessageUserName, setErrorMessageUserName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [ticketNumber, setTicketNumber] = useState("");
    const [errorMessageTicketNumber, setErrorMessageTicketNumber] = useState("");
    const [userName, setUserName] = useState("");
    const [loading, setLoading] = useState(false);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^[0-9]{10}$/;
    const [nominiDetails, setNominiDetails] = useState("");


    const [formData, setFormData] = useState({
        ticketNumber: '',
        rejectReason: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleUpdateEmailAddress = async (email, ticketNumber) => {
        if (!email) {
            setErrorMessage("Please enter email address");
            return;
        } if (!emailRegex.test(email)) {
            setErrorMessage("Enter a valid email address.");
            return;
        }
        if (!ticketNumber) {
            setErrorMessageTicketNumber("Please enter Ticket Number");
            return;
        }
        setLoading(true);
        await AuthService.updateEmailAddress(email, ticketNumber, userDetail?.kyc?.name || "Crypto India User", userDetail?.user?._id).then(async (result) => {
            if (result.success) {
                try {
                    setShowModel(false);
                    alertSuccessMessage(result?.message);
                    setLoading(false);
                    setUserName("");
                } catch (error) {
                    alertErrorMessage(error);
                    setLoading(false);
                }
            } else {
                alertErrorMessage(result);
                setLoading(false);
            }
        });
    };


    const handleUpdateMobileNumber = async (mobileNumber, userName) => {
        if (!mobileNumber) {
            setErrorMessage("Please enter Mobile Number");
            return;
        } if (!phoneRegex.test(mobileNumber)) {
            setErrorMessage("Enter a valid Mobile Number.");
            return;
        }
        if (!userName) {
            setErrorMessageUserName("Please enter UseName");
            return;
        }
        setLoading(true);
        await AuthService.updateMobileNumber(mobileNumber, userName, userDetail?.user?._id).then(async (result) => {
            if (result.success) {
                try {
                    setShowModelChangeMob(false);
                    alertSuccessMessage(result?.message);
                    setLoading(false);
                    setUserName("");
                } catch (error) {
                    alertErrorMessage(error);
                    setLoading(false);
                }
            } else {
                alertErrorMessage(result);
                setLoading(false);
            }
        });
    };



    const handleUserNominiDetails = async () => {
        await AuthService.getUserNominiDetails(userDetail?.user?._id).then(async (result) => {
            if (result.success) {
                try {
                    setNominiDetails(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result);
            }
        });
    };

    useEffect(() => {
        if (activeTab == 0 && userDetail?.user?._id) {
            handleUserNominiDetails();
        }
    }, [activeTab, userDetail])

    const handleUpadteNomini = async () => {
        try {
            const result = await AuthService.updateNominiDetails(formData, userDetail?.user?._id);
            if (result?.success) {
                alertSuccessMessage(result.message);
                setActiveModelNomini(false);
                handleUserNominiDetails();
                setFormData(() => ({
                    ticketNumber: '',
                    rejectReason: ''
                }));
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };


    console.log(nominiDetails,'nominiDetails');
    

    return (
        <>
            <div className={`tab-pane ${activeTab === 0 ? "active" : ""}`} >
                <div className="card-body py-4" >
                    <div className="profile_data  ac_body " >
                        <h5>  Account Information </h5>
                        <div className="row g-4">
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label>  Joined Date   </label>
                                    <p> {moment(userDetail?.user?.createdAt).format('Do MMMM YYYY, h:mm:ss a')}</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label> Kyc Status   </label>
                                    <p className={userDetail?.user?.kycVerified == 2 ? "text-success" : userDetail?.user?.kycVerified == 3 ? "text-danger" : userDetail?.user?.kycVerified == 4 ? "text-danger" : "text-warning"}>
                                        {userDetail?.user?.kycVerified == 0 ? "Not Submited" : userDetail?.user?.kycVerified == 1 ? "Pending" : userDetail?.user?.kycVerified == 2 ? "Verified" : userDetail?.user?.kycVerified == 3 ? "Rejected" : userDetail?.user?.kycVerified == 4 ? "Re-Kyc" : '------'}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label> User Id (UID)   </label><p>   {userDetail?.user?.uid || '------'}  </p></div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label> User Kyc Type   </label><p>  {userDetail?.user?.user_type || '------'} </p></div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label>  Account Status   </label><p className={userDetail?.user?.status === "Active" ? "text-success" : "text-danger"} >   {userDetail?.user?.status || '------'}  </p></div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label>  Referral Code   </label><p>  {userDetail?.user?.referral_code || '------'}   </p></div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group d-flex align-items-center justify-content-start">
                                    <div>
                                        <label>  Mobile Number  </label>
                                        <p className="d-flex align-items-center justify-content-start" >  {userDetail?.user?.phone || '------'}
                                        </p>
                                    </div>
                                    <button type="button" className="btn btn-dark ms-4 btn-sm" onClick={() => setShowModelChangeMob(true)}> Update </button>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group d-flex align-items-center justify-content-start">
                                    <div>
                                        <label> Email Address </label>
                                        <p className="d-flex align-items-center justify-content-start" >  {userDetail?.user?.email || '------'}
                                        </p>
                                    </div>
                                    <button type="button" className="btn btn-dark ms-4 btn-sm" onClick={() => setShowModel(true)}> Update </button>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label> Full Name  </label><p>   {row?.aadharDetails?.full_name || '------'} </p></div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label> Care of  </label><p>   {row?.aadharDetails?.care_of || '------'} </p></div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label> Gender </label><p>   {row?.aadharDetails?.gender || '------'} </p></div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label>  Date of Birth   </label><p>  {row?.aadharDetails?.dob || '------'}  </p></div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label>  Street   </label><p> {row?.aadharDetails?.address?.street || '------'}</p></div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label>  Landmark   </label><p> {row?.aadharDetails?.address?.landmark || '------'}</p></div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label>  Post Office   </label><p> {row?.aadharDetails?.address?.post_office || '------'}</p></div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label>  Vtc   </label><p> {row?.aadharDetails?.address?.vtc || '------'}</p></div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label>  District   </label><p>  {row?.aadharDetails?.address?.district || '------'}  </p></div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label>  Sub District   </label><p>  {row?.aadharDetails?.address?.sub_district|| '------'} </p></div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label>  State  </label><p>  {row?.aadharDetails?.address?.state|| '------'} </p></div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label>  Country  </label><p>  {row?.aadharDetails?.address?.country|| '------'} </p></div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="label-group"><label>   Zip Code  </label><p>  {row?.aadharDetails?.zip || '------'}  </p></div>
                            </div>

                        </div>
                    </div>
                    <hr className="my-4" />
                    <div className="profile_data  ac_body  " >
                        <div className="d-flex justify-content-between" >
                            <h5>  Update Requests </h5>
                        </div>
                        <div className="table-responsive m_table">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col" className=""> Date/Time </th>
                                        <th scope="col" className=""> Current Email/Mobile </th>
                                        <th scope="col" className=""> Updated Email/Mobile  </th>
                                        <th scope="col" className=""> Updated By </th>
                                        <th scope="col" className=""> Remark  </th>
                                        <th scope="col" className="">Status </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="">
                                            <span> 11-12-2024 </span>
                                        </td>
                                        <td className="">
                                            <span> +91 1234567890 </span>
                                        </td>
                                        <td className="">
                                            <span>  +91 0987454321  </span>
                                        </td>
                                        <td className="">
                                            <span> Uknown Admin </span>
                                        </td>
                                        <td className="">
                                            <span> Message here......... </span>
                                        </td>
                                        <td className="text-warning">
                                            <span>  Pending </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <hr className="my-4" />
                    <div className="profile_data  ac_body">
                        {
                            nominiDetails?.full_name &&
                            <div className="d-flex justify-content-between">
                                <h5>  Nominee Information </h5>
                                <button type="button" className="btn btn-dark px-4 btn-md" onClick={() => setActiveModelNomini(true)}>Edit</button>
                            </div>

                        }

                        <div className="row g-4" >
                            <div className="col-lg-4 col-xl-2">
                                <div className="label-group"><label>User Name</label>
                                    <p>{nominiDetails?.full_name || "------"} </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-2">
                                <div className="label-group">
                                    <label>Relation</label>
                                    <p>{nominiDetails?.relation || "------"}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-2">
                                <div className="label-group">
                                    <label>Date of Birth:</label>
                                    <p>
                                        {
                                            nominiDetails?.dob ? (
                                                nominiDetails.dob
                                            ) : (
                                                "------"
                                            )
                                        }

                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-2">
                                <div className="label-group">
                                    <label>Aadhaar Number:</label>
                                    <p>  {nominiDetails?.adhaar_number || "------"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="my-4" />

                    <div className="profile_data  ac_body my-4 " >
                        <div className="d-flex justify-content-between" >
                            <h5>  Previous Nominee </h5>
                        </div>
                        <div className="oul_scroll" >
                            <div className="table-responsive m_table">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Added Date/Time</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Relation</th>
                                            <th scope="col">DOB</th>
                                            <th scope="col">Aadhar Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {nominiDetails?.previousNomineeList?.length > 0 ? (
                                            nominiDetails?.previousNomineeList?.map((nominee, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {nominee.nomineeAddedDate ? moment(nominee.nomineeAddedDate).format('Do MMMM YYYY') : "------"}
                                                        <br />
                                                        <small>{nominee.nomineeAddedDate ? moment(nominee.nomineeAddedDate).format('h:mm:ss a') : ""}</small>
                                                    </td>
                                                    <td>{nominee.full_name || "----"}</td>
                                                    <td>{nominee.relation || "----"}</td>
                                                    <td>{nominee.dob || "----"}</td>
                                                    <td>{nominee.adhaar_number || "----"}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" className="text-center">No Data Available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <hr className="my-4" />
                    <div className="profile_data  ac_body my-4 " >
                        <div className="d-flex justify-content-between" >
                            <h5>  Remarks </h5>
                        </div>

                        <div className="oul_scroll" >
                            <div className="table-responsive m_table">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Rejected Date</th>
                                            <th scope="col">Admin Id</th>
                                            <th scope="col">Admin Name</th>
                                            <th scope="col">Ticket Number</th>
                                            <th scope="col">Admin Remark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {nominiDetails?.remarks?.length > 0 ? (
                                            nominiDetails?.remarks?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {item.rejectedDate ? moment(item.rejectedDate).format('Do MMMM YYYY') : "------"}
                                                        <br />
                                                        <small>{item.rejectedDate ? moment(item.rejectedDate).format('h:mm:ss a') : ""}</small>
                                                    </td>
                                                    <td>{item.adminId}</td>
                                                    <td>{item.admin_name}</td>
                                                    <td>{item.ticketNumber || "----"}</td>
                                                    <td>{item.admin_remark || "----"}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" className="text-center">No Data Available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            {
                showModel && (
                    <>
                        <div className="modal show d-block" >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content pb-3">
                                    <div className="modal-header">
                                        <h5 className="modal-title" >Update Email Address</h5>
                                    </div>
                                    <div className="modal-body ">
                                        <div className="form-group mb-4 ">
                                            <label className="small mb-1"> Enter Email </label>
                                            <input className="form-control py-3" name="email" value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                    setErrorMessage("");
                                                }} />
                                            {errorMessage && (
                                                <div className="text-danger mt-2 small">{errorMessage}</div>
                                            )}
                                        </div>
                                        <div className="form-group mb-4 ">
                                            <label className="small mb-1"> Enter Request Ticket Number </label>
                                            <input className="form-control py-3" name="ticketNumber" value={ticketNumber}
                                                onChange={(e) => {
                                                    setTicketNumber(e.target.value);
                                                    setErrorMessageTicketNumber("");
                                                }} />
                                            {errorMessageTicketNumber && (
                                                <div className="text-danger mt-2 small">{errorMessageTicketNumber}</div>
                                            )}
                                        </div>
                                    </div>
                                    <button className="btn btn-success  btn-block w-100" type="button" onClick={() => handleUpdateEmailAddress(email, ticketNumber)}>
                                        {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <span> Submit </span>}
                                    </button>
                                    <button className="btn btn-secondary  btn-block w-100 mt-1" type="button" onClick={() => setShowModel(false)}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </>
                )
            }



            {
                showModelChangeMob && (
                    <>
                        <div className="modal show d-block" >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content pb-3">
                                    <div className="modal-header">
                                        <h5 className="modal-title" >Update Mobile Number</h5>
                                    </div>
                                    <div className="modal-body ">
                                        <div className="form-group mb-4 ">
                                            <label className="small mb-1"> Enter mobile Number </label>
                                            <input type="text" className="form-control py-3" name="mobileNumber" value={mobileNumber} maxLength={10}
                                                onChange={(e) => {
                                                    const value = e.target.value.replace(/\D/g, "");
                                                    if (value.length <= 10) {
                                                        setMobileNumber(value);
                                                        setErrorMessage("");
                                                    }
                                                }}
                                            />
                                            {errorMessage && (
                                                <div className="text-danger mt-2 small">{errorMessage}</div>
                                            )}
                                        </div>
                                        <div className="form-group mb-4 ">
                                            <label className="small mb-1"> Enter UserName </label>
                                            <input className="form-control py-3" name="userName" value={userName}
                                                onChange={(e) => {
                                                    setUserName(e.target.value);
                                                    setErrorMessageUserName("");
                                                }} />
                                            {errorMessageUserName && (
                                                <div className="text-danger mt-2 small">{errorMessageUserName}</div>
                                            )}
                                        </div>
                                    </div>
                                    <button className="btn btn-success  btn-block w-100" type="button" onClick={() => handleUpdateMobileNumber(mobileNumber, userName)}>
                                        {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <span> Submit </span>}
                                    </button>
                                    <button className="btn btn-secondary  btn-block w-100 mt-1" type="button" onClick={() => setShowModelChangeMob(false)}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </>
                )
            }


            {
                activeModelNomini && (
                    <>
                        <div className="modal show d-block" >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content pb-3">
                                    <div className="modal-header">
                                        <h5 className="modal-title" >Update Nomini Details</h5>
                                    </div>
                                    <div className="modal-body ">
                                        <div className="form-group mb-2 ">
                                            <label htmlFor="ticketNumber" className="small mb-1">Ticket Number</label>
                                            <input
                                                type="text"
                                                name="ticketNumber"
                                                className="form-control py-3"
                                                value={formData.ticketNumber}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group mb-4">
                                            <label htmlFor="rejectReason" className="small mb-1">Reason</label>
                                            <textarea className="form-control form-control-solid" rows="7" placeholder="Enter your message.."
                                                name="rejectReason"
                                                value={formData.rejectReason}
                                                onChange={handleChange}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <button className="btn btn-success  btn-block w-100" type="button" onClick={handleUpadteNomini}>
                                        {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <span> Submit </span>}
                                    </button>
                                    <button className="btn btn-secondary  btn-block w-100 mt-1" type="button" onClick={() => setActiveModelNomini(false)}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </>
                )
            }



        </>
    )
};

export default PersonalDetails;