import React from "react";

const PermissionDetails = ({ activeTab }) => {

    return (
        <div className={`tab-pane ${activeTab === 4 ? "active" : ""}`} >
        <div className="card-body py-4" >
            <div className="profile_data " >

                {/* <div className=" " >
                    <div className="custom-tab-2">
                        <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a href="#Permissions" id="Permissions-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true">  Permissions  </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a href="#Limits" id="Limits-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> Limits </a>
                            </li>
                        </ul>
                    </div>
                </div> */}

                <div className="tab-content">

                    {/* Permissions Tab  */}
                    <div className="tab-pane active" id="Permissions" role="tabpanel" aria-labelledby="Permissions-tab">
                        <div className="profile_data  ac_body" >
                            <div className="d-flex justify-content-between" >
                                <h5> Permissitions  </h5>
                            </div>
                            <div className="  row" >

                                <div className="col-lg-6" >
                                    <div className="per_col mb-3" >
                                        <div className="d-flex align-items-center " >
                                            <div className="pr_icon" ><i className="ri-arrow-up-down-line"></i></div>
                                            <div>
                                                <h5>Buy & Sell</h5>
                                                <p> Revoke user side buy sell access. </p>
                                            </div>
                                        </div>
                                        <label className="switch">
                                            <input type="checkbox" name="" id="forBuySell" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-6" >
                                    <div className="per_col mb-3" >
                                        <div className="d-flex align-items-center " >
                                            <div className="pr_icon" ><i className="ri-line-chart-fill"></i></div>
                                            <div>
                                                <h5>  Spot  Trading </h5>
                                                <p> Revoke user side  Spot Trading freture. </p>
                                            </div>
                                        </div>
                                        <label className="switch">
                                            <input type="checkbox" name="" id="forBuySell" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-6" >
                                    <div className="per_col mb-3" >
                                        <div className="d-flex align-items-center " >
                                            <div className="pr_icon" ><i className="ri-p2p-fill"></i></div>
                                            <div>
                                                <h5> P2P Trading  </h5>
                                                <p> Revoke user side  P2P Trading freture. </p>
                                            </div>
                                        </div>
                                        <label className="switch">
                                            <input type="checkbox" name="" id="forBuySell" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-6" >
                                    <div className="per_col mb-3" >
                                        <div className="d-flex align-items-center " >
                                            <div className="pr_icon" ><i className="ri-arrow-up-line"></i></div>
                                            <div>
                                                <h5> Deposit  Funds</h5>
                                                <p> Revoke user side Deposit freture. </p>
                                            </div>
                                        </div>
                                        <label className="switch">
                                            <input type="checkbox" name="" id="forBuySell" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-6" >
                                    <div className="per_col mb-3" >
                                        <div className="d-flex align-items-center " >
                                            <div className="pr_icon" ><i className="ri-arrow-down-line"></i></div>
                                            <div>
                                                <h5> Withdraw Funds </h5>
                                                <p> Revoke user side Withdraw freture. </p>
                                            </div>
                                        </div>
                                        <label className="switch">
                                            <input type="checkbox" name="" id="forBuySell" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-6" >
                                    <div className="per_col mb-3" >
                                        <div className="d-flex align-items-center " >
                                            <div className="pr_icon" ><i className="ri-arrow-down-line"></i></div>
                                            <div>
                                                <h5> Quick Pay</h5>
                                                <p> Revoke user side Quick Pay freture. </p>
                                            </div>
                                        </div>
                                        <label className="switch">
                                            <input type="checkbox" name="" id="forBuySell" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-6" >
                                    <div className="per_col mb-3" >
                                        <div className="d-flex align-items-center " >
                                            <div className="pr_icon" ><i class="ri-shield-user-fill"></i></div>
                                            <div>
                                                <h5>  Level 2 KYC </h5>
                                                <p>  If user Trusted then you can enable Level 2 KYC </p>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary btn-md" >
                                            Enable L2 KYC
                                        </button>
                                        {/* <button className="btn btn-muted btn-md" >
                                                Enabled 
                                            </button> */}
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="profile_data  ac_body my-4 " >
                            <div className="d-flex justify-content-between" >
                                <h5> Updated History </h5>
                            </div>
                            <div className="table-responsive m_table">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col" className=""> Date/Time </th>
                                            <th scope="col" className=""> Permissition Type </th>
                                            <th scope="col" className=""> Prev. Status </th>
                                            <th scope="col" className=""> Updated Status </th>
                                            <th scope="col" className=""> Remark By </th>
                                            <th scope="col" className=""> Remark </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="">
                                                <span> 11-12-2024 </span>
                                            </td>
                                            <td className="">
                                                <span> Unknown Name </span>
                                            </td>
                                            <td className="">
                                                <span> ----  </span>
                                            </td>
                                            <td className="">
                                                <span> ----  </span>
                                            </td>
                                            <td className="">
                                                <span> ----  </span>
                                            </td>
                                            <td className="">
                                                <span> Message here......... </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                    {/* Limits Tab  */}
                    {/* <div className="tab-pane" id="Limits" role="tabpanel" aria-labelledby="Limits-tab">
                        <div className="profile_data  ac_body" >
                            <div className="d-flex justify-content-between" >
                                <h5> Limits  </h5>
                            </div>
                            <div className="row g-4" >
                                <div className=" col-lg-6" >
                                    <div className="profile_data fc_details  ac_body " >
                                        <div className="d-flex align-items-center justify-content-between mb-4" >
                                            <h5 className="mb-0" > Account Withdrawal Limit (1FY)  </h5>
                                            <button className="btn btn-warning btn-sm"> Update Max Limit  </button>
                                        </div>
                                        <div className="row g-4"> 
                                            <div className="col-lg-4 col-xl-6">
                                                <div className="label-group"><label>    Max. INR Withdrawal Limit     </label><p>  --------₹  </p></div>
                                            </div>
                                            <div className="col-lg-4 col-xl-6">
                                                <div className="label-group"><label>   Max. Crypto Withdrawal Limit     </label><p>   --------₹  </p></div>
                                            </div>
 
                                            <div className="col-lg-4 col-xl-6">
                                                <div className="label-group"><label>  Remaning INR Withdrawal Limit     </label><p>   --------₹  </p></div>
                                            </div>
                                            <div className="col-lg-4 col-xl-6">
                                                <div className="label-group"><label> Remaning Crypto Withdrawal Limit   </label><p>  --------₹ </p></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className=" col-lg-6" >
                                    <div className="profile_data fc_details  ac_body " >
                                        <div className="d-flex align-items-center justify-content-between mb-4" >
                                            <h5 className="mb-0" > INR Withdrawal Limits  (24h)  </h5>
                                            <button className="btn btn-warning btn-sm"> Update Max Limit  </button>
                                        </div>
                                        <div className="row g-4">
 
                                            <div className="col-lg-6 col-xl-6">
                                                <div className="label-group"><label>    Max. Current Withdrawal Limit    </label><p>  --------₹  </p></div>
                                            </div>
                                            <div className="col-lg-6 col-xl-6">
                                                <div className="label-group"><label> Remaning Withdrawal Limit      </label><p>   --------₹ </p></div>
                                            </div>
 
                                            <div className="col-lg-6 col-xl-6">
                                                <div className="label-group"><label>   Max. Current Withdrawal Orders    </label><p>   ------  </p></div>
                                            </div>
                                            <div className="col-lg-6 col-xl-6">
                                                <div className="label-group"><label>  Remaning Withdrawal  Orders    </label><p>  ------ </p></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className=" col-lg-6" >
                                    <div className="profile_data fc_details ac_body " >
                                        <div className="d-flex align-items-center justify-content-between mb-4" >
                                            <h5 className="mb-0" >  Quick Buy Sell Limits <small>(24hrs.) </small></h5>
                                            <button className="btn btn-warning btn-sm"> Update Max Limit  </button>
                                        </div>
                                        <div className="row g-4">
                                            <div className="col-lg-6 col-xl-6">
                                                <div className="label-group"><label>  Max. Current Buying Limit (INR)    </label><p> --------  </p></div>
                                            </div>
                                            <div className="col-lg-6 col-xl-6">
                                                <div className="label-group"><label>   Remaning Buying Limit (INR)   </label><p>  --------  </p></div>
                                            </div>
                                            <div className="col-lg-6 col-xl-6">
                                                <div className="label-group"><label> Max. Current Orders    </label><p>   --------  </p></div>
                                            </div>
                                            <div className="col-lg-6 col-xl-6">
                                                <div className="label-group"><label>  Remaning  Orders   </label><p>  -------- </p></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile_data  ac_body my-4 " >
                            <div className="d-flex justify-content-between" >
                                <h5> Updated History </h5>
                            </div>
                            <div className="table-responsive m_table">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col" className=""> Date/Time </th>
                                            <th scope="col" className=""> Limit Type </th>
                                            <th scope="col" className="">   Prev. Limit  </th>
                                            <th scope="col" className="">   New Limit </th>
                                            <th scope="col" className="">  Prev. No of Orders </th>
                                            <th scope="col" className="">  New No of Orders   </th>
                                            <th scope="col" className=""> Remark By </th>
                                            <th scope="col" className=""> Remark </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="">
                                                <span> 11-12-2024 </span>
                                            </td>
                                            <td className="">
                                                <span> Unknown Name </span>
                                            </td>
                                            <td className="">
                                                <span> ----  </span>
                                            </td>
                                            <td className="">
                                                <span> ----  </span>
                                            </td>
                                            <td className="">
                                                <span> ----  </span>
                                            </td>
                                            <td className="">
                                                <span> ----  </span>
                                            </td>
                                            <td className="">
                                                <span> ----  </span>
                                            </td>
                                            <td className="">
                                                <span> Message here......... </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>


                    </div> */}
                </div>

            </div>
        </div>
    </div>
    )
};

export default PermissionDetails;