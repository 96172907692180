import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { MultiSelect } from "react-multi-select-component";
import { handleAddSubAdmin,handleChange, options } from '../../../../services/Context/SubadminContext';

const AddSubadmin = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    fullName: '',
    mobileNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    permissions: [],
  });

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header">
          <div className="container-xl px-4">
            <div className="page-header-content">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <h1 className="page-header-title">Add new sub admin</h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4">
          <div className="card mb-4">
            <div className="card-header">Enter Sub Admin Details</div>
            <div className="card-body pt-4">
              <form>
                <div className="row gx-3 mb-3">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="fullName">Full Name <em>*</em></label>
                    <input
                      className="form-control form-control-solid"
                      id="fullName"
                      name="fullName"
                      type="text"
                      placeholder="Enter your First Name"
                      value={formData.fullName}
                      onChange={(e) => handleChange(e, formData, setFormData, setError, setApiResponse)}
                    />
                    {error.fullName && <span className="text-danger">{error.fullName}</span>}
                  </div>

                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="mobileNumber">Mobile Number <em>*</em></label>
                    <input
                      className="form-control form-control-solid"
                      id="mobileNumber"
                      name="mobileNumber"
                      type="tel"
                      placeholder="Enter your Mobile Number"
                      value={formData.mobileNumber}
                      maxLength={10}
                      onChange={(e) => handleChange(e, formData, setFormData, setError, setApiResponse)}
                    />
                    {error.mobileNumber && <div className="text-danger">{error.mobileNumber}</div>}
                  </div>

                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="email">Email <em>*</em></label>
                    <input
                      className="form-control form-control-solid"
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Enter your email address"
                      value={formData.email}
                      onChange={(e) => handleChange(e, formData, setFormData, setError, setApiResponse)}
                    />
                    {error.email && <div className="text-danger">{error.email}</div>}
                  </div>
                </div>

                <div className="row gx-3 mb-3">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="password">Password <em>*</em></label>
                    <div className="input-group">

                      <input
                        className="form-control form-control-solid"
                        id="password"
                        type={showPassword ? 'text' : "password"}
                        placeholder="Enter your Password"
                        name="password"
                        value={formData.password}
                        onChange={(e) => handleChange(e, formData, setFormData, setError, setApiResponse)}
                      />
                      <button
                        type="button"
                        className="btn btn-dark btn-icon btn_view_pass"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <i className={`fa-solid fa-eye${showPassword ? "-slash" : ""}`} />
                      </button>

                      {error.password && <div className="text-danger">{error.password}</div>}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="confirmPassword">Confirm Password</label>
                    <div className="input-group">

                      <input
                        className="form-control form-control-solid"
                        id="confirmPassword"
                        type={showPassword2 ? 'text' : "password"}
                        placeholder="Enter your Password Again"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={(e) => handleChange(e, formData, setFormData, setError, setApiResponse)}
                      />
                      <button
                        type="button"
                        className="btn btn-dark btn-icon btn_view_pass"
                        onClick={() => setShowPassword2(!showPassword2)}
                      >
                        <i className={`fa-solid fa-eye${showPassword2 ? "-slash" : ""}`} />
                      </button>
                      {error.confirmPassword && <div className="text-danger">{error.confirmPassword}</div>}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="Permissions">Permissions <em>*</em></label>
                  {/*   <MultiSelect
                      options={options}
                      value={formData.permissions}
                      onChange={(e) => handleChange(e, formData, setFormData, setError, setApiResponse)}
                      labelledBy="Permissions"
                    /> */}
                    {error.permissions && <div className="text-danger">{error.permissions}</div>}
                  </div>
                </div>

                <div className="d-flex justify-content-center w-100 mt-5 mb-4">
                  <button
                    className="btn btn-indigo w-25 fs-6"
                    type="button"
                    onClick={() => handleAddSubAdmin(formData, setError, navigate, setApiResponse, setIsLoading)}
                  >
                    {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      : <span> Submit Details </span>}
                  </button>

                </div>

              </form>
              {apiResponse && <div className="text-danger">{apiResponse}</div>}

            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddSubadmin;
