import React from "react";
import { Link } from "react-router-dom";

const TransactionHistory = ({ activeTab }) => {

    return (
        <div className={`tab-pane ${activeTab === 7 ? "active" : ""}`} >
            <div className="card-body py-4" >

                <div className="profile_data  ac_body  " >
                    <div className=" " >
                        <div className="custom-tab-2">
                            <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a href="#DepositWithdrawals" id="DepositWithdrawals-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true"> Deposit & Withdrawals  </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a href="#Transfer" id="Transfer-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> Transfer </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a href="#Pay" id="Pay-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> Pay </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content">

                        {/* crypto Tab  */}
                        <div className="tab-pane active" id="DepositWithdrawals" role="tabpanel" aria-labelledby="DepositWithdrawals-tab">
                            <div className=" " >
                                <div className="profile_data  ac_body d-flex align-items-center mb-3 justify-content-between" >
                                    <h5 className="mb-0" >  Deposit & Withdrawals  </h5>
                                    <div className="custom-tab-2 custom-tab-curve">
                                        <ul className="nav nav-pills justify-content-start " role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <a href="#Crypto" id="Crypto-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true">   Crypto </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a href="#INR" id="INR-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> INR </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="tab-content">

                                    {/* crypto Tab  */}
                                    <div className="tab-pane active" id="Crypto" role="tabpanel" aria-labelledby="Crypto-tab">
                                        <ul className="nav custom-tabs mb-0">
                                            <li className="buysell-tab">
                                                <a className="active"> All   </a>
                                            </li>
                                            <li className="buysell-tab">
                                                <a className="">  Deposits </a>
                                            </li>
                                            <li className="buysell-tab">
                                                <a className="">  Withdrawals </a>
                                            </li>
                                        </ul>

                                        <div className="filter_bar search-header px-0 g-2">
                                            <form className="row align-items-center w-100 gx-2">
                                                <div className="col-2">
                                                    <select className="form-control form-select cursor-pointer">
                                                        <option value=""> Coins List </option>
                                                        <option value="Completed"> All Coins </option>
                                                        <option value="Pending"> Coin 1 </option>
                                                        <option value="Pending"> Coin 2 </option>
                                                    </select>
                                                </div>
                                                <div className="col-2">
                                                    <select className="form-control form-select cursor-pointer">
                                                        <option value=""> Order Status</option>
                                                        <option value="Completed"> All  </option>
                                                        <option value="Completed">Completed </option>
                                                        <option value="Pending">Pending</option>
                                                        <option value="Rejected">Rejected</option>
                                                    </select>
                                                </div>
                                                <div className="col-2">
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-solid"
                                                        data-provide="datepicker"
                                                        id="litepickerRangePlugin"
                                                        name="dateFrom"
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-solid"
                                                        data-provide="datepicker"
                                                        id="litepickerRangePlugin"
                                                        name="dateTo"

                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <div className="row align-items-center gx-2">
                                                        <div className="col">
                                                            <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                                                Apply
                                                            </button>
                                                        </div>
                                                        <div className="col">
                                                            <button className="btn btn-light btn-block w-100" type="button" >
                                                                <i className="ri-reset-left-line"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <label className="small text-dark mb-1" >  </label>
                                            <div className="dd_search">
                                                <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                                <i className="fas fa-search"></i>
                                            </div>

                                        </div>
                                        <div className="table-responsive m_table">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className=""> Date/Time </th>
                                                        <th scope="col" className=""> Order No. </th>
                                                        <th scope="col" className="">Currency </th>
                                                        <th scope="col" className="">Amount </th>
                                                        <th scope="col" className="">Chain </th>
                                                        <th scope="col" className=""> Address </th>
                                                        <th scope="col" className="">Fee </th>
                                                        <th scope="col" className="">Txn Id </th>
                                                        <th scope="col" className="">Completed Time </th>
                                                        <th scope="col" className="">Action </th>
                                                        <th scope="col" className="">Status </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>



                                    </div>

                                    {/* INR Tab  */}
                                    <div className="tab-pane" id="INR" role="tabpanel" aria-labelledby="INR-tab">
                                        <ul className="nav custom-tabs mb-0">
                                            <li className="buysell-tab">
                                                <a className="active"> All   </a>
                                            </li>
                                            <li className="buysell-tab">
                                                <a className="">  Deposits </a>
                                            </li>
                                            <li className="buysell-tab">
                                                <a className="">  Withdrawals </a>
                                            </li>
                                        </ul>
                                        <div className="filter_bar search-header px-0 g-2">
                                            <form className="row align-items-center w-100 gx-2">
                                                <div className="col-2">
                                                    <select className="form-control form-select cursor-pointer">
                                                        <option value=""> Order Status</option>
                                                        <option value="Completed"> All  </option>
                                                        <option value="Completed">Completed </option>
                                                        <option value="Pending">Pending</option>
                                                        <option value="Rejected">Rejected</option>
                                                    </select>
                                                </div>
                                                <div className="col-2">
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-solid"
                                                        data-provide="datepicker"
                                                        id="litepickerRangePlugin"
                                                        name="dateFrom"
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-solid"
                                                        data-provide="datepicker"
                                                        id="litepickerRangePlugin"
                                                        name="dateTo"

                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <div className="row align-items-center gx-2">
                                                        <div className="col">
                                                            <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                                                Apply
                                                            </button>
                                                        </div>
                                                        <div className="col">
                                                            <button className="btn btn-light btn-block w-100" type="button" >
                                                                <i className="ri-reset-left-line"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <label className="small text-dark mb-1" >  </label>
                                            <div className="dd_search">
                                                <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                                <i className="fas fa-search"></i>
                                            </div>
                                        </div>
                                        <div className="table-responsive m_table">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className=""> Date/Time </th>
                                                        <th scope="col" className=""> Order No. </th>
                                                        <th scope="col" className="">Currency </th>
                                                        <th scope="col" className=""> Payment Method </th>
                                                        <th scope="col" className="">Amount </th>
                                                        <th scope="col" className="">Fee </th>
                                                        <th scope="col" className="">  Bank Details   </th>
                                                        <th scope="col" className="">UTR No. </th>
                                                        <th scope="col" className="">Completed Time </th>
                                                        <th scope="col" className="">Action </th>
                                                        <th scope="col" className="">Status </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                        <td className="">
                                                            <span> ---- </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>



                                    </div>


                                </div>

                            </div>
                        </div>

                        {/* crypto Tab  */}
                        <div className="tab-pane" id="Transfer" role="tabpanel" aria-labelledby="Transfer-tab">
                            <div className=" " >
                                <div className="profile_data  ac_body" >
                                    <h5 className="" >  Transfer History </h5>
                                </div>

                                {/* crypto Tab  */}
                                <div>
                                    <div className="filter_bar search-header px-0 g-2">
                                        <form className="row align-items-center w-100 gx-2">
                                            <div className="col-2">
                                                <select className="form-control form-select cursor-pointer">
                                                    <option hidden> Select Coin </option>
                                                    <option value="Completed"> All Coins </option>
                                                    <option value="Pending"> Coin 1 </option>
                                                    <option value="Pending"> Coin 2 </option>
                                                </select>
                                            </div>
                                            <div className="col-2">
                                                <select className="form-control form-select cursor-pointer">
                                                    <option hidden > Select From  </option>
                                                    <option value="Rejected"> Funding Wallet </option>
                                                    <option value="Completed"> Spot Wallet </option>
                                                    <option value="Pending"> Future Wallet </option>
                                                </select>
                                            </div>
                                            <div className="col-2">
                                                <select className="form-control form-select cursor-pointer">
                                                    <option hidden > Select  To  </option>
                                                    <option value="Rejected"> Funding Wallet </option>
                                                    <option value="Completed"> Spot Wallet </option>
                                                    <option value="Pending"> Future Wallet </option>
                                                </select>
                                            </div>
                                            <div className="col-2">
                                                <input
                                                    type="date"
                                                    className="form-control form-control-solid"
                                                    data-provide="datepicker"
                                                    id="litepickerRangePlugin"
                                                    name="dateFrom"
                                                    placeholder="Date"
                                                />
                                            </div>
                                            <div className="col-2">
                                                <input
                                                    type="date"
                                                    className="form-control form-control-solid"
                                                    data-provide="datepicker"
                                                    id="litepickerRangePlugin"
                                                    name="dateTo"

                                                />
                                            </div>
                                            <div className="col-2">
                                                <div className="row align-items-center gx-2">
                                                    <div className="col">
                                                        <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                                            Apply
                                                        </button>
                                                    </div>
                                                    <div className="col">
                                                        <button className="btn btn-light btn-block w-100" type="button" >
                                                            <i className="ri-reset-left-line"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <label className="small text-dark mb-1" >  </label>
                                        <div className="dd_search">
                                            <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                            <i className="fas fa-search"></i>
                                        </div>

                                    </div>
                                    <div className="table-responsive m_table">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className=""> Date/Time </th>
                                                    <th scope="col" className=""> Coin </th>
                                                    <th scope="col" className="">Amount </th>
                                                    <th scope="col" className="">From/To </th>
                                                    <th scope="col" className="">Fee </th>
                                                    <th scope="col" className="">Status </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* crypto Tab  */}
                        <div className="tab-pane" id="Pay" role="tabpanel" aria-labelledby="Pay-tab">
                            <div className=" " >
                                <div className="profile_data  ac_body" >
                                    <h5>  Pay History </h5>
                                    <ul className="nav custom-tabs mb-0">
                                        <li className="buysell-tab">
                                            <a className="active">   All  </a>
                                        </li>
                                        <li className="buysell-tab">
                                            <a className="">  Paid   </a>
                                        </li>
                                        <li className="buysell-tab">
                                            <a className="">  Received </a>
                                        </li>
                                    </ul>
                                </div>

                                {/* crypto Tab  */}
                                <div>
                                    <div className="filter_bar search-header px-0 g-2">
                                        <form className="row align-items-center w-100 gx-2">
                                            <div className="col-2">
                                                <select className="form-control form-select cursor-pointer">
                                                    <option hidden> Select Coin </option>
                                                    <option value="Completed"> All Coins </option>
                                                    <option value="Pending"> Coin 1 </option>
                                                    <option value="Pending"> Coin 2 </option>
                                                </select>
                                            </div>
                                            <div className="col-2">
                                                <input
                                                    type="date"
                                                    className="form-control form-control-solid"
                                                    data-provide="datepicker"
                                                    id="litepickerRangePlugin"
                                                    name="dateFrom"
                                                    placeholder="Date"
                                                />
                                            </div>
                                            <div className="col-2">
                                                <input
                                                    type="date"
                                                    className="form-control form-control-solid"
                                                    data-provide="datepicker"
                                                    id="litepickerRangePlugin"
                                                    name="dateTo"

                                                />
                                            </div>
                                            <div className="col-2">
                                                <div className="row align-items-center gx-2">
                                                    <div className="col">
                                                        <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                                            Apply
                                                        </button>
                                                    </div>
                                                    <div className="col">
                                                        <button className="btn btn-light btn-block w-100" type="button" >
                                                            <i className="ri-reset-left-line"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <label className="small text-dark mb-1" >  </label>
                                        <div className="dd_search">
                                            <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                            <i className="fas fa-search"></i>
                                        </div>

                                    </div>
                                    <div className="table-responsive m_table">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className=""> Date/Time </th>
                                                    <th scope="col" className=""> Coin </th>
                                                    <th scope="col" className=""> Type </th>
                                                    <th scope="col" className="">Amount </th>
                                                    <th scope="col" className="">From/To </th>
                                                    <th scope="col" className="">Fee </th>
                                                    <th scope="col" className="">Status </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                    <td className="">
                                                        <span> ---- </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>





                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
};

export default TransactionHistory;