import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";

const FeestructureQbs = () => {

    const [feeStractureData, setFeeStractureData] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [skip, setSkip] = useState(0);
    const [totalDataLength, setTotalDataLength] = useState(0);
    const [showModel, setShowModel] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState("");
    const [rowData, setRowData] = useState("");
    const limit = 10;

    const [formData, setFormData] = useState({
        currency_name: "",
        short_name: "",
        trading_fee: "",
        min_deposit: "",
        min_withdrawal: "",
        deposit_fee: "",
        withdrawal_fee: "",
    });

    useEffect(() => {
        if (rowData) {
            setFormData({
                currency_name: rowData?.currency_name || "",
                short_name: rowData?.short_name || "",
                trading_fee: rowData?.trading_fee || "",
                min_deposit: rowData?.min_deposit || "",
                min_withdrawal: rowData?.min_withdrawal || "",
                deposit_fee: rowData?.deposit_fee || "",
                withdrawal_fee: rowData?.withdrawal_fee || "",
            });
        }
    }, [rowData]);


    const handleChange = (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        }
        setApiResponse("");
    };

    const linkFollow = (row) => {
        return (
            <div>
                <button className="btn-success btn-sm me-2 text-dark" onClick={() => { setRowData(row); setShowModel(true); }}><i class="ri-edit-box-fill"></i></button>
                <button className="btn-danger btn-sm me-2" onClick={() => showAlert(row)}><i class="ri-delete-bin-fill"></i></button>
            </div>
        );
    };

    const columns = [
        {
            name: "DateTime",
            wrap: true,
            selector: row => {
                const createdAt = row?.createdAt ? moment(row.createdAt) : null;
                return createdAt ? (
                    <span>
                        {createdAt.format("DD MMMM YYYY")}
                        <br />
                        <small>{createdAt.format("h:mm:ss a")}</small>
                    </span>
                ) : (
                    "-------"
                );
            },
        },
        { name: "Currency Name", selector: row => row.currency_name || "-------", sortable: true, },
        { name: "Short Name", selector: row => row.short_name, sortable: true, },
        { name: "Deposit Fee", wrap: true, selector: row => row.deposit_fee, sortable: true, },
        { name: "Min Deposit", selector: row => row.min_deposit, sortable: true, },
        { name: "Min Withdrawal", selector: row => row.min_withdrawal, sortable: true, },
        { name: "Trading Fee", selector: row => row.trading_fee, sortable: true, },
        { name: "Withdrawal Fee", selector: row => row.withdrawal_fee, sortable: true, },
        { name: "Action", cell: (row) => linkFollow(row) },

    ];

    const handleData = async (skip, limit, searchKey) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.getFeeStractureQbsList(skip, limit, searchKey);
            if (result.success) {
                setFeeStractureData(result.data || []);
                setTotalDataLength(result?.data?.totalCount || 0);
            } else {
                setFeeStractureData([]);
            }
        } catch (error) {
            alertErrorMessage(error);
            setFeeStractureData([]);
        } finally {
            LoaderHelper.loaderStatus(false);
        }
    };

    useEffect(() => {
        handleData(skip, limit, searchKey);
    }, [skip, limit, searchKey]);


    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchKey(searchTerm);
    };

    const handlePagination = (action, setSkip, skip, limit, totalDataLength) => {
        switch (action) {
            case "prev":
                if (skip - limit >= 0) setSkip(skip - limit);
                break;
            case "next":
                if (skip + limit < totalDataLength) setSkip(skip + limit);
                break;
            case "first":
                setSkip(0);
                break;
            case "last":
                setSkip(Math.max(totalDataLength - limit, 0));
                break;
            default:
                break;
        }
    };

    const handleEditFeeStracture = async () => {
        try {
            setIsLoading(true);
            const result = await AuthService.editFeeStractureQbs(formData, rowData?._id);
            if (result?.success) {
                setShowModel(false);
                handleData(skip, limit, searchKey);
            } else {
                setApiResponse(result || 'An unexpected error occurred.');
                setIsLoading(false);
            }
        } catch (error) {
            setApiResponse(error?.message || 'An unexpected error occurred.');
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };


    const showAlert = (rowData) => {
        Swal.fire({
            title: "Are you sure?",
            html: `Are you sure you want to delete : <b>${rowData?.currency_name}</b>?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#e64942",
            cancelButtonColor: "#e7e7e7",
            confirmButtonText: "Yes Delete!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteQbsFeeStracture(rowData?._id)
            }
        });
    };

    const deleteQbsFeeStracture = async (userId) => {
        try {
            const result = await AuthService.deleteFeeStractureQbs(userId);
            if (result?.success) {
                handleData(skip, limit, searchKey);
                return true;
            } else {
                alertErrorMessage(result.message);
                return false;
            }
        } catch (error) {
            alertErrorMessage(error?.message);
            return false;
        }
    };

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header ">
                    <div className="container-xl px-4">
                        <div className="page-header-content">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-12">
                                    <div className="d-flex justify-content-between" >
                                        <h1 className="page-header-title">
                                            Fee structure Quick Buy Sell
                                        </h1>
                                        {
                                            feeStractureData?.length > 0 &&
                                            <div className="dropdown">
                                                <button className="btn btn-dark btn-sm dropdown-toggle" type="button" >
                                                    <i className="fa fa-download me-2"></i>
                                                    <CSVLink data={feeStractureData} className="text-white" filename={`feeStractur.csv`}>
                                                        Export as CSV
                                                    </CSVLink>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4">
                    <div className="tab-content" id="nav-tabContent">
                        <div className="card mb-4 tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <div className="card-header pb-0">
                                <ul className="nav nav-tabs nav-tabs-custom" id="pills-tab" role="tablist">
                                    <li className="nav-item mb-2" role="presentation">
                                        Quick Buy Sell List
                                    </li>
                                </ul>
                            </div>
                            <div className="search-header">
                                <div className="dd_search">
                                    <input type="search" placeholder="Search here..." name="searchKey" id="searchKey" value={searchKey} onChange={handleSearch} />
                                    <i className="fas fa-search"></i>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive" width="100%">
                                    <DataTable columns={columns} data={feeStractureData}
                                        noDataComponent={
                                            <p> <img src="/assets/img/not_found.svg" width="300" className='img-fluid mt-5' alt='' /> </p>
                                        }
                                    />
                                    {
                                        feeStractureData?.length > 0 &&
                                        <div className="hVPalX gap-2" >
                                            <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                            <div className="sc-eAKtBH gVtWSU">
                                                <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first', setSkip, skip, limit, totalDataLength)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                        <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                    </svg>
                                                </button>
                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev', setSkip, skip, limit, totalDataLength)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>
                                                </button>

                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next', setSkip, skip, limit, totalDataLength)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>
                                                </button>
                                                <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last', setSkip, skip, limit, totalDataLength)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                        <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {showModel && (
                <>
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content pb-3">
                                <div className="modal-header">
                                    <h5 className="modal-title" >You want to Edit Fee Stracutre Qbs ?</h5>
                                </div>
                                <div className="modal-body ">
                                    <div className="row" >
                                        {/* <div className="col-lg-6" >
                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="currency_name">Currency Name<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="currency_name"
                                                    name="currency_name"
                                                    type="text"
                                                    value={formData.currency_name}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div> */}
                                        {/* <div className="col-lg-6" >

                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="short_name">Short Name<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="short_name"
                                                    name="short_name"
                                                    type="text"
                                                    value={formData.short_name}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div> */}
                                        <div className="col-lg-6" >

                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="trading_fee">Trading Fee<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="trading_fee"
                                                    name="trading_fee"
                                                    type="number"
                                                    value={formData.trading_fee}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6" >

                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="min_deposit">Min Deposit<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="min_deposit"
                                                    name="min_deposit"
                                                    type="number"
                                                    value={formData.min_deposit}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6" >

                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="min_withdrawal">Min Withdrawal<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="min_withdrawal"
                                                    name="min_withdrawal"
                                                    type="number"
                                                    value={formData.min_withdrawal}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6" >

                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="deposit_fee">Deposit Fee<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="deposit_fee"
                                                    name="deposit_fee"
                                                    type="number"
                                                    value={formData.deposit_fee}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6" >

                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="withdrawal_fee">Withdrawal Fee<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="withdrawal_fee"
                                                    name="withdrawal_fee"
                                                    type="number"
                                                    value={formData.withdrawal_fee}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center w-100 mb-4">
                                        <button className="btn btn-danger  btn-block w-100" type="button" onClick={handleEditFeeStracture}>
                                            {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                : <span> Submit Details </span>}
                                        </button>
                                        <button className="btn btn-secondary  btn-block w-100 mt-1" type="button" onClick={() => setShowModel(false)}>
                                            Cancel
                                        </button>

                                    </div>
                                    {apiResponse && <div className="text-danger">{apiResponse}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            )}

        </div>
    )
};
export default FeestructureQbs;