import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import LoaderHelper from "../../../CustomComponent/LoaderHelper/LoaderHelper";
import { alertErrorMessage } from "../../../CustomComponent/CustomAlert";
import Swal from "sweetalert2";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
const BuySellHistory = () => {

    const [buySellData, setBuySellData] = useState([]);
    const [totalDataLength, setTotalDataLength] = useState([]);
    const [orderType, setOrderType] = useState("All");
    const [orderSide, setOrderSide] = useState("BUY");
    const [searchKey, setSearchKey] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [bulkOrder, setBulkOrder] = useState();
    const [buySellCoinList, setBuySellCoinList] = useState([]);
    const [coinType, setCoinType] = useState("");
    const [orderDetails, setOrderDetails] = useState();
    const [isOpenModel, setIsOpenModel] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [fileUrl, setFileUrl] = useState("");
    const [skip, setSkip] = useState(0);
    const limit = 10;

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const parts = dateString.split("-");
        if (parts.length !== 3) return "";
        const [year, month, day] = parts;
        return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
    };
    const handleBuySellHistory = async () => {
        const formattedSearchKey = searchKey || "";
        const formattedStartDate = startDate ? formatDate(startDate) : "";
        const formattedEndDate = endDate ? formatDate(endDate) : "";

        let filters = {};
        if (orderType && orderType !== "All") {
            if (orderType === "Unpaid_Orders") {
                filters.amount_deposited = false;
                filters.status = "Pending";
            } else if (orderType === "Paid_Orders") {
                filters.amount_deposited = true;
                filters.status = "Pending";
            } else if (orderType === "Appeal_Orders") {
                filters.amount_deposited = true;
                filters.remindedAdmin = true;
                filters.status = "Pending";
            } else if (orderType === "Completed_Orders") {
                filters.status = "Completed";
            } else if (orderType === "Refunded_Orders") {
                filters.status = "Refund Processed";
            } else if (orderType === "Refund_Orders") {
                filters.status = "Refund Initiated";
            } else if (orderType === "Cancelled_Orders") {
                filters.status = "Cancelled By User";
            } else if (orderType === "Rejected_Orders") {
                filters.status = "Rejected";
            } else {
                filters.orderType = orderType;
            }
        }
        if (bulkOrder) {
            filters.bulk_order = bulkOrder;
        }

        if (orderSide) {
            filters.side = orderSide;
        }

        if (coinType) {
            filters.base_currency = coinType;
        }

        if (formattedSearchKey) {
            filters.search = formattedSearchKey;
        }

        if (formattedStartDate) {
            filters.startDate = formattedStartDate;
        }

        if (formattedEndDate) {
            filters.endDate = formattedEndDate;
        }
        const queryString = new URLSearchParams(filters).toString();
        await AuthService.getBuySellAllHistory(skip, limit, queryString).then(async (result) => {
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                try {
                    setBuySellData(result?.data);
                    setTotalDataLength(result?.count);
                } catch (error) {
                    alertErrorMessage(error);
                    setBuySellData([])
                }
            } else {
                setBuySellData([])
                LoaderHelper.loaderStatus(false);
            }
        });
    };


    const handleBuysellList = async () => {
        await AuthService.getQbsList(orderSide, "", "").then(async (result) => {
            if (result.success) {
                try {
                    setBuySellCoinList(result?.data?.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                    setBuySellCoinList([]);
                }
            } else {
                alertErrorMessage(result.message);
                setBuySellCoinList([]);
            }
        });
    };

    useEffect(() => {
        handleBuySellHistory();
        handleBuysellList();

    }, [skip, limit, orderType, orderSide, searchKey, bulkOrder, coinType]);


    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchKey(searchTerm);
    };

    const handleFilterReset = () => {
        handleBuySellHistory();
        setSearchKey("");
        setOrderType("All");
        setOrderSide("BUY");
        setStartDate("");
        setEndDate("");
        setBulkOrder()
        setCoinType("")
        setSkip(0)
    };


    const handleDateFilterData = (startDate, endDate) => {
        if (!startDate) {
            alertErrorMessage("Please select Start date")
            return;
        }
        if (!endDate) {
            alertErrorMessage("Please select End date")
            return;
        }
        handleBuySellHistory();
    };

    const handleUpdateStatus = async (userId, orderId, status) => {
        try {
            const { isConfirmed, value: remark } = await Swal.fire({
                title: "Are you sure?",
                html: `
                  <label for="remark">Enter Remark:</label>
                  <textarea id="remark" class="form-control mt-2" rows="3" placeholder="Enter Remark"></textarea>
                `,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#e64942",
                cancelButtonColor: "#e7e7e7",
                confirmButtonText: "Yes, Update!",
                preConfirm: () => {
                    const remark = document.getElementById("remark").value.trim();
                    if (!remark) {
                        Swal.showValidationMessage("You need to enter a Remark!");
                        return false;
                    }
                    return remark;
                }
            });

            if (isConfirmed && remark) {
                const response = await AuthService.updateQbsStatus(userId, orderId, status, remark);

                if (response?.success) {
                    Swal.fire({
                        title: "Updated!",
                        text: response?.message || "Order status updated successfully.",
                        icon: "success",
                    });

                    handleBuySellHistory();
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response || "Failed to update order status.",
                        icon: "error",
                    });
                }
            }
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: error?.response?.data?.message || "Something went wrong. Please try again.",
                icon: "error",
            });
        }
    };



    const handleUpdateRefund = async (orderDetails) => {
        try {
            const { isConfirmed, value } = await Swal.fire({
                title: "Are you sure?",
                html: `
                  <div style="text-align: left;">
                                           
                      <label for="utr">Enter UTR Number:</label>
                      <input id="utr" class="form-control mt-2" placeholder="Enter UTR Number"></input>
                      
                      <label for="mode" class="mt-3">Select Refund Mode:</label>
                      <select id="mode" class="form-control mt-2">
                          <option value="">Select Refund Mode</option>
                          <option value="NEFT">NEFT</option>
                          <option value="RTGS">RTGS</option>
                          <option value="IMPS">IMPS</option>
                          <option value="UPI">UPI</option>
                          <option value="Intra Bank Transfer">Intra Bank Transfer</option>

                      </select>

                    <label for="remark" class="mt-3">Enter Remark:</label>
                     <textarea id="remark" class="form-control mt-2" rows="3" placeholder="Enter Remark"></textarea>

                  </div>
                `,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#e64942",
                cancelButtonColor: "#e7e7e7",
                confirmButtonText: "Yes, Update!",
                preConfirm: () => {
                    const remark = document.getElementById("remark").value.trim();
                    const utr = document.getElementById("utr").value.trim();
                    const mode = document.getElementById("mode").value.trim();

                    if (!utr) return Swal.showValidationMessage("You need to enter a UTR Number!");
                    if (!mode) return Swal.showValidationMessage("You need to select a Refund Mode!");
                    if (!remark) return Swal.showValidationMessage("You need to enter a Remark!");

                    return { remark, utr, mode };
                }
            });

            if (isConfirmed && value) {
                const { remark, utr, mode } = value;

                const response = await AuthService.updateRefundStatus(remark, utr, mode, orderDetails?._id);

                if (response?.success) {
                    Swal.fire({
                        title: "Updated!",
                        text: response?.message || "Order status updated successfully.",
                        icon: "success",
                    });

                    handleBuySellHistory();
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response || "Failed to update order status.",
                        icon: "error",
                    });
                }
            }
        } catch (error) {
            Swal.fire("Error!", "Something went wrong. Please try again.", "error");
        }
    };



    const handlePagination = (action) => {
        if (action === 'prev') {
            if (skip - limit >= 0) {
                setSkip(skip - limit);
            }
        } else if (action === 'next') {
            if (skip + limit < totalDataLength) {
                setSkip(skip + limit);
            }
        } else if (action === 'first') {
            setSkip(0);
        } else if (action === 'last') {
            const lastPageSkip = Math.floor(totalDataLength);
            const data = lastPageSkip - 10
            setSkip(data);
        }
    };

    const uniqueCurrencies = [...new Set(buySellCoinList.map(order => order.base_currency))];

    const handleOpen = (url) => {
        if (!url) return;
        setFileUrl(url);
        setIsOpen(true);
    };


    console.log(orderDetails, 'orderDetails=>>>>>>>>>');


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header ">
                    <div className="container-xl px-4">
                        <div className="page-header-content">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="far fa-user"></i></div>
                                        Buy Sell History
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4">
                    <div className='custom-tab-2' >
                        <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link ${orderSide === "BUY" && "active"}`} type="button" onClick={() => setOrderSide("BUY")}> Buy Orders  </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link ${orderSide === "SELL" && "active"}`} type="button" onClick={() => setOrderSide("SELL")}>  Sell Orders </button>
                            </li>
                        </ul>
                    </div>
                    <div className="card mb-3">
                        <div className="card-header pb-0">
                            <ul className="nav nav-tabs nav-tabs-custom" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={`nav-link ${orderType === "All" && "active"}`} onClick={() => setOrderType("All")}> All  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={`nav-link ${orderType === "Unpaid_Orders" && "active"}`} onClick={() => setOrderType("Unpaid_Orders")}> Unpaid   </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={`nav-link ${orderType === "Paid_Orders" && "active"}`} onClick={() => setOrderType("Paid_Orders")}> Paid  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={`nav-link ${orderType === "Appeal_Orders" && "active"}`} onClick={() => setOrderType("Appeal_Orders")}> Appeal   </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={`nav-link ${orderType === "Completed_Orders" && "active"}`} onClick={() => setOrderType("Completed_Orders")}> Completed  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={`nav-link ${orderType === "Refunded_Orders" && "active"}`} onClick={() => setOrderType("Refunded_Orders")}> Refunded   </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={`nav-link ${orderType === "Refund_Orders" && "active"}`} onClick={() => setOrderType("Refund_Orders")}> Refund   </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={`nav-link ${orderType === "Cancelled_Orders" && "active"}`} onClick={() => setOrderType("Cancelled_Orders")}> Cancelled  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={`nav-link ${orderType === "Rejected_Orders" && "active"}`} onClick={() => setOrderType("Rejected_Orders")}> Rejected  </button>
                                </li>
                            </ul>
                            <div className="dropdown">
                                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Export{" "}
                                </button>
                                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                    <CSVLink data={buySellData} className="dropdown-item">Export as CSV</CSVLink>
                                </div>
                            </div>
                        </div>
                        <div className="filter_bar search-header">
                            <div className="dd_search">
                                <input type="search" placeholder="Search here..." name="searchKey" id="searchKey" value={searchKey} onChange={handleSearch} />
                                <i className="fas fa-search"></i>
                            </div>
                            <form className="row align-items-center">
                                <div className="col-3">
                                    <select className=" form-control form-select" onClick={(e) => setBulkOrder(e.target.value)}>
                                        <option value={false}> Normal Orders </option>
                                        <option value={true}> Bulk Orders </option>
                                    </select>
                                </div>
                                <div className="col-3">
                                    <select className=" form-control form-select" onClick={(e) => setCoinType(e.target.value)}>
                                        <option> Select Coin </option>
                                        {uniqueCurrencies.map((currency, index) => (
                                            <option key={index} value={currency}>{currency}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-3">
                                    <div className="row">
                                        <div className="col-6">
                                            <input
                                                type="date"
                                                className="form-control form-control-solid"
                                                data-provide="datepicker"
                                                id="litepickerRangePlugin"
                                                name="startDate"
                                                value={startDate}
                                                max={new Date().toISOString().split("T")[0]}
                                                onChange={(e) => setStartDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <input
                                                type="date"
                                                className="form-control form-control-solid"
                                                data-provide="datepicker"
                                                id="litepickerRangePlugin"
                                                name="endDate"
                                                value={endDate}
                                                max={new Date().toISOString().split("T")[0]}
                                                onChange={(e) => setEndDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <button className="btn btn-black btn-block w-100 px-4" type="button" onClick={() => handleDateFilterData(startDate, endDate)}>
                                                Apply
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button className="btn btn-light btn-block w-100" type="button" onClick={handleFilterReset}>
                                                <i className="ri-reset-left-line"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="card" >
                            <div className="card-body" >
                                <div className="table-responsive odd_custom_table" width="100%">
                                    {buySellData && buySellData.length > 0 ? (
                                        <table class="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th> <span>Crypto</span>  </th>
                                                    <th>
                                                        <span> Order Number  </span>
                                                        <span> Side   </span>
                                                        <span> Order Type  </span>
                                                        <span> Ref No  </span>
                                                    </th>
                                                    <th>
                                                        <span> Order Amount  </span>
                                                        <span> Price   </span>
                                                        <span> Crypto Amount  </span>
                                                    </th>
                                                    <th>
                                                        <span> User Name    </span>
                                                        <span> Email </span>
                                                        <span> Mobile No </span>
                                                        <span> User ID    </span>
                                                        <span> Order CreatedAt    </span>
                                                    </th>
                                                    <th>
                                                        <span> UTR Number </span>
                                                        <span> Payment Type   </span>
                                                        <span> Paid at   </span>
                                                        <span>  Remark    </span>
                                                    </th>
                                                    <th> <span>Status</span> </th>
                                                    <th> <span>Action</span> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {buySellData?.map((order, index) => (
                                                    <tr key={index} >
                                                        <td onClick={() => { setIsOpenModel(true); setOrderDetails(order) }} className="cursor-pointer">
                                                            <span>
                                                                <div class="c_view c_view_sm  ">
                                                                    <div className="d-flex align-items-center">
                                                                        <img src={order?.base_currency_image || "assets/img/nocoin.png"} alt=""
                                                                            onError={(e) => {
                                                                                e.target.onerror = null;
                                                                                e.target.src = "assets/img/nocoin.png";
                                                                            }}
                                                                        />
                                                                        <span>{order.base_currency + "/" + order.quote_currency}</span>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </td>
                                                        <td onClick={() => { setIsOpenModel(true); setOrderDetails(order) }} className="cursor-pointer">
                                                            <span className="link-primary" >
                                                                #{order.order_id}
                                                            </span>
                                                            <span>
                                                                {moment(order?.createdAt).format('DD MMM YYYY')},
                                                                <small>{moment(order?.createdAt).format('h:mm:ss a')} </small>
                                                            </span>
                                                            <span className={order.side === "BUY" ? "text-success" : "text-danger"}>
                                                                {order.side}
                                                            </span>
                                                            <span>
                                                                {order.bulk_order === true ? "Bulk Traders" : "Normal"}
                                                            </span>
                                                            <span>
                                                                {order.ref_no}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>{order.pay_amount} {order.pay_currency} </span>
                                                            <span> {order.price} {order.pay_currency} </span>
                                                            <span> {order.final_get_amount} {order.get_currency}</span>
                                                        </td>
                                                        <td>
                                                            <span> {order.user_name} </span>
                                                            <span  > {order.email} </span>
                                                            <span  > {order.phone} </span>
                                                            <span> UID: <span className=" link-primary" > {order.uid} </span></span>
                                                            <span>
                                                                {order.order_created_at ? (
                                                                    <>
                                                                        {moment(order.order_created_at).format('DD MMM YYYY')},
                                                                        <small> {moment(order.order_created_at).format('h:mm:ss a')} </small>
                                                                    </>
                                                                ) : "------"}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span> {order.payment_details?.utrNumber || "------"} </span>
                                                            <span> {order.payment_details?.paymentMode || "------"}  </span>
                                                            <span>
                                                                {order.payment_details?.utrAddedAt ? (
                                                                    <>
                                                                        {moment(order.payment_details?.utrAddedAt).format('DD MMM YYYY')},
                                                                        <small> {moment(order.payment_details?.utrAddedAt).format('h:mm:ss a')} </small>
                                                                    </>
                                                                ) : "------"}
                                                            </span>
                                                            <span>  {order.payment_details?.remark || "------"}    </span>
                                                            <span>
                                                                <div class="d-flex align-items-center mt-1">
                                                                    <img src={order.payment_details?.attachment || "assets/img/blank_img.png"} class="rounded-lg me-2" alt="" width="24"
                                                                        onError={(e) => {
                                                                            e.target.onerror = null;
                                                                            e.target.src = "assets/img/blank_img.png";
                                                                        }} />
                                                                </div>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                <b className={`badge badge-sm mb-1 ms-0 justify-content-end ${order?.status === 'Completed' ? "bg-success" : order?.status === 'Cancelled By User' ? "bg-danger" : order?.status === 'Rejected' ? "bg-danger" : order.status === "Pending" ? order.amount_deposited === true ? "bg-success" : "bg-warning" : order?.status === 'Refund Initiated' ? "bg-primary" : order?.status === "System Cancelled" ? "bg-danger" : "bg-success"}`}
                                                                >
                                                                    {order.status === "Pending" ? order.amount_deposited === true ? "Paid" : "UnPaid" : order.status}
                                                                </b>
                                                            </span>
                                                            <span>
                                                                {order.status_updated_at ? (
                                                                    <>
                                                                        {moment(order?.status_updated_at).format('DD MMM YYYY')},
                                                                        <small>{moment(order?.status_updated_at).format('h:mm:ss a')} </small>
                                                                    </>
                                                                ) : "------"}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-3 w-auto justify-content-start ms-auto" >
                                                                <button className="btn btn-sm btn-success" type="button" onClick={() => handleUpdateStatus(order?.user_id, order?._id, "Completed")} disabled={order?.status !== 'Pending' || order.amount_deposited !== true}> Approve </button>


                                                                <button className="btn btn-sm btn-danger" type="button" onClick={() => handleUpdateStatus(order?.user_id, order?._id, "Rejected")} disabled={order?.status !== 'Pending'}> Reject </button>

                                                                <div className="dropdown w-auto d-inline-block">
                                                                    <button type="button" className="btn btn-blank btn-sm p-0" data-bs-toggle="dropdown" fdprocessedid="1s52ls" aria-expanded="false">
                                                                        <i className="ri-more-2-fill"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu">
                                                                        {
                                                                            order?.status === 'Pending' &&
                                                                            <span className="dropdown-item cursor-pointer" onClick={() => handleUpdateStatus(order?.user_id, order?._id, "Refund Initiated")}>Initiate Refund   </span>
                                                                        }

                                                                        {
                                                                            order?.status === 'Refund Initiated' &&
                                                                            <span className="dropdown-item cursor-pointer" onClick={() => handleUpdateRefund(order)}> Process Refund    </span>
                                                                        }

                                                                        <span className="dropdown-item cursor-pointer" onClick={() => { setIsOpenModel(true); setOrderDetails(order) }}> View Order Details   </span>
                                                                        <span className="dropdown-item cursor-pointer"  >  Download Receipt   </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <p className="text-center mt-3">No Data Available</p>
                                    )}

                                    {
                                        buySellData?.length > 0 &&
                                        <div className="hVPalX gap-2" >
                                            <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                            <div className="sc-eAKtBH gVtWSU">
                                                <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                        <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                    </svg>
                                                </button>
                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>
                                                </button>

                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>
                                                </button>
                                                <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                        <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {
                isOpenModel &&
                <div>
                    <div className="modal ccc_modal show d-block" >
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content p-0  ">
                                <button type="button" class=" btn-sm btn-icon btn-dark modal-close" onClick={() => setIsOpenModel(false)}><i class="ri-close-large-line"></i> </button>
                                <div className="modal-body">
                                    <div className="row g-0" >
                                        <div className="col-lg-6" >
                                            <div className="oh_left" >
                                                <div className={`oh_top ${orderDetails?.status === 'Completed' ? " bg-success" : orderDetails?.status === 'Cancelled By User' ? "bg-danger" : orderDetails?.status === 'Rejected' ? "bg-danger" : orderDetails?.status === 'Pending' ? "bg-warning" : orderDetails?.status === 'Refund Initiated' ? "bg-primary" : orderDetails?.status === "System Cancelled" ? "bg-danger" : "bg-success"}`}>
                                                    <div className="ot_top" >
                                                        <span className="" > {orderDetails.order_id} </span>
                                                        <span>
                                                            {orderDetails.status}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="ot_body" >
                                                    <div>
                                                        <span className={orderDetails.side === "BUY" ? "text-success" : "text-danger"} > {orderDetails.side}</span> | <span> Total amount </span>
                                                        <h6 className="mb-0"> {orderDetails.pay_amount} <sub>{orderDetails.pay_currency}</sub> </h6>
                                                    </div>
                                                    <div class="d-flex align-items-center gap-1 w-auto justify-content-end ms-auto">
                                                        <button class="btn btn-sm btn-success" type="button" onClick={() => handleUpdateStatus(orderDetails?.user_id, orderDetails?._id, "Completed")} disabled={orderDetails?.status !== 'Pending'} > Approve </button>
                                                        <button class="btn btn-sm btn-danger" type="button" onClick={() => handleUpdateStatus(orderDetails?.user_id, orderDetails?._id, "Rejected")} disabled={orderDetails?.status !== 'Pending'}> Reject </button>

                                                        <div class="dropdown w-auto d-inline-block">
                                                            <button type="button" class="btn btn-blank btn-xs p-0" data-bs-toggle="dropdown" fdprocessedid="1s52ls" aria-expanded="false">
                                                                <i class="ri-more-2-fill"></i>
                                                            </button>
                                                            <div class="dropdown-menu">

                                                                {
                                                                    orderDetails?.status === 'Pending' &&
                                                                    <span className="dropdown-item cursor-pointer" onClick={() => handleUpdateStatus(orderDetails?.user_id, orderDetails?._id, "Refund Initiated")}>Initiate Refund   </span>
                                                                }

                                                                {
                                                                    orderDetails?.status === 'Refund Initiated' &&
                                                                    <span className="dropdown-item cursor-pointer" onClick={() => handleUpdateRefund(orderDetails)}> Process Refund    </span>
                                                                }

                                                                <span class="dropdown-item cursor-pointer"> Download Receipt </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>




                                                <div className="oh_body">
                                                    <img src="assets/img/logo.svg" className="wt_mark" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between" >
                                                        <h6> Order Details </h6>
                                                        <div className="oh_data text-end">
                                                            <p className="mb-0">  Final Amonut:<br />  {orderDetails.final_pay_amount} <small>{orderDetails.pay_currency}</small> </p>
                                                        </div>
                                                    </div>
                                                    <div className="row g-1">
                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted"> Order Currency </span>
                                                                <p className="mb-0"> {orderDetails.base_currency + "/" + orderDetails.quote_currency} </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" > Rate  </span>
                                                                <p className="mb-0"> {orderDetails.price} {orderDetails.quote_currency}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" > Quantity </span>
                                                                <p className="mb-0">  {orderDetails.final_get_amount} {orderDetails.base_currency} </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" > Order Type   </span>
                                                                <p className="mb-0"> {orderDetails.bulk_order === true ? "Bulk Order" : "Normal"}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" > Fee ({orderDetails?.qbs_fees}%) </span>
                                                                <p className="mb-0">  {orderDetails.qbs_fees_amount} {orderDetails.quote_currency}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" > TDS ({orderDetails?.tds}%)   </span>
                                                                <p className="mb-0">  {orderDetails.tds_amount} {orderDetails.quote_currency} </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" >Order Created Time   </span>
                                                                <p className="mb-0"> {moment(orderDetails.order_created_at).format('DD MMM YYYY')}, <br />
                                                                    <small> {moment(orderDetails.order_created_at).format('h:mm:ss a')}</small> </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" >Order Paid Time   </span>
                                                                <p className="mb-0"> {moment(orderDetails.amount_deposited_at).format('DD MMM YYYY')}, <br />
                                                                    <small> {moment(orderDetails.amount_deposited_at).format('h:mm:ss a')}</small> </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" >Order Remind Time</span>
                                                                {
                                                                    orderDetails?.payment_details?.remindedAt &&
                                                                    <p className="mb-0">
                                                                        {moment(orderDetails.payment_details.remindedAt).format('DD MMM YYYY')}, <br />
                                                                        <small> {moment(orderDetails.payment_details.remindedAt).format('h:mm:ss a')}</small>
                                                                    </p>
                                                                }

                                                            </div>
                                                        </div>

                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" >Order Updatd Time   </span>
                                                                <p className="mb-0"> {moment(orderDetails.status_updated_at).format('DD MMM YYYY')}, <br />
                                                                    <small> {moment(orderDetails.status_updated_at).format('h:mm:ss a')}</small> </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" > Order Reff. No   </span>
                                                                <p className="mb-0">  {orderDetails.ref_no} </p>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {
                                                        orderDetails.use_available &&

                                                        <>
                                                            <hr />
                                                            <h6> Deduct From Wallet </h6>
                                                            <div className="row g-1">

                                                                <div className="col-4" >
                                                                    <div className="oh_data" >
                                                                        <span className="text-muted"> Payment Type </span>
                                                                        <p className="mb-0">From Wallet</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4" >
                                                                    <div className="oh_data" >
                                                                        <span className="text-muted"> Paid Amount </span>
                                                                        <p className="mb-0">{orderDetails?.pay_amount - orderDetails?.inr_deposit_amount} </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4" >
                                                                    <div className="oh_data" >
                                                                        <span className="text-muted"> Remaining Amount </span>
                                                                        <p className="mb-0">{orderDetails?.inr_deposit_amount} </p>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </>
                                                    }
                                                    <hr />
                                                    <h6> Payment Details </h6>
                                                    <div className="row g-1">
                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted"  > Payment Type </span>
                                                                <p className="mb-0">{orderDetails?.payment_details?.paymentType}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" > Payment Method </span>
                                                                <p className="mb-0">  {orderDetails?.payment_details?.paymentMode}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" > Payment Details </span>
                                                                <p className="mb-0"> Name : Unknown <br /> Ac. No: 01321352413521<br /> IFSC:    1SA32DF1  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" > UTR No.   </span>
                                                                <p className="mb-0"> {orderDetails?.payment_details?.utrNumber}  </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" > Reff. No   </span>
                                                                <p className="mb-0"> {orderDetails?.payment_details?.refNumber}  </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-4 Attachment">
                                                            <div className="oh_data">
                                                                <span className="text-muted">Attachment</span>
                                                                <p className="mb-0">
                                                                    {
                                                                        orderDetails?.payment_details?.attachment ?
                                                                            <button
                                                                                className="btn btn-outline-dark btn-xs"
                                                                                type="button"
                                                                                onClick={() => handleOpen(orderDetails?.payment_details?.attachment)}
                                                                            >
                                                                                View
                                                                            </button>
                                                                            : "---"
                                                                    }
                                                                </p>
                                                            </div>
                                                            {isOpen && (
                                                                <Lightbox
                                                                    open={isOpen}
                                                                    close={() => setIsOpen(false)}
                                                                    slides={[
                                                                        {
                                                                            src: fileUrl.endsWith(".pdf")
                                                                                ? `https://docs.google.com/gview?url=${fileUrl}&embedded=true`
                                                                                : fileUrl,
                                                                        },
                                                                    ]}
                                                                />
                                                            )}
                                                        </div>

                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" > UTR Added At </span>
                                                                <p className="mb-0">
                                                                    {moment(orderDetails?.payment_details?.utrAddedAt).format('DD MMM YYYY')}, <br />
                                                                    <small> {moment(orderDetails?.payment_details?.utrAddedAt).format('h:mm:ss a')}</small>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-8" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" > User Remark </span>
                                                                <p className="mb-0">{orderDetails?.payment_details?.remark}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" > UTR Added By  </span>
                                                                <p className="mb-0">{orderDetails?.payment_details?.utrAddedBy} </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-8" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" > Admin Remark   </span>
                                                                <p className="mb-0"> {orderDetails?.payment_details?.remark}  </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        orderDetails?.status === "Refund Processed" &&
                                                        <>
                                                            <hr />
                                                            <h6> Refund Details </h6>
                                                            {orderDetails?.refund_details?.map((refund, index) => (
                                                                <>
                                                                    <div className="row g-1">
                                                                        <div className="col-6">
                                                                            <div className="oh_data">
                                                                                <span className="text-muted">Refund Initiated At</span>

                                                                                <p className="mb-0">
                                                                                    {moment(refund.timestamp).format('DD MMM YYYY')}, <br />
                                                                                    <small> {moment(refund.timestamp).format('h:mm:ss a')}</small>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="oh_data">
                                                                                <span className="text-muted">Initiated By</span>
                                                                                <p className="mb-0">{refund.admin_name}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="oh_data">
                                                                                <span className="text-muted">Remark</span>
                                                                                <p className="mb-0">{refund.refund_remark}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="oh_data">
                                                                                <span className="text-muted">Refund Mode</span>
                                                                                <p className="mb-0">{refund.refund_mode}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                </>
                                                            ))}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6" >
                                            <div className="oh_right" >
                                                <div className="or_top" >
                                                    <div className="oh_user" >
                                                        <img src="assets/img/dummy.png" className="u_img img-fluid" alt="" />
                                                        <span className="">{orderDetails?.user_name}<small>{orderDetails?.user_id}</small> </span>
                                                    </div>
                                                    <div className="or_action" >
                                                        <button className="btn btn-xs btn-secondary"> Block </button>
                                                        <button className="btn btn-xs btn-secondary"> Order History </button>
                                                    </div>
                                                </div>
                                                <div className="or_body" >
                                                    <div className="row g-2">
                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted"  > 30d Trades   </span>
                                                                <p className="mb-0"> 2 </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" > 30d Completion  Rate </span>
                                                                <p className="mb-0"> USDT </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-4" >
                                                            <div className="oh_data" >
                                                                <span className="text-muted" > Avg. Pay Time Type </span>
                                                                <p className="mb-0"> Currency (USDT/INR) </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="my-0" />

                                                {/* modal chat */}
                                                <div class="card chat-sm ">
                                                    <div class="card-body ">
                                                        <div class="messages bg_watermark">
                                                            <div class="ag_status">
                                                                <p>You are currently chating with - Unknown Agent</p>
                                                            </div>
                                                            <div class=" clip received">
                                                                <div class="text"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. <small className="time recived_time" >12:11</small> </div>

                                                            </div>
                                                            <div class=" clip sent">
                                                                <div class="text"> hen an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchan
                                                                    <small className="time send_time" >12:11</small>
                                                                </div>
                                                            </div>
                                                            <div class=" clip received">
                                                                <div class="text"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. <small className="time recived_time" >12:11</small> </div>

                                                            </div>
                                                            <div class=" clip sent">
                                                                <div class="text"> hen an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchan
                                                                    <small className="time send_time" >12:11</small>
                                                                </div>
                                                            </div>
                                                            <div class=" clip received">
                                                                <div class="text"> sdfdsf dd_search</div>
                                                            </div>
                                                            <div class=" clip sent">
                                                                <div class="text"> sdfdsf dd_search</div>
                                                            </div>
                                                            <div class=" clip sent">
                                                                <div class="text"> sdfdsf dd_search</div>
                                                            </div>
                                                            <div class=" clip received">
                                                                <div class="text"> sdfdsf dd_search</div>
                                                            </div>
                                                            <div class=" clip sent">
                                                                <div class="text"> sdfdsf dd_search</div>
                                                            </div>
                                                            <div class=" clip received">
                                                                <div class="text"> sdfdsf dd_search</div>
                                                            </div>
                                                            <div class=" clip sent">
                                                                <div class="text"> sdfdsf dd_search</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card-footer ">
                                                        <div class="bottom">
                                                            <div class="cup">
                                                                <input type="text" id="message" placeholder="Message..." name="message" />
                                                                <div class="chat_action">
                                                                    <button class="btn_attach  btn-icon" fdprocessedid="jmahaf">
                                                                        <input type="file" />
                                                                        <span class="ac_alert"></span>
                                                                        <i class="ri-link"></i>
                                                                    </button>
                                                                    <button class="btn btn-primary send"  >
                                                                        <i class="ri-send-plane-fill ri-xl me-2"></i>Send </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div>
            }





        </div>
    )
}

export default BuySellHistory;