import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

const CoinListing = () => {

    const navigate = useNavigate();
    const [coinListData, setCoinListData] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [skip, setSkip] = useState(0);
    const [totalDataLength, setTotalDataLength] = useState(0);
    const limit = 10;


    const linkFollow = (row) => {
        return (
            <button className="verifybtn" onClick={() => navigate('/listing_coin_details', { state: { row } })}>
                <i className="ri-eye-line"></i>
            </button>
        );
    };

    const columns = [
        {
            name: "DateTime",
            wrap: true,
            selector: row => {
                const createdAt = row?.createdAt ? moment(row.createdAt) : null;
                return createdAt ? (
                    <span>
                        {createdAt.format("DD MMMM YYYY")}
                        <br />
                        <small>{createdAt.format("h:mm:ss a")}</small>
                    </span>
                ) : (
                    "-------"
                );
            },
        },
        { name: "User Name", selector: row => row.full_name || "-------", sortable: true, },
        { name: "EmailId", wrap: true, selector: row => row.email, sortable: true, },
        { name: "Mobile No.", selector: row => row.phone, sortable: true, },
        { name: "Action", selector: linkFollow, },

    ];

    const handleData = async (skip, limit, searchKey) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.getCoinListingList(skip, limit, searchKey);
            if (result.success) {
                setCoinListData(result.data || []);
                setTotalDataLength(result?.data?.totalCount || 0);
            } else {
                setCoinListData([]);
            }
        } catch (error) {
            alertErrorMessage(error);
            setCoinListData([]);
        } finally {
            LoaderHelper.loaderStatus(false);
        }
    };

    useEffect(() => {
        handleData(skip, limit, searchKey);
    }, [skip, limit, searchKey]);


    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchKey(searchTerm);
    };

    const handlePagination = (action, setSkip, skip, limit, totalDataLength) => {
        switch (action) {
            case "prev":
                if (skip - limit >= 0) setSkip(skip - limit);
                break;
            case "next":
                if (skip + limit < totalDataLength) setSkip(skip + limit);
                break;
            case "first":
                setSkip(0);
                break;
            case "last":
                setSkip(Math.max(totalDataLength - limit, 0));
                break;
            default:
                break;
        }
    };

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header ">
                    <div className="container-xl px-4">
                        <div className="page-header-content">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-12">
                                    <div className="d-flex justify-content-between" >
                                        <h1 className="page-header-title">
                                            Coin List
                                        </h1>

                                        {
                                            coinListData?.length > 0 &&
                                            <div className="dropdown">
                                                <button className="btn btn-dark btn-sm dropdown-toggle" type="button" >
                                                    <i className="fa fa-download me-2"></i>
                                                    <CSVLink data={coinListData} className="text-white" filename={`coinlist.csv`}>
                                                        Export as CSV
                                                    </CSVLink>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4">

                    <div className="tab-content" id="nav-tabContent">
                        <div className="card mb-4 tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <div className="card-header pb-0">
                                <ul className="nav nav-tabs nav-tabs-custom" id="pills-tab" role="tablist">
                                    <li className="nav-item mb-2" role="presentation">
                                        Coin List
                                    </li>
                                </ul>
                            </div>
                            <div className="search-header">
                                <div className="dd_search">
                                    <input type="search" placeholder="Search here..." name="searchKey" id="searchKey" value={searchKey} onChange={handleSearch} />
                                    <i className="fas fa-search"></i>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive" width="100%">
                                    <DataTable columns={columns} data={coinListData}
                                        noDataComponent={
                                            <p> <img src="/assets/img/not_found.svg" width="300" className='img-fluid mt-5' alt='' /> </p>
                                        }
                                    />
                                    {
                                        coinListData?.length > 0 &&
                                        <div className="hVPalX gap-2" >
                                            <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                            <div className="sc-eAKtBH gVtWSU">
                                                <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first', setSkip, skip, limit, totalDataLength)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                        <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                    </svg>
                                                </button>
                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev', setSkip, skip, limit, totalDataLength)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>
                                                </button>

                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next', setSkip, skip, limit, totalDataLength)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>
                                                </button>
                                                <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last', setSkip, skip, limit, totalDataLength)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                        <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
};
export default CoinListing;