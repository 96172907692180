import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';

const ViewSubadmin = () => {
  const location = useLocation()
  const navigate = useNavigate();

  const subadminData = location.state;

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header ">
          <div className="container-xl px-4">
            <div className="page-header-content">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <h1 className="page-header-title">
                    <Link to="/sub_admin_list" className="page-header-icon">
                      <i className="fa fa-arrow-left"></i>
                    </Link>
                    Admin Details
                  </h1>
                </div>
                <div className="col-auto">
                  <button type='button' class="btn btn-black" onClick={() => navigate('/edit_sub_admin', { state: subadminData })}>
                    <i class="ri-edit-line me-2"></i> Edit Admin
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4">
          <div className='row' >
            <div className='col-lg-4' >
              <div className="card mb-4">
                <div className='card-header' >
                  <h5 className='mb-0' >Admin Details</h5>
                </div>
                <div className="card-body row justify-content-center py-4 py-lg-5">
                  <form className='col-lg-12' >
                    <div className="row gy-3 d-flex justify-content-evenly">
                      <div className="col-md-12">
                        <label className="small mb-1" htmlFor="inputFirstName">Full Name <em>*</em></label>
                        <input className="form-control  form-control-solid" id="inputFirstName" type="text" name='firstName' Value="Name Here" disabled />
                      </div>
                      <div className="col-md-12">
                        <label className="small mb-1" htmlFor="inputNumber">Mobile Number   <em>*</em> </label>
                        <input className="form-control form-control-solid" id="inputNumber" type="text" name='number' Value="Number Here" disabled />
                      </div>

                      <div className="col-md-12">
                        <label className="small mb-1" htmlFor="inputEmailAddress">Email</label>
                        <input className="form-control form-control-solid" id="inputEmailAddress" name='email' type="email" Value="Name Here" disabled />
                      </div>
                      <div className="col-md-12" >
                        <label className="small mb-1" htmlFor="inputLocation">Permissions</label>
                        <div className='permissions_row' >
                          <span className='badge badge-lg bg-green-lite '> <i class="ri-check-line me-2"></i> KYC Managment  </span>
                          <span className='badge badge-lg bg-green-lite '> <i class="ri-check-line me-2"></i> Currency Managment  </span>
                          <span className='badge badge-lg bg-green-lite '> <i class="ri-check-line me-2"></i>   Currency Pair Managment   </span>
                          <span className='badge badge-lg bg-green-lite '> <i class="ri-check-line me-2"></i>   Fund Deposit   </span>
                          <span className='badge badge-lg bg-green-lite '> <i class="ri-check-line me-2"></i>    Fund Withdraw    </span>
                          <span className='badge badge-lg bg-green-lite '> <i class="ri-check-line me-2"></i>   Accounting   </span>
                          <span className='badge badge-lg bg-green-lite '> <i class="ri-check-line me-2"></i>   Orderbook   </span>
                          <span className='badge badge-lg bg-green-lite '> <i class="ri-check-line me-2"></i>   User Support     </span>
                          <span className='badge badge-lg bg-green-lite '> <i class="ri-check-line me-2"></i>   Nodal Officer   </span>
                          <span className='badge badge-lg bg-green-lite '> <i class="ri-check-line me-2"></i>  CEO Desk   </span>
                        </div>
                      </div>


                    </div>

                    {/* <button className="btn btn-indigo btn-block w-100 mt-4" type="button"  > Submit Details </button> */}
                  </form>
                </div>
              </div>
            </div>
            <div className='col-lg-8' >
              <div className="card mb-4">
                <div className='card-header' >
                  <h5 className='mb-0' > Admin Activity  </h5>
                </div>

                <div className="card-body py-4">
                  <div className='custom-tab-1' >
                    <ul class="nav nav-pills justify-content-start mb-4" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a href="#navpills2-1" class="nav-link active" data-bs-toggle="tab" aria-expanded="false" aria-selected="true" role="tab"> All  </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a href="#navpills2-2" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1"> KYC Managment  </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a href="#navpills2-3" class="nav-link" data-bs-toggle="tab" aria-expanded="true" aria-selected="false" role="tab" tabindex="-1"> Currency Managment  </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a href="#navpills2-3" class="nav-link" data-bs-toggle="tab" aria-expanded="true" aria-selected="false" role="tab" tabindex="-1"> Currency Pair    </a>
                      </li>
                      <li>
                        <div className="form-group" >
                          <select className="form-control form-select " >
                            <option> More. </option>
                            <option>    Fund Deposit </option>
                            <option>   Fund Withdraw  </option>
                            <option>  Accounting </option>
                            <option>  Orderbook </option>
                            <option>  User Support L.1  </option>
                            <option>  Nodal Officer </option>
                            <option> CEO Desk </option>
                          </select>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="table-responsive" >
                    <table class="table table-responsive-md" >
                      <thead >
                        <tr >
                          <th >
                            <strong >#</strong>
                          </th>
                          <th >
                            <strong >PATIENT</strong>
                          </th>
                          <th >
                            <strong >DR NAME</strong>
                          </th>
                          <th >
                            <strong >DATE</strong>
                          </th>
                          <th >
                            <strong >STATUS</strong>
                          </th>
                          <th >
                            <strong >PRICE</strong>
                          </th>
                          <th ></th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr >
                          <td >
                            <strong >01</strong>
                          </td>
                          <td >Mr. Bobby</td>
                          <td >Dr. Jackson</td>
                          <td >01 August 2020</td>
                          <td >
                            <span class="badge light badge-success" >Successful</span>
                          </td>
                          <td >$21.56</td>
                          <td >
                            <div class="dropdown" >
                              <button type="button" class="btn btn-success light sharp" data-bs-toggle="dropdown" fdprocessedid="avh92">
                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" >
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" >
                                    <rect x="0" y="0" width="24" height="24" ></rect>
                                    <circle fill="#000000" cx="5" cy="12" r="2" ></circle>
                                    <circle fill="#000000" cx="12" cy="12" r="2" ></circle>
                                    <circle fill="#000000" cx="19" cy="12" r="2" ></circle>
                                  </g>
                                </svg>
                              </button>
                              <div class="dropdown-menu" >
                                <a class="dropdown-item" href="#" >Edit</a>
                                <a class="dropdown-item" href="#" >Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr >
                          <td >
                            <strong >02</strong>
                          </td>
                          <td >Mr. Bobby</td>
                          <td >Dr. Jackson</td>
                          <td >01 August 2020</td>
                          <td >
                            <span class="badge light badge-danger" >Canceled</span>
                          </td>
                          <td >$21.56</td>
                          <td >
                            <div class="dropdown" >
                              <button type="button" class="btn btn-danger light sharp" data-bs-toggle="dropdown" fdprocessedid="7xyivk">
                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" >
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" >
                                    <rect x="0" y="0" width="24" height="24" ></rect>
                                    <circle fill="#000000" cx="5" cy="12" r="2" ></circle>
                                    <circle fill="#000000" cx="12" cy="12" r="2" ></circle>
                                    <circle fill="#000000" cx="19" cy="12" r="2" ></circle>
                                  </g>
                                </svg>
                              </button>
                              <div class="dropdown-menu" >
                                <a class="dropdown-item" href="#" >Edit</a>
                                <a class="dropdown-item" href="#" >Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr >
                          <td >
                            <strong >03</strong>
                          </td>
                          <td >Mr. Bobby</td>
                          <td >Dr. Jackson</td>
                          <td >01 August 2020</td>
                          <td >
                            <span class="badge light badge-warning" >Pending</span>
                          </td>
                          <td >$21.56</td>
                          <td >
                            <div class="dropdown" >
                              <button type="button" class="btn btn-warning light sharp" data-bs-toggle="dropdown" fdprocessedid="qs3thh">
                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" >
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" >
                                    <rect x="0" y="0" width="24" height="24" ></rect>
                                    <circle fill="#000000" cx="5" cy="12" r="2" ></circle>
                                    <circle fill="#000000" cx="12" cy="12" r="2" ></circle>
                                    <circle fill="#000000" cx="19" cy="12" r="2" ></circle>
                                  </g>
                                </svg>
                              </button>
                              <div class="dropdown-menu" >
                                <a class="dropdown-item" href="#" >Edit</a>
                                <a class="dropdown-item" href="#" >Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default ViewSubadmin