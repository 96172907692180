import React, { useState } from "react";
import AuthService from "../../../../../services/ApiIntegration/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../../CustomComponent/CustomAlert";
import { useNavigate } from "react-router-dom";

const CreateTicket = () => {

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phoneNumber: "",
        subject: "",
        description: "",
        attachment: null,
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState("");
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === "file" ? files[0] : value,
        }));
        setError("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.fullName.trim()) return setError("Full Name is required.");
        if (!formData.email.trim()) return setError("Email is required.");
        if (!emailRegex.test(formData.email)) return setError("Enter a valid email address.");
        if (!formData.phoneNumber.trim()) return setError("Mobile Number is required.");
        if (!phoneRegex.test(formData.phoneNumber)) return setError("Enter a valid 10-digit mobile number.");
        if (!formData.subject) return setError("Please select a subject.");
        if (!formData.description.trim()) return setError("Description is required.");
        if (!formData.attachment) return setError("Please attach a file.");
        setLoading(true);
        try {
            const formDataObj = new FormData();
            formDataObj.append('full_name', formData.fullName);
            formDataObj.append('email', formData.email);
            formDataObj.append('phone', formData.phoneNumber);
            formDataObj.append('subject', formData.subject);
            formDataObj.append('description', formData.description);
            formDataObj.append('support_ticket_attachment', formData.attachment);
            const result = await AuthService.AddSupportTicket(formDataObj);
            if (result.success) {
                alertSuccessMessage(result?.message);
                navigate('/support_ticket_list');
                setLoading(false);
                setFormData({
                    fullName: "",
                    email: "",
                    phoneNumber: "",
                    subject: "",
                    description: "",
                    attachment: null,
                });
                setError("");
            } else {
                alertErrorMessage(result);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            alertErrorMessage(error.message || "Something went wrong.");
        }
    };


    return (
        <div id="layoutSidenav_content">
            <main>
                <form className="form-data" onSubmit={handleSubmit}>
                    <header className="page-header">
                        <div className="container-xl px-4">
                            <div className="page-header-content pb-2">
                                <div className="row align-items-center justify-content-between">
                                    <h6 className="page-header-title mb-3">
                                        <small>New Support Ticket</small>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </header>

                    <div className="container-xl px-4">
                        <div className="row justify-content-center">
                            <div className="col-xl-12">
                                <div className="card mb-4">
                                    <div className="card-body py-4">
                                        <div className="profile_data ac_body px-4">
                                            <div className="row">
                                                {/* Full Name */}
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">Full Name</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${error === "Full Name is required." ? "is-invalid" : ""}`}
                                                        name="fullName"
                                                        value={formData.fullName}
                                                        onChange={handleChange}
                                                    />
                                                    {error === "Full Name is required." &&
                                                        <div className="invalid-feedback mb-0">
                                                            <small>{error}</small>
                                                        </div>}
                                                </div>

                                                {/* Email */}
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">Email Address</label>
                                                    <input
                                                        type="email"
                                                        className={`form-control ${(error === "Email is required." || error === "Enter a valid email address.") ? "is-invalid" : ""}`}
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                    />
                                                    {(error === "Email is required." || error === "Enter a valid email address.") &&
                                                        <div className="invalid-feedback mb-0">
                                                            <small>{error}</small>
                                                        </div>}
                                                </div>

                                                {/* Mobile Number */}
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">Mobile Number</label>
                                                    <input
                                                        type="tel"
                                                        className={`form-control ${(error === "Mobile Number is required." || error === "Enter a valid 10-digit mobile number.") ? "is-invalid" : ""}`}
                                                        name="phoneNumber"
                                                        value={formData.phoneNumber}
                                                        onChange={handleChange}
                                                        maxLength={10}
                                                    />
                                                    {(error === "Mobile Number is required." || error === "Enter a valid 10-digit mobile number.") &&
                                                        <div className="invalid-feedback mb-0">
                                                            <small>{error}</small>
                                                        </div>}
                                                </div>

                                                {/* Subject */}
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">Subject</label>
                                                    <select
                                                        className={`form-control form-select ${error === "Please select a subject." ? "is-invalid" : ""}`}
                                                        name="subject"
                                                        value={formData.subject}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="" disabled>
                                                            Select Subject
                                                        </option>
                                                        <option value="Unable to Sign up">Unable to Sign up</option>
                                                        <option value="Unable to Download Mobile App">Unable to Download Mobile App</option>
                                                        <option value="Unable to Login Account.">Unable to Login Account.</option>
                                                        <option value="Unable to Forgot Password.">Unable to Forgot Password.</option>
                                                        <option value="Frozen Account.">Frozen Account.</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    {error === "Please select a subject." && <div className="invalid-feedback mb-0">
                                                        <small>{error}</small>
                                                    </div>}
                                                </div>

                                                {/* Description */}
                                                <div className="col-lg-12 col-md-12 col-12 mb-3">
                                                    <label className="form-label mb-1">Description</label>
                                                    <textarea
                                                        rows="4"
                                                        placeholder="Type message here"
                                                        name="description"
                                                        className={`form-control ${error === "Description is required." ? "is-invalid" : ""}`}
                                                        value={formData.description}
                                                        onChange={handleChange}
                                                    />
                                                    {error === "Description is required." && <div className="invalid-feedback mb-0">
                                                        <small>{error}</small>
                                                    </div>}
                                                </div>

                                                {/* Attachments */}
                                                <div className="col-md-12 mb-3">
                                                    <label className="form-label">Attachments</label>
                                                    <input
                                                        type="file"
                                                        name="attachment"
                                                        className={`form-control ${error === "Please attach a file." ? "is-invalid" : ""}`}
                                                        onChange={handleChange}
                                                    />
                                                    {error === "Please attach a file." && <div className="invalid-feedback mb-0">
                                                        <small>{error}</small>
                                                    </div>}
                                                </div>

                                                {/* Submit Button */}
                                                <div className="col-md-12 mt-2">
                                                    <button className="btn w-auto px-5 btn-warning" type="submit">
                                                        {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            : <span> Submit </span>}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </main>
        </div>
    );
};

export default CreateTicket;
