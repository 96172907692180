import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import DataTable from "react-data-table-component";

const VerificationUpi = () => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [upiStatus, setUpiStatus] = useState("pending-upi-list");

  const linkFollow = (row) => {
    return (
      <button className="btn btn-success btn-xs me-2">
        <i class="fas fa-eye" ></i>
      </button>
    );
  };

  const columns = [
    { name: "Date", selector: row => moment(row?.createdAt).format("Do MMMM YYYY"), wrap: true },
    { name: "UserID", wrap: true, selector: row => row.userId, },
    { name: "UserName In Pan", wrap: true, selector: row => row.accountHolderName, },
    { name: "User Name In Bank", wrap: true, selector: row => row.userNameInBank, },
    { name: "Bank Name", selector: row => row.bankName, },
    { name: "Account Number", selector: row => row.accountNumber, },

    { name: "IFSC", wrap: true, selector: row => row.ifsc, },
    { name: "Branch Name", wrap: true, selector: row => row.branchName, },
    { name: "Action", selector: linkFollow, },
  ];

  const handleData = async (upiStatus) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getBankList(upiStatus).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setData(result.data.reverse());
          setAllData(result.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
      }
    });
  };


  function searchObjects(e) {
    const keysToSearch = ["accountHolderName", "userNameInBank", "accountNumber", "bankName", "ifsc", "branchName"];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = allData.filter(obj => {
      return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
    });
    setData(matchingObjects);
  }

  useEffect(() => {
    handleData(upiStatus);
  }, [upiStatus]);

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header ">
          <div className="container-xl px-4">
            <div className="page-header-content">
              <div className="row align-items-center justify-content-between">
                <div className="col-12">
                  <div className="d-flex justify-content-between" >
                    <h1 className="page-header-title">
                      UPI Verification 
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4">
          <div className="card mb-4">
            <div className="card-header pb-0">
              <ul className="nav nav-tabs nav-tabs-custom" id="pills-tab" role="tablist">

                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-Pending-tab" data-bs-toggle="pill" data-bs-target="#pills-Pending" type="button" role="tab" aria-controls="pills-Pending" aria-selected="false" onClick={() => setUpiStatus("pending-upi-list")}> Pending  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-Approved-tab" data-bs-toggle="pill" data-bs-target="#pills-Approved" type="button" role="tab" aria-controls="pills-Approved" aria-selected="false" onClick={() => setUpiStatus("approved-upi-list")}> Approved  </button>
                </li>
              </ul>
              <div className="dropdown">
                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  <i className="fa fa-download me-3"></i>Export
                </button>
                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp" >
                  <CSVLink data={data} className="dropdown-item">
                    Export as CSV
                  </CSVLink>
                </div>
              </div>
            </div>
            <div className="search-header">
              <div className="dd_search">
                <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" onChange={searchObjects} />
                <i class="fas fa-search"></i>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTable columns={columns} data={data} pagination />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
};
export default VerificationUpi;
