import React from "react";
import { Link } from "react-router-dom";

const PaymentDetails = ({ activeTab }) => {

    return (
        <div className={`tab-pane ${activeTab === 2 ? "active" : ""}`} >
            <div className="card-body py-4" >

                <div className="profile_data  ac_body  " >
                    <div className=" " >
                        <div className="custom-tab-2">
                            <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a href="#bankaccounts" id="bankaccounts-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true"> Bank Accounts  </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a href="#UPIids" id="UPIids-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> UPI Id's </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content">

                        {/* crypto Tab  */}
                        <div className="tab-pane active" id="bankaccounts" role="tabpanel" aria-labelledby="bankaccounts-tab">
                            <div className=" " >


                                <div className="profile_data  ac_body" >
                                    <h5 className="mb-4" >  Bank Accounts </h5>
                                    <ul className="nav custom-tabs mb-4  ">
                                        <li className="buysell-tab">
                                            <a className="active">  Verified Accounts  </a>
                                        </li>
                                        <li className="buysell-tab">
                                            <a className="">  Pending Accounts  </a>
                                        </li>
                                        <li className="buysell-tab">
                                            <a className="">   Inactive Accounts </a>
                                        </li>
                                    </ul>
                                    <div className="ac_lists" >
                                        <div className="ac_card" >
                                            <div className="row g-4 align-items-end">
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  Bank Name   </label><p>  HDFC BANK <span className="badge bg-dark" >Default</span> </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  Account Number   </label><p>  HDFC BANK  </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  IFSC Code </label><p> DFGV2FD31G </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label> Verification Date </label><p>  11 Nov. 2024    </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label> Status </label><p>   <span className="badge bg-success" > Verified </span> </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"> <Link to="/users_bank_details" className="btn btn-sm btn-primary" >View</Link> </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                        <div className="ac_card" >
                                            <div className="row g-4 align-items-end">
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  Bank Name   </label><p>  HDFC BANK  </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  Account Number   </label><p>  HDFC BANK  </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  IFSC Code </label><p> DFGV2FD31G </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label> Verification Date </label><p>  11 Nov. 2024    </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label> Status </label><p>   <span className="badge bg-success" > Verified </span>    </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"> <Link to="/users_bank_details" className="btn btn-sm btn-primary" >View</Link> </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                        <div className="ac_card" >
                                            <div className="row g-4 align-items-end">
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  Bank Name   </label><p>  HDFC BANK  </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  Account Number   </label><p>  HDFC BANK  </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  IFSC Code </label><p> DFGV2FD31G </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label> Applied Date </label><p> ----   </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label> Status </label><p>   <span className="badge bg-warning" > Pending </span>    </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"> <Link to="/users_bank_details" className="btn btn-sm btn-primary" >View</Link> </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                        <div className="ac_card" >
                                            <div className="row g-4 align-items-end">
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  Bank Name   </label><p>  HDFC BANK  </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  Account Number   </label><p>  HDFC BANK  </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  IFSC Code </label><p> DFGV2FD31G </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label> Deleted Date </label><p> ----   </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label> Status </label><p>   <span className="badge bg-danger" > Deleted </span>    </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"> <Link to="/users_bank_details" className="btn btn-sm btn-primary" >View</Link> </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* crypto Tab  */}
                        <div className="tab-pane" id="UPIids" role="tabpanel" aria-labelledby="UPIids-tab">
                            <div className="">
                                <div className="profile_data  ac_body" >
                                    <h5 className="mb-4" >  UPI ID </h5>
                                    <ul className="nav custom-tabs mb-4  ">
                                        <li className="buysell-tab">
                                            <a className="active">  Verified UPI's  </a>
                                        </li>
                                        <li className="buysell-tab">
                                            <a className="">   Deleted UPI's </a>
                                        </li>
                                    </ul>
                                    <div className="ac_lists" >
                                        <div className="ac_card" >
                                            <div className="row g-4 align-items-end">
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  KYC User Name     </label><p>  Unknown username  </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  UPI ID   </label><p>  asdasd@ybl <span className="badge bg-dark" >Default</span> </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>    UPI ID Status  </label><p className="text-success" > <small><i className="ri-shield-check-line"></i>  Valid</small>   </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  UPI Name </label><p> asdasdsad </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label> Name Match Status </label><p>   <span className="badge bg-success" > Verified </span> </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"> <Link to="/users_bank_details" className="btn btn-sm btn-danger" >Delete</Link> </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                        <div className="ac_card" >
                                            <div className="row g-4 align-items-end">
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  KYC User Name     </label><p>  Unknown username  </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  UPI ID   </label><p>  asdasd@ybl <span className="badge bg-dark" >Default</span> </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>    UPI ID Status  </label><p className="text-danger" > <small><i className="ri-spam-2-line"></i>  Invalid </small>   </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label>  UPI Name </label><p> asdasdsad </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"><label> Name Match Status </label><p>   <span className="badge bg-success" > Verified </span> </p></div>
                                                </div>
                                                <div className="col-lg-6 col-xl-2">
                                                    <div className="label-group"> <Link to="/users_bank_details" className="btn btn-sm btn-danger" >Delete</Link> </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
};

export default PaymentDetails;