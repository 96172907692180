import React, { useEffect, useState } from "react";
import { handleUpdateValue } from "../../../../services/Context/LimitContext";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import Swal from "sweetalert2";
import { alertErrorMessage } from "../../../CustomComponent/CustomAlert";

const DepositLimit = ({ activeTab, userLimitData, handleLimitData }) => {

    const [isActiveModel, setIsActiveModel] = useState(false);
    const [apiResponse, setApiResponse] = useState("");
    const [updatedValue, setUpdatedValue] = useState("");


    const [formData, setFormData] = useState({
        totalDepositYear: "",
        totalCashDepositYear: "",
        dailyDepositLimit: "",
        dailyCashDepositLimit: "",
    });

    useEffect(() => {
        if (updatedValue || userLimitData) {
            setFormData({
                totalDepositYear: updatedValue?.totalInrDeposit || userLimitData?.totalInrDeposit || "",
                totalCashDepositYear: updatedValue?.totalInrCashDeposit || userLimitData?.totalInrCashDeposit || "",
                dailyDepositLimit: updatedValue?.dailyInrDeposit || userLimitData?.dailyInrDeposit || "",
                dailyCashDepositLimit: updatedValue?.dailyInrCashDeposit || userLimitData?.dailyInrCashDeposit || "",
            });
        }
    }, [updatedValue, userLimitData]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
        setApiResponse("");
    };

    const handleActivatedModel = (name) => {
        setIsActiveModel(true);
        handleGetUpdatedValue();
    };


    const handleUpdateLimitRemark = async () => {
        try {
            const { isConfirmed } = await Swal.fire({
                title: "Are you sure ?",
                html: `
              <label for="remark">Enter Remark:</label>
              <textarea id="remark" class="form-control mt-2" rows="3" placeholder="Enter Remark"></textarea>
            `,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#e64942",
                cancelButtonColor: "#e7e7e7",
                confirmButtonText: "Yes, Update!",
                preConfirm: () => {
                    const remark = document.getElementById("remark").value.trim();
                    if (!remark) {
                        Swal.showValidationMessage("You need to enter a Remark!");
                    }
                    return remark;
                }
            });
            if (isConfirmed) {
                const remark = document.getElementById("remark").value.trim();
                const response = await AuthService.updateLimitRemark(remark);
                if (response.success) {
                    handleLimitData();
                    setIsActiveModel(false);
                    handleReset();
                    Swal.fire("Updated!", "User Limit Updated Successfully.", "success");
                } else {
                    Swal.fire("Error!", response || "Failed to Update User Limit.", "error");
                }
            }
        } catch (error) {
            Swal.fire("Error!", "Something went wrong. Please try again.", "error");
        }
    };


    const handleGetUpdatedValue = async () => {
        await AuthService.exchangeLimitSession().then(async (result) => {
            if (result.success) {
                setUpdatedValue(result?.data);
            }
        });
    };

    const handleReset = () => {
        setFormData({
            totalDepositYear: "",
            totalCashDepositYear: "",
            dailyDepositLimit: "",
            dailyCashDepositLimit: "",
        })
    }

    return (

        <div className={`tab-pane ${activeTab === "depositLimit" ? "active" : ""}`} >
            <div className="profile_data ac_body" >
                <div className="d-flex align-items-center justify-content-end mt-0 " >
                    {/* <h5 className="mb-0" > Overall Deposit Limits  </h5> */}
                    <button className="btn btn-warning btn-sm" type="button"
                        onClick={() => handleActivatedModel("Overall INR Deposit Limit")}> Update Limits  </button>
                </div>
                <div className="row g-4 mt-0">
                    <div className="col-lg-6" >
                        <div className="card h-100">
                            <div className="card-body py-4">
                                <div className="d-flex align-items-center justify-content-between mb-2" >
                                    <h5  > INR Deposit Limits  </h5>
                                </div>
                                <div className="row g-4" >
                                    <div className=" col-lg-12" >
                                        <div className="profile_data fc_details  ac_body " >
                                            <div className="d-flex align-items-center justify-content-between mb-4" >
                                                <h5 className="mb-0" > Overall Deposit Limit (1FY)  </h5>
                                            </div>
                                            <div className="row g-4">
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="label-group">
                                                        <label>Total</label>
                                                        <p>  {userLimitData?.totalInrDeposit} ₹  </p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="label-group">
                                                        <label>Total Cash Deposit</label>
                                                        <p>  {userLimitData?.totalInrCashDeposit} ₹  </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-lg-12" >
                                        <div className="profile_data fc_details  ac_body " >
                                            <div className="d-flex align-items-center justify-content-between mb-4" >
                                                <h5 className="mb-0" >  Daily Deposit Limit (24H)  </h5>
                                            </div>
                                            <div className="row g-4">
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="label-group">
                                                        <label>Total</label>
                                                        <p>  {userLimitData?.dailyInrDeposit} ₹  </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="label-group">
                                                        <label>Total Cash</label>
                                                        <p>  {userLimitData?.dailyInrCashDeposit} ₹  </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6" >
                        <div className="card h-100">
                            <div className="card-body py-4">
                                <div className="d-flex align-items-center justify-content-between mb-2" >
                                    <h5> Crypto Deposit  Limits </h5>                                  
                                </div>
                                <div className="row g-4" >
                                    <div className=" col-lg-12" >
                                        <div className="profile_data fc_details  ac_body " >
                                            <div className="d-flex align-items-center justify-content-between mb-4" >
                                                <h5 className="mb-0" > Overall Deposit Limit (1FY)  </h5>
                                            </div>
                                            <div className="row g-4">
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="label-group">
                                                        <label>Total</label>
                                                        <p>Unlimited</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-lg-12" >
                                        <div className="profile_data fc_details  ac_body " >
                                            <div className="d-flex align-items-center justify-content-between mb-4" >
                                                <h5 className="mb-0" >  Daily Deposit Limit (24H)  </h5>
                                            </div>
                                            <div className="row g-4">
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="label-group">
                                                        <label>Total</label>
                                                        <p>  Unlimited  </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {isActiveModel && (
                <>
                    <div className="modal ccc_modal show d-block" >
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content ">                              
                                <div className="modal-body p-0 row">
                                    <div className=" col-lg-5   " >
                                        <h6 className="mb-3" > INR Deposit Limits  </h6>
                                        <div className="">
                                            <div className="form-group mb-3">
                                                <label className="small mb-1" htmlFor="totalDepositYear">Total INR Deposit Limit (1FY) <em>*</em></label>
                                                <div className="otp_box">
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="totalDepositYear"
                                                        name="totalDepositYear"
                                                        type="text"
                                                        placeholder="Enter Amount"
                                                        value={formData.totalDepositYear}
                                                        onChange={handleChange}

                                                    />
                                                    <button className="btn btn-warning btn-xs" type="button" disabled={!formData.totalDepositYear}
                                                        onClick={() => handleUpdateValue(formData.totalDepositYear, "totalInrDeposit", handleGetUpdatedValue)}>
                                                        <span>Update</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="form-group  ">
                                                <label className="small mb-1" htmlFor="dailyDepositLimit">Daily INR Deposit Limit (24H)<em>*</em></label>
                                                <div className="otp_box">
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="dailyDepositLimit"
                                                        name="dailyDepositLimit"
                                                        type="text"
                                                        placeholder="Enter Amount"
                                                        value={formData.dailyDepositLimit}
                                                        onChange={handleChange}
                                                    />
                                                    <button className="btn btn-warning btn-xs" type="button" disabled={!formData.dailyDepositLimit}
                                                        onClick={() => handleUpdateValue(formData.dailyDepositLimit, "dailyInrDeposit", handleGetUpdatedValue)}>  Update  </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 text-center" >
                                        <hr className="dashed_hr hr_long" />
                                    </div>
                                    <div className="   col-lg-5 " >
                                        <h6 className="mb-3" > Crypto Deposit Limits  </h6>
                                        <div className="">
                                            <div className="form-group mb-3">
                                                <label className="small mb-1" htmlFor="totalCashDepositYear">Total Cash Deposit Limit (1FY)<em>*</em></label>
                                                <div className="otp_box">
                                                    <input
                                                        className="form-control form-control-solid"
                                                        placeholder="Enter Amount"
                                                        id="totalCashDepositYear"
                                                        name="totalCashDepositYear"
                                                        type="text"
                                                        value={formData.totalCashDepositYear}
                                                        onChange={handleChange}
                                                    />
                                                    <button className="btn btn-warning btn-xs" type="button" disabled={!formData.totalCashDepositYear}
                                                        onClick={() => handleUpdateValue(formData.totalCashDepositYear, "totalInrCashDeposit", handleGetUpdatedValue)}> Update  </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="form-group  ">
                                                <label className="small mb-1" htmlFor="dailyCashDepositLimit">Daily Cash Deposit Limit (24H)<em>*</em></label>
                                                <div className="otp_box">
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="dailyCashDepositLimit"
                                                        name="dailyCashDepositLimit"
                                                        type="number"
                                                        placeholder="Enter Amount"
                                                        value={formData.dailyCashDepositLimit}
                                                        onChange={handleChange}
                                                    />
                                                    <button className="btn btn-warning btn-xs" type="button" disabled={!formData.dailyCashDepositLimit}
                                                        onClick={() => handleUpdateValue(formData.dailyCashDepositLimit, "dailyInrCashDeposit", handleGetUpdatedValue)}> Update  </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" d-flex justify-content-center gap-3 flex-between w-100 mt-5">
                                        <button className="btn btn-primary btn-block px-5 btn-md " type="button" onClick={handleUpdateLimitRemark} disabled={!updatedValue}>
                                            APPLY TO USER
                                        </button>
                                        <button className="btn btn-secondary btn-block px-5  btn-md " type="button"
                                            onClick={() => { setIsActiveModel(false); setApiResponse("") }}>
                                            Cancel
                                        </button>

                                    </div>
                                    {apiResponse && <div className="text-danger">{apiResponse}  </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            )}

        </div>
    )
};

export default DepositLimit;