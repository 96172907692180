import React, { useEffect, useState } from "react";
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import copy from "copy-to-clipboard";
import { SlideshowLightbox } from "lightbox.js-react";


const UpgradeKycDetails = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { mergedData } = location.state || {};
  const [kycStep, setKycStep] = useState("step1");
  const [userKycDetails, setUserKycDetails] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [approvedRemark, setApprovedremark] = useState([]);
  const [showModelApprove, setShowModelApprove] = useState(false);
  const [copied, setCopied] = useState(false);
  const [userKycStatus, setUserKycStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [rejectRemarks, setRejectRemarks] = useState("");
  const [documentType, setDocumentType] = useState("");

  const [itrAttempt, setItrAttempt] = useState(null);
  const [bankAttempt, setBankAttempt] = useState(null);
  const [salaryAttempt, setSalaryAttempt] = useState(null);
  const [videoAttempt, setVideoAttempt] = useState(null);
  const [otherDocAttempt, setOtherDocAttempt] = useState(null);
  const [setpTwoAttemp, setStepTwoAttemp] = useState(null);
  const [setpThreeAttemp, setStepThreeAttemp] = useState(null);

  const [activeTabItrRejected, setActiveTabItrRejected] = useState(0);
  const [activeTabBankRejected, setActiveTabBankRejected] = useState(0);
  const [activeTabSalaryRejected, setActiveTabSalaryRejected] = useState(0);
  const [activeTabVideoRejected, setActiveTabVideoRejected] = useState(0);
  const [activeTabRejectedStepTwo, setActiveTabRejectedStepTwo] = useState(0);
  const [activeTabOtherDocRejected, setActiveTabOtherDocRejected] = useState(0);
  const [activeTabOtherDeclarattion, setActiveTabOtherDeclarattion] = useState(0);
  const [rejectedSteps, setRejectedSteps] = useState([]);
  const [mainRejectModel, setMainRejectModel] = useState(false);
  const [verificationRemark, setVerificationRemark] = useState("");


  const tabClickRejectedItr = (index, data) => {
    setActiveTabItrRejected(index);
    setItrAttempt(data);
  };

  const tabClickRejectedBank = (index, data) => {
    setActiveTabBankRejected(index);
    setBankAttempt(data);
  };

  const tabClickRejectedSalary = (index, data) => {
    setActiveTabSalaryRejected(index);
    setSalaryAttempt(data);
  };

  const tabClickRejectedVideo = (index, data) => {
    setActiveTabVideoRejected(index);
    setVideoAttempt(data);
  };

  const tabClickRejectedOtherDoc = (index, data) => {
    setActiveTabOtherDocRejected(index);
    setOtherDocAttempt(data);
  };


  const tabClickStepTwo = (index, data) => {
    setActiveTabRejectedStepTwo(index);
    setStepTwoAttemp(data);
  };


  const handleOtherDeclarattion = (index, data) => {
    setActiveTabOtherDeclarattion(index);
    setStepThreeAttemp(data);
  };


  useEffect(() => {
    setItrAttempt(userKycDetails?.rejectedItrData?.[activeTabItrRejected])
    setBankAttempt(userKycDetails?.rejectedBankData?.[activeTabBankRejected])
    setSalaryAttempt(userKycDetails?.rejectedSalarySlipData?.[activeTabSalaryRejected])
    setVideoAttempt(userKycDetails?.rejectedVideoKYCData?.[activeTabVideoRejected])
    setOtherDocAttempt(userKycDetails?.rejectedOtherDocData?.[activeTabOtherDocRejected])
    setStepTwoAttemp(userKycDetails?.otherStep2Data?.[activeTabRejectedStepTwo])
    setStepThreeAttemp(userKycDetails?.otherDeclarationData?.[activeTabOtherDeclarattion])

  }, [userKycDetails])


  const fetchUserDetails = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getUpgradeUserStepData(mergedData?.row?._id, kycStep).then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setUserKycDetails(result?.data)
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    }
    );
  };


  const handleGetApprovedRemark = async () => {
    await AuthService.getRemarkUpgrade(mergedData?.row?._id).then(
      async (result) => {
        if (result?.success) {
          try {
            setApprovedremark(result?.data?.approvedRemark);
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          alertErrorMessage(result.message);
        }
      }
    );
  };


  useEffect(() => {
    fetchUserDetails();
  }, [mergedData?.row?._id, kycStep])


  const handleClickStep = (step) => {
    setKycStep(step);
    if (step === "step3") {
      handleGetApprovedRemark();
    }
  };

  const handleCopy = (item) => {
    copy(item);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  const handleRejectDocument = (item) => {
    setDocumentType(item);
    setShowModel(true)
  };


  const storeStepRejectedReason = (rejectRemarks) => {
    if (!rejectRemarks) {
      setErrorMessage("Please enter a reason")
      return;
    }
    const newRemark = {
      docName: documentType,
      step: kycStep,
      remark: rejectRemarks,
    };
    setRejectedSteps((prev) => [...prev, newRemark]);
    setShowModel(false)
    setRejectRemarks("");
  };

  /*   const formatRejectedSteps = (rejectedSteps) => {
      
      const groupedSteps = {};
      rejectedSteps.forEach(({ step, docName, remark }) => {
        if (!docName || !remark) return; 
  
        if (!groupedSteps[step]) {
          groupedSteps[step] = { step, documents: [] };
        }
        groupedSteps[step].documents.push({ docName, remark });
      });
      return Object.values(groupedSteps).filter(item => item.documents.length > 0);
    };
   */

  const formatRejectedSteps = (rejectedSteps) => {
    const groupedSteps = {};
    rejectedSteps.forEach(({ step, docName, remark }) => {
      if (!remark) return;
      if (!groupedSteps[step]) {
        groupedSteps[step] = { step };
      }
      if (!docName) {
        groupedSteps[step].remark = remark;
        delete groupedSteps[step].documents;
      } else {
        groupedSteps[step].documents = groupedSteps[step].documents || [];
        groupedSteps[step].documents.push({ docName, remark });
      }
    });
    return Object.values(groupedSteps);
  };



  const handleRejectKyc = async () => {
    setLoading(true);
    await AuthService.rejectUpgradeUserKycStep(formatRejectedSteps(rejectedSteps), mergedData?.row?._id).then(async (result) => {
      setLoading(false);
      if (result.success) {
        setShowModel(false);
        setMainRejectModel(false);
        fetchUserDetails(mergedData?.row?._id, kycStep);
        alertSuccessMessage(result?.message);
        navigate("/upgrade_kyc", { state: { kycStatus: mergedData?.kycStatus } });
        setRejectedSteps([]);
      } else {
        alertErrorMessage(result);
      }
    })
  };

  const [editingStep, setEditingStep] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleEditRemark = (stepData) => {
    setMainRejectModel(false);
    setEditingStep(stepData);
    setShowEditModal(true);
  };

  const saveEditedRemark = (updatedRemark) => {
    setRejectedSteps((prev) =>
      prev.map((item) =>
        item.step === editingStep.step && item.docName === editingStep.docName
          ? { ...item, remark: updatedRemark }
          : item
      )
    );
    setShowEditModal(false);
    setMainRejectModel(true);
  };
  const filterSteps = rejectedSteps?.map(item => item?.docName);

  const handleKycApproveRemark = async (verificationRemark) => {
    if (!verificationRemark) {
      setErrorMessage("Please Enter Approval Remark");
      return;
    }
    LoaderHelper.loaderStatus(true);
    await AuthService.upgradeKycVerifyRemark(verificationRemark, mergedData?.row?._id).then(
      async (result) => {
        if (result?.success) {
          LoaderHelper.loaderStatus(false);
          try {
            alertSuccessMessage(result?.message);
            setShowModelApprove(false);
            navigate("/upgrade_kyc", { state: { kycStatus: mergedData?.kycStatus } });
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(result.message);
        }
      }
    );
  };


  const [isModalOpenItr, setIsModalOpenItr] = useState(false);
  const [isModalOpenBank, setIsModalOpenBank] = useState(false);
  const [isModalOpenSalary, setIsModalOpenSalary] = useState(false);
  const [isModalOpenVideo, setIsModalOpenVideo] = useState(false);
  const [isModalOpenOther, setIsModalOpenOther] = useState(false);


  const [isModalOpenRejectedVideo, setIsModalOpenRejectedVideo] = useState(false);
  const [isModalOpenRejectedSalary, setIsModalOpenRejectedSalary] = useState(false);
  const [isModalOpenRejectedBank, setIsModalOpenRejectedBank] = useState(false);
  const [isModalOpenRejectedItr, setIsModalOpenRejectedItr] = useState(false);
  const [isModalOpenRejectedOther, setIsModalOpenRejectedOther] = useState(false);


  /*   useEffect(() => {
      const handleEscKey = (event) => {
        if (event.key === "Escape") {
          setIsModalOpenRejectedOther(false);
        }
      };
  
      if (isModalOpenRejectedOther) {
        document.addEventListener("keydown", handleEscKey);
      }
  
      return () => {
        document.removeEventListener("keydown", handleEscKey);
      };
    }, [isModalOpenRejectedOther]); */

  const handleFullscreen = (id) => {
    /*  const iframe = document.getElementById(id);
     if (iframe.requestFullscreen) {
       iframe.requestFullscreen();
     } else if (iframe.mozRequestFullScreen) {
       iframe.mozRequestFullScreen();
     } else if (iframe.webkitRequestFullscreen) {
       iframe.webkitRequestFullscreen();
     } else if (iframe.msRequestFullscreen) {
       iframe.msRequestFullscreen();
     } */
  };


  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <form className="form-data">
            <header className="page-header ">
              <div className="container-xl px-4">
                <div className="page-header-content pb-2">
                  <div className="row align-items-center justify-content-between">
                    <h6 className="page-header-title mb-5">
                      <span className="page-header-icon cursor-pointer" onClick={() => navigate('/upgrade_kyc', { state: { kycStatus: mergedData?.kycStatus } })}>
                        <i className="fa fa-arrow-left" ></i>
                      </span>
                      <small> {mergedData?.kycStatus?.toUpperCase()}</small>
                    </h6>

                    <div className="col-auto">
                      <h1 className="page-header-title">
                        <div className="hd_u_info" >
                          <img
                            src="assets/img/illustrations/profiles/profile-5.png" alt="" className="img-account-profile rounded-circle" />
                          <span>  {mergedData?.row?.name || "-------"}
                            <small>
                              User ID: {mergedData?.row?.uin || "-------"}
                              <i
                                className={`ms-1 link cursor-pointer ${copied ? "ri-check-fill text-success" : "ri-file-copy-fill"
                                  }`}
                                onClick={() => handleCopy(mergedData?.row?.uin)}
                              ></i>
                            </small>
                          </span>
                        </div>
                      </h1>
                    </div>
                    <div className="col-auto">
                      <div className="d-flex">
                        {
                          mergedData?.kycStatus === "pending" &&
                          <>
                            {
                              kycStep === "step3" &&
                              <>
                                <button className="btn btn-danger btn-block btn-sm" type="button" onClick={() => setMainRejectModel(true)}>
                                  Reject KYC
                                </button>
                              </>
                            }
                            {
                              mergedData?.kycStatus === "pending" && kycStep === "step3" &&
                              <button className="btn mx-2 btn-success btn-block btn-sm" type="button" onClick={() => setShowModelApprove(true)}>
                                Approve
                              </button>
                            }
                          </>
                        }
                      </div>
                    </div>
                  </div>
                  {/* <div className="custom-tab-2 d-flex justify-content-start ">
                    <ul className="nav nav-pills justify-content-end mt-4 mb-0" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className={`nav-link ${location?.pathname === "/kyc_detail_personal" ? "active" : ""}`} type="button" onClick={() => navigate('/kyc_detail_personal', { state: { kycStatus: kycStatus, row: row } })}>  Level 1 KYC </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className={`nav-link ${location?.pathname === "/upgrade_kyc_details" ? "active" : ""}`} type="button" onClick={() => navigate('/upgrade_kyc_details', { state: { kycStatus: kycStatus, row: row } })}>  Level 2 KYC    </button>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </header>
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-xl-12 mb-4">
                  <ul id="progressbar" >
                    <li className={`cursor-pointer ${kycStep === "step1" ? "active" : ""}  ${userKycStatus?.[0]?.status === "complete" ? "success" : userKycStatus?.[0]?.status === "pending" ? "warning" : userKycStatus?.[0]?.status === "rejected" ? "danger" : userKycStatus?.[0]?.status === "not-submitted" ? "current" : ""}`} id="step1" onClick={() => handleClickStep("step1")}>
                      <strong> Documents List  </strong>
                    </li>
                    <li className={`cursor-pointer ${kycStep === "step2" ? "active" : ""} ${userKycStatus?.[3]?.status === "complete" ? "success" : userKycStatus?.[1]?.status === "pending" ? "warning" : userKycStatus?.[2]?.status === "rejected" ? "danger" : userKycStatus?.[2]?.status === "not-submitted" ? "current" : ""}`} id="step2" onClick={() => handleClickStep("step2")}>
                      <strong> Account Limits </strong>
                    </li>
                    <li className={`cursor-pointer ${kycStep === "step3" ? "active" : ""} ${userKycStatus?.[3]?.status === "complete" ? "success" : userKycStatus?.[3]?.status === "pending" ? "warning" : userKycStatus?.[3]?.status === "rejected" ? "danger" : userKycStatus?.[3]?.status === "not-submitted" ? "current" : ""}`} id="step3" onClick={() => handleClickStep("step3")}>
                      <strong>Declaration and Submission</strong>
                    </li>
                  </ul>
                  <div className="card">
                    <div className="card-body py-4">
                      <div className="">
                        <div className="profile_data w-100  ac_body px-4 pt-4 ">

                          {/* step 1 */}
                          <div className={`step_1 ${kycStep === "step1" ? "" : "d-none"}`} >
                            <div className="row g-4 justify-content-center">
                              <div className="col-xl-8">
                                <div className="d-flex align-items-center justify-content-between" >
                                  <h5> Documents List  </h5>
                                  {/* <p class="text-end "><small> Subitted Date & Time </small>11th January 2025, 3:59:03 pm</p> */}
                                </div>
                                <div className="fc_details mb-2 py-3 d-flex align-items-center justify-content-between " >
                                  <h5 className="mb-0" > ITR Declaration <br />

                                    {userKycDetails?.latestItrData?.user_submitted_date && (
                                      <small>
                                        Submitted on: {moment(userKycDetails?.latestItrData?.user_submitted_date).format('Do MMMM YYYY, h:mm:ss a')}
                                      </small>
                                    )}

                                  </h5>
                                  <div className="d-flex align-items-center gap-2" >
                                    {userKycDetails?.latestItrData?.documentLink ? (
                                      <>
                                        <button
                                          onClick={() => setIsModalOpenItr(true)}
                                          className="btn btn-primary btn-sm"
                                          type="button"
                                        >
                                          <i class="ri-article-fill me-2"></i> View Doc
                                        </button>

                                        {isModalOpenItr && (
                                          <>
                                            <div className="custom_modal modal fade show d-block"  >
                                              <div className="modal-dialog modal-xl" >
                                                <div className="modal-content" >
                                                  <div className="cs_mod_arrow" >
                                                    <h5 className="mb-0" >ITR Declaration</h5>
                                                    <div className="cs_row" >
                                                      <span className="cursor-pointer" onClick={() => handleFullscreen("itrDocument")} ><i class="ri-fullscreen-line"></i></span>
                                                      <span className="cursor-pointer" onClick={() => setIsModalOpenItr(false)}  >
                                                        <i class="ri-close-large-line"></i>
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <iframe
                                                    src={userKycDetails?.latestItrData?.documentLink}
                                                    width="100%"
                                                    height="100%"
                                                    id="itrDocument"
                                                  ></iframe>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="modal-backdrop fade show"></div>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <small className="text-danger">No Data Available</small>
                                    )}

                                    {
                                      mergedData?.kycStatus === "pending" &&
                                      userKycDetails?.latestItrData?.documentLink &&
                                      !filterSteps.includes("itrDocument") &&
                                      (
                                        <button
                                          className="btn btn-sm btn-danger"
                                          type="button"
                                          onClick={() => handleRejectDocument("itrDocument")}>
                                          Reason ITR
                                        </button>
                                      )
                                    }
                                  </div>
                                </div>

                                <div className="fc_details mb-2 py-3 d-flex align-items-center justify-content-between " >
                                  <h5 className="mb-0" >  Bank Statment (6 Months) <br />
                                    {userKycDetails?.latestBankData?.user_submitted_date && (
                                      <small>
                                        Submitted on: {moment(userKycDetails?.latestBankData?.user_submitted_date).format('Do MMMM YYYY, h:mm:ss a')}
                                      </small>
                                    )}
                                  </h5>
                                  <div className="d-flex align-items-center gap-2" >

                                    {userKycDetails?.latestBankData?.documentLink ? (
                                      <>

                                        <button
                                          onClick={() => setIsModalOpenBank(true)}
                                          className="btn btn-primary btn-sm"
                                          type="button"
                                        >
                                          <i class="ri-article-fill me-2"></i> View Doc

                                        </button>

                                        {isModalOpenBank && (
                                          <>
                                            <div className="custom_modal modal fade show d-block"  >
                                              <div className="modal-dialog modal-xl" >
                                                <div className="modal-content" >
                                                  <div className="cs_mod_arrow" >
                                                    <h5 className="mb-0" > Bank Statment (6 Months) </h5>
                                                    <div className="cs_row" >
                                                      <span className="cursor-pointer" ><i class="ri-fullscreen-line"></i></span>
                                                      <span className="cursor-pointer" onClick={() => setIsModalOpenBank(false)}  >
                                                        <i class="ri-close-large-line"></i>
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <iframe
                                                    src={userKycDetails?.latestBankData?.documentLink}
                                                    width="100%"
                                                    height="100%"
                                                  ></iframe>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="modal-backdrop fade show"></div>
                                          </>

                                        )}
                                      </>
                                    ) : (
                                      <small className="text-danger">No Data Available</small>
                                    )}

                                    {
                                      mergedData?.kycStatus === "pending" &&
                                      userKycDetails?.latestBankData?.documentLink &&
                                      !filterSteps.includes("bankStatement") &&
                                      (
                                        <button
                                          className="btn btn-sm btn-danger"
                                          type="button"
                                          onClick={() => handleRejectDocument("bankStatement")}>
                                          Reason Bank
                                        </button>
                                      )
                                    }
                                  </div>
                                </div>

                                <div className="fc_details mb-2 py-3 d-flex align-items-center justify-content-between " >
                                  <h5 className="mb-0" > Salary Slip (6 Months) <br />
                                    {userKycDetails?.latestSalarySlipData?.user_submitted_date && (
                                      <small>
                                        Submitted on: {moment(userKycDetails?.latestSalarySlipData?.user_submitted_date).format('Do MMMM YYYY, h:mm:ss a')}
                                      </small>
                                    )}
                                  </h5>
                                  <div className="d-flex align-items-center gap-2" >
                                    {userKycDetails?.latestSalarySlipData?.documentLink ? (
                                      <>

                                        <button
                                          onClick={() => setIsModalOpenSalary(true)}
                                          className="btn btn-primary btn-sm"
                                          type="button"
                                        >
                                          <i class="ri-article-fill me-2"></i> View Doc
                                        </button>

                                        {isModalOpenSalary && (

                                          <>
                                            <div className="custom_modal modal fade show d-block"  >
                                              <div className="modal-dialog modal-xl" >
                                                <div className="modal-content" >
                                                  <div className="cs_mod_arrow" >
                                                    <h5 className="mb-0" >  Salary Slip (6 Months) </h5>
                                                    <div className="cs_row" >
                                                      <span className="cursor-pointer" ><i class="ri-fullscreen-line"></i></span>
                                                      <span className="cursor-pointer" onClick={() => setIsModalOpenSalary(false)}  >
                                                        <i class="ri-close-large-line"></i>
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <iframe
                                                    src={userKycDetails?.latestSalarySlipData?.documentLink}
                                                    width="100%"
                                                    height="100%"
                                                  ></iframe>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="modal-backdrop fade show"></div>
                                          </>


                                        )}
                                      </>
                                    ) : (
                                      <small className="text-danger">No Data Available</small>
                                    )}

                                    {
                                      mergedData?.kycStatus === "pending" &&
                                      userKycDetails?.latestSalarySlipData?.documentLink &&
                                      !filterSteps.includes("salarySlip") &&
                                      (
                                        <button
                                          className="btn btn-sm btn-danger"
                                          type="button"
                                          onClick={() => handleRejectDocument("salarySlip")}>
                                          Reason Salary
                                        </button>
                                      )
                                    }
                                  </div>
                                </div>

                                <div className="fc_details mb-2 py-3 d-flex align-items-center justify-content-between " >
                                  <h5 className="mb-0" > Video KYC with PAN <br />
                                    {userKycDetails?.latestVideoKYCData?.user_submitted_date && (
                                      <small>
                                        Submitted on: {moment(userKycDetails?.latestVideoKYCData?.user_submitted_date).format('Do MMMM YYYY, h:mm:ss a')}
                                      </small>
                                    )}
                                  </h5>
                                  <div className="d-flex align-items-center gap-2" >
                                    {userKycDetails?.latestVideoKYCData?.documentLink ? (
                                      <>

                                        <button
                                          onClick={() => setIsModalOpenVideo(true)}
                                          className="btn btn-primary btn-sm"
                                          type="button"
                                        >
                                          <i class="ri-article-fill me-2"></i> View Video
                                        </button>


                                        {isModalOpenVideo && (
                                          <>
                                            <div className="custom_modal modal fade show d-block"  >
                                              <div className="modal-dialog modal-xl" >
                                                <div className="modal-content" >
                                                  <div className="cs_mod_arrow" >
                                                    <h5 className="mb-0" > Video KYC with PAN </h5>
                                                    <div className="cs_row" >
                                                      <span className="cursor-pointer" ><i class="ri-fullscreen-line"></i></span>
                                                      <span className="cursor-pointer" onClick={() => setIsModalOpenVideo(false)}  >
                                                        <i class="ri-close-large-line"></i>
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <iframe
                                                    src={userKycDetails?.latestVideoKYCData?.documentLink}
                                                    width="100%"
                                                    height="100%"
                                                  ></iframe>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="modal-backdrop fade show"></div>
                                          </>

                                        )}
                                      </>
                                    ) : (
                                      <small className="text-danger">No Data Available</small>
                                    )}

                                    {
                                      mergedData?.kycStatus === "pending" &&
                                      userKycDetails?.latestVideoKYCData?.documentLink &&
                                      !filterSteps.includes("videoKYC") &&
                                      (
                                        <button
                                          className="btn btn-sm btn-danger"
                                          type="button"
                                          onClick={() => handleRejectDocument("videoKYC")}>
                                          Reason Video
                                        </button>
                                      )
                                    }
                                  </div>

                                </div>

                                <div className="fc_details mb-2 py-3 d-flex align-items-center justify-content-between " >
                                  <h5 className="mb-0" >  Other Document <br />
                                    {userKycDetails?.latestOtherDocData?.user_submitted_date && (
                                      <small>
                                        Submitted on: {moment(userKycDetails?.latestOtherDocData?.user_submitted_date).format('Do MMMM YYYY, h:mm:ss a')}
                                      </small>
                                    )}
                                  </h5>
                                  <div className="d-flex align-items-center gap-2 ottt_dc " >


                                    {userKycDetails?.latestOtherDocData?.documentLink ? (

                                      userKycDetails?.latestOtherDocData?.documentLink?.toLowerCase().endsWith(".pdf") || userKycDetails?.latestOtherDocData?.documentLink?.toLowerCase().endsWith(".pdf.jpg") ? (
                                        <>

                                          <button
                                            onClick={() => setIsModalOpenOther(true)}
                                            className="btn btn-primary btn-sm"
                                            type="button"
                                          >
                                            <i class="ri-article-fill me-2"></i> View Doc
                                          </button>

                                          {isModalOpenOther && (
                                            <>
                                              <div className="custom_modal modal fade show d-block"  >
                                                <div className="modal-dialog modal-xl" >
                                                  <div className="modal-content" >
                                                    <div className="cs_mod_arrow" >
                                                      <h5 className="mb-0" >   Other Document </h5>
                                                      <div className="cs_row" >
                                                        <span className="cursor-pointer" ><i class="ri-fullscreen-line"></i></span>
                                                        <span className="cursor-pointer" onClick={() => setIsModalOpenOther(false)}  >
                                                          <i class="ri-close-large-line"></i>
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <iframe
                                                      src={userKycDetails?.latestOtherDocData?.documentLink}
                                                      width="100%"
                                                      height="100%"
                                                    ></iframe>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="modal-backdrop fade show"></div>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <SlideshowLightbox className="">
                                          <img
                                            src={userKycDetails?.latestOtherDocData?.documentLink || "assets/img/blank_img.png"}
                                            className="img-fluid"
                                            width="50"
                                            alt="Support Ticket Attachment"
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src = "assets/img/blank_img.png";
                                            }}
                                          />
                                        </SlideshowLightbox>
                                      )
                                    ) : (
                                      <small className="text-danger">No Data Available</small>
                                    )
                                    }
                                    {
                                     mergedData?.kycStatus === "pending" &&
                                      userKycDetails?.latestOtherDocData?.documentLink &&
                                      !filterSteps.includes("otherDoc") &&
                                      (
                                        <button
                                          className="btn btn-sm btn-danger"
                                          type="button"
                                          onClick={() => handleRejectDocument("otherDoc")}>
                                          Reason Other
                                        </button>
                                      )
                                    }
                                  </div>
                                </div>

                              </div>
                            </div>

                            {
                              userKycDetails?.rejectedItrData?.length > 0 &&

                              <div className="att_row mt-5">
                                <h5>Rejected History ITR Declaration</h5>
                                <div className="att_card">
                                  {/* Tabs */}
                                  <div className="nav nav-tabs nav-tabs-custom px-2">
                                    <Tabs
                                      className="nav nav-tabs nav-tabs-custom px-2"
                                      activeTab={activeTabItrRejected}
                                    >
                                      {userKycDetails?.rejectedItrData?.map((attempt, index) => (
                                        <Tab
                                          key={attempt?._id}
                                          className={`nav-link ${activeTabItrRejected === index ? "active" : ""}`}
                                          onTabClick={() => tabClickRejectedItr(index, attempt)}
                                        >
                                          {attempt.admin_rejected_date
                                            ? moment(attempt.admin_rejected_date).format('Do MMMM YYYY, h:mm:ss a')
                                            : "-------"
                                          }
                                        </Tab>
                                      ))}
                                    </Tabs>
                                  </div>

                                  {/* Selected Tab Details */}
                                  <div className="att_col">
                                    {itrAttempt ? (
                                      <div className="row g-3">
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label> ITR Declaration </label>
                                            {
                                              itrAttempt?.documentLink ?
                                                <p className="mt-2" >
                                                  <button type="button" className="btn btn-outline-dark  btn-xs"
                                                    onClick={() => setIsModalOpenRejectedItr(true)}
                                                  >
                                                    <i class="ri-eye-line me-2"></i> View Document
                                                  </button>
                                                </p>
                                                :
                                                <small className="text-danger"> No Data Available </small>
                                            }


                                            {isModalOpenRejectedItr && (
                                              <>
                                                <div className="custom_modal modal fade show d-block"  >
                                                  <div className="modal-dialog modal-xl" >
                                                    <div className="modal-content" >
                                                      <div className="cs_mod_arrow" >
                                                        <h5 className="mb-0" > Rejected ITR Declaration  </h5>
                                                        <div className="cs_row" >
                                                          <span className="cursor-pointer" ><i class="ri-fullscreen-line"></i></span>
                                                          <span className="cursor-pointer" onClick={() => setIsModalOpenRejectedItr(false)}  >
                                                            <i class="ri-close-large-line"></i>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <iframe
                                                        src={itrAttempt?.documentLink}
                                                        width="100%"
                                                        height="100%"
                                                      ></iframe>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="modal-backdrop fade show"></div>
                                              </>
                                            )}

                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-9">
                                          <div class="rm_col">
                                            <h5>
                                              <span>{itrAttempt?.admin_name}</span>
                                              <small>{itrAttempt?.admin_id}</small>
                                            </h5>
                                            <p>
                                              <b>Remark : </b>{itrAttempt?.remark}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-center align-items-center">
                                        No Data Available
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                            }

                            {
                              userKycDetails?.rejectedBankData?.length > 0 &&

                              <div className="att_row mt-5">
                                <h5>Rejected History Bank Statment</h5>
                                <div className="att_card">
                                  {/* Tabs */}
                                  <div className="nav nav-tabs nav-tabs-custom px-2">
                                    <Tabs
                                      className="nav nav-tabs nav-tabs-custom px-2"
                                      activeTab={activeTabBankRejected}
                                    >
                                      {userKycDetails?.rejectedBankData?.map((attempt, index) => (
                                        <Tab
                                          key={attempt?._id}
                                          className={`nav-link ${activeTabBankRejected === index ? "active" : ""}`}
                                          onTabClick={() => tabClickRejectedBank(index, attempt)}
                                        >
                                          {attempt.admin_rejected_date
                                            ? moment(attempt.admin_rejected_date).format('Do MMMM YYYY, h:mm:ss a')
                                            : "-------"
                                          }
                                        </Tab>
                                      ))}
                                    </Tabs>

                                  </div>

                                  {/* Selected Tab Details */}
                                  <div className="att_col">
                                    {bankAttempt ? (
                                      <div className="row g-3">

                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label>Bank Statment (6 Months)  </label>
                                            {
                                              bankAttempt?.documentLink ?
                                                <p className="mt-2" >
                                                  <button type="button"
                                                    className="btn btn-outline-dark  btn-xs"
                                                    onClick={() => setIsModalOpenRejectedBank(true)}
                                                  >
                                                    <i class="ri-eye-line me-2"></i> View Document
                                                  </button>
                                                </p>
                                                :
                                                <small className="text-danger"> No Data Available </small>
                                            }

                                            {isModalOpenRejectedBank && (
                                              <>
                                                <div className="custom_modal modal fade show d-block"  >
                                                  <div className="modal-dialog modal-xl" >
                                                    <div className="modal-content" >
                                                      <div className="cs_mod_arrow" >
                                                        <h5 className="mb-0" > Rejected Bank Statments  </h5>
                                                        <div className="cs_row" >
                                                          <span className="cursor-pointer" ><i class="ri-fullscreen-line"></i></span>
                                                          <span className="cursor-pointer" onClick={() => setIsModalOpenRejectedBank(false)}  >
                                                            <i class="ri-close-large-line"></i>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <iframe
                                                        src={bankAttempt?.documentLink}
                                                        width="100%"
                                                        height="100%"
                                                      ></iframe>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="modal-backdrop fade show"></div>
                                              </>
                                            )}

                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-9">
                                          <div class="rm_col">
                                            <h5>
                                              <span>{bankAttempt?.admin_name}</span>
                                              <small>{bankAttempt?.admin_id}</small>
                                            </h5>
                                            <p>
                                              <b>Remark : </b>{bankAttempt?.remark}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-center align-items-center">
                                        No Data Available
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                            }

                            {
                              userKycDetails?.rejectedSalarySlipData?.length > 0 &&

                              <div className="att_row mt-5">
                                <h5>Rejected History Salary Slip</h5>
                                <div className="att_card">
                                  {/* Tabs */}
                                  <div className="nav nav-tabs nav-tabs-custom px-2">
                                    <Tabs
                                      className="nav nav-tabs nav-tabs-custom px-2"
                                      activeTab={activeTabSalaryRejected}
                                    >
                                      {userKycDetails?.rejectedSalarySlipData?.map((attempt, index) => (
                                        <Tab
                                          key={attempt?._id}
                                          className={`nav-link ${activeTabSalaryRejected === index ? "active" : ""}`}
                                          onTabClick={() => tabClickRejectedSalary(index, attempt)}
                                        >
                                          {attempt.admin_rejected_date
                                            ? moment(attempt.admin_rejected_date).format('Do MMMM YYYY, h:mm:ss a')
                                            : "-------"
                                          }
                                        </Tab>
                                      ))}
                                    </Tabs>

                                  </div>

                                  {/* Selected Tab Details */}
                                  <div className="att_col">
                                    {salaryAttempt ? (
                                      <div className="row g-3">

                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label>Salary Slip (6 Months)</label>
                                            {
                                              salaryAttempt?.documentLink ?
                                                <p className="mt-2" >
                                                  <button type="button"
                                                    onClick={() => setIsModalOpenRejectedSalary(true)}
                                                    className="btn btn-outline-dark  btn-xs"
                                                  >
                                                    <i class="ri-eye-line me-2"></i> View Document
                                                  </button>
                                                </p>
                                                :
                                                <small className="text-danger"> No Data Available </small>
                                            }

                                            {isModalOpenRejectedSalary && (
                                              <>
                                                <div className="custom_modal modal fade show d-block"  >
                                                  <div className="modal-dialog modal-xl" >
                                                    <div className="modal-content" >
                                                      <div className="cs_mod_arrow" >
                                                        <h5 className="mb-0" > Rejected Salary Slips  </h5>
                                                        <div className="cs_row" >
                                                          <span className="cursor-pointer" ><i class="ri-fullscreen-line"></i></span>
                                                          <span className="cursor-pointer" onClick={() => setIsModalOpenRejectedSalary(false)}  >
                                                            <i class="ri-close-large-line"></i>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <iframe
                                                        src={salaryAttempt?.documentLink}
                                                        width="100%"
                                                        height="100%"
                                                      ></iframe>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="modal-backdrop fade show"></div>
                                              </>
                                            )}

                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-9">
                                          <div class="rm_col">
                                            <h5>
                                              <span>{salaryAttempt?.admin_name}</span>
                                              <small>{salaryAttempt?.admin_id}</small>
                                            </h5>
                                            <p>
                                              <b>Remark : </b>{salaryAttempt?.remark}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-center align-items-center">
                                        No Data Available
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                            }

                            {
                              userKycDetails?.rejectedVideoKYCData?.length > 0 &&

                              <div className="att_row mt-5">
                                <h5>Rejected History Video KYC</h5>
                                <div className="att_card">
                                  {/* Tabs */}
                                  <div className="nav nav-tabs nav-tabs-custom px-2">
                                    <Tabs
                                      className="nav nav-tabs nav-tabs-custom px-2"
                                      activeTab={activeTabVideoRejected}
                                    >
                                      {userKycDetails?.rejectedVideoKYCData?.map((attempt, index) => (
                                        <Tab
                                          key={attempt?._id}
                                          className={`nav-link ${activeTabVideoRejected === index ? "active" : ""}`}
                                          onTabClick={() => tabClickRejectedVideo(index, attempt)}
                                        >
                                          {attempt.admin_rejected_date
                                            ? moment(attempt.admin_rejected_date).format('Do MMMM YYYY, h:mm:ss a')
                                            : "-------"
                                          }
                                        </Tab>
                                      ))}
                                    </Tabs>

                                  </div>

                                  {/* Selected Tab Details */}
                                  <div className="att_col">
                                    {videoAttempt ? (
                                      <div className="row g-3">
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label> Video KYC with PAN </label>

                                            {
                                              videoAttempt?.documentLink ?
                                                <p className="mt-2" >
                                                  <button type="button"
                                                    onClick={() => setIsModalOpenRejectedVideo(true)}
                                                    className="btn btn-outline-dark  btn-xs"
                                                  >
                                                    <i class="ri-eye-line me-2"></i> View Document
                                                  </button>
                                                </p>
                                                :
                                                <small className="text-danger"> No Data Available </small>
                                            }

                                            {isModalOpenRejectedVideo && (
                                              <>
                                                <div className="custom_modal modal fade show d-block"  >
                                                  <div className="modal-dialog modal-xl" >
                                                    <div className="modal-content" >
                                                      <div className="cs_mod_arrow" >
                                                        <h5 className="mb-0" >  Video KYC with PAN  </h5>
                                                        <div className="cs_row" >
                                                          <span className="cursor-pointer" ><i class="ri-fullscreen-line"></i></span>
                                                          <span className="cursor-pointer"
                                                            onClick={() => setIsModalOpenRejectedVideo(false)}  >
                                                            <i class="ri-close-large-line"></i>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <iframe
                                                        src={videoAttempt?.documentLink}
                                                        width="100%"
                                                        height="100%"
                                                      ></iframe>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="modal-backdrop fade show"></div>
                                              </>
                                            )}

                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-9">
                                          <div class="rm_col">
                                            <h5>
                                              <span>{videoAttempt?.admin_name}</span>
                                              <small>{videoAttempt?.admin_id}</small>
                                            </h5>
                                            <p>
                                              <b>Remark : </b>{videoAttempt?.remark}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-center align-items-center">
                                        No Data Available
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                            }


                            {
                              userKycDetails?.rejectedOtherDocData?.length > 0 &&

                              <div className="att_row mt-5">
                                <h5>Rejected History Other Document</h5>
                                <div className="att_card">
                                  {/* Tabs */}
                                  <div className="nav nav-tabs nav-tabs-custom px-2">
                                    <Tabs
                                      className="nav nav-tabs nav-tabs-custom px-2"
                                      activeTab={activeTabOtherDocRejected}
                                    >
                                      {userKycDetails?.rejectedOtherDocData?.map((attempt, index) => (
                                        <Tab
                                          key={attempt?._id}
                                          className={`nav-link ${activeTabOtherDocRejected === index ? "active" : ""}`}
                                          onTabClick={() => tabClickRejectedOtherDoc(index, attempt)}
                                        >
                                          {attempt.admin_rejected_date
                                            ? moment(attempt.admin_rejected_date).format('Do MMMM YYYY, h:mm:ss a')
                                            : "-------"
                                          }
                                        </Tab>
                                      ))}
                                    </Tabs>

                                  </div>

                                  {/* Selected Tab Details */}
                                  <div className="att_col">
                                    {otherDocAttempt ? (
                                      <div className="row g-3">
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label> Other Document </label>

                                            <>
                                              {otherDocAttempt?.documentLink?.toLowerCase().endsWith(".pdf") ||
                                                otherDocAttempt?.documentLink?.toLowerCase().endsWith(".pdf.jpg") ? (
                                                <>
                                                  <p className="mt-2">
                                                    <button
                                                      type="button"
                                                      onClick={() => setIsModalOpenRejectedOther(true)}
                                                      className="btn btn-outline-dark btn-xs"
                                                    >
                                                      <i className="ri-eye-line me-2"></i> View Document
                                                    </button>
                                                  </p>

                                                  {isModalOpenRejectedOther && (
                                                    <>
                                                      <div className="custom_modal modal fade show d-block">
                                                        <div className="modal-dialog modal-xl">
                                                          <div className="modal-content">
                                                            <div className="cs_mod_arrow">
                                                              <h5 className="mb-0">Other Document</h5>
                                                              <div className="cs_row">
                                                                <span className="cursor-pointer" onClick={() => handleFullscreen("rejected_other_document")}>
                                                                  <i className="ri-fullscreen-line"></i>
                                                                </span>
                                                                <span className="cursor-pointer" onClick={() => setIsModalOpenRejectedOther(false)}>
                                                                  <i className="ri-close-large-line"></i>
                                                                </span>
                                                              </div>
                                                            </div>
                                                            <iframe
                                                              id="rejected_other_document"
                                                              src={otherDocAttempt?.documentLink}
                                                              width="100%"
                                                              height="100%"
                                                            ></iframe>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="modal-backdrop fade show"></div>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <SlideshowLightbox>
                                                  <img
                                                    src={otherDocAttempt?.documentLink || "assets/img/blank_img.png"}
                                                    className="img-fluid"
                                                    width="50"
                                                    alt="Support Ticket Attachment"
                                                    onError={(e) => {
                                                      e.target.onerror = null;
                                                      e.target.src = "assets/img/blank_img.png";
                                                    }}
                                                  />
                                                </SlideshowLightbox>
                                              )}
                                            </>


                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-9">
                                          <div class="rm_col">
                                            <h5>
                                              <span>{otherDocAttempt?.admin_name}</span>
                                              <small>{otherDocAttempt?.admin_id}</small>
                                            </h5>
                                            <p>
                                              <b>Remark : </b>{otherDocAttempt?.remark}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-center align-items-center">
                                        No Data Available
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                            }

                            <div className="puk_row puk_row_fixed" >
                              <button className="btn btn-sm btn-black px-4" type="button" onClick={() => handleClickStep("step2")}>
                                Next
                              </button>

                            </div>
                          </div>

                          {/* step 2 */}
                          <div className={`step_2 ${kycStep === "step2" ? "" : "d-none"}`} >
                            <div className="row g-4 justify-content-center">
                              <div className="col-xl-8">
                                <div className="d-flex align-items-center justify-content-between" >
                                  <h5> Account Limits  </h5>
                                </div>
                                <div className="fc_details mb-2 py-3 d-flex align-items-center justify-content-between " >
                                  <h5 className="mb-0" >  Desired Total (FY) Account Withdrawal Limit (INR)   </h5>
                                  <p className="mb-0 text-dark" > <b>{userKycDetails?.latestStep2Data?.[0]?.total_account_withdrawal_limit} </b> </p>
                                </div>
                                <div className="fc_details mb-2 py-3 d-flex align-items-center justify-content-between " >
                                  <h5 className="mb-0" >  Desired Daily Withdrawal Limit (INR)   </h5>
                                  <p className="mb-0 text-dark" > <b>{userKycDetails?.latestStep2Data?.[0]?.daily_desired_limit} </b> </p>
                                </div>
                                <div className="fc_details mb-2 py-3 d-flex align-items-center justify-content-between " >
                                  <h5 className="mb-0" >Desired  Daily  Quick Buy Limit (INR) </h5>
                                  <p className="mb-0 text-dark" > <b>{userKycDetails?.latestStep2Data?.[0]?.daily_qb_limit} </b> </p>
                                </div>
                                <div className="fc_details mb-2 py-3  " >
                                  <h5 className="mb-2 " >  Reason to limit Enhancement </h5>
                                  <p className="mb-0" > <small>
                                    {userKycDetails?.latestStep2Data?.[0]?.reason}
                                  </small></p>
                                </div>
                              </div>
                            </div>



                            <div className="att_row mt-5">
                              <h5>Rejected History</h5>
                              <div className="att_card">
                                <div className="nav nav-tabs nav-tabs-custom px-2">
                                  <Tabs
                                    className="nav nav-tabs nav-tabs-custom px-2"
                                    activeTab={activeTabRejectedStepTwo}
                                  >
                                    {userKycDetails?.otherStep2Data?.map((attempt, index) => (
                                      <Tab
                                        key={attempt?._id}
                                        className={`nav-link ${activeTabRejectedStepTwo === index ? "active" : ""}`}
                                        onTabClick={() => tabClickStepTwo(index, attempt)}
                                      >
                                        {attempt.admin_rejected_date
                                          ? moment(attempt.admin_rejected_date).format('Do MMMM YYYY, h:mm:ss a')
                                          : "-------"
                                        }
                                      </Tab>
                                    ))}
                                  </Tabs>
                                </div>
                                <div className="att_col">
                                  {setpTwoAttemp ? (
                                    <div className="row g-5 " >
                                      <div className="col-lg-9" >
                                        <div className="row g-3">
                                          <div className="col-lg-6 col-xl-6">
                                            <div className="label-group">
                                              <label>  Desired Total (FY) Account Withdrawal Limit (INR) </label>
                                              <p className="mt-2" >
                                                {setpTwoAttemp?.total_account_withdrawal_limit}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-6">
                                            <div className="label-group">
                                              <label>  Desired Daily Withdrawal Limit </label>
                                              <p className="mt-2" >
                                                {setpTwoAttemp?.daily_desired_limit}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-6">
                                            <div className="label-group">
                                              <label> Desired Daily Quick Buy Limit (INR) </label>
                                              <p className="mt-2" >
                                                {setpTwoAttemp?.daily_qb_limit}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-12 col-xl-6">
                                            <div className="label-group">
                                              <label> Reason to limit Enhancement</label>
                                              <p className="fw-normal" >
                                                <small> {setpTwoAttemp?.reason}</small>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-xl-3">
                                        <div class="rm_col">
                                          <h5>
                                            <span>{setpTwoAttemp?.admin_name}</span>
                                            <small>{setpTwoAttemp?.admin_id}</small>
                                          </h5>
                                          <p>
                                            <b>Remark : </b>{setpTwoAttemp?.rejected_reason}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                  ) : (
                                    <div className="d-flex justify-content-center align-items-center">
                                      No Data Available
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="puk_row puk_row_fixed" >
                              <button className="btn btn-sm btn-gray px-4" type="button" onClick={() => handleClickStep("step1")}>
                                Prev.
                              </button>

                              <button className="btn btn-sm btn-black px-4" type="button" onClick={() => handleClickStep("step3")}>
                                Next
                              </button>
                              {
                               mergedData?.kycStatus === "pending" &&
                                userKycDetails?.latestStep2Data &&
                                !filterSteps.includes("") &&
                                (
                                  <button
                                    className="btn btn-sm btn-danger px-4"
                                    type="button"
                                    onClick={() => handleRejectDocument("")}
                                  >
                                    Reject Reamrk
                                  </button>
                                )
                              }
                            </div>
                          </div>

                          {/* step 3*/}
                          <div className={`step_3 ${kycStep === "step3" ? "" : "d-none"}`}>
                            <div className=" mb-4" >
                              <h4 className=" " > <small>Declaration of Source of Funds </small>  </h4>
                              <ul className="desc mb-0">
                                <li className="mb-2 d-block">
                                  <b>[{userKycDetails?.latestDeclarationData?.[0]?.name || "-------"}]</b>, a citizen of India, holding Aadhar Number <b>[{userKycDetails?.latestDeclarationData?.[0]?.maskedAadhar || "-------"}]</b> and PAN Card number <b>
                                    [{userKycDetails?.latestDeclarationData?.[0]?.pan || "-------"}]</b>,  hereby declare and affirm that all funds used in my financial transactions, with CRYPTO INDIA (Incryp Blockchain Private Limited) in INR and any other crypto currency, are obtained from legitimate and legal sources. The funds are fully compliant with all applicable laws, regulations, and guidelines, including Anti-Money Laundering (AML) and Counter-Terrorist Financing (CTF) regulations.
                                </li>
                                <li className="mb-2">We further declare that:</li>
                                <li className="mb-2 d-block">   1. Legitimate Source: All funds used in our company’s financial operations, including the acquisition of crypto assets, are free from any association with illegal activities such as money laundering, tax evasion, or terrorist financing.</li>
                                <li className="mb-2 d-block"> 2. Compliance with AML Regulations: Our company follows stringent internal controls and compliance measures, ensuring full adherence to AML, CTF, and Know Your Customer (KYC) guidelines as stipulated by regulatory authorities. We have systems in place to detect and prevent suspicious or unlawful financial activities</li>
                                <li className="mb-2 d-block">   3. Due Diligence: Our company undertakes regular due diligence on all counterparties and customers to verify the legitimacy of funds, consistent with legal requirements, before entering any transaction involving crypto assets or traditional financial services.</li>
                                <li className="mb-2 d-block">4. Ethical Standards: Our company upholds the highest standards of ethical conduct in all financial dealings, ensuring that funds used in all transactions are derived from legitimate business activities and are compliant with domestic and international regulations.</li>
                                <li className="mb-2 d-block"> 5. Cooperation with Authorities: In the event of any suspicion, inquiry, or investigation into the source of our company’s funds, we commit to full cooperation with law enforcement agencies, financial institutions, and regulatory authorities. We are prepared to provide all necessary documentation and information to verify the source of funds when required.</li>
                              </ul>

                              <div className="row g-3 mt-3" >
                                <div className="col-lg-6" >
                                  <p className="fc_details p-3 d-flex justify-content-center align-items-center" >
                                    <small> Level 2 T&C Declared on: &nbsp;
                                      <b className="text-dark" >
                                        {userKycDetails?.latestDeclarationData?.[0]?.createdAt
                                          ? moment(userKycDetails?.latestDeclarationData?.[0]?.createdAt).format('Do MMM YYYY,h:mm:ss a')
                                          : "-------"
                                        }
                                      </b>
                                      {userKycDetails?.latestDeclarationData?.[0]?.createdAt &&
                                        <i className="ri-checkbox-circle-fill text-success ms-2"></i>
                                      }
                                    </small>
                                  </p>
                                </div>
                                <div className="col-lg-6" >
                                  <p className="fc_details p-3 d-flex justify-content-center align-items-center" >
                                    <small>Level 2 KYC Approved on: &nbsp;
                                      <b className="text-dark" >
                                        {userKycDetails?.latestDeclarationData?.[0]?.admin_accepted_time
                                          ? moment(userKycDetails?.latestDeclarationData?.[0]?.admin_accepted_time).format('Do MMM YYYY,h:mm:ss a')
                                          : "-------"
                                        }
                                      </b>&nbsp;
                                      {userKycDetails?.latestDeclarationData?.[0]?.admin_accepted_time &&
                                        <i className="ri-checkbox-circle-fill text-success"></i>
                                      }
                                    </small>
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row hns_row_two  mt-5" >
                              <div className="col-lg-8">
                                <div className="att_row mt-0" >
                                  <h5 className="mb-2" > <small>Rejected History</small></h5>
                                  <div className="att_card" >




                                    <Tabs
                                      className="nav nav-tabs nav-tabs-custom px-2"
                                      activeTab={activeTabOtherDeclarattion}
                                    >
                                      {userKycDetails?.otherDeclarationData?.map((attempt, index) => (
                                        <Tab
                                          key={attempt?._id}
                                          className={`nav-link ${activeTabRejectedStepTwo === index ? "active" : ""}`}
                                          onTabClick={() => handleOtherDeclarattion(index, attempt)}
                                        >
                                          {attempt.admin_rejected_time
                                            ? moment(attempt.admin_rejected_time).format('Do MMMM YYYY, h:mm:ss a')
                                            : "-------"
                                          }
                                        </Tab>
                                      ))}
                                    </Tabs>


                                    <div className="att_col" >
                                      {setpThreeAttemp ? (
                                        <div className="row g-4 align-items-center">
                                          <div className="col-lg-6 col-xl-6">
                                            <div className="label-group">
                                              <label> Declared Date & Time </label>
                                              <p>
                                                <small>
                                                  {setpThreeAttemp.createdAt
                                                    ? moment(setpThreeAttemp.createdAt).format('Do MMMM YYYY, h:mm:ss a')
                                                    : "-------"
                                                  }
                                                </small>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-6">
                                            <div className="label-group">
                                              <label> Full Name </label> <p> <small> {setpThreeAttemp.name || "-------"}  </small> </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-6">
                                            <div className="label-group">
                                              <label> Aadhar Number </label> <p> <small> {setpThreeAttemp.maskedAadhar || "-------"}  </small> </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-6">
                                            <div className="label-group">
                                              <label> Pan Number </label> <p> <small> {setpThreeAttemp.pan || "-------"}  </small> </p>
                                            </div>
                                          </div>


                                          <small>Approved on: &nbsp;
                                            <b className="text-dark" >
                                              {userKycDetails?.latestDeclarationData?.[0]?.admin_accepted_time
                                                ? moment(userKycDetails?.latestDeclarationData?.[0]?.admin_accepted_time).format('Do MMM YYYY,h:mm:ss a')
                                                : "-------"
                                              }
                                            </b>&nbsp;
                                            {userKycDetails?.latestDeclarationData?.[0]?.admin_accepted_time &&
                                              <i className="ri-checkbox-circle-fill text-success"></i>
                                            }
                                          </small>
                                        </div>
                                      ) :
                                        <div className="fc_details d-flex justify-content-center align-items-center">
                                          <img src="/assets/img/not_found.svg" className="img-fluid mt-5" width="200" alt="" />
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-4" >
                                <h5 className="mb-2" > <small>Approved Remarkdd</small> </h5>
                                <div className="re_row ap_row" >
                                  {
                                    approvedRemark?.length > 0 ? (
                                      approvedRemark?.map((item, index) => (
                                        <div className="rm_col" key={index}>
                                          <div className="row" >
                                            <div className="col-6" >
                                              <h5> <span> {item?.admin_name === "null" ? "Crypto India" : item?.admin_name} <small>
                                                {item?.admin_id === "null" ? "Crypto India System" : item?.admin_id}</small> </span> </h5>
                                            </div>
                                            <div className="col-6" >
                                              <h5 className="text-end" ><small>Date & Time</small>
                                                {item?.admin_accepted_time
                                                  ? (
                                                    <>
                                                      {moment(item?.admin_accepted_time).format('Do MMMM YYYY')}
                                                      <br />
                                                      {moment(item?.admin_accepted_time).format('h:mm:ss a')}
                                                    </>
                                                  )
                                                  : "-------"}
                                              </h5>
                                            </div>
                                          </div>
                                          <p>
                                            <b>Remark : </b>
                                            {item?.remark || item?.reason || "-------"}
                                          </p>
                                        </div>
                                      ))
                                    ) : null
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="puk_row puk_row_fixed" >
                              <button className="btn btn-sm btn-gray px-4" type="button" onClick={() => handleClickStep("step2")}>
                                Prev.
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </main>
      </div >



      {showModel && (
        <>
          <div className="modal show d-block" >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content pb-3">
                <div className="modal-header">
                  <h5 className="modal-title" > Are you sure you want to reject the <b>{documentType}</b> details?</h5>
                </div>
                <div className="modal-body ">
                  <div className="form-group mb-4 ">
                    <label className="small mb-1"> Enter Reason/Message </label>
                    <textarea className="form-control py-3" rows="4" placeholder="Enter your message.." name="rejectRemarks" value={rejectRemarks}
                      onChange={(e) => {
                        setRejectRemarks(e.target.value);
                        setErrorMessage("");
                      }}>
                    </textarea>
                    {errorMessage && (
                      <div className="text-danger mt-2 small">{errorMessage}</div>
                    )}
                  </div>
                </div>
                <button className="btn btn-danger  btn-block w-100" type="button" onClick={() => storeStepRejectedReason(rejectRemarks)}>
                  <span> Submit </span>
                </button>
                <button className="btn btn-secondary  btn-block w-100 mt-1" type="button" onClick={() => setShowModel(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )
      }


      {
        mainRejectModel && (
          <>
            <div className="modal show d-block">
              <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
                <div className="modal-content ">
                  <div className="modal-header">
                    <h5 className="modal-title"> Are You Sure?</h5>
                    <button className="btn-close" type="button" onClick={() => setMainRejectModel(false)}></button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group pc_row">
                        {rejectedSteps?.length > 0 ? (
                          rejectedSteps.map((item, index) => (/* .filter(item => item.step === "step1") */
                            <div className="pc_col" key={index}>
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <h6 className="mb-0">
                                  <b>Step</b> - {item?.step}
                                </h6>

                                <button className="btn btn-xs btn-danger" type="button" onClick={() => handleEditRemark(item)}>
                                  Edit Remark
                                </button>
                              </div>
                              {item?.step === "step1" &&
                                <p>
                                  <b>Document: </b> {item?.docName}
                                </p>
                              }
                              <p>
                                <b>Remark: </b> {item?.remark}
                              </p>
                            </div>
                          ))
                        ) : (
                          <p>No remarks for step1</p>
                        )}
                      </div>

                      {
                        rejectedSteps?.length == 0 ?
                          <button className="btn btn-danger btn-block w-100" type="button" onClick={() => { setMainRejectModel(false); handleClickStep("step1") }}>
                            Add Remark
                          </button>
                          :
                          <button className="btn btn-danger btn-block w-100" type="button" onClick={handleRejectKyc}>
                            {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              : <span> Continue </span>}
                          </button>
                      }

                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-backdrop fade show"></div>
          </>
        )
      }

      {
        showEditModal && editingStep && (
          <>
            <div className="modal show d-block" >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content pb-3">
                  <div className="modal-header">
                    <h5 className="modal-title" > Are you sure you ?</h5>
                  </div>
                  <div className="modal-body ">
                    <div className="form-group mb-4 ">
                      <label className="small mb-1"> Edit Remark for <b>{editingStep.docName} ({editingStep.step})</b></label>
                      <textarea className="form-control py-3" rows="4" placeholder="Enter your message.." name="rejectRemarks" value={editingStep.remark} onChange={(e) => setEditingStep({ ...editingStep, remark: e.target.value })}></textarea>
                    </div>
                  </div>
                  <div>
                    <button className="btn btn-danger  btn-block w-100" type="button"
                      onClick={() => saveEditedRemark(editingStep.remark)}
                    >
                      Save
                    </button>
                    <button className="btn btn-secondary  btn-block w-100 mt-1" type="button" onClick={() => { setShowEditModal(false); setMainRejectModel(true) }}>
                      Cancel
                    </button>
                  </div>

                </div>
              </div>
            </div>
            <div className="modal-backdrop fade show"></div>
          </>
        )
      }

      {
        showModelApprove && (
          <>
            <div className="modal show d-block" >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content pb-3">
                  <div className="modal-header">
                    <h5 className="modal-title" >You are about to approve the KYC.</h5>
                  </div>
                  <div className="modal-body ">
                    <div className="form-group mb-4 ">
                      <label className="small mb-1"> Enter Kyc Approval Remark </label>
                      <textarea className="form-control py-3" rows="4" placeholder="Enter your Remark.." name="verificationRemark" value={verificationRemark}
                        onChange={(e) => {
                          setVerificationRemark(e.target.value);
                          setErrorMessage("");
                        }}>
                      </textarea>
                      {errorMessage && (
                        <div className="text-danger mt-2 small">{errorMessage}</div>
                      )}
                    </div>
                  </div>
                  <button className="btn btn-success  btn-block w-100" type="button" onClick={() => handleKycApproveRemark(verificationRemark)}>
                    {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      : <span> Submit </span>}
                  </button>
                  <button className="btn btn-secondary  btn-block w-100 mt-1" type="button" onClick={() => setShowModelApprove(false)}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-backdrop fade show"></div>
          </>
        )
      }


    </>

  );
};

export default UpgradeKycDetails;
