import { ApiCallDelete, ApiCallGet, ApiCallPost, ApiCallPut } from "./ApiCall";
import { ApiConfig } from "./ApiConfig";
const TAG = "AuthService";

const AuthService = {

  login: async ({ email, password }) => {
    const { baseUrl, login } = ApiConfig;
    const url = baseUrl + login;
    const params = {
      email: email,
      password: password,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getTotaluser: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, dashboard } = ApiConfig;
    const url = baseUrl + dashboard;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getSubAdminList: async (skip, limit, searchKey) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, getSubAdminList } = ApiConfig;
    const url = `${baseUrl}${getSubAdminList}?skip=${skip}&limit=${limit}&search=${searchKey}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getAdminUserDetails: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, single_subadmin } = ApiConfig;
    const url = `${baseUrl}${single_subadmin}/${id}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  AddsubAdmin: async ({ fullName, mobileNumber, email, password, confirmPassword, permissions }) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, AddsubAdmin } = ApiConfig;
    const url = baseUrl + AddsubAdmin;
    const params = {
      full_name: fullName,
      phone: mobileNumber,
      email: email,
      password: password,
      confirm_password: confirmPassword,
      permissions: permissions,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  updateSubAdmin: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, updateSubadmin } = ApiConfig;
    const url = baseUrl + updateSubadmin;
    const params = {
      full_name: formData.userName,
      email: formData.email,
      phone: formData.mobileNumber,
      password: formData.password,
      permissions: formData.permissions,
      id: formData.userId,
      status: formData.status,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  deleteSubAdminList: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, subadmindelete } = ApiConfig;
    const url = `${baseUrl}${subadmindelete}/${userId}`;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  // Subadmin ends


  getAllTickets: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, getAllTickets } = ApiConfig;
    const url = baseUrl + getAllTickets;
    const headers = {
      "content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },









  // TradeList starts

    getUsersList: async (skip, limit, kycTypeUsers, activeTab, searchKey, fromDate, toDate) => {
      const token = sessionStorage.getItem("token");
      const { baseUrl, users_list } = ApiConfig;
      const url = `${baseUrl}${users_list}/${kycTypeUsers}?status=${activeTab}&startDate=${fromDate}&endDate=${toDate}&skip=${skip}&limit=${limit}&searchParams=${searchKey}`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };
      return ApiCallGet(url, headers);
    },

/*   getUsersList: async () => {
    const token = sessionStorage.getItem("token");

    const { baseUrl, users_list } = ApiConfig;
    const url = baseUrl + users_list;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,

    };
    return ApiCallGet(url, headers);
  }, */

  changeUsersStatus: async (_id, status) => {
    const token = sessionStorage.getItem("token");

    const { baseUrl, update_user_status } = ApiConfig;
    const url = baseUrl + update_user_status;
    const params = {
      userId: _id,
      status
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,

    };
    return ApiCallPut(url, params, headers);
  },



  // Trade Details


  getuserDetails: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl } = ApiConfig;
    const url = `${baseUrl}${'user-details'}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },



  updateEmailAddress: async (email, userName, userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl } = ApiConfig;
    const url = `${baseUrl}${'change-email'}/${userId}`;

    const params = {
      new_email: email,
      name: userName,
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  updateMobileNumber: async (mobileNumber, userName, userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl } = ApiConfig;
    const url = `${baseUrl}${'change-mobile'}/${userId}`;

    const params = {
      new_phone_number: mobileNumber,
      name: userName,
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },




  transferhistory: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, transferhistory } = ApiConfig;
    const url = baseUrl + transferhistory;
    const params = {
      userId: id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getuserKycDetail: async (skip, limit, searchKey, status) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, user_list } = ApiConfig;
    const url = `${baseUrl}${user_list}/${status}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getKycDetail: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl } = ApiConfig;
    const url = `${baseUrl}${'kyc-details'}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getKycdataEntity: async (skip, limit, searchKey, status) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, entity_user_list } = ApiConfig;
    const url = `${baseUrl}${entity_user_list}/${status}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getStepUserData: async (userId, step) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, step_wise_data } = ApiConfig;
    const url = `${baseUrl}${step_wise_data}/${userId}/${step}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getuserAmlCheck: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, aml_details } = ApiConfig;
    const url = `${baseUrl}${aml_details}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getMasterKycData: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, master_kyc_data } = ApiConfig;
    const url = `${baseUrl}${master_kyc_data}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  getCurrentKycStatus: async (userId, step) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, kyc_status_user } = ApiConfig;
    const url = `${baseUrl}${kyc_status_user}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  kycVerifyRemark: async (aprovalRemark, userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, kycVerify_remark } = ApiConfig;
    const url = `${baseUrl}${kycVerify_remark}/${userId}`;
    const params = {
      remark: aprovalRemark
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getVerifyRemark: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, getVerify_remark } = ApiConfig;
    const url = `${baseUrl}${getVerify_remark}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getBannerList: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, bannerList } = ApiConfig;
    const url = baseUrl + bannerList;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  getUserPersnolKycDetails: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, user_details } = ApiConfig;
    const url = `${baseUrl}${user_details}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },











  addNotify: async (notificationTitle, description) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, addNotify } = ApiConfig;
    const url = baseUrl + addNotify;
    const params = {
      title: notificationTitle,
      description: description,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  deleteNotify: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, deleteNotify } = ApiConfig;
    const url = `${baseUrl}${deleteNotify}/${id}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token
    };

    return ApiCallDelete(url, headers);
  },






  cancelOrder: async (orderID, userID) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, cancelOrder } = ApiConfig;
    const url = baseUrl + cancelOrder;
    const params = {
      order_id: orderID,
      userId: userID,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  getNotificationList: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, notificationList } = ApiConfig;
    const url = baseUrl + notificationList;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },



  handleFundDenied: async (id, status, Hash) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, transactionstatus } = ApiConfig;
    const url = baseUrl + transactionstatus;
    const params = {
      id: id,
      status: status,
      transaction_hash: Hash,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },






  addCoinWidthraw: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseSecure, setcoinwithdrawal } = ApiConfig;
    const url = baseSecure + setcoinwithdrawal;

    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: token,
    };

    return ApiCallPut(url, formData, headers);
  },

  CoinCategory: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, CoinCategory } = ApiConfig;
    const url = baseUrl + CoinCategory;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGet(url, headers);
  },


  tradingCommission: async (skip, limit) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, tradingCommission } = ApiConfig;
    const url = baseUrl + tradingCommission;
    const params = {
      skip: skip,
      limit: limit,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },



  AddCoinPair: async (fShortName, fId, sShortName, sId, sellPrice, buyPrice, available) => {
    const token = sessionStorage.getItem("token");
    const { baseCoin, AddCoinPair } = ApiConfig;
    const url = baseCoin + AddCoinPair;
    const params = {
      base_currency: fShortName,
      quote_currency: sShortName,
      base_currency_id: fId,
      quote_currency_id: sId,
      buy_price: buyPrice,
      sell_price: sellPrice,
      available: available
    };



    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },


  getCurrencyPairList: async () => {
    const token = sessionStorage.getItem("token");
    const { baseCoin, getCurrencyPairList } = ApiConfig;

    const url = baseCoin + getCurrencyPairList;

    const params = {};



    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGet(url, headers);
  },

  deleteCurrency: async (_id, status) => {
    const token = sessionStorage.getItem("token");
    const { baseSecure, deleteCoinPair } = ApiConfig;
    const url = baseSecure + deleteCoinPair;
    const params = {
      _id: _id,
      status: status,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },
  botStatus: async (_id, status, gap) => {
    const token = sessionStorage.getItem("token");
    const { baseSecure, botStatus } = ApiConfig;
    const url = baseSecure + botStatus;
    const params = {
      _id: _id,
      status: status,
      gap: gap,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },



  tradeHistory: async (skip, limit) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, tradeHistory } = ApiConfig;
    const url = `${baseUrl}${tradeHistory}?skip=${skip}&limit=${limit}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  OrderBook: async () => {
    const token = sessionStorage.getItem("token");
    const { baseExchange, OrderBook } = ApiConfig;

    const url = baseExchange + OrderBook;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },
  tradeById: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseExchange, tradeById } = ApiConfig;

    const url = baseExchange + tradeById;
    const params = {
      "order_id": id
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  completeWithdrawalRequest: async () => {
    const { baseUrl, completeWithdrawalRequest } = ApiConfig;
    const url = baseUrl + completeWithdrawalRequest;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },
  PendingWithdrwal: async () => {
    const { baseUrl, PendingWithdrwal } = ApiConfig;
    const url = baseUrl + PendingWithdrwal;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },


  CancelledWithdrwal: async () => {
    const { baseUrl, CancelledWithdrwal } = ApiConfig;
    const url = baseUrl + CancelledWithdrwal;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },

  completeDepositRequest: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, completeDepositRequest } = ApiConfig;
    const url = baseUrl + completeDepositRequest;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGet(url, headers);
  },
  getPendingDeposit: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, pendingDeposits } = ApiConfig;
    const url = baseUrl + pendingDeposits;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  miscellaneousRequest: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, miscellaneousRequest } = ApiConfig;

    const url = baseUrl + miscellaneousRequest;


    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGet(url, headers);
  },


  getCoinList: async () => {
    const { baseCoin, getCoinList } = ApiConfig;
    const url = baseCoin + getCoinList;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },

  getAdminWalletBalance: async (currency_id) => {
    const token = sessionStorage.getItem("token");

    const { baseWallet, admin_wallet } = ApiConfig;
    const url = `${baseWallet}${admin_wallet}?currency_id=${currency_id}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  createCategory: async (name) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, createCategory } = ApiConfig;
    const url = baseUrl + createCategory;
    const params = {
      name: name
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },



  exportApprovedList: async (dateFrom, dateTo) => {
    const token = sessionStorage.getItem("token");
    const { baseReport, verifiedtrader } = ApiConfig;
    const url = baseReport + verifiedtrader;
    const params = {
      fromDate: dateFrom,
      toDate: dateTo,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },



  addCoins: async (formData) => {
    const { baseCoin, addNewCoins } = ApiConfig;
    const url = baseCoin + addNewCoins;
    const params = {
    };

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return ApiCallPost(url, formData, headers);
  },



  AddBanner: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, Addbanner } = ApiConfig;
    const url = baseUrl + Addbanner;

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPost(url, formData, headers);
  },

  getBannerList: async () => {
    const token = sessionStorage.getItem("token");
    const { baseSecure, bannerList } = ApiConfig;
    const url = baseSecure + bannerList;
    const params = {};

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  updateBannerList: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, updateBanner } = ApiConfig;
    const url = baseUrl + updateBanner;

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPut(url, formData, headers);
  },

  deletebannerlist: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, bannerdelete } = ApiConfig;
    const url = baseUrl + bannerdelete;
    const params = {
      _id: userId,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  handleBannerStatus: async (userId, cell) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, BannerStatus } = ApiConfig;
    const url = baseUrl + BannerStatus;
    const params = {
      _id: userId,
      status: cell,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  updateTicketStatus: async (id, status) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, updateTicketStatus } = ApiConfig;
    const url = baseUrl + updateTicketStatus;
    const params = {
      id, status
    };
    const headers = {
      "content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },


  replyTicket: async (messagerply, id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, replyTicket } = ApiConfig;
    const url = baseUrl + replyTicket;
    const params = {
      message_by: 0,
      message: messagerply,
      ticket_id: id,
    };
    const headers = {
      "content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getMessageById: async (ticketId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, single_ticket } = ApiConfig;
    const url = `${baseUrl}${single_ticket}/${ticketId}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };

    return ApiCallGet(url, headers);
  },



  AddQuickyBuySell: async (side, base_currency, quote_currency, base_currency_id, quote_currency_id, price, quote_min, quote_max, bulk_trades, total_quantity, base_currency_image, quote_currency_image) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, add_qbs } = ApiConfig;
    const url = baseUrl + add_qbs;
   const params ={
     side,
     base_currency,
     quote_currency,
     base_currency_id,
     quote_currency_id,
     price: Number(price),
     quote_min : Number(quote_min),
     quote_max: Number(quote_max),
     bulk_trades,
     total_quantity : Number(total_quantity),
     base_currency_image,
     quote_currency_image
   }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },




  getQbsList: async (orderSide, status, adsType) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, addedqbsHistoy } = ApiConfig;
    const url = `${baseUrl}${addedqbsHistoy}?side=${orderSide}&status=${status}&bulk_trades=${adsType}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGet(url, headers);
  },

  deleteQbs: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, deleteQbsList } = ApiConfig;
    const url = `${baseUrl}${deleteQbsList}/${id}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallDelete(url, headers);
  },

  updateQbsSettingTargetValue: async (id,newPrice, newQuantity) => {
    const token = sessionStorage.getItem("token");
   const { baseUrl, update_qbs_settings } = ApiConfig;
    const url = baseUrl + update_qbs_settings;
    const params = {
      id:id,
      updated_price : Number(newPrice),
      updated_total_quantity: Number(newQuantity),
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url,params, headers);
  },

  updateQbsSettingStatus: async (id,status) => {
    const token = sessionStorage.getItem("token");
   const { baseUrl, update_qbs_settings_status } = ApiConfig;
    const url = baseUrl + update_qbs_settings_status;
    const params = {id,status};
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url,params, headers);
  },


  getBuySellAllHistory: async (skip, limit, queryString) => {
    const token = sessionStorage.getItem("token");
    const { baseQbs, allqbsHistoy } = ApiConfig;
    const url = `${baseQbs}${allqbsHistoy}?${queryString}&skip=${skip}&limit=${limit}`;
    const headers = {
        "Content-Type": "application/json",
        Authorization: token,
    };
    return ApiCallGet(url, headers);
},


  updateQbsStatus: async (userId, orderId, status,remark) => {
    const token = sessionStorage.getItem("token");
   const { baseQbs, update_qbs_status } = ApiConfig;
    const url = baseQbs + update_qbs_status;
    const params = {
      userId:userId, 
      qbsId:orderId, 
      status:status, 
      remark:remark
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url,params, headers);
  },


  updateRefundStatus: async (remark, utr, mode, qbsId) => {
    const token = sessionStorage.getItem("token");
   const { baseQbs, process_refund } = ApiConfig;
    const url = baseQbs + process_refund;
    const params = {
      refund_remark:remark,
      refund_utr:utr,
      refund_mode:mode,
      qbsId:qbsId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url,params, headers);
  },

  getBankList: async (status, skip, limit, searchKey) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, bank_list } = ApiConfig;
    const url = `${baseUrl}${bank_list}/${status}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  getBankUserList: async (accountNumber, userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, bank_details } = ApiConfig;
    const url = `${baseUrl}${bank_details}/${userId}/${accountNumber}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  verifyBankDetails: async (accountNumber, ifsc, kycName, userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrlInternal, verify_bank_details } = ApiConfig;
    const url = baseUrlInternal + verify_bank_details;
    const params = {
      accountNumber: accountNumber,
      ifsc: ifsc,
      kycName: kycName,
      userId: userId
    };
    const headers = {
      "content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  changeBankStatus: async (status, id, userId, remarks) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, change_bank_status } = ApiConfig;
    const url = `${baseUrl}${change_bank_status}/${userId}`;
    const params = {
      status: status,
      id: id,
      remarks: remarks,
    };
    const headers = {
      "content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  getUpiList: async (status, skip, limit, searchKey) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, upi_list } = ApiConfig;
    const url = `${baseUrl}${upi_list}/${status}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },



  rejectUserKycStep: async (rejectedType, reasonMessage, rejectedSteps, userId, reasonNew) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, reject_user_kyc } = ApiConfig;
    const url = `${baseUrl}${reject_user_kyc}/${userId}`;
    const params = {
      type: rejectedType,
      kyc_reject_reason: reasonNew,
      ...(reasonMessage && rejectedType === "reject_all" || "rekyc" ? { arr: [{ reason: reasonMessage }] } : {}),
      ...(rejectedSteps?.length && rejectedType === "partial_reject" ? { arr: rejectedSteps } : {}),
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },


  getCoinListingList: async (skip, limit, searchKey) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, coin_listing_list } = ApiConfig;
    const url = `${baseUrl}${coin_listing_list}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getFeedbackList: async (skip, limit, searchKey) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, feedback_list } = ApiConfig;
    const url = `${baseUrl}${feedback_list}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  getPartnershipList: async (skip, limit, searchKey) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, partnership_list } = ApiConfig;
    const url = `${baseUrl}${partnership_list}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  getFeeStractureQbsList: async (skip, limit, searchKey) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, qbs_fee_list } = ApiConfig;
    const url = `${baseUrl}${qbs_fee_list}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getFeeStractureSpotList: async (skip, limit, searchKey) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, spot_fee_list } = ApiConfig;
    const url = `${baseUrl}${spot_fee_list}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  addFeeStractureQbs: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, add_qbs_fee } = ApiConfig;
    const url = baseUrl + add_qbs_fee;
    const params = {
      currency_name: formData?.currency_name,
      short_name: formData?.short_name,
      trading_fee: formData?.trading_fee,
      min_deposit: formData?.min_deposit,
      min_withdrawal: formData?.min_withdrawal,
      deposit_fee: formData?.deposit_fee,
      withdrawal_fee: formData?.withdrawal_fee,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  editFeeStractureQbs: async (formData, id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl } = ApiConfig;
    const url = baseUrl + `update-qbs-fee/${id}`;
    const params = {
      currency_name: formData?.currency_name,
      short_name: formData?.short_name,
      trading_fee: formData?.trading_fee,
      min_deposit: formData?.min_deposit,
      min_withdrawal: formData?.min_withdrawal,
      deposit_fee: formData?.deposit_fee,
      withdrawal_fee: formData?.withdrawal_fee,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPut(url, params, headers);
  },

  deleteFeeStractureQbs: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, delete_qbs_fee } = ApiConfig;
    const url = `${baseUrl}${delete_qbs_fee}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallDelete(url, headers);
  },


  addFeeStractureSpot: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, add_spot_fee } = ApiConfig;
    const url = baseUrl + add_spot_fee;
    const params = {
      currency_name: formData?.currency_name,
      short_name: formData?.short_name,
      trading_fee: formData?.trading_fee,
      min_withdrawal: formData?.min_withdrawal,
      withdrawal_fee: formData?.withdrawal_fee,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  editFeeStractureSpot: async (formData, id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl } = ApiConfig;
    const url = baseUrl + `update-spot-fee/${id}`;
    const params = {
      trading_fee: formData?.trading_fee,
      min_withdrawal: formData?.min_withdrawal,
      withdrawal_fee: formData?.withdrawal_fee,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPut(url, params, headers);
  },

  deleteFeeStractureSpot: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, delete_spot_fee } = ApiConfig;
    const url = `${baseUrl}${delete_spot_fee}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallDelete(url, headers);
  },


  getMarketMakerList: async (skip, limit, searchKey) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, market_maker_list } = ApiConfig;
    const url = `${baseUrl}${market_maker_list}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getFeeStractureQbsList: async (skip, limit, searchKey) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, qbs_fee_list } = ApiConfig;
    const url = `${baseUrl}${qbs_fee_list}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  getOpeningJobList: async (skip, limit, searchKey) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, job_openings_list } = ApiConfig;
    const url = `${baseUrl}${job_openings_list}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  changeJobOpeningStatus: async (status, id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, update_job_opening_status } = ApiConfig;
    const url = baseUrl + update_job_opening_status;
    const params = {
      status, id
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  CreateNewJobOpenings: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, create_job_opening } = ApiConfig;
    const url = baseUrl + create_job_opening;
    const params = {
      job_title: formData?.job_title,
      experience: formData?.experience,
      location: formData?.location,
      skills: formData?.skills,
      job_description: formData?.job_description,
      deposit_fee: formData?.deposit_fee,
      withdrawal_fee: formData?.withdrawal_fee,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },


  getApplicationJobList: async (skip, limit, searchKey) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, all_job_applications } = ApiConfig;
    const url = `${baseUrl}${all_job_applications}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  ViewJobApplicationbyId: async (skip, limit, searchKey, userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, job_application } = ApiConfig;
    const url = `${baseUrl}${job_application}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  AddSupportTicket: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, create_support_ticket } = ApiConfig;
    const url = baseUrl + create_support_ticket;
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: token,
    };
    return ApiCallPost(url, formData, headers);
  },

  closeSupportTicket: async (formData, userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, update_ticket } = ApiConfig;
    const url = `${baseUrl}${update_ticket}/${userId}`;
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: token,
    };
    return ApiCallPut(url, formData, headers);
  },


  getUserSupport: async (skip, limit, searchKey, ticketStatus, ticketLevel) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, all_support_tickets } = ApiConfig;
    const url = `${baseUrl}${all_support_tickets}?skip=${skip}&limit=${limit}&searchParams=${searchKey}&status=${ticketStatus}&ticket_level=${ticketLevel}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getUserSupportTicketDetails: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, support_ticket } = ApiConfig;
    const url = `${baseUrl}${support_ticket}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },











  getUpgradeUserKycList: async (skip, limit, searchKey, status) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, upgrade_user_list } = ApiConfig;
    const url = `${baseUrl}${upgrade_user_list}/${status}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },
  getUpgradeUserStepData: async (userId, step) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, upgrade_user_step_data } = ApiConfig;
    const url = `${baseUrl}${upgrade_user_step_data}/${userId}/${step}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  rejectUpgradeUserKycStep: async (formattedSteps, userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, reject_user_upgrade } = ApiConfig;
    const url = `${baseUrl}${reject_user_upgrade}/${userId}`;
    const params = {
      payload: formattedSteps
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  upgradeKycVerifyRemark: async (aprovalRemark, userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, upgrade_kycVerify_remark } = ApiConfig;
    const url = `${baseUrl}${upgrade_kycVerify_remark}/${userId}`;
    const params = {
      remark: aprovalRemark
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getRemarkUpgrade: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, get_remark_upgrade } = ApiConfig;
    const url = `${baseUrl}${get_remark_upgrade}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  adminBankList: async (status) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, admin_bank_details } = ApiConfig;
    
    let url = `${baseUrl}${admin_bank_details}`;
    url += status !== "Deleted" && status !== "Unsuccessful" ? `?status=${status}` : status === "Unsuccessful" ? `?verified=false` : `?isDeleted=true`;
  
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
  
    return ApiCallGet(url, headers);
  },

  adminUpiList: async (status) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, get_admin_upi_details } = ApiConfig;
    
    let url = `${baseUrl}${get_admin_upi_details}`;
    // url += status !== "Deleted" ? `?status=${status}` : `?isDeleted=true`;
    url += status !== "Deleted" && status !== "Unsuccessful" ? `?status=${status}` : status === "Unsuccessful" ? `?verified=false` : `?isDeleted=true`;
  
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
  
    return ApiCallGet(url, headers);
  },

  adminAddBankDetails: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, add_admin_bank_details } = ApiConfig;
    const url = baseUrl + add_admin_bank_details;
    const params = {
      accountHolderName: formData?.accountHolderName,
      accountNumber: formData?.accountNumber,
      ifsc: formData?.ifsc,
      bankName: formData?.bankName,
      branchName: formData?.branchName,
      accountType: formData?.accountType,
      additionRemark: formData?.closeRemark,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },
  
  deleteAdminBank: async (id, remark) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, delete_admin_bank_details } = ApiConfig;
    const url = baseUrl + delete_admin_bank_details;
    const params = {
      bankId: id,
      deletionRemark: remark,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };

    return ApiCallPut(url,params, headers);
  },


  updateBankStatus: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, update_bank_status } = ApiConfig;
    const url = `${baseUrl}${update_bank_status}/${id}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };

    return ApiCallGet(url, headers);
  },


  adminAddUpiDetails: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, add_admin_upi_details } = ApiConfig;
    const url = baseUrl + add_admin_upi_details;
    const params = {
      upiName: formData?.upiName,
      vpaId: formData?.vpaId,
      additionRemark: formData?.upiAdditionRemark
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },


  updateUpiStatus: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, update_upi_status } = ApiConfig;
    const url = `${baseUrl}${update_upi_status}/${id}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };

    return ApiCallGet(url, headers);
  },


  deleteAdminUpi: async (id, remark) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, delete_admin_upi_details } = ApiConfig;
    const url = baseUrl + delete_admin_upi_details;
    const params = {
      upiId: id,
      deletionRemark: remark,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };

    return ApiCallPut(url,params, headers);
  },
 
  updateUserDepositWithdrawalLimit: async (formData,userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, apply_limit_user } = ApiConfig;
    const url = `${baseUrl}${apply_limit_user}/${userId}`;
    const params = {
      remark: formData.remark,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  updateOverAllLimit: async (value, key,userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, check_user_overall_limit } = ApiConfig;
    const url = `${baseUrl}${check_user_overall_limit}/${userId}`;
    const params = {
      data: key,
      amount: value,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  
  adminLimiDetails: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, internal_exchange_limit} = ApiConfig;
    const url = baseUrl + internal_exchange_limit;
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };

    return ApiCallGet(url, headers);
  },

  exchangeLimitSession: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, exchange_limit_session} = ApiConfig;
    const url = baseUrl + exchange_limit_session;
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };

    return ApiCallGet(url, headers);
  },

  updateLimitRemark: async (remark) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, apply_users_limit } = ApiConfig;
    const url = baseUrl + apply_users_limit;
    const params = {remark};
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };

    return ApiCallPut(url,params, headers);
  },
  
  userLimiDetails: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, user_limit } = ApiConfig;
    const url = `${baseUrl}${user_limit}/${id}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };

    return ApiCallGet(url, headers);
  },

  getUserNominiDetails: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, nominee_details } = ApiConfig;
    const url = `${baseUrl}${nominee_details}/${id}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };

    return ApiCallGet(url, headers);
  },

  updateNominiDetails: async (formData, userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl } = ApiConfig;
    const url = `${baseUrl}${'reset-nominee'}/${userId}`;

    const params = {
      remark: formData?.rejectReason,
      ticketNumber: formData?.ticketNumber,
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

 

  adminQuickPayLimiDetails: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, quick_pay_limit} = ApiConfig;
    const url = baseUrl + quick_pay_limit;
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };

    return ApiCallGet(url, headers);
  },

  getUserQbsLimits: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseuserQbs, get_qbs_limits } = ApiConfig;
    const url = `${baseuserQbs}${get_qbs_limits}/${id}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };
    return ApiCallGet(url, headers);
  },


  updateUserQbsLimit: async (formData,userId) => {
    const token = sessionStorage.getItem("token");
    const { baseQbs, update_user_limit } = ApiConfig;
    const url = baseQbs + update_user_limit;
    const params = {
      userId:userId,
      updatedBuyLimit: formData?.updatedBuyLimit,
      updatedCashLimit: formData?.updatedCashLimit,
      updatedOrders: formData?.updatedOrders,
      updatedBuyFees: formData?.updatedBuyFees,
      updatedBuyTds: formData?.updatedBuyTds,
      updatedSellFees: formData?.updatedSellFees,
      updatedSellTds: formData?.updatedSellTds,
      bulkTrader: !formData?.bulkTrader ? false : formData?.bulkTrader,
      remark: formData?.remarkQbs,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },


  getUserQuickPayLimits: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, quick_pay_limit_user } = ApiConfig;
    const url = `${baseUrl}${quick_pay_limit_user}/${id}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };

    return ApiCallGet(url, headers);
  },
  
  updateUserQuickPayLimit: async (formData,userId) => {
    const token = sessionStorage.getItem("token");
   
    const { baseUrl, quick_pay_limit_user } = ApiConfig;
    const url = `${baseUrl}${quick_pay_limit_user}/${userId}`;
    
    const params = {
      freeInrLimit: formData?.quickyPayfreeInrLimit,
      dailyInrLimit: formData?.quickyPaydailyInrLimit,
      minAmountInr: formData?.quickyPayminAmountInr,
      maxAmountInr: formData?.quickyPaymaxAmountInr,
      inrFee: formData?.quickyPayinrFee,
      freeUsdLimit: formData?.quickyPayfreeUsdLimit,
      dailyUsdLimit: formData?.quickyPaydailyUsdLimit,
      minAmountUsd: formData?.quickyPayminAmountUsd,
      maxAmountUsd: formData?.quickyPaymaxAmountUsd,
      Fees: formData?.quickyPaycryptoFee,
      totalOrders: formData?.quickyPaytotalOrders,
      remark: formData?.quickyPayremark,
    
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },
  

  adminBuySellLimiDetails: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAdminQbs, get_qbs_limits} = ApiConfig;
    const url = baseAdminQbs + get_qbs_limits;
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };

    return ApiCallGet(url, headers);
  },

  updateExchangeQuickyPayLimit: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, quick_pay_limit} = ApiConfig;
    const url = baseUrl + quick_pay_limit;
    const params = {
      freeInrLimit: Number(formData?.inrTotalWithdrawal),
      dailyInrLimit: Number(formData?.inrTotalDailyWithdrawalLimit),
      minAmountInr: Number(formData?.inrMinWithdrawalLimit),
      maxAmountInr: Number(formData?.inrMaxWithdrawalLimit),
      freeUsdLimit: Number(formData?.cryptoTotalWithdrawal),
      dailyUsdLimit: Number(formData?.cryptoTotalDailyWithdrawalLimit),
      minAmountUsd: Number(formData?.cryptoMinWithdrawalLimit),
      maxAmountUsd: Number(formData?.cryptoMaxWithdrawalLimit),
      inrFee: Number(formData?.inrFee),
      cryptoFee: Number(formData?.cryptoFee),
      totalOrders: Number(formData?.dailyPay),
      remark: formData?.remark,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },


  addPaymentOptions: async (formData,paymentMethod) => {
    const token = sessionStorage.getItem("token");
    const { baseAdminQbs, add_payment_option } = ApiConfig;
    const url = baseAdminQbs + add_payment_option;
    const params = {
      paymentCategory: paymentMethod,
      paymentType: formData?.paymentType,
      bankDetailsId: formData?.bankName,
      upiDetailsId: formData?.upiId,
      additionRemark: formData?.remark,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  qbsPaymentOptions: async (paymentMode, paymentStatus) => {
    const token = sessionStorage.getItem("token");
    const { baseAdminQbs, payment_options } = ApiConfig;
    let url = `${baseAdminQbs}${payment_options}?paymentCategory=${paymentMode}`;
     url += paymentStatus !== "Deleted" ? `&status=${paymentStatus}` : `&isDeleted=true`;
      const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
  
    return ApiCallGet(url, headers);
  },



  updateQbsPaymentOptions: async (status,item) => {
     const token = sessionStorage.getItem("token");
    const { baseAdminQbs, update_payment_option } = ApiConfig;
    const url = baseAdminQbs + update_payment_option;
    const params = {
      status: status,
      Id: item?._id,
      bankDetailsId:  item?.bankDetails?._id,
      upiDetailsId: item?.upiDetails?._id
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };
    return ApiCallPut(url, params,headers);
  },

  deletePaymentMethod: async (id, remark) => {
    const token = sessionStorage.getItem("token");
    const { baseAdminQbs, delete_payment_option } = ApiConfig;
    const url = baseAdminQbs + delete_payment_option;
    const params = {
      option_id: id,
      deletionRemark: remark,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };

    return ApiCallPut(url,params, headers);
  },

  updatedValueNewVar: async (value,key) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, exchange_limit_validity } = ApiConfig;
    const url = baseUrl + exchange_limit_validity;
    const params = {
      value: Number(value),
      key
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  updateExchangeBuySellLimit: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseAdminQbs, update_limit} = ApiConfig;
    const url = baseAdminQbs + update_limit;
    const params = {
      dailyBuyLimit: Number(formData?.dailyBuyLimit),
      dailyCashLimit: Number(formData?.dailyCashLimit),
      dailyOrders: Number(formData?.dailyOrders),
      buyFees: Number(formData?.buyFees),
      sellFees: Number(formData?.sellFees),
      buyTds: Number(formData?.buyTds),
      sellTds: Number(formData?.sellTds),
      remark: formData?.remark,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  applyUserupdate: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseQbs, update_dependent_limits} = ApiConfig;
    const url = baseQbs + update_dependent_limits;
    const params = {
      updatedBuyLimit: Number(formData?.dailyBuyLimit),
      updatedCashLimit: Number(formData?.dailyCashLimit),
      updatedOrders: Number(formData?.dailyOrders),
      updatedBuyFees: Number(formData?.buyFees),
      updatedSellFees: Number(formData?.sellFees),
      updatedBuyTds: Number(formData?.buyTds),
      updatedSellTds: Number(formData?.sellTds),
      remark: formData?.remark,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  getAdminWallets: async (queryString) => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, admin_wallet } = ApiConfig;
    const url = `${baseWallet}${admin_wallet}?${queryString}`;
    const headers = {
        "Content-Type": "application/json",
        Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  updateWalletBalance: async (selectedWalletId, amount, activeFundTab, currencyId, remark) => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, update_balance} = ApiConfig;
    const url = baseWallet + update_balance;
    const params = {
      wallet_id: selectedWalletId,
      amount: amount,
      currency_id:currencyId,
      remark:remark,
      type:activeFundTab
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getUserWallets: async (skip, limit, queryString) => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, user_wallets } = ApiConfig;
    const url = `${baseWallet}${user_wallets}?${queryString}&skip=${skip}&limit=${limit}`;
    const headers = {
        "Content-Type": "application/json",
        Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  fundTransferUsers: async (remark, amount, walletId, userId) => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, user_fund_transfer} = ApiConfig;
    const url = baseWallet + user_fund_transfer;
    const params = {
      remark: remark,
      amount: Number(amount),
      currency_id: walletId,
      user_id: userId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  userFundholdUnhold: async (remark, amount, mode, currecncyId, userID) => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, hold_unhold_fund_user} = ApiConfig;
    const url = baseWallet + hold_unhold_fund_user;
    const params = {
      remark: remark,
      amount: Number(amount),
      type: mode,
      currency_id: currecncyId,
      user_id: userID,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  adminFundholdUnhold: async (selectedWalletId, currencyId, amountType, amount, remark) => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, hold_unhold_fund_admin} = ApiConfig;
    const url = baseWallet + hold_unhold_fund_admin;
    const params = {
      remark: remark,
      amount: Number(amount),
      type: amountType,
      currency_id: currencyId,
      wallet_id: selectedWalletId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },




  fetchTransactions: async (queryString) => {
    const token = sessionStorage.getItem("token");
    const { baseWalletAdmin, fetch_transactions } = ApiConfig;
    const url = `${baseWalletAdmin}${fetch_transactions}?${queryString}`;
    const headers = {
        "Content-Type": "application/json",
        Authorization: token,
    };
    return ApiCallGet(url, headers);
  },
  




  getWithdrwalPaymentOptions: async (queryString) => {
    const token = sessionStorage.getItem("token");
    const { baseWithdrawal, payment_option } = ApiConfig;
    const url = `${baseWithdrawal}${payment_option}?${queryString}`;
    const headers = {
        "Content-Type": "application/json",
        Authorization: token,
    };
    return ApiCallGet(url, headers);
  },



  withdrawalPayment: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseWithdrawal, payment_option} = ApiConfig;
    const url = baseWithdrawal + payment_option;
    const params = {
      paymentOption: formData?.paymentOption,
      paymentMode: formData?.paymentMode,
      bankMode:formData?.bankMode,
      remark:formData?.remark,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  updateWithdrawaltOptions: async (status, Id) => {
    const token = sessionStorage.getItem("token");
    const { baseWithdrawal, payment_option } = ApiConfig;
    const url = `${baseWithdrawal}${payment_option}/${Id}/${status}`;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  deleteWithdrawalPaymentMethod: async (id, remark) => {
    const token = sessionStorage.getItem("token");
    const { baseWithdrawal2, delete_payment_option } = ApiConfig;
    const url = `${baseWithdrawal2}${delete_payment_option}/${id}`;
    const params = {
      remark: remark,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token
    };

    return ApiCallPut(url,params, headers);
  },


  getDirectWithdrawalLimit: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, get_withdrawal_limit} = ApiConfig;
    const url = baseUrl + get_withdrawal_limit;
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };

    return ApiCallGet(url, headers);
  },
  

  updateDirectWithdrawalLimit: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, update_withdrawal_limit} = ApiConfig;
    const url = baseUrl + update_withdrawal_limit;
    const params = {
      total_orders: Number(formData?.total_orders),
      min_order: Number(formData?.min_order),
      max_order: Number(formData?.max_order),
      fee: Number(formData?.fee),
      tds: Number(formData?.tds),
      remark: formData?.remark,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },



  getDirectWithdrawalLimitUser: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, user_withdrawal_limit } = ApiConfig;
    const url = `${baseUrl}${user_withdrawal_limit}/${userId}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization : token
    };
    return ApiCallGet(url, headers);
  },

  updateDirectWithdrawalLimitUser: async (formData,userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, update_withdrawal_limit } = ApiConfig;
    const url = `${baseUrl}${update_withdrawal_limit}/${userId}`;
    const params = {
      total_orders: Number(formData?.total_orders),
      min_order: Number(formData?.min_order),
      max_order: Number(formData?.max_order),
      fee: Number(formData?.fee),
      tds: Number(formData?.tds),
      remark: formData?.remark,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },



  adminFundTransfer: async (selectedFromWallet, selectedToWallet, amount, selectedWalletId,remark) => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, internal_fund_transfer } = ApiConfig;
    const url = baseWallet + internal_fund_transfer;
    const params = {
      from_wallet_type: selectedFromWallet,
      to_wallet_type: selectedToWallet,
      amount: amount,
      currency_id: selectedWalletId,
      remark: remark,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },



  fetchMasterAdminTransactions: async (queryString) => {
    const token = sessionStorage.getItem("token");
    const { baseWalletAdmin, fetch_transactions } = ApiConfig;
    const url = `${baseWalletAdmin}${fetch_transactions}?${queryString}`;
    const headers = {
        "Content-Type": "application/json",
        Authorization: token,
    };
    return ApiCallGet(url, headers);
  },



};

export default AuthService;



// const { baseUrl, add_admin_bank_details, edit_admin_bank_details } = ApiConfig;
    
// const url = editButton ? baseUrl + edit_admin_bank_details : baseUrl + add_admin_bank_details;