import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../../../../services/ApiIntegration/AuthService';

const AddJobOpening = () => {


    const navigate = useNavigate();
    const [apiResponse, setApiResponse] = useState("");
    const [error, setError] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        job_title: '',
        experience: '',
        location: '',
        skills: '',
        job_description: '',
    });

    const handleChange = (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
            setError((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }
        setApiResponse("");
    };


    const validationAddJob = (formData) => {
        if (!formData.job_title) {
            return { field: 'job_title', message: 'Job Title is required' };
        }
        if (!formData.experience) {
            return { field: 'experience', message: 'Job Experience is required' };
        }
        if (!formData.location) {
            return { field: 'location', message: 'Job Loacation is required' };
        }
        if (!formData.skills) {
            return { field: 'skills', message: 'Skills is required' };
        }
        if (!formData.job_description) {
            return { field: 'job_description', message: 'Job Description is required' };
        }
        return null;
    };

    const handleAddJob = async () => {
        const validationError = validationAddJob(formData);
        if (validationError) {
            setError({ [validationError.field]: validationError.message });
            return;
        }
        try {
            setIsLoading(true);
            const result = await AuthService.CreateNewJobOpenings(formData);
            if (result?.success) {
                navigate('/job_opening');
            } else {
                setApiResponse(result || 'An unexpected error occurred.');
                setIsLoading(false);
            }
        } catch (error) {
            setApiResponse(error?.message || 'An unexpected error occurred.');
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };



    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header">
                    <div className="container-xl px-4">
                        <div className="page-header-content">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto">
                                    <h1 className="page-header-title">Add Job Openings</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4">
                    <div className="card mb-4">
                        <div className="card-header">Enter Job Openings Details</div>
                        <div className="card-body pt-4">
                            <form>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-4 mt-2">
                                        <label className="small mb-1" htmlFor="job_title"> Job Title<em>*</em></label>
                                        <input
                                            className="form-control"
                                            id="job_title"
                                            name="job_title"
                                            type="text"
                                            value={formData.job_title}
                                            onChange={handleChange}
                                        />
                                        {error.job_title && <span className="text-danger">{error.job_title}</span>}
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="small mb-1" htmlFor="experience">Experience<em>*</em></label>
                                        <input
                                            className="form-control"
                                            id="experience"
                                            name="experience"
                                            type="text"
                                            value={formData.experience}
                                            onChange={handleChange}

                                        />
                                        {error.experience && <span className="text-danger">{error.experience}</span>}
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="small mb-1" htmlFor="location">Location<em>*</em></label>
                                        <input
                                            className="form-control"
                                            id="location"
                                            name="location"
                                            type="text"
                                            value={formData.location}
                                            onChange={handleChange}

                                        />
                                        {error.location && <span className="text-danger">{error.location}</span>}
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="small mb-1" htmlFor="skills">Skills<em>*</em></label>                                      
                                        <textarea className="form-control py-3" rows="3" placeholder="Enter Skills.." name="skills" value={formData.skills} onChange={handleChange}></textarea>

                                        {error.skills && <span className="text-danger">{error.skills}</span>}
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="small mb-1" htmlFor="job_description">Job Description<em>*</em></label>
                                        <textarea className="form-control py-3" rows="3" placeholder="Enter Job Description.." name="job_description" value={formData.job_description} onChange={handleChange}></textarea>
                                        {error.job_description && <span className="text-danger">{error.job_description}</span>}
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center w-100 mt-5 mb-4">
                                    <button
                                        className="btn btn-indigo w-25 fs-6"
                                        type="button"
                                        onClick={handleAddJob}
                                    >
                                        {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <span> Submit Details </span>}
                                    </button>

                                </div>

                            </form>
                            {apiResponse && <div className="text-danger">{apiResponse}</div>}

                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default AddJobOpening;
