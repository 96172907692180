import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import AuthService from "../../../services/ApiIntegration/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../CustomComponent/CustomAlert";
import Swal from "sweetalert2";
import moment from "moment";

const ExchangePayments = () => {

  const [activeTabMain, setActiveTabMain] = useState('Deposit');
  const [paymentMode, setPaymentMode] = useState("Manual");
  const [paymentMethod, setPaymentMethod] = useState("Manual");
  const [paymentStatus, setPaymentStatus] = useState("Active");
  const [isActiveModel, setIsActiveModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [bankData, setBankData] = useState([]);
  const [upiData, setUpiData] = useState([]);
  const [qbsDataList, setQbsDataList] = useState([]);
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [isActiveWithdrawalModel, setIsActiveWithdrawalModel] = useState(false);


  const handleResetMenu = () => {
    setPaymentMode("Manual");
    setPaymentMethod("Manual");
    setPaymentStatus("Active");
  };



  const [formData, setFormData] = useState({
    paymentType: "Cash",
    bankName: "",
    upiId: "",
    autoPaymentMethod: "PhonePay",
    remark: "",
    paymentMode: "",
    paymentOption: "",
    bankMode: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
    setApiResponse("");
  };

  const handleAddPaymentMethod = async () => {
    setIsLoading(true);
    try {
      const result = await AuthService.addPaymentOptions(formData, paymentMethod);
      if (result.success) {
        setIsLoading(false);
        setIsActiveModel(false);
        setFormData({
          paymentType: "Cash",
          bankName: "",
          upiId: "",
          autoPaymentMethod: "PhonePay",
          remark: "",
        });
        alertSuccessMessage(result?.message);
      } else {
        setIsLoading(false);
        setApiResponse(result);
      }
    } catch (error) {
      setIsLoading(false);
      setApiResponse(error);
    }
  };


  const handleBankAccountList = async () => {
    await AuthService.adminBankList("Active").then(async (result) => {
      if (result.success) {
        try {
          setBankData(result.data?.banks);
          handleUpiAccountList();
        } catch (error) {
          setBankData([]);
        }
      } else {
        setBankData([]);
      }
    });
  };

  const handleUpiAccountList = async () => {
    await AuthService.adminUpiList("Active").then(async (result) => {
      if (result.success) {
        try {
          setUpiData(result.data?.upi);
        } catch (error) {
          setUpiData([]);
        }
      } else {
        setUpiData([]);
      }
    });
  };

  const handleQbsPaymentList = async (paymentMode, paymentStatus) => {
    await AuthService.qbsPaymentOptions(paymentMode, paymentStatus).then(async (result) => {
      if (result.success) {
        try {
          setQbsDataList(result.data?.options);
        } catch (error) {
          setQbsDataList([]);
        }
      } else {
        setQbsDataList([]);
      }
    });
  };

  const handleQbsStatusChange = async (status, item) => {
    setIsLoading(true);
    await AuthService.updateQbsPaymentOptions(status, item).then(async (result) => {
      setIsLoading(false);
      if (result.success) {
        try {
          handleQbsPaymentList(paymentMode, paymentStatus);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        setIsLoading(false);
        alertErrorMessage(result);
      }
    });
  };


  const handleDeletePaymentMethod = async (item) => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: "Are you sure?",
        html: `
          <label for="remark" class="form-label text-start w-100 mt-3" >Reason for deletion</label>
          <textarea id="remark" class="form-control " rows="3" placeholder="Enter Reason"></textarea>
        `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e64942",
        cancelButtonColor: "#e7e7e7",
        confirmButtonText: "Yes, Delete!",
        preConfirm: () => {
          const remark = document.getElementById("remark").value.trim();
          if (!remark) {
            Swal.showValidationMessage("You need to enter a reason!");
          }
          return remark;
        }
      });
      if (isConfirmed) {
        const remark = document.getElementById("remark").value.trim();
        const response = await AuthService.deletePaymentMethod(item?._id, remark);
        if (response.success) {
          handleQbsPaymentList(paymentMode, "Deleted");
          setPaymentStatus("Deleted");
          Swal.fire("Deleted!", "Payment Method account has been deleted.", "success");
        } else {
          Swal.fire("Error!", response || "Failed to delete Payment Method account.", "error");
        }
      }
    } catch (error) {
      Swal.fire("Error!", "Something went wrong. Please try again.", "error");
    }
  };



  useEffect(() => {
    if (activeTabMain === "BuySell") {
      handleQbsPaymentList(paymentMode, paymentStatus);
    }
  }, [activeTabMain, paymentMode, paymentStatus]);


  const handleWithdrawalPaymentList = async () => {
    let filters = {};
    if (paymentMode) {
      filters.paymentOption = paymentMode;
    }
    if (paymentStatus && paymentStatus !== "Deleted") {
      filters.status = paymentStatus;
      filters.isDeleted = false;
    }
    if (paymentStatus === "Deleted") {
      filters.isDeleted = true;
    }

    const queryString = new URLSearchParams(filters).toString();
    await AuthService.getWithdrwalPaymentOptions(queryString).then(async (result) => {
      if (result.success) {
        try {
          setWithdrawalData(result.data);
        } catch (error) {
          setWithdrawalData([]);
          alertErrorMessage(error);
        }
      } else {
        setWithdrawalData([]);
      }
    });
  };


  const handleAddPaymentOptions = async () => {
    setIsLoading(true);
    try {
      const result = await AuthService.withdrawalPayment(formData);
      if (result.success) {
        setIsLoading(false);
        setIsActiveWithdrawalModel(false);
        setFormData({
          paymentType: "Cash",
          bankName: "",
          upiId: "",
          autoPaymentMethod: "PhonePay",
          remark: "",
          paymentMod: "",
          paymentOption: "",
          bankMode: ""
        });
        alertSuccessMessage(result?.message);
        handleWithdrawalPaymentList();
      } else {
        setIsLoading(false);
        setApiResponse(result);
      }
    } catch (error) {
      setIsLoading(false);
      setApiResponse(error);
    }
  };


  const handleWithdrawalStatusChange = async (status, Id) => {
    await AuthService.updateWithdrawaltOptions(status, Id).then(async (result) => {
      if (result.success) {
        try {
          handleWithdrawalPaymentList(paymentMode, paymentStatus);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result);
      }
    });
  };



  const handleDeleteWithdrawalPayment = async (item) => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: "Are you sure?",
        html: `
          <label for="remark" class="form-label text-start w-100 mt-3" >Reason for deletion</label>
          <textarea id="remark" class="form-control " rows="3" placeholder="Enter Reason"></textarea>
        `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e64942",
        cancelButtonColor: "#e7e7e7",
        confirmButtonText: "Yes, Delete!",
        preConfirm: () => {
          const remark = document.getElementById("remark").value.trim();
          if (!remark) {
            Swal.showValidationMessage("You need to enter a reason!");
          }
          return remark;
        }
      });
      if (isConfirmed) {
        const remark = document.getElementById("remark").value.trim();
        const response = await AuthService.deleteWithdrawalPaymentMethod(item?._id, remark);
        if (response.success) {
          handleWithdrawalPaymentList();
          Swal.fire("Deleted!", "Payment Method account has been deleted.", "success");
        } else {
          Swal.fire("Error!", response || "Failed to delete Payment Method account.", "error");
        }
      }
    } catch (error) {
      Swal.fire("Error!", "Something went wrong. Please try again.", "error");
    }
  };

  useEffect(() => {
    if (activeTabMain === "Withdrawal") {
      handleWithdrawalPaymentList();
    }
  }, [activeTabMain, paymentMode, paymentStatus]);


  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header">
          <div className="page-header-content">
            <div className="container-xl px-4">
              <h1 class="page-header-title"> Payment Settings </h1>
            </div>
          </div>
        </header>
        <div className="container-xl px-4">
          <div className="card">
            <div className="card-header pb-0">
              <div className="header_tab_row">
                <div className="nav nav-tabs nav-tabs-custom px-2">
                  <div className={`nav-link ${activeTabMain === "Deposit" && "active"}`} onClick={() => { setActiveTabMain("Deposit"); handleResetMenu() }}>Deposit</div>
                  <div className={`nav-link ${activeTabMain === "Withdrawal" && "active"}`} onClick={() => { setActiveTabMain("Withdrawal"); handleResetMenu() }}>Withdrawals</div>
                  <div className={`nav-link ${activeTabMain === "BuySell" && "active"}`} onClick={() => { setActiveTabMain("BuySell"); handleResetMenu() }}>Quick Buy/Sell</div>
                </div>
              </div>
            </div>

            <div className="card-body py-4">
              <div className="profile_data ac_body">

                {
                  activeTabMain === "Deposit" &&
                  <div className="tab-content">
                    Not Available deposit
                  </div>
                }

                {
                  activeTabMain === "Withdrawal" &&
                  <div className="tab-content">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <div className="custom-tab-2">
                        <ul className="nav nav-pills justify-content-start " role="tablist">
                          <li className="nav-item" role="presentation">
                            <button type="button" className={`nav-link ${paymentMode === "Manual" ? "active" : ""}`} onClick={() => setPaymentMode("Manual")}>
                              Manual
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button type="button" className={`nav-link ${paymentMode === "Automatic" ? "active" : ""}`} onClick={() => setPaymentMode("Automatic")}>
                              Automatic
                            </button>
                          </li>
                        </ul>
                      </div>
                      <button className="btn btn-md btn-warning" type="button" onClick={() => setIsActiveWithdrawalModel(true)}>Withdrawal Payment</button>
                    </div>

                    <div className="tab-pane active">
                      <div className="profile_data  ac_body">
                        <div className="d-flex mb-3 align-items-center justify-content-between">
                          <h5 className="mb-0"> {paymentMode} Payment Options</h5>
                        </div>
                        <ul className="nav custom-tabs mb-4">
                          <li className="buysell-tab">
                            <span className={paymentStatus === "Active" ? "active" : ""} onClick={() => setPaymentStatus("Active")}> Active </span>
                          </li>
                          <li className="buysell-tab">
                            <span className={paymentStatus === "Inactive" ? "active" : ""} onClick={() => setPaymentStatus("Inactive")}> Inactive </span>
                          </li>

                          <li className="buysell-tab">
                            <span className={paymentStatus === "Deleted" ? "active" : ""} onClick={() => setPaymentStatus("Deleted")}> Deleted </span>
                          </li>
                        </ul>
                        <div className="table-responsive m_table">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th scope="col" className=""> Date/Time </th>
                                <th scope="col" className=""> Admin Name. </th>
                                <th scope="col" className="">Payment Mode </th>
                                <th scope="col" className="">Payment Option </th>
                                <th scope="col" className="">Status </th>
                                {
                                  paymentStatus === "Deleted" &&
                                  <th scope="col" className="">Remark </th>
                                }
                              </tr>
                            </thead>
                            <tbody>
                              {withdrawalData?.length > 0 ?
                                withdrawalData.map((item, key) => (
                                  <tr key={key}>
                                    {
                                      paymentStatus === "Deleted" ?
                                        <td>{moment(item?.adminDeletionDetails?.timestamp).format('Do MMMM YYYY, ')}
                                          <small>{moment(item?.adminDeletionDetails?.timestamp).format('h:mm:ss a')}</small>
                                        </td>
                                        :
                                        <td>{moment(item?.adminAdditionDetails?.timestamp).format('Do MMMM YYYY, ')}
                                          <small>{moment(item?.adminAdditionDetails?.timestamp).format('h:mm:ss a')}</small>
                                        </td>
                                    }
                                    <td>{item?.adminAdditionDetails?.adminName}</td>
                                    <td>{item.paymentMode}</td>
                                    <td>{item.paymentOption}</td>
                                    {
                                      paymentStatus !== "Deleted" ?
                                        <td>
                                          <div class="d-flex gap-0 align-items-center">
                                            <div className="custom-tab-2">
                                              <ul className="nav nav-pills justify-content-start mb-1 link_status" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                  <button className={`nav-link link-success ${item?.status === "Active" ? "active" : ""}`}
                                                    onClick={() => handleWithdrawalStatusChange("Active", item?._id)}
                                                    disabled={item?.status === "Active"}>
                                                    <span>Active</span>
                                                  </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                  <button type="button" className={`nav-link link-danger ${item?.status === "Inactive" ? "active" : ""}`}
                                                    onClick={() => handleWithdrawalStatusChange("Inactive", item?._id)}
                                                    disabled={item?.status === "Inactive"}>
                                                    <span>Inactive</span>
                                                  </button>
                                                </li>
                                              </ul>
                                            </div>

                                            {paymentStatus === "Inactive" &&
                                              <div className="d-flex align-items-end gap-2" >
                                                <span className="sm_icon sm_danger" title="Delete" onClick={() => handleDeleteWithdrawalPayment(item)} >
                                                  <i class="ri-delete-bin-fill"></i>
                                                </span>
                                              </div>
                                            }
                                          </div>
                                        </td>
                                        : <td>{item?.status}</td>
                                    }
                                    <td>{item?.adminDeletionDetails?.adminRemark}</td>
                                  </tr>
                                ))
                                : <div className=" d-flex justify-content-center align-items-center">
                                  <img src="/assets/img/not_found.svg" className="img-fluid mt-5" width="200" alt="" />
                                </div>}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                }

                {
                  activeTabMain === "BuySell" &&
                  <div className="tab-content">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <div className="custom-tab-2">
                        <ul className="nav nav-pills justify-content-start " role="tablist">
                          <li className="nav-item" role="presentation">
                            <button type="button" className={`nav-link ${paymentMode === "Manual" ? "active" : ""}`} onClick={() => setPaymentMode("Manual")}>
                              Manual
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button type="button" className={`nav-link ${paymentMode === "Automatic" ? "active" : ""}`} onClick={() => setPaymentMode("Automatic")}>
                              Automatic
                            </button>
                          </li>
                        </ul>
                      </div>
                      <button className="btn btn-md btn-warning" type="button" onClick={() => { setIsActiveModel(true); handleBankAccountList() }}>
                        <b><i class="ri-add-fill me-2"></i></b>
                        Add Payment
                      </button>
                    </div>

                    <div className={`tab-pane ${paymentMode === "Manual" ? "active" : "d-none"}`}>
                      <div className="profile_data  ac_body">
                        <div className="d-flex mb-3 align-items-center justify-content-between">
                          <h5 className="mb-0"> Manual Payment Method</h5>
                        </div>
                        <ul className="nav custom-tabs mb-4">
                          <li className="buysell-tab">
                            <span className={paymentStatus === "Active" ? "active" : ""} onClick={() => setPaymentStatus("Active")}> Active </span>
                          </li>
                          <li className="buysell-tab">
                            <span className={paymentStatus === "Inactive" ? "active" : ""} onClick={() => setPaymentStatus("Inactive")}> Inactive </span>
                          </li>

                          <li className="buysell-tab">
                            <span className={paymentStatus === "Deleted" ? "active" : ""} onClick={() => setPaymentStatus("Deleted")}> Deleted </span>
                          </li>
                        </ul>

                        {
                          qbsDataList?.length > 0 ?
                            qbsDataList.map((item, index) => (
                              <div class="fc_row" key={index}>
                                <div class="fc_details mb-3">
                                  <div class="d-flex gap-3 align-items-center justify-content-between mb-2">
                                    <h5 class="mb-0 d-flex gap-3 align-items-center  ">
                                      {" "}
                                      <b className="pr_icon pr_icon_sm fw-normal me-0">
                                        {item?.paymentType === "Digital" ? <i class="ri-bank-fill"></i> : item?.paymentType === "Cash" ? <i class="ri-cash-line"></i> : ""}
                                      </b>
                                      {item?.paymentType}
                                    </h5>

                                    {
                                      paymentStatus !== "Deleted" &&
                                      <div class="d-flex gap-0 align-items-center justify-content-end">
                                        <div className="custom-tab-2">
                                          <ul className="nav nav-pills justify-content-start mb-1 link_status" role="tablist">
                                            <li className="nav-item" role="presentation">
                                              <button className={`nav-link link-success ${item?.status === "Active" ? "active" : ""}`}
                                                onClick={() => handleQbsStatusChange("Active", item)}
                                                disabled={item?.status === "Active"}>
                                                {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Active</span>}
                                              </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                              <button type="button" className={`nav-link link-danger ${item?.status === "Inactive" ? "active" : ""}`}
                                                onClick={() => handleQbsStatusChange("Inactive", item)}
                                                disabled={item?.status === "Inactive"}>
                                                {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Inactive</span>}
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="d-flex align-items-end gap-2" >
                                          {/* <div class="checkbox_switch">
                                            <input class="tgl" id="toggle" type="checkbox" />
                                            <label class="tgl-btn" for="toggle"></label>
                                          </div> */}
                                          <span className="sm_icon sm_danger" title="Delete" onClick={() => handleDeletePaymentMethod(item)} > <i class="ri-delete-bin-fill"></i> </span>
                                        </div>
                                        {/* <div className="dropdown">
                                          <button type="button" className="btn btn-blank p-1" data-bs-toggle="dropdown" fdprocessedid="1s52ls" aria-expanded="false">
                                            <i className="ri-more-2-fill"></i>
                                          </button>
                                          <div className="dropdown-menu">
                                            <span className="dropdown-item cursor-pointer" onClick={() => handleDeletePaymentMethod(item)}> Delete Account </span>
                                          </div>
                                        </div> */}
                                      </div>
                                    }

                                  </div>
                                  <div class="row ">
                                    <div class="col-lg-3">
                                      <div class="label-group">
                                        <label> Bank </label>
                                        <p>{item?.bankDetails?.bankName}<br /> <small className="fw-normal">Account Type: {item?.bankDetails?.accountType} </small></p>
                                      </div>
                                    </div>
                                    <div class="col-lg-3">
                                      <div class="label-group">
                                        <label> Account Holder Name </label>
                                        <p> {item?.bankDetails?.accountHolderName}</p>
                                      </div>
                                    </div>
                                    <div class="col-lg-3">
                                      <div class="label-group">
                                        <label> Account Number </label>
                                        <p> {item?.bankDetails?.accountNumber} </p>
                                      </div>
                                    </div>
                                    <div class="col-lg-3">
                                      <div class="label-group">
                                        <label> IFSC Code </label>
                                        <p>
                                          {" "}
                                          {item?.bankDetails?.ifsc} <br /> <small className="fw-normal">Branch: {item?.bankDetails?.branchName} </small>{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {
                                    item?.paymentType === "Digital" &&
                                    <div>
                                      <hr />
                                      <div class="row  align-items-center">
                                        <div class="col-lg-3">
                                          <div class="label-group">
                                            <label> UPI Name </label>
                                            <p> {item?.upiDetails?.upiName || "-----"} </p>
                                          </div>
                                        </div>
                                        <div class="col-lg-3">
                                          <div class="label-group">
                                            <label> UPI ID </label>
                                            <p> {item?.upiDetails?.vpaId || "-----"}</p>
                                          </div>
                                        </div>
                                        <div class="col-lg-3">
                                          <div class="label-group">
                                            <label> Added By </label>
                                            <p> {item?.addedBy} </p>
                                          </div>
                                        </div>
                                        <div class="col-lg-3">
                                          <div class="label-group">
                                            <label> Added on </label>
                                            <p> {moment(item?.addedAt).format('Do MMM YYYY, h:mm:ss a')} </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  <hr />
                                  <div class="row  align-items-center">
                                    <div class="col-lg-6">
                                      <div class="label-group">
                                        <label> Addition Remark </label>
                                        <p> {item?.additionRemark || "-----"} </p>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="label-group">
                                        <label> Deletion Remark </label>
                                        <p> {item?.deletionRemark || "-----"} </p>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            ))
                            : null
                        }
                      </div>
                    </div>



                    <div className={`tab-pane ${paymentMode === "Automatic" ? "active" : "d-none"}`}>
                      <div className="profile_data  ac_body">
                        <div className="d-flex mb-3 align-items-center justify-content-between">
                          <h5 className="mb-0"> Automatic </h5>
                        </div>
                        <ul className="nav custom-tabs mb-4">
                          <li className="buysell-tab">
                            <span className={paymentStatus === "Active" ? "active" : ""} onClick={() => setPaymentStatus("Active")}> Active </span>
                          </li>
                          <li className="buysell-tab">
                            <span className={paymentStatus === "Inactive" ? "active" : ""} onClick={() => setPaymentStatus("Inactive")}> Inactive </span>
                          </li>

                          <li className="buysell-tab">
                            <span className={paymentStatus === "Deleted" ? "active" : ""} onClick={() => setPaymentStatus("Deleted")}> Deleted </span>
                          </li>
                        </ul>
                        <div class="fc_row">
                          {/*  Automatic Payment Method */}
                          <div class="fc_details mb-3">
                            <div class="row align-items-start ">
                              <div class="col-lg-3">
                                <h5 class="mb-0 d-flex gap-3 align-items-center  ">
                                  {" "}
                                  <b className="pr_icon bg-transperant  pr_icon_sm fw-normal me-0">
                                    <img src="assets/img/phonepay_img.png" className="img-fluid" alt="" />
                                  </b>
                                  PhonePe
                                </h5>
                              </div>
                              <div class="col-lg-3">
                                <div class="label-group">
                                  <label> Added </label>
                                  <p>
                                    {" "}
                                    Piyush
                                    <br /> <small className="fw-normal"> Date: 14/02/2025, 14:18:50 </small>
                                    <br /> <small className="fw-normal"> Remark: Elevate your creative projects with Free Flat Upi Logo Icons, </small>
                                  </p>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="label-group">
                                  <label> Deleted </label>
                                  <p>
                                    {" "}
                                    Piyush
                                    <br /> <small className="fw-normal"> Date: 14/02/2025, 14:18:50 </small>
                                    <br /> <small className="fw-normal"> Remark: Elevate your creative projects with Free Flat Upi Logo Icons, </small>
                                  </p>
                                </div>
                              </div>

                              <div class="col-lg-3">
                                <div class="d-flex gap-0 align-items-center justify-content-end">
                                  <div class="custom-tab-2">
                                    <ul class="nav nav-pills justify-content-start link_status" role="tablist">
                                      <li class="nav-item" role="presentation">
                                        <button class="nav-link link-success active" disabled="">
                                          <span>Active</span>
                                        </button>
                                      </li>
                                      <li class="nav-item" role="presentation">
                                        <button type="button" class="nav-link link-danger ">
                                          <span>Inactive</span>
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="dropdown">
                                    <button type="button" className="btn btn-blank p-1" data-bs-toggle="dropdown" fdprocessedid="1s52ls" aria-expanded="false">
                                      <i className="ri-more-2-fill"></i>
                                    </button>
                                    <div className="dropdown-menu">
                                      <span className="dropdown-item cursor-pointer"> Delete Account </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/*  Automatic Payment Method */}
                          <div class="fc_details mb-3">
                            <div class="row align-items-start ">
                              <div class="col-lg-3">
                                <h5 class="mb-0 d-flex gap-3 align-items-center  ">
                                  {" "}
                                  <b className="pr_icon bg-transperant  pr_icon_sm fw-normal me-0">
                                    <img src="assets/img/phonepay_img.png" className="img-fluid" alt="" />
                                  </b>
                                  PhonePe
                                </h5>
                              </div>
                              <div class="col-lg-3">
                                <div class="label-group">
                                  <label> Added </label>
                                  <p>
                                    {" "}
                                    Piyush
                                    <br /> <small className="fw-normal"> Date: 14/02/2025, 14:18:50 </small>
                                    <br /> <small className="fw-normal"> Remark: Elevate your creative projects with Free Flat Upi Logo Icons, </small>
                                  </p>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="label-group">
                                  <label> Deleted </label>
                                  <p>
                                    {" "}
                                    Piyush
                                    <br /> <small className="fw-normal"> Date: 14/02/2025, 14:18:50 </small>
                                    <br /> <small className="fw-normal"> Remark: Elevate your creative projects with Free Flat Upi Logo Icons, </small>
                                  </p>
                                </div>
                              </div>

                              <div class="col-lg-3">
                                <div class="d-flex gap-0 align-items-center justify-content-end">
                                  <div class="custom-tab-2">
                                    <ul class="nav nav-pills justify-content-start link_status" role="tablist">
                                      <li class="nav-item" role="presentation">
                                        <button class="nav-link link-success active" disabled="">
                                          <span>Active</span>
                                        </button>
                                      </li>
                                      <li class="nav-item" role="presentation">
                                        <button type="button" class="nav-link link-danger ">
                                          <span>Inactive</span>
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="dropdown">
                                    <button type="button" className="btn btn-blank p-1" data-bs-toggle="dropdown" fdprocessedid="1s52ls" aria-expanded="false">
                                      <i className="ri-more-2-fill"></i>
                                    </button>
                                    <div className="dropdown-menu">
                                      <span className="dropdown-item cursor-pointer"> Delete Account </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/*  Automatic Payment Method */}
                          <div class="fc_details mb-3">
                            <div class="row align-items-start ">
                              <div class="col-lg-3">
                                <h5 class="mb-0 d-flex gap-3 align-items-center  ">
                                  {" "}
                                  <b className="pr_icon bg-transperant  pr_icon_sm fw-normal me-0">
                                    <img src="assets/img/phonepay_img.png" className="img-fluid" alt="" />
                                  </b>
                                  PhonePe
                                </h5>
                              </div>
                              <div class="col-lg-3">
                                <div class="label-group">
                                  <label> Added </label>
                                  <p>
                                    {" "}
                                    Piyush
                                    <br /> <small className="fw-normal"> Date: 14/02/2025, 14:18:50 </small>
                                    <br /> <small className="fw-normal"> Remark: Elevate your creative projects with Free Flat Upi Logo Icons, </small>
                                  </p>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="label-group">
                                  <label> Deleted </label>
                                  <p>
                                    {" "}
                                    Piyush
                                    <br /> <small className="fw-normal"> Date: 14/02/2025, 14:18:50 </small>
                                    <br /> <small className="fw-normal"> Remark: Elevate your creative projects with Free Flat Upi Logo Icons, </small>
                                  </p>
                                </div>
                              </div>

                              <div class="col-lg-3">
                                <div class="d-flex gap-0 align-items-center justify-content-end">
                                  <div class="custom-tab-2">
                                    <ul class="nav nav-pills justify-content-start link_status" role="tablist">
                                      <li class="nav-item" role="presentation">
                                        <button class="nav-link link-success active" disabled="">
                                          <span>Active</span>
                                        </button>
                                      </li>
                                      <li class="nav-item" role="presentation">
                                        <button type="button" class="nav-link link-danger ">
                                          <span>Inactive</span>
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="dropdown">
                                    <button type="button" className="btn btn-blank p-1" data-bs-toggle="dropdown" fdprocessedid="1s52ls" aria-expanded="false">
                                      <i className="ri-more-2-fill"></i>
                                    </button>
                                    <div className="dropdown-menu">
                                      <span className="dropdown-item cursor-pointer"> Delete Account </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                }

              </div>
            </div>
          </div>
        </div>
      </main >

      {
        isActiveModel &&
        <div>
          <div className="modal show d-block">
            <div className="modal-dialog modal-dialog-centered modal-md-lg">
              <div className="modal-content pb-3">
                <div className="modal-header">
                  <h5 className="modal-title"> Add New Payment Method </h5>
                </div>
                <div className="modal-body ">
                  <div className="custom-tab-2 ct_three">
                    <ul className="nav nav-pills justify-content-center " role="tablist">
                      <li className="nav-item" role="presentation">
                        <button type="button" className={`nav-link ${paymentMethod === "Manual" ? "active" : ""}`} onClick={() => setPaymentMethod("Manual")}>
                          Manual
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button type="button" className={`nav-link ${paymentMethod === "Automatic" ? "active" : ""}`} onClick={() => setPaymentMethod("Automatic")}>
                          Automatic
                        </button>
                      </li>
                    </ul>
                  </div>

                  <div className="tab-content mt-4">
                    {/* manual payment tab */}
                    <div className={`tab-pane ${paymentMethod === "Manual" ? "active" : ""}`}>
                      <div className="row_radio">
                        <div className="form-group mb-4">
                          <label className="small mb-1">Select Type</label>
                          <div className="radio_tab d-flex align-items-center gap-5">
                            <div className="form-group">
                              <input
                                className="custom-radio"
                                type="radio"
                                id="radio_1"
                                name="paymentType"
                                value="cash"
                                checked={formData.paymentType === "Cash"}
                                onChange={handleChange}
                              />
                              <label htmlFor="radio_1">
                                <span></span> Cash
                              </label>
                            </div>
                            <div className="form-group">
                              <input
                                className="custom-radio"
                                type="radio"
                                id="radio_2"
                                name="paymentType"
                                value="Digital"
                                checked={formData.paymentType === "Digital"}
                                onChange={handleChange}
                              />
                              <label htmlFor="radio_2">
                                <span></span> Bank
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row gx-3">
                          <div className="col-lg-12">
                            <div className="form-group mb-4">
                              <label className="small mb-1" htmlFor="bankName">
                                Banks List
                              </label>
                              <select
                                className="form-control form-control-solid form-select"
                                id="bankName"
                                name="bankName"
                                value={formData.bankName}
                                onChange={handleChange}
                              >
                                <option value="">Select a Bank</option>
                                {bankData.map((bank, index) => (
                                  <option key={index} value={bank?._id}>{bank?.bankName}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group mb-4">
                              <label className="small mb-1" htmlFor="upiId">
                                UPI ID
                              </label>
                              <select
                                className="form-control form-control-solid form-select"
                                id="upiId"
                                name="upiId"
                                value={formData.upiId}
                                onChange={handleChange}
                              >
                                <option value="">Select UPI Id</option>
                                {upiData.map((item, index) => (
                                  <option key={index} value={item?._id}>{item?.vpaId}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group mb-4">
                              <label className="small mb-1" htmlFor="upiId">
                                Remark
                              </label>
                              <textarea
                                className="form-control py-3"
                                rows="3"
                                placeholder="Enter your Remark.."
                                name="remark"
                                value={formData.remark}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* automatic payment tab */}
                    <div className={`tab-pane ${paymentMethod === "Automatic" ? "active" : ""}`}>
                      <div className="row_radio">
                        <div className="form-group mb-4">
                          <label className="small mb-3">Choose Payment Method </label>
                          <div className="radio_tab ">
                            <div class="form-group mb-2">
                              <input class="custom-radio" type="radio" id="PhonePay" name="autoPaymentMethod"
                                value="PhonePay"
                                checked={formData.autoPaymentMethod === "PhonePay"}
                                onChange={handleChange}
                              />
                              <label for="PhonePay">
                                <span></span>  PhonePay
                              </label>
                            </div>
                            <div class="form-group mb-2">
                              <input class="custom-radio" type="radio" id="RazorPay" name="autoPaymentMethod" value="RazorPay"
                                checked={formData.autoPaymentMethod === "RazorPay"}
                                onChange={handleChange}
                              />
                              <label for="RazorPay">
                                <span></span> RazorPay
                              </label>
                            </div>
                            <div class="form-group mb-2">
                              <input class="custom-radio" type="radio" id="CashFree" name="autoPaymentMethod" value="CashFree"
                                checked={formData.autoPaymentMethod === "CashFree"}
                                onChange={handleChange} />
                              <label for="CashFree">
                                <span></span> CashFree
                              </label>
                            </div>
                            <div class="form-group mb-2">
                              <input class="custom-radio" type="radio" id="Van" name="autoPaymentMethod" value="Van"
                                checked={formData.autoPaymentMethod === "Van"}
                                onChange={handleChange} />
                              <label for="Van">
                                <span></span> Van
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="row g-3 mb-4">
                    <div className="col-lg-12">
                      <button className="btn btn-primary btn-md btn-block w-100" type="button" onClick={handleAddPaymentMethod}
                        disabled={isLoading}>

                        {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                          <span>Submit</span>}
                      </button>
                    </div>
                    <div className="col-lg-12">
                      <button className="btn btn-secondary btn-md btn-block w-100 " type="button" onClick={() => setIsActiveModel(false)}>
                        Cancel
                      </button>
                    </div>
                    {apiResponse && <div className="text-danger">{apiResponse}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </div>
      }





      {
        isActiveWithdrawalModel &&
        <div>
          <div className="modal show d-block">
            <div className="modal-dialog modal-dialog-centered modal-md-lg">
              <div className="modal-content pb-3">
                <div className="modal-header">
                  <h5 className="modal-title"> Withdrawal Payment Options </h5>
                </div>
                <div className="modal-body ">
                  <div className="tab-content mt-4">
                    <div className="tab-pane active">
                      <div className="row_radio">

                        <div className="row gx-3">
                          <div className="col-lg-12">
                            <div className="form-group mb-4">
                              <label className="small mb-1" htmlFor="paymentOption">
                                paymentOption
                              </label>
                              <select
                                className="form-control form-control-solid form-select"
                                id="paymentOption"
                                name="paymentOption"
                                value={formData.paymentOption}
                                onChange={handleChange}
                              >
                                <option value="">Select Type</option>
                                <option value="Manual">Manual</option>
                                <option value="Automatic">Automatic</option>

                              </select>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group mb-4">
                              <label className="small mb-1" htmlFor="paymentMode">
                                Select Payment Mode
                              </label>
                              <select
                                className="form-control form-control-solid form-select"
                                id="paymentMode"
                                name="paymentMode"
                                value={formData.paymentMode}
                                onChange={handleChange}
                              >
                                <option value=""> Select Payment Mode</option>
                                <option value="Cash">Cash</option>
                                <option value="Bank">Bank</option>
                              </select>
                            </div>
                          </div>

                          {
                            formData.paymentMode === "Bank" &&
                            <div className="col-lg-12">
                              <div className="form-group mb-4">
                                <label className="small mb-1" htmlFor="bankMode">
                                  Select Bank Mode
                                </label>
                                <select
                                  className="form-control form-control-solid form-select"
                                  id="bankMode"
                                  name="bankMode"
                                  value={formData.bankMode}
                                  onChange={handleChange}
                                >
                                  <option value=""> Select Payment Mode</option>
                                  <option value="NEFT">NEFT</option>
                                  <option value="RTGS">RTGS</option>
                                  <option value="IMPS">IMPS</option>
                                  <option value="UPI">UPI</option>
                                  <option value="Intra Bank Transfer">Intra Bank Transfer</option>
                                </select>
                              </div>
                            </div>
                          }
                          <div className="col-lg-12">
                            <div className="form-group mb-4">
                              <label className="small mb-1" htmlFor="upiId">
                                Remark
                              </label>
                              <textarea
                                className="form-control py-3"
                                rows="3"
                                placeholder="Enter your Remark.."
                                name="remark"
                                value={formData.remark}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row g-3 mb-4">
                    <div className="col-lg-12">
                      <button className="btn btn-primary btn-md btn-block w-100" type="button" onClick={handleAddPaymentOptions}
                        disabled={isLoading}>

                        {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                          <span>Submit</span>}
                      </button>
                    </div>
                    <div className="col-lg-12">
                      <button className="btn btn-secondary btn-md btn-block w-100 " type="button" onClick={() => setIsActiveWithdrawalModel(false)}>
                        Cancel
                      </button>
                    </div>
                    {apiResponse && <div className="text-danger">{apiResponse}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </div>
      }

    </div >
  );
};
export default ExchangePayments;
