import React from "react";
import { Link, useLocation } from 'react-router-dom';




const UserBankDetails = () => {

  const location = useLocation();
  const { row, bankStatus } = location.state || {};

  let heading = bankStatus?.toLowerCase()?.split("-list")?.filter(Boolean);
  heading?.push("Details");
  let updatedHeading = heading?.join("-")?.toUpperCase();


  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header ">
          <div className="container-xl px-4">
            <div className="page-header-content">
              <div className="row align-items-center justify-content-between">
                <div className="col-12">
                  <div className="d-flex justify-content-between" >
                    <h1 className="page-header-title">
                      <Link to="/bank_verification" className="page-header-icon">
                        <i className="fa fa-arrow-left"></i>
                      </Link>
                      Bank Verification Details
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="container-xl px-4">
          <div className="card ud_card" >
            <div className="card-body" >
              <div className="ud_row" >
                <div className="ud_details" >
                  <div className="row g-4" >
                    <div className="col-lg-4" >
                      <div className="ud_img" >
                        <label> Aadhar Card Image </label>
                        <div className="img" ><img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                          <h6>
                            <small> Account created Date </small>
                            12 Nov. 2024
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4" >
                      <div className="ud_img" >
                        <label> Liveness Selfie </label>
                        <div className="img" ><img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                          <h6>
                            <small> Kyc Status </small>
                            Verified  <span>(12 Nov 2024) </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4" >
                      <div className="ud_img" >
                        <label> Selfie with cancelled cheque </label>
                        <div className="img" ><img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                          <h6>
                            <small> Applied for bank Verification </small>
                            12 Nov. 2024
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header pb-0">
              <ul className="nav nav-tabs nav-tabs-custom" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-Pending-tab" data-bs-toggle="pill" data-bs-target="#pills-Pending" type="button" role="tab" aria-controls="pills-Pending" aria-selected="false"  >  Bank Details  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-Approved-tab" data-bs-toggle="pill" data-bs-target="#pills-Approved" type="button" role="tab" aria-controls="pills-Approved" aria-selected="false">  User Activity  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-Rejected-tab" data-bs-toggle="pill" data-bs-target="#pills-Rejected" type="button" role="tab" aria-controls="pills-Rejected" aria-selected="false">  Wallet Activity  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-Rejected-tab" data-bs-toggle="pill" data-bs-target="#pills-Rejected" type="button" role="tab" aria-controls="pills-Rejected" aria-selected="false">  Bank Ac. Activity  </button>
                </li>
              </ul>
              <div className="dropdown">
                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  <i className="fa fa-download me-3"></i>Export
                </button>
                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp" >

                </div>
              </div>
            </div>
            <div className="card-body py-4">
              <div className="ac_body profile_data" >
                <div className="row gx-5" >
                  <div className="col-lg-6" >
                    <div className="">
                      <div className="mb-3 d-flex align-items-center justify-content-between" >
                        <h5> <i class="ri-arrow-left-line me-2"></i>  Account Details  </h5>
                        <span className="badge bg-dark"> Default </span>
                      </div>
                      <div className="row g-4" >
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label> Bank Name </label>
                            <p>   Bank of Baroda  </p>
                          </div>
                        </div>

                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label>  Status </label>
                            <p>  <span className="badge bg-success"> Verified </span>  </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label>   Account Holder Name </label>
                            <p> ----  </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label> Account Number</label>
                            <p> ----  </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label>   Account Type </label>
                            <p> ----  </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label> Branch Name </label>
                            <p> ----  </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label> IFSC Code   </label>
                            <p> ----  </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 border-left-dashed" >
                    <div className="fc_details" >
                      <div className="d-flex gap-3 align-items-center justify-content-between mb-4" >
                        <h5 className="mb-0" > Verify Details   </h5>
                        <button className="btn btn-sm btn-warning"> Fatch Details </button>
                      </div>
                      <div className="row g-4" >
                        <div className="col-lg-4" >
                          <div className="label-group" >
                            <label>Name Match  </label>
                            <p> ----  </p>
                          </div>
                        </div>
                        <div className="col-lg-4" >
                          <div className="label-group" >
                            <label> User name in Bank   </label>
                            <p>  ----  </p>
                          </div>
                        </div>
                        <div className="col-lg-4" >
                          <div className="label-group" >
                            <label> Bank Status</label>
                            <p> ----  </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label> Bank  Response </label>
                            <p> ----  </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label>  IFSC Code  </label>
                            <p> ----  </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label> Account Number   </label>
                            <p> ----  </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label> Bank Txn. Status      </label>
                            <p> ----  </p>
                          </div>
                        </div>
                        <div className="col-lg-4" >
                          <div className="label-group" >
                            <label> Approved Date </label>
                            <p> ----  </p>
                          </div>
                        </div>
                        <div className="col-lg-4" >
                          <div className="label-group" >
                            <label> Rejected Date </label>
                            <p> ----  </p>
                          </div>
                        </div>
                        <div className="col-lg-4" >
                          <div className="label-group" >
                            <label> Deleted Date </label>
                            <p> ----  </p>
                          </div>
                        </div>
                        <div className="col-lg-12" >
                          <div className="d-flex gap-2 align-items-center justify-content-end" >
                            <span className="apr_status" > <small>Approved by</small> <br/> Pardeep Yadav (Master Admin) </span>
                            <span className="apr_status" > <small>Rejected by</small> <br/> Pardeep Yadav (Master Admin) </span> 
                            <button className="btn btn-md btn-success px-4"> Approve </button>
                            <button className="btn btn-md btn-danger  px-4"> Reject   </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
};
export default UserBankDetails;
