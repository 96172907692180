import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { SlideshowLightbox } from 'lightbox.js-react'
import "react-tabs-scrollable/dist/rts.css";
import copy from "copy-to-clipboard";
import ViewDocumentModel from "../../../../CustomComponent/ViewDocumentModel";
import AuthService from "../../../../../services/ApiIntegration/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../../CustomComponent/CustomAlert";
import LoaderHelper from "../../../../CustomComponent/LoaderHelper/LoaderHelper";

const SupportTicketDetails = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { row, ticketLevel } = location.state || {};
    const [copied, setCopied] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [documentData, setDocumentData] = useState();
    const [modelHeading, setModelHeading] = useState();
    const [closeRemark, setCloseRemark] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [attachedData, setAttachedData] = useState(null);
    const [isModalOpenCloseTicket, setIsModalOpenCloseTicket] = useState(false);
    const [ticketStatus, setTicketStatus] = useState();
    const [ticketDetails, setTicketDetails] = useState();

    const handleCopy = (item) => {
        copy(item);
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setAttachedData(file);
        }
    };

    const handleCloseTicket = async (closeRemark, attachedData) => {
        if (!closeRemark) {
            setErrorMessage("Please Enter your Remark")
            return;
        }
        setLoading(true);
        try {
            const formDataObj = new FormData();
            formDataObj.append('response', closeRemark);
            formDataObj.append('ticket_attachment', attachedData);
            formDataObj.append('ticket_closed', ticketStatus);
            const result = await AuthService.closeSupportTicket(formDataObj, row?.support_ticket_id);
            if (result.success) {
                alertSuccessMessage(result?.message);
                navigate('/support_ticket_list');
                setLoading(false);
            } else {
                alertErrorMessage(result);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            alertErrorMessage(error.message || "Something went wrong.");
        }
    };

    const handleData = async () => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.getUserSupportTicketDetails(row?.support_ticket_id);
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                setTicketDetails(result.data || []);
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        handleData();
    }, []);

    return (
        <div id="layoutSidenav_content">
            <main>
                <form className="form-data">
                    <header className="page-header ">
                        <div className="container-xl px-4">
                            <div className="page-header-content pb-2">
                                <div className="row align-items-center justify-content-between">
                                    <h6 className="page-header-title mb-5">
                                        <span className="page-header-icon cursor-pointer"
                                            onClick={() => { navigate('/support_ticket_list', { state: { ticketLevel } }) }} >
                                            <i className="fa fa-arrow-left" ></i>
                                        </span>
                                        <small>Support Ticket Details</small>
                                    </h6>
                                    <div className="col-auto">
                                        <h1 className="page-header-title">
                                            <div className="hd_u_info" >
                                                <img src="assets/img/illustrations/profiles/profile-5.png" className="img-account-profile rounded-circle" />
                                                <span>  {ticketDetails?.full_name || "-------"}
                                                    <small>
                                                        Support Ticket ID: {ticketDetails?.support_ticket_id || "-------"}
                                                        <i
                                                            className={`ms-1 link cursor-pointer ${copied ? "ri-check-fill text-success" : "ri-file-copy-fill"
                                                                }`}
                                                            onClick={() => handleCopy(ticketDetails?.support_ticket_id)}
                                                        ></i>
                                                    </small>
                                                </span>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    <div className="container-xl px-4 mt-2">
                        <div className="row">
                            <div className="col-xl-12">
                                {/* Level 1 customer support */}
                                <div className="card  mb-4" >
                                    <div className="card-body py-4">
                                        <div className="profile_data w-100  ac_body px-4   ">

                                            <div className="row align-items-start" >
                                                <div className="col-lg-9" >
                                                    <div className="d-flex align-items-center justify-content-between mt-2" >
                                                        <h5> Level 1 - Customer Support  </h5>
                                                    </div>
                                                    <div className="row g-3">
                                                        <div className="col-lg-6 col-xl-4">
                                                            <div className="label-group">
                                                                <label>  Ticket Created Date </label>
                                                                <p>
                                                                    {moment(ticketDetails?.ticket_raised_at).format('Do MMMM YYYY, h:mm:ss a')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-4">
                                                            <div className="label-group">
                                                                <label>  Full Name  </label>
                                                                <p>  {ticketDetails?.full_name || "-------"}   </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-4">
                                                            <div className="label-group">
                                                                <label>  Email Address    </label>
                                                                <p> {ticketDetails?.email || "-------"}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-3">
                                                            <div className="label-group">
                                                                <label> Mobile Number </label>
                                                                <p> {ticketDetails?.phone || "-------"}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-3">
                                                            <div className="label-group">
                                                                <label>  Subject  </label>
                                                                <p>  {ticketDetails?.subject} </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-xl-3">
                                                            <div className="label-group">
                                                                <label>  Ticket Status  </label>
                                                                <p className="cnn_msg" > <small>{ticketDetails?.status}</small> </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-3">
                                                            <div className="label-group">
                                                                <label>  Ticket Created by  </label>
                                                                <p className="cnn_msg" > <small>{ticketDetails?.ticket_raised_by}</small> </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-xl-12">
                                                            <div className="label-group">
                                                                <label>  Concern Message   </label>
                                                                <p className="cnn_msg" > <small> {ticketDetails?.description || "-----"}</small> </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3" >
                                                    <div className="ud_img">
                                                        <label className="mb-1 text-xs mt-2" > Attachments </label>
                                                        <div className="img p-1">
                                                            {ticketDetails?.support_ticket_attachment?.toLowerCase().endsWith(".pdf") || ticketDetails?.support_ticket_attachment?.toLowerCase().endsWith(".pdf.jpg") ? (

                                                                <button type="button" className="pdf_viewer pdf_viewer_xxl" onClick={() => { setIsModalOpen(true); setDocumentData(ticketDetails?.support_ticket_attachment); setModelHeading("Level 1 - Customer Support") }}>
                                                                    <img src="assets/img/document.webp" className="img-fluid" />
                                                                    <span> Document Attached <small>Click to Open</small> </span>
                                                                </button>

                                                            ) : (
                                                                !ticketDetails?.support_ticket_attachment ? (
                                                                    <div  className="ratio rc_img ratio-16x9" >
                                                                        <img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                                                                    </div>
                                                                ) : (
                                                                <SlideshowLightbox className="ratio ratio-16x9">
                                                                    <img
                                                                        src={ticketDetails?.support_ticket_attachment || "assets/img/blank_img.png"}
                                                                        className="img-fluid"
                                                                        alt="Support Ticket Attachment"
                                                                        onError={(e) => {
                                                                            e.target.onerror = null;
                                                                            e.target.src = "assets/img/blank_img.png";
                                                                        }}
                                                                    />
                                                                </SlideshowLightbox>
                                                                 )
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        ticketDetails?.ticket_level == 1 && ticketDetails?.status === "Open" &&
                                        <div className="card-footer py-3 d-flex justify-content-end gap-3" >
                                            <button type="button" className="btn btn-primary btn-md fw-normal" onClick={() => { setIsModalOpenCloseTicket(true); setTicketStatus("false") }}> Esclate Ticket </button>
                                            <button type="button" className="btn btn-dark btn-md fw-normal" onClick={() => { setIsModalOpenCloseTicket(true); setTicketStatus("true") }}> Close Ticket </button>
                                        </div>
                                    }
                                    {/* user support remark */}
                                    {
                                        ticketDetails?.level_1_response ?
                                            <div className="support_remark p-4 pt-0" >
                                                <div class="rm_col mb-0">
                                                    <div class="row">
                                                        <div class="col-3">
                                                            <h5>
                                                                <span> Action by  </span>
                                                                <small> {ticketDetails?.level_1_action} </small>
                                                            </h5>
                                                        </div>
                                                        <div class="col-3">
                                                            <h5>
                                                                <span> Status  </span>
                                                                <small> {ticketDetails?.status} </small>
                                                            </h5>
                                                        </div>
                                                        <div class="col-3">
                                                            <h5>
                                                                <span> Updated On: </span>
                                                                <small> {moment(ticketDetails?.level_1_timestamp).format('Do MMMM YYYY, h:mm:ss a')} </small>
                                                            </h5>
                                                        </div>
                                                        <div class="col-3">
                                                            <h5>
                                                                <span> Attachments </span>
                                                                <small>
                                                                    <button type="button" className="btn btn-outline-dark  btn-xs" onClick={() => { setIsModalOpen(true); setDocumentData(ticketDetails?.level_1_attachment); setModelHeading("Level 1 - Customer Support") }}>
                                                                        View Document
                                                                    </button>
                                                                </small>
                                                            </h5>
                                                        </div>
                                                        <div class="col-12">
                                                            <h5>
                                                                <span> Remark </span>
                                                                <small>  {ticketDetails?.level_1_response} </small>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        ticketDetails?.user_esc_level_1_response ?
                                            <div className="support_remark p-4 pt-0" >
                                                <div class="rm_col mb-0">
                                                    <div class="row">
                                                        <div class="col-3">
                                                            <h5>
                                                                <span> Action by  </span>
                                                                <small> {ticketDetails?.user_esc_level_1_action} </small>
                                                            </h5>
                                                        </div>
                                                        <div class="col-3">
                                                            <h5>
                                                                <span> Status  </span>
                                                                <small> {ticketDetails?.status} </small>
                                                            </h5>
                                                        </div>
                                                        <div class="col-3">
                                                            <h5>
                                                                <span> Updated On: </span>
                                                                <small> {moment(ticketDetails?.user_esc_level_1_timestamp).format('Do MMMM YYYY, h:mm:ss a')} </small>
                                                            </h5>
                                                        </div>
                                                        <div class="col-3">
                                                            <h5>
                                                                <span> Attachments </span>
                                                                <small>
                                                                    <button type="button" className="btn btn-outline-dark  btn-xs" onClick={() => { setIsModalOpen(true); setDocumentData(ticketDetails?.user_esc_level_1_attachment); setModelHeading("Level 1 - Customer Support") }}>
                                                                        View Document
                                                                    </button>
                                                                </small>
                                                            </h5>
                                                        </div>
                                                        <div class="col-12">
                                                            <h5>
                                                                <span> Remark </span>
                                                                <small>  {ticketDetails?.user_esc_level_1_response} </small>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }


                                </div>


                                {/* Level 2 - Nodal Officer */}
                                {
                                    ticketDetails?.ticket_level !== 1 &&

                                    <div className="card  mb-4" >
                                        <div className="card-body py-4">
                                            <div className="profile_data w-100  ac_body px-4   ">

                                                <div className="row align-items-start" >
                                                    <div className="col-lg-9" >
                                                        <div className="d-flex align-items-center justify-content-between mt-2" >
                                                            <h5> Level 2 - Nodal Officer  </h5>
                                                        </div>
                                                        <div className="row g-4">
                                                            <div className="col-lg-12">
                                                                <div className="label-group">
                                                                    <label> Level Esclated Date </label>


                                                                    {
                                                                        ticketDetails?.user_esc_level_1_action === "Escalated by User" ?
                                                                            <p>
                                                                                {ticketDetails?.user_esc_level_1_timestamp
                                                                                    ? moment(ticketDetails?.user_esc_level_1_timestamp).format('Do MMM YYYY, h:mm:ss a')
                                                                                    : "-------"}
                                                                            </p> :
                                                                            <p>
                                                                                {ticketDetails?.level_1_timestamp
                                                                                    ? moment(ticketDetails?.level_1_timestamp).format('Do MMM YYYY, h:mm:ss a')
                                                                                    : "-------"}
                                                                            </p>
                                                                    }


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            ticketDetails?.ticket_level == 2 && ticketDetails?.status === "Open" &&
                                            <div className="card-footer py-3 d-flex justify-content-end gap-3 " >
                                                <a className="btn btn-primary btn-md fw-normal" onClick={() => {
                                                    setIsModalOpenCloseTicket(true);
                                                    setTicketStatus("false")
                                                }}> Esclate Ticket </a>
                                                <a className="btn btn-dark btn-md  fw-normal" onClick={() => {
                                                    setIsModalOpenCloseTicket(true);
                                                    setTicketStatus("true")
                                                }}> Close Ticket </a>
                                            </div>
                                        }

                                        {
                                            ticketDetails?.level_2_response ?

                                                <div className="support_remark p-4 pt-0" >
                                                    <div class="rm_col mb-0">
                                                        <div class="row">
                                                            <div class="col-3">
                                                                <h5>
                                                                    <span> Action by  </span>
                                                                    <small> {ticketDetails?.level_2_action} </small>
                                                                </h5>
                                                            </div>
                                                            <div class="col-3">
                                                                <h5>
                                                                    <span> Status  </span>
                                                                    <small> {ticketDetails?.status} </small>
                                                                </h5>
                                                            </div>
                                                            <div class="col-3">
                                                                <h5>
                                                                    <span> Date </span>


                                                                    {
                                                                        ticketDetails?.level_2_timestamp ?
                                                                            <small>
                                                                                {ticketDetails?.level_2_timestamp
                                                                                    ? moment(ticketDetails?.level_2_timestamp).format('Do MMM YYYY, h:mm:ss a')
                                                                                    : "-------"}
                                                                            </small>
                                                                            :
                                                                            <small>
                                                                                {ticketDetails?.user_esc_level_2_timestamp
                                                                                    ? moment(ticketDetails?.user_esc_level_2_timestamp).format('Do MMM YYYY, h:mm:ss a')
                                                                                    : "-------"}
                                                                            </small>
                                                                    }
                                                                </h5>
                                                            </div>
                                                            <div class="col-3">
                                                                <h5>
                                                                    <span> Attachments </span>
                                                                    <small>
                                                                        <button type="button" className="btn btn-outline-dark  btn-xs" onClick={() => { setIsModalOpen(true); setDocumentData(ticketDetails?.level_2_attachment); setModelHeading("Level 2 - Nodal Officer") }}>
                                                                            View Document
                                                                        </button>
                                                                    </small>
                                                                </h5>
                                                            </div>
                                                            <div class="col-12">
                                                                <h5>
                                                                    <span> Remark </span>
                                                                    <small> {ticketDetails?.level_2_response} </small>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }


                                        {
                                            ticketDetails?.user_esc_level_2_response ?
                                                <div className="support_remark p-4 pt-0" >
                                                    <div class="rm_col mb-0">
                                                        <div class="row">
                                                            <div class="col-3">
                                                                <h5>
                                                                    <span> Action by  </span>
                                                                    <small> {ticketDetails?.user_esc_level_2_action} </small>
                                                                </h5>
                                                            </div>
                                                            <div class="col-3">
                                                                <h5>
                                                                    <span> Status  </span>
                                                                    <small> {ticketDetails?.status} </small>
                                                                </h5>
                                                            </div>
                                                            <div class="col-3">
                                                                <h5>
                                                                    <span> Updated On: </span>
                                                                    <small> {moment(ticketDetails?.user_esc_level_2_timestamp).format('Do MMMM YYYY, h:mm:ss a')} </small>
                                                                </h5>
                                                            </div>
                                                            <div class="col-3">
                                                                <h5>
                                                                    <span> Attachments </span>
                                                                    <small>
                                                                        <button type="button" className="btn btn-outline-dark  btn-xs" onClick={() => { setIsModalOpen(true); setDocumentData(ticketDetails?.user_esc_level_2_attachment); setModelHeading("Level 1 - Customer Support") }}>
                                                                            View Document
                                                                        </button>
                                                                    </small>
                                                                </h5>
                                                            </div>
                                                            <div class="col-12">
                                                                <h5>
                                                                    <span> Remark </span>
                                                                    <small>  {ticketDetails?.user_esc_level_2_response} </small>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }

                                    </div>
                                }

                                {/* Level 3 - CEO Desk */}
                                {/* Level 3 - CEO Desk */}

                                {
                                    ticketDetails?.ticket_level == 3 &&
                                    <div className="card  mb-4" >
                                        <div className="card-body py-4">
                                            <div className="profile_data w-100  ac_body px-4   ">

                                                <div className="row align-items-start" >
                                                    <div className="col-lg-9" >
                                                        <div className="d-flex align-items-center justify-content-between mt-2" >
                                                            <h5> Level 3 - CEO Desk  </h5>
                                                        </div>
                                                        <div className="row g-4">
                                                            <div className="col-lg-12">
                                                                <div className="label-group">
                                                                    <label> Level Esclated Date </label>
                                                                    {
                                                                        ticketDetails?.user_esc_level_2_action === "Escalated by User" ?
                                                                            <p>
                                                                                {ticketDetails?.user_esc_level_2_timestamp
                                                                                    ? moment(ticketDetails?.user_esc_level_1_timestamp).format('Do MMM YYYY, h:mm:ss a')
                                                                                    : "-------"}
                                                                            </p> :
                                                                            <p>
                                                                                {ticketDetails?.level_2_timestamp
                                                                                    ? moment(ticketDetails?.level_2_timestamp).format('Do MMM YYYY, h:mm:ss a')
                                                                                    : "-------"}
                                                                            </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            ticketDetails?.ticket_level == 3 && ticketDetails?.status === "Open" &&
                                            <div className="card-footer py-3 d-flex justify-content-end gap-3 " >
                                                <a className="btn btn-dark btn-md  fw-normal" onClick={() => {
                                                    setIsModalOpenCloseTicket(true);
                                                    setTicketStatus("true")
                                                }}> Close Ticket </a>
                                            </div>
                                        }

                                        {/* user support remark */}
                                        {
                                            ticketDetails?.level_3_response || ticketDetails?.user_esc_level_3_response ?

                                                <div className="support_remark p-4 pt-0" >
                                                    <div class="rm_col mb-0">
                                                        <div class="row">
                                                            <div class="col-3">
                                                                <h5>
                                                                    <span> Action by  </span>
                                                                    <small> {ticketDetails?.level_3_action} </small>
                                                                </h5>
                                                            </div>
                                                            <div class="col-3">
                                                                <h5>
                                                                    <span> Status  </span>
                                                                    <small> {ticketDetails?.status} </small>
                                                                </h5>
                                                            </div>
                                                            <div class="col-3">
                                                                <h5>
                                                                    <span> Date </span>

                                                                    {
                                                                        ticketDetails?.level_3_timestamp ?
                                                                            <small>
                                                                                {ticketDetails?.level_3_timestamp
                                                                                    ? moment(ticketDetails?.level_3_timestamp).format('Do MMM YYYY, h:mm:ss a')
                                                                                    : "-------"}
                                                                            </small>
                                                                            :
                                                                            <small>
                                                                                {ticketDetails?.user_esc_level_3_timestamp
                                                                                    ? moment(ticketDetails?.user_esc_level_3_timestamp).format('Do MMM YYYY, h:mm:ss a')
                                                                                    : "-------"}
                                                                            </small>
                                                                    }

                                                                </h5>
                                                            </div>
                                                            <div class="col-3">
                                                                <h5>
                                                                    <span> Attachments </span>
                                                                    <small>
                                                                        <button type="button" className="btn btn-outline-dark  btn-xs" onClick={() => { setIsModalOpen(true); setDocumentData(ticketDetails?.user_esc_level_3_attachment || ticketDetails?.level_3_attachment); setModelHeading("Level 3 - CEO Desk") }}>
                                                                            View Document
                                                                        </button>
                                                                    </small>
                                                                </h5>
                                                            </div>
                                                            <div class="col-12">
                                                                <h5>
                                                                    <span> Remark </span>
                                                                    <small> {ticketDetails?.level_3_response || ticketDetails?.user_esc_level_3_response} </small>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>

                                }

                            </div>
                        </div>
                    </div>
                </form>
            </main>

            {isModalOpen && <ViewDocumentModel documentData={documentData} modelHeading={modelHeading} setIsModalOpen={setIsModalOpen} />}

            {
                isModalOpenCloseTicket && (
                    <>
                        <div className="modal show d-block" >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content pb-3">
                                    <div className="modal-header">
                                        <h5 className="modal-title" >You are about to Close Ticket.</h5>
                                    </div>
                                    <div className="modal-body ">

                                        <div className="col-md-12 mb-3">
                                            <label className="form-label">Attachments (optional)</label>
                                            <input
                                                type="file"
                                                name="attachment"
                                                className="form-control"
                                                onChange={handleFileChange}
                                            />

                                        </div>

                                        <div className="form-group mb-4 ">
                                            <label className="small mb-1"> Enter Remark </label>
                                            <textarea className={`form-control py-3 ${errorMessage ? "is-invalid" : ""}`} rows="4" placeholder="Enter your Remark.." name="closeRemark" value={closeRemark}
                                                onChange={(e) => {
                                                    setCloseRemark(e.target.value);
                                                    setErrorMessage("");
                                                }}>
                                            </textarea>
                                            {errorMessage && (
                                                <div className="invalid-feedback mb-0">
                                                    <small>{errorMessage}</small>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <button className="btn btn-success  btn-block w-100" type="button" onClick={() => handleCloseTicket(closeRemark, attachedData)}>
                                        {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <span> Submit </span>}
                                    </button>
                                    <button className="btn btn-secondary  btn-block w-100 mt-1" type="button" onClick={() => setIsModalOpenCloseTicket(false)}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </>
                )
            }
        </div>
    );
};

export default SupportTicketDetails;
