import React, { useEffect, useState } from "react";
import DepositLimit from "../../SessionComponent/LimitManagement/DepositLimit";
import WithdrawalLimit from "../../SessionComponent/LimitManagement/WithdrawalLimit";
import BuySellLimit from "../../SessionComponent/LimitManagement/BuySellLimit";
import QuickPayLimit from "../../SessionComponent/LimitManagement/QuickPayLimit";
import AuthService from "../../../services/ApiIntegration/AuthService";
import { alertErrorMessage } from "../../CustomComponent/CustomAlert";
import moment from "moment";
import DirectWithdrawalLimit from "../../SessionComponent/LimitManagement/DirectWithdrawalLimit";

const ExchangeLimits = () => {
  const [activeTab, setActiveTab] = useState("depositLimit");
  const [userLimitData, setUserLimitData] = useState();


  const handleLimitData = async () => {
    await AuthService.adminLimiDetails().then(async (result) => {
      if (result.success) {
        try {
          setUserLimitData(result?.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result);
      }
    });
  };

  useEffect(() => {
    handleLimitData();
  }, [])


  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header">
          <div className="page-header-content pb-4">
            <div className="container-xl px-4">
              <h1 class="page-header-title"> Exchange Limits </h1>
            </div>
          </div>
        </header>
        <div className="container-xl px-4">
          <div className="page-header-content">
            <div className=" ">
              <div className="custom-tab-2">
                <ul className="nav nav-pills justify-content-start mb-0" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button type="button" className={`nav-link ${activeTab === "depositLimit" ? "active" : ""}`} onClick={() => setActiveTab("depositLimit")}>
                      {" "}
                      Overall Deposit{" "}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button type="button" className={`nav-link ${activeTab === "withdrawalLimit" ? "active" : ""}`} onClick={() => setActiveTab("withdrawalLimit")}>
                      {" "}
                      Overall Withdrawals{" "}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button type="button" className={`nav-link ${activeTab === "buysellLimit" ? "active" : ""}`} onClick={() => setActiveTab("buysellLimit")}>
                      {" "}
                      Quick Buy/Sell{" "}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button type="button" className={`nav-link ${activeTab === "quickPayLimit" ? "active" : ""}`} onClick={() => setActiveTab("quickPayLimit")}>
                      {" "}
                      Quick Pay{" "}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button type="button" className={`nav-link ${activeTab === "investLimit" ? "active" : ""}`} onClick={() => setActiveTab("investLimit")}>
                      {" "}
                      Invest{" "}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button type="button" className={`nav-link ${activeTab === "spotLimit" ? "active" : ""}`} onClick={() => setActiveTab("spotLimit")}>
                      {" "}
                      Spot{" "}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button type="button" className={`nav-link ${activeTab === "futuresLimit" ? "active" : ""}`} onClick={() => setActiveTab("futuresLimit")}>
                      {" "}
                      Futures{" "}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button type="button" className={`nav-link ${activeTab === "merchentLimit" ? "active" : ""}`} onClick={() => setActiveTab("merchentLimit")}>
                      {" "}
                      Merchent{" "}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button type="button" className={`nav-link ${activeTab === "DirectDeposit" ? "active" : ""}`} onClick={() => setActiveTab("DirectDeposit")}>
                      {" "}
                      Direct Deposit  {" "}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button type="button" className={`nav-link ${activeTab === "DirectWithdrawal" ? "active" : ""}`} onClick={() => setActiveTab("DirectWithdrawal")}>
                      {" "}
                      Direct Withdrawal  {" "}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container-xl px-4">
          <div className="tab-content" >

            <DepositLimit activeTab={activeTab} userLimitData={userLimitData} handleLimitData={handleLimitData} />
            <WithdrawalLimit activeTab={activeTab} userLimitData={userLimitData} handleLimitData={handleLimitData} />
            <BuySellLimit activeTab={activeTab} />
            <QuickPayLimit activeTab={activeTab} />

            <DirectWithdrawalLimit activeTab={activeTab} />


            {(activeTab === "depositLimit" || activeTab === "withdrawalLimit") && userLimitData?.remarks?.length > 0 && (
              <div className="card h-100 mt-4">
                <div className="card-body py-4">
                  <div className="profile_data ac_body ">
                    <div className="d-flex justify-content-between">
                      <h5>Overall Updated History</h5>
                    </div>
                    <div className="oul_scroll" >
                      <div className="table-responsive m_table">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">Date/Time</th>
                              <th scope="col">Limit</th>
                              <th scope="col">Remark</th>
                              <th scope="col">Remark By</th>
                            </tr>
                          </thead>
                          <tbody>
                            {userLimitData?.remarks?.map((item, index) => {
                               const limitEntries = item.limit
                               ? Object.entries(item.limit).filter(([key]) => key !== "_id" && key !== "__v" && key !== "createdAt")
                               : [];
                              return (
                                <tr key={item._id || index}>
                                  <td>{moment(item.timestamp).format('Do MMM YYYY, h:mm:ss a')}</td>
                                  <td>
                                    {limitEntries.length > 0 ? (
                                      <ul className="ouh_list" >
                                        {limitEntries.map(([key, value]) => (
                                          <li key={key}>{key}: {value || "N/A"}</li>
                                        ))}
                                      </ul>
                                    ) : (
                                      "----"
                                    )}</td>
                                  <td> 
                                    <div className="uh_tabs" > {item.remark || "--------"} </div> </td>
                                  <td>{item.admin_name || "----"}</td>
                                </tr>
                              );
                            })}
                          </tbody>

                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </main>
    </div>
  );
};
export default ExchangeLimits;
