import React, { useEffect, useState } from "react";
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { $ } from "react-jquery-plugin";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ApiConfig } from "../../../../services/ApiIntegration/ApiConfig";


const KycDetailsPersonal = () => {

  const [rejectReason, setRejectReason] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = location.state;

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "rejectReason":
        setRejectReason(event.target.value);
        break;
    }
  };

  const verifyIdentity = async (id, status, reason) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getverifyidentity(id, status, reason).then(
      async (result) => {
        if (result?.success) {
          LoaderHelper.loaderStatus(false);
          try {
            alertSuccessMessage("user Kyc successfully Verified");
            $("#rejectmodal").modal("hide");
            navigate("/personal_kyc");
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(result.message);
        }
      }
    );
  };

  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <form className="form-data">
            <header className="page-header ">
              <div className="container-xl px-4">

                {/* <div className="page-header-content">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto">
                      <h1 className="page-header-title">
                        <Link to="/personal_kyc" className="page-header-icon" >
                          <i className="fa fa-arrow-left"></i>
                        </Link>
                        Hi, {userDetails?.name || userDetails?.firstName}
                      </h1>
                    </div>
                    {
                      userDetails?.kycVerified == 1 &&
                      <div className="col-auto">
                        <div className="row">
                          <div className="d-flex">
                            <button className="btn btn-danger btn-block" data-bs-toggle="modal" data-bs-target="#rejectmodal" type="button" >Reject</button>
                            <button className="btn mx-2 btn-success btn-block" type="button" onClick={() => verifyIdentity(userDetails?._id, 2)}>
                              Approve
                            </button>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div> */}

                <div className="page-header-content">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto">
                      <h1 className="page-header-title">
                        <Link to="/personal_kyc" className="page-header-icon">
                          <i className="fa fa-arrow-left" ></i>
                        </Link>
                        <div className="hd_u_info" >
                          <img
                            src={!userDetails?.user_selfie ? "assets/img/illustrations/profiles/profile-5.png" : ApiConfig?.appUrl + userDetails?.user_selfie} alt="" className="img-account-profile rounded-circle"
                            onError={(e) => {
                              e.target.src = "assets/img/illustrations/profiles/profile-5.png";
                            }}
                          />
                          <span>  {userDetails?.kyc?.first_name || 'Unknown User'} {userDetails?.kyc?.last_name}
                            <small className=" " > User ID: #32151456</small>
                          </span>
                        </div>
                      </h1>
                    </div>
                    <div className="col-auto">
                      <div className="d-flex">
                        <button className="btn btn-danger btn-block btn-md" data-bs-toggle="modal" data-bs-target="#rejectmodal" type="button" >Reject</button>
                        <button className="btn mx-2 btn-success btn-block btn-md" type="button" onClick={() => verifyIdentity(userDetails?._id, 2)}>
                          Approve
                        </button>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </header>
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-xl-12 mb-4">
                  <div className="card" >
                    <div className="card-body py-4">


                      <div className="d-flex align-items-start " >

                        <ul id="progressbar">
                          <ul id="progressbar">
                            <li class="cursor-pointer active" id="step1">
                              <strong>Proof of Identification</strong>
                            </li>
                            <li class="cursor-pointer warning" id="step2">
                              <strong>Check Liveness</strong>
                            </li>
                            <li class="cursor-pointer" id="step3">
                              <strong>Education &amp; Occupation</strong>
                            </li>
                            <li id="step4">
                              <strong>Declaration and Submission</strong>
                            </li>
                          </ul>
                        </ul>


                        <div class="profile_data w-100  ac_body px-4 pt-4 ">
                          {/* step 1 */}
                          <div className="step_1 " >
                            <div className="d-flex align-items-center justify-content-between mb-4" >
                              <h4> Proof of Identification </h4>
                            </div>
                            <div class="row g-4 ">
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label> Name on Aadhar & Pan Card    </label>
                                  <p> Unknown User </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label> Pan Card  </label>
                                  <p> 00000000000  </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label> Aadhar Card    </label>
                                  <p> 00000000000   </p>
                                </div>
                              </div>
                            </div>
                            <div className="my-4 mb-5 " />
                            <div className="d-flex align-items-center justify-content-between mb-4" >
                              <h4> Personal Information </h4>
                            </div>

                            <div class="row g-4">
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label> First Name </label>
                                  <p> {userDetails?.name || userDetails?.firstName || '-------'} </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label> Middle Name </label>
                                  <p> {userDetails?.name || userDetails?.middleName || '-------'} </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label> Last Name </label>
                                  <p> {userDetails?.name || userDetails?.lastName || '-------'} </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label> Gender </label>
                                  <p> {userDetails?.name || userDetails?.gender || '-------'} </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>  Email Address   </label>
                                  <p> {userDetails?.name || userDetails?.email || '-------'} <i class="ri-verified-badge-fill text-success"></i> </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label> Mobile Number </label>
                                  <p> {userDetails?.name || userDetails?.phone || '-------'} <i class="ri-verified-badge-fill text-success"></i> </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>  Date of Birth </label>
                                  <p>   {moment(userDetails?.dob).format('MMMM Do YYYY') || '-------'} </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>  Registration Date </label>
                                  <p>   {moment(userDetails?.createdAt).format('MMMM Do YYYY') || '-------'} </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label> Address </label>
                                  <p>
                                    {
                                      userDetails?.address?.line1 &&
                                      <>
                                        {userDetails?.address?.line1},&nbsp; {userDetails?.address?.line2},&nbsp; {userDetails?.address?.street},&nbsp; {userDetails?.address?.city},&nbsp; {userDetails?.address?.state}&nbsp; ({userDetails?.address?.pincode})
                                      </>
                                      ||
                                      '-------'
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>

                            {/* Step action  */}
                            <div className="puk_row" >
                              {/* <button className="btn btn-md btn-gray px-4" >
                              Prev.
                            </button> */}
                              <button className="btn btn-md btn-black px-4" >
                                Next
                              </button>
                            </div>

                          </div>

                          {/* step 2 */}
                          <div className="step_2  d-none"  >
                            <div className="d-flex align-items-center justify-content-between " >
                              <h4>   Check Liveness / Face Match </h4>
                            </div>

                            <div class="ud_details">
                              <div class="row">
                                <div class="col-lg-6">
                                  <div class="ud_img">
                                    <label> Aadhar Card Image </label>
                                    <div class="img">
                                      <img src="assets/img/blank_img.png" class="img-fluid w-100" alt="" />
                                      <h6>
                                        <small> Update on:  </small>12 Nov. 2024, 12:45 pm
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="ud_img">
                                    <label> Liveness Selfie </label>
                                    <div class="img">
                                      <img src="assets/img/blank_img.png" class="img-fluid w-100" alt="" />
                                      <h6>
                                        <small> Update on </small> 12 Nov 2024, 12:45 pm
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>





                            {/* Step action  */}
                            <div className="puk_row" >
                              <button className="btn btn-md btn-gray px-4" >
                                Prev.
                              </button>
                              <button className="btn btn-md btn-black px-4" >
                                Next
                              </button>
                            </div>



                          </div>

                          {/* step 3 */}
                          <div className="step_3  d-none" >
                            <div className="d-flex align-items-center justify-content-between mb-4" >
                              <h4> Education & Occupation </h4>
                            </div>
                            <div class="row g-4 ">
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label> Education    </label>
                                  <p>  Matriculation </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label> Occupation  </label>
                                  <p>   Self Employed  </p>
                                </div>
                              </div>

                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>  Marital Status</label>
                                  <p>  Single  </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>  Annual Income   </label>
                                  <p> 5,00,000 </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>  Annual Turnover   </label>
                                  <p>  5,000,000 </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-xl-4">
                                <div class="label-group">
                                  <label>  Politically Exposed Person   </label>
                                  <p>  No</p>
                                </div>
                              </div>


                            </div>



                            {/* Step action  */}
                            <div className="puk_row" >
                              <button className="btn btn-md btn-gray px-4" >
                                Prev.
                              </button>
                              <button className="btn btn-md btn-black px-4" >
                                Next
                              </button>
                            </div>

                          </div>

                          {/* step 3 */}
                          <div className="step_4  d-none" >
                            <div className=" mb-4" >
                              <h4> Declaration of Source of Funds   </h4>
                              <p>Date: &nbsp; <b className="text-dark" > 12/9/2024, 2:38:54 PM </b></p>
                            </div>

                            {/* Step action  */}
                            <div className="puk_row" >
                              <button className="btn btn-md btn-gray px-4" >
                                Prev.
                              </button>
                              <button className="btn btn-md btn-danger px-4" >
                                Reject
                              </button>
                              <button className="btn btn-md btn-success px-4" >
                                Approve
                              </button>
                            </div>

                          </div>

                        </div> 

                      </div>





                      {/* <div className="text-center">
                        <img
                          src={!userDetails?.user_selfie ? "assets/img/illustrations/profiles/profile-5.png" : ApiConfig?.appUrl + userDetails?.user_selfie} alt="" className="img-account-profile rounded-circle mb-4"
                          onError={(e) => {
                            e.target.src = "assets/img/illustrations/profiles/profile-5.png";
                          }}
                        />

                        <h3 className="fw-bolder fs-2 mb-0">
                          {userDetails?.name || userDetails?.firstName}
                        </h3>
                      </div> */}

                      {/* <div className="doc_img py-5 px-4 my-4">
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">First Name:</label>
                          <div className="col-lg-7">
                            <span className="fw-bolder fs-6 text-dark">{userDetails?.firstName}</span>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Middle Name:</label>
                          <div className="col-lg-7">
                            <span className="fw-bolder fs-6 text-dark">{userDetails?.middleName}</span>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Last Name:</label>
                          <div className="col-lg-7">
                            <span className="fw-bolder fs-6 text-dark">{userDetails?.lastName}</span>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Full Name:</label>
                          <div className="col-lg-7">
                            <span className="fw-bolder fs-6 text-dark">{userDetails?.name}</span>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">UserType:</label>
                          <div className="col-lg-7">
                            <span className="fw-bold fs-6 text-dark">{userDetails?.user_type}</span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Gender:</label>
                          <div className="col-lg-7">
                            <span className="fw-bold fs-6 text-dark">{userDetails?.gender}</span>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Email ID:</label>
                          <div className="col-lg-7">
                            <span className="fw-bold fs-6 text-dark">{userDetails?.email}</span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Mobile Number:</label>
                          <div className="col-lg-7">
                            <span className="fw-bold fs-6 text-dark">{userDetails?.phone}</span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Pan Number:</label>
                          <div className="col-lg-7">
                            <span className="fw-bold fs-6 text-dark">{userDetails?.pan}</span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Aadhar Number:</label>
                          <div className="col-lg-7">
                            <span className="fw-bold fs-6 text-dark">{userDetails?.maskedAadhaarNumber}</span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted"> Date of Birth:</label>
                          <div className="col-lg-7">
                            <span className="fw-bold fs-6 text-dark">
                              {moment(userDetails?.dob).format('MMMM Do YYYY')}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Registration Date:</label>
                          <div className="col-lg-7">
                            <span className="fw-bold fs-6 text-dark text-hover-primary">
                              {moment(userDetails?.createdAt).format("Do MMMM YYYY")}
                            </span>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Address:</label>
                          <div className="col-lg-7">

                            {
                              userDetails?.address?.line1 &&
                              <span className="fw-bold fs-6 text-dark text-hover-primary">
                                {userDetails?.address?.line1},&nbsp; {userDetails?.address?.line2},&nbsp; {userDetails?.address?.street},&nbsp; {userDetails?.address?.city},&nbsp; {userDetails?.address?.state}&nbsp; ({userDetails?.address?.pincode})
                              </span>
                            }

                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </form>
        </main>
      </div>

      <div className="modal" id="rejectmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered alert_modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">Are You Sure{" "}<>{userDetails?.first_name}</>{" "}?</h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group mb-4 ">
                  <label className="small mb-1"> Enter Reason </label>
                  <textarea className="form-control form-control-solid" rows="7" placeholder="Enter your message.." value={rejectReason} name="rejectReason" onChange={handleInputChange}></textarea>
                </div>
                <button className="btn btn-danger btn-block w-100" type="button" disabled={!rejectReason}
                  onClick={() => verifyIdentity(location.state?._id, 3, rejectReason)}>
                  Continue
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default KycDetailsPersonal;
