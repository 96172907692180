
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { SlideshowLightbox } from 'lightbox.js-react'
import copy from 'copy-to-clipboard';
import moment from "moment";
import { fatchAccountDetails, handleChangeBankStatusApprove, handleData } from "../../../../services/Context/PaymentContext";
import Swal from "sweetalert2";


const UsersBankDetails = () => {

  const location = useLocation();
  const navigate = useNavigate()
  const { row, bankStatus } = location.state || {};
  const [userBankDetails, setUserBankDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [isActiveCopy, setIsActiveCopy] = useState(null);

  useEffect(() => {
    handleData(row?._id, row?.userId, setUserBankDetails);
  }, []);


  const handleUpdateLimitRemark = async (status) => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: "Are you sure ?",
        html: `
          <label for="remark">Enter Remark:</label>
          <textarea id="remark" class="form-control mt-2" rows="3" placeholder="Enter Remark"></textarea>
        `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: status === "rejected" ? "#D4343E" : "#39C87B",
        cancelButtonColor: "#e7e7e7",
        confirmButtonText: status === "rejected" ? "REJECT" : "VERIFIED",
        preConfirm: () => {
          const remark = document.getElementById("remark").value.trim();
          if (!remark) {
            Swal.showValidationMessage("You need to enter a Remark!");
          }
          return remark;
        }
      });
      if (isConfirmed) {
        const remark = document.getElementById("remark").value.trim();
        handleChangeBankStatusApprove(status, row?._id, row?.userId, setLoadingStatus, handleData, setUserBankDetails, remark)
      }
    } catch (error) {
      Swal.fire("Error!", "Something went wrong. Please try again.", "error");
    }
  };

  console.log(userBankDetails, 'userBankDetails');

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header ">
          <div className="container-xl px-4">
            <div className="page-header-content">
              <div className="row align-items-center justify-content-between">
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <h1 className="page-header-title">
                      <span className="page-header-icon cursor-pointer" onClick={() => navigate('/users_bank_list', { state: { status: bankStatus } })}>
                        <i className="fa fa-arrow-left"></i>
                      </span>
                      <div className="hd_u_info" >
                        <img src="assets/img/illustrations/profiles/profile-5.png" alt="" className="img-account-profile rounded-circle" />
                        <span>
                          {userBankDetails?.bankData?.kycName || "Unknown User"} {" "}

                          <i className={`h6 ms-0 ${userBankDetails?.bankData?.status === "verified" ? "text-success ri-verified-badge-fill" : userBankDetails?.bankData?.status === "pending" ? "text-warning ri-warning-badge-fill" : userBankDetails?.bankData?.status === "rejected" ? "ri-error-warning-fill text-danger" : null}`}>

                          </i>
                          {
                            userBankDetails?.bankData?.userId &&
                            <small> User ID: {userBankDetails?.bankData?.userId}
                              <i className={`text-black cursor-pointer ${isActiveCopy === userBankDetails?.bankData?.userId ? "ri-check-line" : "ri-file-copy-line"}`}
                                onClick={() => { copy(userBankDetails?.bankData?.userId); setIsActiveCopy(userBankDetails?.bankData?.userId) }} ></i>
                            </small>
                          }
                        </span>
                      </div>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="container-xl px-4">
          <div className="card ud_card">
            <div className="card-body">
              <div className="ud_row">
                <div className="ud_details">
                  <div className="row g-4">
                    <div className="col-lg-4">
                      <div className="ud_img">
                        <label>Aadhar Card Image</label>
                        <div className="img">
                          {
                            (
                              !userBankDetails?.otherData?.aadharDetails?.adhaar_image ? (
                                <div className="ratio ratio-16x9" >
                                  <img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                                </div>
                              ) : (
                                <SlideshowLightbox className="ratio ratio-16x9">
                                  <img
                                    src={userBankDetails?.otherData?.aadharDetails?.adhaar_image || 'assets/img/blank_img.png'}
                                    className="img-fluid w-100"
                                    alt="Aadhar"
                                  />
                                </SlideshowLightbox>

                              )
                            )
                          }
                          <h6>
                            <small>Account created Date</small>
                            {
                              userBankDetails?.otherData?.userCreatedDate ?
                                moment(userBankDetails?.otherData?.userCreatedDate).format("Do MMM YYYY, h:mm:ss a")
                                : "-------"}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="ud_img">
                        <label>Liveness Selfie</label>
                        <div className="img">
                          {
                            (
                              !userBankDetails?.otherData?.faceMatchDetails?.imageURL ? (
                                <div className="ratio ratio-16x9" >
                                  <img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                                </div>
                              ) : (


                                <SlideshowLightbox className="ratio ratio-16x9">
                                  <img
                                    src={userBankDetails?.otherData?.faceMatchDetails?.imageURL || 'assets/img/blank_img.png'}
                                    className="img-fluid w-100"
                                    alt="Liveness"
                                  />
                                </SlideshowLightbox>
                              )
                            )
                          }
                          <h6>
                            <small>KYC Approved Date</small>
                            <span>
                              {
                                userBankDetails?.otherData?.kycDeclarationDate?.auto_approved_date ?

                                  moment(userBankDetails?.otherData?.kycDeclarationDate?.auto_approved_date).format("Do MMM YYYY, h:mm:ss a")
                                  : "----------"
                              }
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="ud_img">
                        <label>Selfie with cancelled cheque</label>
                        <div className="img">
                          {
                            (
                              !userBankDetails?.bankData?.bankDocument ? (
                                <div className="ratio ratio-16x9" >
                                  <img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                                </div>
                              ) : (
                                <SlideshowLightbox className="ratio ratio-16x9">
                                  <img
                                    src={userBankDetails?.bankData?.bankDocument || "assets/img/blank_img.png"}
                                    className="img-fluid w-100"
                                    alt=""
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = "assets/img/blank_img.png";
                                    }}
                                  />
                                </SlideshowLightbox>
                              )
                            )
                          }
                          <h6>
                            <small>Applied for bank Verification</small>
                            <span>{
                              userBankDetails?.bankData?.createdAt ?
                                moment(userBankDetails?.bankData?.createdAt).format("Do MMM YYYY, h:mm:ss a")
                                : "--------"} </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card my-4">
            <div className="card-header pb-0">
              <ul className="nav nav-tabs nav-tabs-custom" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-Pending-tab" data-bs-toggle="pill" data-bs-target="#pills-Pending" type="button" role="tab" aria-controls="pills-Pending" aria-selected="false"  >  Bank Details  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-Approved-tab" data-bs-toggle="pill" data-bs-target="#pills-Approved" type="button" role="tab" aria-controls="pills-Approved" aria-selected="false">  User Activity  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-Rejected-tab" data-bs-toggle="pill" data-bs-target="#pills-Rejected" type="button" role="tab" aria-controls="pills-Rejected" aria-selected="false">  Wallet Activity  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-Rejected-tab" data-bs-toggle="pill" data-bs-target="#pills-Rejected" type="button" role="tab" aria-controls="pills-Rejected" aria-selected="false">  Bank Ac. Activity  </button>
                </li>
              </ul>
              <div className="dropdown">
                <button className="btn btn-dark btn-sm dropdown-toggle" type="button" >
                  <i className="fa fa-download me-3"></i>Export
                </button>
              </div>
            </div>
            <div className="card-body py-4">
              <div className="ac_body profile_data" >
                <div className="row gx-5" >
                  <div className="col-lg-6" >
                    <div className="">
                      <div className="mb-3 d-flex align-items-center justify-content-between" >
                        <h5> Account Details  </h5>
                        <div>
                          {
                            userBankDetails?.bankData?.default &&
                            <span className="badge bg-dark"> Default </span>
                          }
                        </div>
                      </div>
                      <div className="row g-4" >
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label> Bank Name </label>
                            <p>{userBankDetails?.bankData?.bankName || "------"}</p>
                          </div>
                        </div>

                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label>  Status </label>
                            <p>
                              <span className={`${userBankDetails?.bankData?.status === "verified" ? "text-success" : userBankDetails?.bankData?.status === "pending" ? "text-warning" : userBankDetails?.bankData?.status === "rejected" ? "text-danger" : null}`}>
                                {userBankDetails?.bankData?.status?.toUpperCase() || "------"}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label> Account Holder Name </label>
                            <p> {userBankDetails?.bankData?.kycName || "------"} </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label> Account Number</label>
                            <p>  {userBankDetails?.bankData?.accountNumber || "------"}  </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label>   Account Type </label>
                            <p>  {userBankDetails?.bankData?.accountType || "------"}  </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label> Branch Name </label>
                            <p>  {userBankDetails?.bankData?.branchName || "------"}  </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label> IFSC Code   </label>
                            <p>  {userBankDetails?.bankData?.ifsc || "------"}  </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 border-left-dashed" >
                    <div className="fc_details" >
                      <div className="d-flex gap-3 align-items-center justify-content-between mb-4" >
                        <h5 className="mb-0" > Verify Details   </h5>

                        {
                          userBankDetails?.bankData?.status !== "rejected" && userBankDetails?.bankData?.isDeleted === false &&
                          <button className="btn btn-sm btn-warning" onClick={() => fatchAccountDetails(row?.accountNumber, row?.ifsc, row?.kycName, row?.userId, row?._id, setLoading, handleData, setUserBankDetails)}>
                            {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              : <span>  Fatch Details </span>}
                          </button>
                        }
                      </div>
                      <div className="row g-4" >
                        <div className="col-lg-4" >
                          <div className="label-group" >
                            <label>Name Match  </label>
                            <p>  {userBankDetails?.bankData?.nameMatch ? <span className="text-success" >YES  </span> : userBankDetails?.bankData?.nameMatch === false ? <span className="text-danger" >No  </span>  : "------"}   </p>
                          </div>
                        </div>
                        <div className="col-lg-4" >
                          <div className="label-group" >
                            <label> User name in Bank   </label>
                            <p>   {userBankDetails?.bankData?.userNameInBank || "------"}   </p>
                          </div>
                        </div>
                        <div className="col-lg-4" >
                          <div className="label-group" >
                            <label> Bank Status</label>
                            <p>  {userBankDetails?.bankData?.bankStatus || "------"}   </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label> Bank  Response </label>
                            <p>  {userBankDetails?.bankData?.bankResponse || "------"}   </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label>  IFSC Code  </label>
                            <p>  {userBankDetails?.bankData?.ifsc || "------"}   </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label> Account Number   </label>
                            <p>  {userBankDetails?.bankData?.accountNumber || "------"}   </p>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="label-group" >
                            <label> Bank Txn. Status </label>
                            <p>  {userBankDetails?.bankData?.bankTxnStatus || "------"}   </p>
                          </div>
                        </div>

                        {
                          userBankDetails?.bankData?.approved_reason &&
                          <div className="col-lg-4" >
                            <div className="label-group" >
                              <label> Approved Date </label>
                              <p> {moment(userBankDetails?.bankData?.approved_reason?.timestamp).format("Do MMM YYYY, h:mm:ss a")}  </p>
                            </div>
                          </div>
                        }

                        {
                          userBankDetails?.bankData?.reject_reason &&
                          <div className="col-lg-4" >
                            <div className="label-group" >
                              <label> Rejected Date </label>
                              <p> {moment(userBankDetails?.bankData?.reject_reason?.timestamp).format("Do MMM YYYY, h:mm:ss a")}  </p>
                            </div>
                          </div>
                        }

                        {
                          userBankDetails?.bankData?.deletedAt &&
                          <div className="col-lg-4" >
                            <div className="label-group" >
                              <label> Deleted Date </label>
                              <p>  {moment(userBankDetails?.bankData?.deletedAt).format("Do MMM YYYY, h:mm:ss a")}  </p>
                            </div>
                          </div>
                        }

                        <div className="col-lg-12" >
                          <div className="d-flex gap-2 align-items-start justify-content-between" >
                            <div>

                              {
                                userBankDetails?.bankData?.approved_reason &&
                                <>
                                  <span className="apr_status" >
                                    <small> Approved by </small>
                                    <br /> {userBankDetails?.bankData?.approved_reason?.admin_name}
                                  </span>
                                  <br />
                                </>

                              }

                              {
                                userBankDetails?.bankData?.reject_reason &&
                                <>
                                  <span className="apr_status" >
                                    <small> Rejected by </small>
                                    <br /> {userBankDetails?.bankData?.reject_reason?.admin_name}
                                  </span>
                                  <br />
                                </>

                              }

                              {
                                userBankDetails?.bankData?.reject_reason &&
                                <span className="apr_status" > <small > Remark </small> <br />  {userBankDetails?.bankData?.reject_reason?.remark} </span>
                              }

                              {
                                userBankDetails?.bankData?.approved_reason &&
                                <span className="apr_status" > <small > Remark </small> <br /> {userBankDetails?.bankData?.approved_reason?.remark}</span>
                              }
                            </div>
                            {
                              userBankDetails?.bankData?.status !== "rejected" &&
                              <div className="d-flex gap-2 align-items-center justify-content-end" >
                                {
                                  userBankDetails?.bankData?.status !== "verified" &&
                                  <button className="btn btn-md btn-success px-4" onClick={() => handleUpdateLimitRemark("verified")} disabled={!userBankDetails?.bankData?.nameMatch}>
                                    {loadingStatus ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                      : <span> Approve </span>}
                                  </button>
                                }
                                <button className="btn btn-md btn-danger px-4" type="button" onClick={() => handleUpdateLimitRemark("rejected")}>
                                  Reject
                                </button>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
};
export default UsersBankDetails;
