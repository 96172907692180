import React, { useEffect, useState } from "react";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import moment from "moment";
import Swal from "sweetalert2";
import LoaderHelper from "../../../CustomComponent/LoaderHelper/LoaderHelper";
import { alertErrorMessage } from "../../../CustomComponent/CustomAlert";

const PaymentDetails = ({ activeTab }) => {


    const [activeStatus, setActiveStatus] = useState("bank");
    const [isActiveModel, setIsActiveModel] = useState(false);
    const [isActiveModelUpi, setIsActiveModelUpi] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState("");
    const [bankData, setBankData] = useState([]);
    const [bankStatus, setBankStatus] = useState("Active");
    const [upiListData, setUpiListData] = useState([]);
    const [upiStatus, setUpiStatus] = useState("Active");

    const [formData, setFormData] = useState({
        accountHolderName: "INCRYP BLOCKCHAIN PRIVATE LIMITED",
        accountNumber: "",
        ifsc: "",
        bankName: "",
        branchName: "",
        accountType: "",
        closeRemark: "",
        upiAdditionRemark: "",
        upiName: "",
        vpaId: "",
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };


    const handleAddBankDetails = async () => {
        setIsLoading(true);
        setApiResponse("");
        try {
            const result = await AuthService.adminAddBankDetails(formData);
            if (result.success) {
                setIsLoading(false);
                setIsActiveModel(false);
                handleBankAccountList(bankStatus);
                setFormData({
                    accountHolderName: "INCRYP BLOCKCHAIN PRIVATE LIMITED",
                    accountNumber: "",
                    ifsc: "",
                    bankName: "",
                    branchName: "",
                    accountType: "",
                    closeRemark: ""
                });
                setApiResponse("");
            } else {
                setIsLoading(false);
                setApiResponse(result);
            }
        } catch (error) {
            setIsLoading(false);
            setApiResponse(error);
        }
    };

    const handleBankAccountList = async (bankStatus) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.adminBankList(bankStatus).then(async (result) => {
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                try {
                    setBankData(result.data?.banks);
                } catch (error) {
                    setBankData([]);
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                setBankData([]);
            }
        });
    };



    const handleDeleteBank = async (item) => {
        try {
            const { isConfirmed } = await Swal.fire({
                title: `<span style="font-size: 16px;">You want to delete: <b>${item?.accountHolderName}</b> Bank Details?</span>`,
                html: `
              <label for="remark">Reason for deletion:</label>
              <textarea id="remark" class="form-control mt-2" rows="3" placeholder="Enter Reason for deletion"></textarea>
            `,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#e64942",
                cancelButtonColor: "#e7e7e7",
                confirmButtonText: "Yes, Delete!",
                preConfirm: () => {
                    const remark = document.getElementById("remark").value.trim();
                    if (!remark) {
                        Swal.showValidationMessage("You need to enter a reason!");
                    }
                    return remark;
                }
            });
            if (isConfirmed) {
                const remark = document.getElementById("remark").value.trim();
                const response = await AuthService.deleteAdminBank(item?._id, remark);
                if (response.success) {
                    handleBankAccountList("Deleted");
                    setBankStatus("Deleted");
                    Swal.fire("Deleted!", "Bank account has been deleted.", "success");
                } else {
                    Swal.fire("Error!", response || "Failed to delete Bank account.", "error");
                }
            }
        } catch (error) {
            Swal.fire("Error!", "Something went wrong. Please try again.", "error");
        }
    };


    const handleUpdateBankStatus = async (status, id) => {
        setIsLoading(true);
        await AuthService.updateBankStatus(id).then(async (result) => {
            setIsLoading(false);
            if (result.success) {
                try {
                    handleBankAccountList(status);
                    setBankStatus(status);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                setIsLoading(false);
                alertErrorMessage(result);
            }
        });
    };



    const handleUpiAccountList = async (upiStatus) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.adminUpiList(upiStatus).then(async (result) => {
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                try {
                    setUpiListData(result.data?.upi);
                } catch (error) {
                    setUpiListData([]);
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                setUpiListData([]);
            }
        });
    };

    const handleAddUpiDetails = async () => {
        setIsLoading(true);
        setApiResponse("");
        try {
            const result = await AuthService.adminAddUpiDetails(formData);
            if (result.success) {
                setIsLoading(false);
                setIsActiveModelUpi(false);
                handleUpiAccountList(upiStatus);
                setApiResponse("");
                setFormData({
                    accountHolderName: "INCRYP BLOCKCHAIN PRIVATE LIMITED",
                    upiName: "",
                    vpaId: "",
                    upiAdditionRemark: "",
                });
            } else {
                setIsLoading(false);
                setApiResponse(result);
            }
        } catch (error) {
            setIsLoading(false);
            setApiResponse(error);
        }
    };

    const handleUpdateUpiStatus = async (status, id) => {
        setIsLoading(true);
        await AuthService.updateUpiStatus(id).then(async (result) => {
            setIsLoading(false);
            if (result.success) {
                try {
                    handleUpiAccountList(status);
                    setUpiStatus(status);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                setIsLoading(false);
                alertErrorMessage(result);
            }
        });
    };

    const handleDeleteUpi = async (item) => {
        try {
            const { isConfirmed } = await Swal.fire({
                title: `<span style="font-size: 16px;">You want to delete: <b>${item?.upiName}</b> UPI Details?</span>`,
                html: `
              <label for="remark">Reason for deletion:</label>
              <textarea id="remark" class="form-control mt-2" rows="3" placeholder="Enter Reason for deletion"></textarea>
            `,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#e64942",
                cancelButtonColor: "#e7e7e7",
                confirmButtonText: "Yes, Delete!",
                preConfirm: () => {
                    const remark = document.getElementById("remark").value.trim();
                    if (!remark) {
                        Swal.showValidationMessage("You need to enter a reason!");
                    }
                    return remark;
                }
            });
            if (isConfirmed) {
                const remark = document.getElementById("remark").value.trim();
                const response = await AuthService.deleteAdminUpi(item?._id, remark);
                if (response.success) {
                    handleUpiAccountList("Deleted");
                    setUpiStatus("Deleted");
                    Swal.fire("Deleted!", "UPI account has been deleted.", "success");
                } else {
                    Swal.fire("Error!", response || "Failed to delete UPI account.", "error");
                }
            }
        } catch (error) {
            Swal.fire("Error!", "Something went wrong. Please try again.", "error");
        }
    };

    useEffect(() => {
        if (activeTab == 1) {
            handleBankAccountList(bankStatus);
            handleUpiAccountList(upiStatus);
        }
    }, [bankStatus, upiStatus, activeTab]);


    return (
        <div className={`tab-pane ${activeTab === 1 ? "active" : ""}`} >
            <div className="card-body py-4" >
                <div className="profile_data  ac_body  " >
                    <div className=" " >
                        <div className="custom-tab-2">
                            <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={`nav-link ${activeStatus === "bank" ? "active" : ""}`} onClick={() => { handleBankAccountList(bankStatus); setActiveStatus("bank") }}> Bank Accounts  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={`nav-link ${activeStatus === "upi" ? "active" : ""}`} onClick={() => { handleUpiAccountList(upiStatus); setActiveStatus("upi") }}> UPI Id's </button>
                                </li>

                                <li className="nav-item" role="presentation">
                                    <button type="button" className={`nav-link ${activeStatus === "otherData" ? "active" : ""}`} onClick={() => { setActiveStatus("otherData") }}> Payment Gateways </button>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className="tab-content">

                        <div className={`tab-pane ${activeStatus === "bank" ? "active" : ""}`}>
                            <div className=" " >
                                <div className="profile_data  ac_body" >
                                    <div className='d-flex mb-2 align-items-center justify-content-between' >
                                        <h5 className="mb-0" >  Bank Accounts </h5>
                                        <button className='btn btn-md btn-dark' onClick={() => setIsActiveModel(true)}><b><i class="ri-add-fill me-2"></i></b>Add New Account</button>
                                    </div>
                                    <ul className="nav custom-tabs mb-4">
                                        <li className="buysell-tab">
                                            <span className={bankStatus === "Active" ? "active" : ""} onClick={() => setBankStatus("Active")}>  Active Accounts   </span>
                                        </li>
                                        <li className="buysell-tab">
                                            <span className={bankStatus === "Inactive" ? "active" : ""} onClick={() => setBankStatus("Inactive")}>  Inactive Accounts   </span>
                                        </li>

                                        <li className="buysell-tab">
                                            <span className={bankStatus === "Deleted" ? "active" : ""} onClick={() => { setBankStatus("Deleted") }}> Deleted Accounts </span>
                                        </li>
                                        <li className="buysell-tab">
                                            <span className={bankStatus === "Unsuccessful" ? "active" : ""} onClick={() => setBankStatus("Unsuccessful")}> Unsuccessful Attempts </span>
                                        </li>
                                    </ul>

                                    <div className='fc_row'>
                                        {bankData?.length > 0 ? (
                                            bankData.map((item, index) => (
                                                <div className="fc_details mb-3" key={index}>
                                                    <div className="d-flex gap-3 align-items-canter justify-content-between mb-3">
                                                        <h5 className="mb-0 d-flex gap-3 align-items-center  " >
                                                            <b className="pr_icon pr_icon_sm fw-normal me-0">
                                                                <i class="ri-bank-fill"></i>{" "}
                                                            </b>
                                                            {item?.bankName}
                                                        </h5>

                                                        <div className='d-flex gap-0 align-items-center justify-content-end' >

                                                            {
                                                                bankStatus !== "Unsuccessful" && bankStatus !== "Deleted" &&
                                                                <div className="custom-tab-2">
                                                                    <ul className="nav nav-pills justify-content-start mb-1 link_status" role="tablist">
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className={`nav-link link-success ${item?.status === "Active" ? "active" : ""}`}
                                                                                onClick={() => handleUpdateBankStatus("Active", item?._id)}
                                                                                disabled={item?.status === "Active"}>
                                                                                {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Active</span>}
                                                                            </button>
                                                                        </li>
                                                                        <li className="nav-item" role="presentation">
                                                                            <button type="button" className={`nav-link link-danger ${item?.status === "Inactive" ? "active" : ""}`}
                                                                                onClick={() => handleUpdateBankStatus("Inactive", item?._id)}
                                                                                disabled={item?.status === "Inactive"}>
                                                                                {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Inactive</span>}
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            }

                                                            {
                                                                bankStatus === "Inactive" &&
                                                                <div className="dropdown">
                                                                    <button type="button" className="btn btn-blank p-1" data-bs-toggle="dropdown" fdprocessedid="1s52ls" aria-expanded="false">
                                                                        <i className="ri-more-2-fill"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu"  >
                                                                        <span className="dropdown-item cursor-pointer" onClick={() => handleDeleteBank(item)}> Delete Account </span>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="row g-3" >
                                                        <div className="col-lg-3" >
                                                            <div className="label-group" >
                                                                <label>  Account Type  </label>
                                                                <p>  {item?.accountType}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3" >
                                                            <div className="label-group" >
                                                                <label>  Account Holder Name   </label>
                                                                <p>  {item?.accountHolderName}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3" >
                                                            <div className="label-group" >
                                                                <label> Account Number </label>
                                                                <p> {item?.accountNumber}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3" >
                                                            <div className="label-group" >
                                                                <label> IFSC Code    </label>
                                                                <p> {item?.ifsc} <br /> <small className="fw-normal" > Branch:  {item?.branchName}  </small> </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3" >
                                                            <div className="label-group" >
                                                                <label> Bank Status   </label>
                                                                <p> {item?.bankStatus || "------"}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3" >
                                                            <div className="label-group" >
                                                                <label> Bank Response   </label>
                                                                <p> {item?.bankResponse}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3" >
                                                            <div className="label-group" >
                                                                <label> Added By  </label>
                                                                <p> {item?.addedBy}
                                                                    <br />
                                                                    <small className="fw-normal" >
                                                                        {
                                                                            item.addedAt ?
                                                                                <>
                                                                                    {moment(item.addedAt).format("DD MMM YYYY  ")},
                                                                                    {moment(item.addedAt).format("HH:mm:ss")}
                                                                                </>
                                                                                : "--------"
                                                                        }
                                                                    </small>
                                                                    <br /> <small className="fw-normal" ><b>Remark</b>:  {item?.additionRemark}  </small>
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3" >
                                                            <div className="label-group" >
                                                                <label> Deleted By  </label>
                                                                <p> {item?.deletedBy}
                                                                    <br />
                                                                    <small className="fw-normal" >
                                                                        {
                                                                            item.deletedAt ?
                                                                                <>
                                                                                    {moment(item.deletedAt).format("DD MMM YYYY  ")},
                                                                                    {moment(item.deletedAt).format("HH:mm:ss")}
                                                                                </>
                                                                                : "--------"
                                                                        }
                                                                    </small>
                                                                    <br /> <small className="fw-normal" ><b>Remark</b>:  {item?.deletionRemark}  </small>
                                                                </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            ))
                                        ) :
                                            <div className="text-center" >
                                                <img src="/assets/img/not_found.svg" alt="" className="img-fluid" width="300" />
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`tab-pane ${activeStatus === "upi" ? "active" : ""}`}>
                            <div className="profile_data  ac_body" >
                                <div className='d-flex mb-2 align-items-center justify-content-between' >
                                    <h5 className="mb-0" >  UPI ID </h5>
                                    <button className='btn btn-md btn-dark' onClick={() => setIsActiveModelUpi(true)}><b><i class="ri-add-fill me-2"></i></b>Add New UPI </button>
                                </div>
                                <ul className="nav custom-tabs mb-4">
                                    <li className="buysell-tab">
                                        <span className={upiStatus === "Active" ? "active" : ""} onClick={() => setUpiStatus("Active")}>  Active UPI's   </span>
                                    </li>
                                    <li className="buysell-tab">
                                        <span className={upiStatus === "Inactive" ? "active" : ""} onClick={() => setUpiStatus("Inactive")}>  Inactive UPI's   </span>
                                    </li>
                                    <li className="buysell-tab">
                                        <span className={upiStatus === "Deleted" ? "active" : ""} onClick={() => setUpiStatus("Deleted")}>  Deleted UPI's  </span>

                                    </li>
                                    <li className="buysell-tab">
                                        <span className={upiStatus === "Unsuccessful" ? "active" : ""} onClick={() => setUpiStatus("Unsuccessful")}> Unsuccessful Attempts </span>
                                    </li>
                                </ul>

                                <div className='fc_row row g-3 '>

                                    {upiListData?.length > 0 ? (
                                        upiListData.map((item, index) => (
                                            <div className='col-lg-12' key={index}>
                                                <div className="fc_details " >

                                                    <div className="d-flex gap-3 align-items-center justify-content-between mb-3" >
                                                        <h5 className="mb-0 d-flex gap-3 align-items-center  " >
                                                            <b className="pr_icon pr_icon_sm fw-normal me-0">
                                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0,0,256,256">
                                                                    <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{ mixBlendMode: 'normal' }}><g transform="scale(5.12,5.12)"><path d="M9,48c-0.172,0 -0.345,-0.044 -0.501,-0.135c-0.393,-0.228 -0.582,-0.691 -0.463,-1.128l12.02,-44c0.111,-0.406 0.465,-0.7 0.885,-0.733c0.428,-0.025 0.816,0.201 0.99,0.584l9.98,22c0.172,0.379 0.091,0.826 -0.204,1.12l-22,22c-0.192,0.192 -0.449,0.292 -0.707,0.292zM40.91,24.587l-10,-22c-0.174,-0.385 -0.573,-0.623 -0.991,-0.583c-0.42,0.034 -0.774,0.329 -0.884,0.736l-1.807,6.683l6.504,14.338c0.514,1.13 0.269,2.481 -0.611,3.36l-14.634,14.634l-1.347,4.984c-0.118,0.438 0.073,0.9 0.465,1.127c0.156,0.09 0.329,0.134 0.5,0.134c0.26,0 0.517,-0.101 0.709,-0.294l21.895,-22c0.293,-0.295 0.373,-0.741 0.201,-1.119z"></path></g></g>
                                                                </svg>
                                                            </b>
                                                            {item?.vpaId}
                                                        </h5>
                                                        <div className="d-flex gap-0 align-items-center justify-content-end" >

                                                            {
                                                                upiStatus !== "Unsuccessful" && upiStatus !== "Deleted" &&
                                                                <div className="custom-tab-2">
                                                                    <ul className="nav nav-pills justify-content-start mb-1 link_status " role="tablist">
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className={`nav-link link-success ${item?.status === "Active" ? "active" : ""}`}
                                                                                onClick={() => handleUpdateUpiStatus("Active", item?._id)}
                                                                                disabled={item?.status === "Active"}>
                                                                                {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Active</span>}
                                                                            </button>
                                                                        </li>
                                                                        <li className="nav-item" role="presentation">
                                                                            <button type="button " className={`nav-link  link-danger ${item?.status === "Inactive" ? "active" : ""}`}
                                                                                onClick={() => handleUpdateUpiStatus("Inactive", item?._id)}
                                                                                disabled={item?.status === "Inactive"}>
                                                                                {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Inactive</span>}
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            }

                                                            {
                                                                upiStatus === "Inactive" &&
                                                                <div className="dropdown">
                                                                    <button type="button" className="btn btn-blank p-1" data-bs-toggle="dropdown" fdprocessedid="1s52ls" aria-expanded="false">
                                                                        <i className="ri-more-2-fill"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu"  >
                                                                        <button className="dropdown-item" onClick={() => handleDeleteUpi(item)}> Delete  UPI </button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="row g-1 align-items-start" >
                                                        <div className="col-lg-3" >
                                                            <div className="label-group" >
                                                                <label> UPI Name  </label>
                                                                <p>  {item?.upiName}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3" >
                                                            <div className="label-group" >
                                                                <label> UPI ID </label>
                                                                <p> {item?.vpaId}   </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3" >
                                                            <div className="label-group" >
                                                                <label> Added By </label>
                                                                <p> {item?.addedBy} <br /> <small className="fw-normal" >
                                                                    {
                                                                        item.addedAt ?
                                                                            <>
                                                                                {moment(item.addedAt).format("DD MMM YYYY  ")},
                                                                                {moment(item.addedAt).format("HH:mm:ss")}
                                                                            </>
                                                                            : "---------"
                                                                    }
                                                                </small>
                                                                    <br /> <small className="fw-normal" ><b>Remark</b>: {item?.additionRemark}.  </small> </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3" >
                                                            <div className="label-group" >
                                                                <label>  Deleted By  </label>
                                                                <p> {item?.deletedBy || " --------"}
                                                                    <br /> <small className="fw-normal" >
                                                                        {
                                                                            item.deletedAt ?
                                                                                <>
                                                                                    {moment(item.deletedAt).format("DD MMM YYYY  ")},
                                                                                    {moment(item.deletedAt).format("HH:mm:ss")}
                                                                                </>
                                                                                : "---------"
                                                                        }

                                                                    </small>
                                                                    <br /> <small className="fw-normal" ><b>Remark</b>:{item?.deletionRemark}.  </small> </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                    ) :
                                        <div className="text-center" >
                                            <img src="/assets/img/not_found.svg" alt="" className="img-fluid" width="300" />
                                        </div>}
                                </div>
                            </div>
                        </div>


                        <div className={`tab-pane ${activeStatus === "otherData" ? "active" : ""}`}>
                            <div className="profile_data  ac_body" >
                                <div className='d-flex mb-2 align-items-center justify-content-between' >
                                    <h5 className="mb-0" >  Payment Gateways </h5>
                                    <button className='btn btn-md btn-dark'>
                                        <b><i class="ri-add-fill me-2"></i></b>Add New Payment Gateways </button>
                                </div>
                                <ul className="nav custom-tabs mb-4">
                                    <li className="buysell-tab">
                                        <span className="active">  Active Payment Gateways   </span>
                                    </li>
                                    <li className="buysell-tab">
                                        <span >  Inactive Payment Gateways  </span>
                                    </li>
                                    <li className="buysell-tab">
                                        <span >  Deleted Payment Gateways  </span>

                                    </li>
                                </ul>

                                <div className='fc_row row g-3 '>
                                    <div class="fc_details mb-3">
                                        <div class="d-flex gap-3 align-items-center justify-content-between mb-3">
                                            <h5 class="mb-0 d-flex gap-3 align-items-center">
                                                <b class="pr_icon pr_icon_sm fw-normal me-0">
                                                    <i class="ri-bank-fill"></i>
                                                </b>
                                                Bank Name
                                            </h5>
                                            <div class="d-flex gap-0 align-items-center justify-content-end"></div>
                                        </div>

                                        <div class="row g-3">
                                            <div class="col-lg-3">
                                                <div class="label-group">
                                                    <label>Account Type</label>
                                                    <p> --------- </p>
                                                </div>
                                            </div>

                                            <div class="col-lg-3">
                                                <div class="label-group">
                                                    <label>Account Holder Name</label>
                                                    <p> --------- </p>
                                                </div>
                                            </div>

                                            <div class="col-lg-3">
                                                <div class="label-group">
                                                    <label>Account Number</label>
                                                    <p> --------- </p>
                                                </div>
                                            </div>

                                            <div class="col-lg-3">
                                                <div class="label-group">
                                                    <label>IFSC Code</label>
                                                    <p>
                                                        --------- <br />
                                                        <small class="fw-normal">Branch: ---------</small>
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="col-lg-3">
                                                <div class="label-group">
                                                    <label>Bank Status</label>
                                                    <p> --------- </p>
                                                </div>
                                            </div>

                                            <div class="col-lg-3">
                                                <div class="label-group">
                                                    <label>Bank Response</label>
                                                    <p> --------- </p>
                                                </div>
                                            </div>

                                            <div class="col-lg-3">
                                                <div class="label-group">
                                                    <label>Added</label>
                                                    <p>
                                                        Rajendra<br />
                                                        <small class="fw-normal">18 Feb 2025, 12:41:54</small><br />
                                                        <small class="fw-normal"><b>Remark</b>: </small>
                                                    </p>
                                                </div>
                                            </div>


                                            <div class="col-lg-3">
                                                <div class="label-group">
                                                    <label>Deleted</label>
                                                    <p>
                                                        Rajendra<br />
                                                        <small class="fw-normal">18 Feb 2025, 12:41:54</small><br />
                                                        <small class="fw-normal"><b>Remark</b>: </small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>




            {isActiveModel && (
                <>
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content pb-3">
                                <div className="modal-header">
                                    <h5 className="modal-title" >You want to Add Bank Accounts ?</h5>
                                </div>
                                <div className="modal-body ">
                                    <div className="row" >
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="accountHolderName">Account Holder Name<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="accountHolderName"
                                                    name="accountHolderName"
                                                    type="text"
                                                    value={formData.accountHolderName}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="accountNumber">Account Number<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="accountNumber"
                                                    name="accountNumber"
                                                    type="number"
                                                    value={formData.accountNumber}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="ifsc">IFSC Code<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="ifsc"
                                                    name="ifsc"
                                                    type="text"
                                                    value={formData.ifsc}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="bankName">Bank Name<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="bankName"
                                                    name="bankName"
                                                    type="text"
                                                    value={formData.bankName}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="branchName">Branch Name<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="branchName"
                                                    name="branchName"
                                                    type="text"
                                                    value={formData.branchName}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="accountType">Account Type<em>*</em></label>
                                                <select
                                                    className="form-select form-select-solid form-control form-control-solid"
                                                    name="accountType"
                                                    value={formData.accountType}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select Account Type</option>
                                                    <option value="savings">Savings</option>
                                                    <option value="current">Current</option>
                                                    <option value="Nodal Account">Nodal Account</option>
                                                    <option value="Escrow Account">Escrow Account</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="remark">Remark<em>*</em></label>
                                                <textarea
                                                    className="form-control py-3"
                                                    rows="3"
                                                    placeholder="Enter your Remark.."
                                                    name="closeRemark"
                                                    value={formData.closeRemark}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center w-100 mb-4">
                                        <button
                                            className="btn btn-danger btn-block w-100"
                                            type="button"
                                            onClick={handleAddBankDetails}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                                                <span>Submit Details</span>}
                                        </button>
                                        <button className="btn btn-secondary  btn-block w-100 mt-1" type="button" onClick={() => setIsActiveModel(false)}>
                                            Cancel
                                        </button>
                                    </div>
                                    {apiResponse && <div className="text-danger">{apiResponse}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            )}


            {isActiveModelUpi && (
                <>
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content pb-3">
                                <div className="modal-header">
                                    <h5 className="modal-title" >You want to Add UPI Details ?</h5>
                                </div>
                                <div className="modal-body ">
                                    <div className="row" >
                                        <div className="col-lg-12">
                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="upiName">UPI Holder Name<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="upiName"
                                                    name="upiName"
                                                    type="text"
                                                    value={formData.upiName}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="vpaId">Virtual Payment Address (VPA) ID<em>*</em></label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="vpaId"
                                                    name="vpaId"
                                                    type="text"
                                                    value={formData.vpaId}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group mb-4">
                                                <label className="small mb-1" htmlFor="upiAdditionRemark">UPI Addition Remark<em>*</em></label>
                                                <textarea
                                                    className="form-control py-3"
                                                    rows="3"
                                                    placeholder="Enter your Remark.."
                                                    name="upiAdditionRemark"
                                                    value={formData.upiAdditionRemark}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="d-flex justify-content-center w-100 mb-4">
                                        <button
                                            className="btn btn-danger btn-block w-100"
                                            type="button"
                                            onClick={handleAddUpiDetails}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                                                <span>Submit Details</span>}
                                        </button>
                                        <button className="btn btn-secondary  btn-block w-100 mt-1" type="button" onClick={() => setIsActiveModelUpi(false)}>
                                            Cancel
                                        </button>
                                    </div>
                                    {apiResponse && <div className="text-danger">{apiResponse}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            )}

        </div>
    )
};

export default PaymentDetails;