import React, { useEffect, useState } from "react";
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { CSVLink } from "react-csv";
import moment from "moment";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";


const UserList = () => {
  const location = useLocation();
  const { mergedData } = location.state || {};
  const [exchangeUsers, setExchangeUsers] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [skip, setSkip] = useState(0);
  const [totalDataLength, setTotalDataLength] = useState(0);
  const [kycTypeUsers, setKycTypeUsers] = useState(mergedData?.kycTypeUsers || "all");
  const [usersKycType, setUsersKycType] = useState(mergedData?.usersKycType || "all");
  const navigate = useNavigate();
  const limit = 20;  

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const handleUsersData = async (skip, limit, kycTypeUsers, usersKycType, searchKey, fromDate, toDate) => {
    LoaderHelper.loaderStatus(true);
    try {
      const formattedSearchKey = searchKey || "";
      const formattedFromDate = formatDate(fromDate);
      const formattedToDate = formatDate(toDate);
      const result = await AuthService.getUsersList(skip, limit, kycTypeUsers, usersKycType, formattedSearchKey, formattedFromDate, formattedToDate);
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        setExchangeUsers(result?.data?.paginatedUsers);
        setTotalDataLength(result?.data?.totalUsers?.total || 0);
      } else {
        alertErrorMessage(result?.message);
        setExchangeUsers([]);
        setTotalDataLength(0);
      }
    } catch (error) {
      alertErrorMessage(error?.message);
      setExchangeUsers([]);
      setTotalDataLength(0);
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };


  const handleStatus = async (_id, status) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.changeUsersStatus(_id, status);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        handleUsersData(skip, limit, "all", "all")
      } else {
        alertErrorMessage(result?.message);
      };
    } catch (error) {
      alertErrorMessage(error?.message);
    }
    finally { LoaderHelper.loaderStatus(false) };
  };


  const columns = [
    {
      name: "Index No",
      selector: (row, index) => index + 1 + skip,
      sortable: false,
      width: "100px",
    },
    {
      name: "Joined Date", sort: true,
      selector: row => {
        const createdAt = row?.createdAt ? moment(row.createdAt) : null;
        return createdAt ? (
          <span>
            {createdAt.format("DD MMMM YYYY")}
            <br />
            <small>{createdAt.format("h:mm:ss a")}</small>
          </span>
        ) : (
          "-------"
        );
      },
    },
    { name: "User ID", sort: true, wrap: true, selector: (row) => row?.uid || '--------------', sortable: true },
    { name: "Name", sort: true, wrap: true, selector: (row) => row?.aadharDetails?.full_name || '--------------', sortable: true },
    { name: "Email", sort: true, wrap: true, selector: (row) => row?.email || '--------', sortable: true },
    { name: "Mobile No.", sort: true, selector: (row) => row?.phone || '--------', sortable: true },
    {
      name: "Action", selector: (row) =>
        <div>
          <button className="btn btn-dark btn-sm me-2" title="View" onClick={() =>
            navigate('/user_details', {
              state: {
                mergedData: {
                  row,
                  kycTypeUsers,
                  usersKycType
                }
              }
            })
          }
          >
            View
          </button>

          {row?.status === "Active" ? (
            <button className="btn btn-success btn-sm me-2"
              onClick={() => handleStatus(row?._id, "Inactive")}>
              Active
            </button>
          ) : (
            <button className="btn btn-danger btn-sm me-2"
              onClick={() => handleStatus(row?._id, "Active")}>
              Inactive
            </button>
          )}
        </div>
    },
  ];

  const handlePagination = (action, setSkip, skip, limit, totalDataLength) => {
    switch (action) {
      case "prev":
        if (skip - limit >= 0) setSkip(skip - limit);
        break;
      case "next":
        if (skip + limit < totalDataLength) setSkip(skip + limit);
        break;
      case "first":
        setSkip(0);
        break;
      case "last":
        setSkip(Math.max(totalDataLength - limit, 0));
        break;
      default:
        break;
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchKey(searchTerm);
  };

  const handleDateFilterData = (fromDate, toDate) => {
    if (!fromDate) {
      alertErrorMessage("Please select Start date")
      return;
    }
    if (!toDate) {
      alertErrorMessage("Please select End date")
      return;
    }
    handleUsersData(skip, limit, kycTypeUsers, usersKycType, searchKey, fromDate, toDate);
  };


  const handleFilterReset = () => {
    handleUsersData(skip, limit, "all", "all")
    setSearchKey("");
    setKycTypeUsers("all");
    setUsersKycType("all");
    setFromDate("");
    setToDate("");
  };

  useEffect(() => {
    handleUsersData(skip, limit, kycTypeUsers, usersKycType, searchKey, fromDate, toDate);
  }, [skip, limit, kycTypeUsers, usersKycType, searchKey,]);




  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-xl px-4 mt-4">
          <div className='custom-tab-2' >
            <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
              <li className="nav-item" role="presentation">
                <button type="button" className={`nav-link ${kycTypeUsers === "all" ? "active" : ""}`} onClick={() => setKycTypeUsers("all")}> All Users </button>
              </li>
              <li className="nav-item" role="presentation">
                <button type="button" className={`nav-link ${kycTypeUsers === "Personal" ? "active" : ""}`} onClick={() => setKycTypeUsers("Personal")}>  Individual Users  </button>
              </li>
              <li className="nav-item" role="presentation">
                <button type="button" className={`nav-link ${kycTypeUsers === "Entity" ? "active" : ""}`} onClick={() => setKycTypeUsers("Entity")}> Corporate Users </button>
              </li>
            </ul>
          </div>

          <div className="card mb-4">
            <div className="card-header pb-0">
              <ul className="nav nav-tabs nav-tabs-custom" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className={`nav-link ${usersKycType === "all" ? "active" : ""}`} onClick={() => setUsersKycType("all")}> All Users  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={`nav-link ${usersKycType === "verified" ? "active" : ""}`} onClick={() => setUsersKycType("verified")} > Verified  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={`nav-link ${usersKycType === "unverified" ? "active" : ""}`} onClick={() => setUsersKycType("unverified")} > Unverified  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={`nav-link ${usersKycType === "blocked" ? "active" : ""}`} onClick={() => setUsersKycType("blocked")} > Blocked Users  </button>
                </li>
              </ul>
              {
                exchangeUsers?.length > 0 &&
                <div className="dropdown">
                  <CSVLink className="btn btn-dark btn-sm dropdown-toggle" data={exchangeUsers} filename={`user_list.csv`}>
                    <i className="fa fa-download me-3"></i>Export
                  </CSVLink>
                </div>
              }

            </div>

            <div className="filter_bar search-header">
              <div className="dd_search">
                <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" value={searchKey} onChange={handleSearch} />
                <i className="fas fa-search"></i>
              </div>
              <form className="row align-items-center">
                <div className="col-4">
                  <input
                    type="date"
                    className="form-control form-control-solid"
                    data-provide="datepicker"
                    id="litepickerRangePlugin"
                    name="dateFrom"
                    value={fromDate}
                    max={new Date().toISOString().split("T")[0]}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>
                <div className="col-4">
                  <input
                    type="date"
                    className="form-control form-control-solid"
                    data-provide="datepicker"
                    id="litepickerRangePlugin"
                    name="dateTo"
                    value={toDate}
                    max={new Date().toISOString().split("T")[0]}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
                <div className="col-4">
                  <div className="row align-items-center">
                    <div className="col">
                      <button className="btn btn-black btn-block w-100 px-4" type="button" onClick={() => handleDateFilterData(fromDate, toDate)}>
                        Apply
                      </button>
                    </div>
                    <div className="col">
                      <button className="btn btn-light btn-block w-100" type="button" onClick={handleFilterReset}>
                        <i className="ri-reset-left-line"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTable
                  className="table table-hover"
                  columns={columns}
                  data={exchangeUsers}
                  noDataComponent={
                    <p> <img src="/assets/img/not_found.svg" width="300" className='img-fluid mt-5' alt='' /> </p>
                  }

                  subHeader
                  subHeaderComponent={
                    <div style={{ padding: "10px", fontWeight: "bold" }}>
                      Total Users: {totalDataLength}
                    </div>
                  }

                />
                {
                  exchangeUsers?.length > 0 &&
                  <div className="hVPalX gap-2" >
                    <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                    <div className="sc-eAKtBH gVtWSU">
                      <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first', setSkip, skip, limit, totalDataLength)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                          <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                          <path fill="none" d="M24 24H0V0h24v24z"></path>
                        </svg>
                      </button>
                      <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev', setSkip, skip, limit, totalDataLength)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                      </button>

                      <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next', setSkip, skip, limit, totalDataLength)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                          <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                      </button>
                      <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last', setSkip, skip, limit, totalDataLength)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                          <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                          <path fill="none" d="M0 0h24v24H0V0z"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UserList;
