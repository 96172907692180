import React, { useEffect, useState } from "react";
import { handleUpdateUserLimitWithDrawal } from "../../../../services/Context/LimitContext";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import moment from "moment";
import DirectWithdrawalLimit from "../../UserLimitManagement/DirectWithdrawalLimit";

const LimitDetails = ({ activeTab, userId }) => {

    const [isActiveTab, setIsActiveTab] = useState("Deposit");
    const [isActiveModelDeposit, setIsActiveModelDeposit] = useState(false);
    const [isActiveModelWithdrawal, setIsActiveModelWithdrawal] = useState(false);
    const [isActiveModelQbs, setIsActiveModelQbs] = useState(false);
    const [isActiveModelQuickPay, setIsActiveModelQuickPay] = useState(false);

    const [apiResponse, setApiResponse] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [depositWithdrawalLimit, setDepositWithdrawalLimit] = useState();
    const [quickyPayLimit, setQuickyPayLimit] = useState();
    const [userQbsLimitData, setUserQbsLimitData] = useState();


    const [formData, setFormData] = useState({
        totalDepositYear: "",
        totalCashDepositYear: "",
        dailyDepositLimit: "",
        dailyCashDepositLimit: "",
        totalWithdrawalYear: "",
        totalCashWithdrawalYear: "",
        dailyWithdrawalLimit: "",
        dailyCashWithdrawalLimit: "",
        totalCryptoWithdrawalLimit: "",
        cryptoDailyCashWithdrawalLimit: "",
        remark: "",
        inrTotalWithdrawal: "",
        inrTotalDailyWithdrawalLimit: "",
        inrMinWithdrawalLimit: "",
        inrMaxWithdrawalLimit: "",
        cryptoTotalWithdrawal: "",
        cryptoTotalDailyWithdrawalLimit: "",
        cryptoMinWithdrawalLimit: "",
        cryptoMaxWithdrawalLimit: "",
        inrFee: "",
        cryptoFee: "",
        dailyPay: "",
        remarkQuickPay: "",
        updatedBuyLimit: "",
        updatedCashLimit: "",
        updatedOrders: "",
        updatedBuyFees: "",
        updatedBuyTds: "",
        updatedSellFees: "",
        updatedSellTds: "",
        bulkTrader: "",
        remarkQbs: "",

        quickyPayfreeInrLimit: "",
        quickyPaydailyInrLimit: "",
        quickyPayminAmountInr: "",
        quickyPaymaxAmountInr: "",
        quickyPayinrFee: "",
        quickyPayfreeUsdLimit: "",
        quickyPaydailyUsdLimit: "",
        quickyPayminAmountUsd: "",
        quickyPaymaxAmountUsd: "",
        quickyPayFees: "",
        quickyPaytotalOrders: "",
        quickyPayremark: ""


    });

    useEffect(() => {
        if (depositWithdrawalLimit) {
            setFormData({
                totalDepositYear: depositWithdrawalLimit?.totalInrDeposit?.total || "",
                totalCashDepositYear: depositWithdrawalLimit?.totalInrCashDeposit?.total || "",
                dailyDepositLimit: depositWithdrawalLimit?.dailyInrDeposit?.total || "",
                dailyCashDepositLimit: depositWithdrawalLimit?.dailyInrCashDeposit?.total || "",
                totalWithdrawalYear: depositWithdrawalLimit?.totalInrWithdrawal?.total || "",
                totalCashWithdrawalYear: depositWithdrawalLimit?.totalInrCashWithdrawal?.total || "",
                dailyWithdrawalLimit: depositWithdrawalLimit?.dailyInrWithdrawal?.total || "",
                dailyCashWithdrawalLimit: depositWithdrawalLimit?.dailyInrCashWithdrawal?.total || "",
                totalCryptoWithdrawalLimit: depositWithdrawalLimit?.totalCryptoWithdrawal?.total || "",
                cryptoDailyCashWithdrawalLimit: depositWithdrawalLimit?.dailyCryptoWithdrawal?.total || "",
                remark: "",
                inrTotalWithdrawal: depositWithdrawalLimit.freeInrLimit || "",
                inrTotalDailyWithdrawalLimit: depositWithdrawalLimit.dailyInrLimit || "",
                inrMinWithdrawalLimit: depositWithdrawalLimit.minAmountInr || "",
                inrMaxWithdrawalLimit: depositWithdrawalLimit.maxAmountInr || "",
                cryptoTotalWithdrawal: depositWithdrawalLimit.freeUsdLimit || "",
                cryptoTotalDailyWithdrawalLimit: depositWithdrawalLimit.dailyUsdLimit || "",
                cryptoMinWithdrawalLimit: depositWithdrawalLimit.minAmountInr || "",
                cryptoMaxWithdrawalLimit: depositWithdrawalLimit.maxAmountUsd || "",
                inrFee: depositWithdrawalLimit.inrFee || "",
                cryptoFee: depositWithdrawalLimit.cryptoFee || "",
                dailyPay: depositWithdrawalLimit.totalOrders || "",
                remarkQuickPay: depositWithdrawalLimit.remark || "",
                updatedBuyLimit: userQbsLimitData?.dailyBuyLimit || "",
                updatedCashLimit: userQbsLimitData?.dailyCashLimit || "",
                updatedOrders: userQbsLimitData?.dailyOrders || "",
                updatedBuyFees: userQbsLimitData?.buyFees || 0,
                updatedBuyTds: userQbsLimitData?.buyTds || 0,
                updatedSellFees: userQbsLimitData?.sellFees || 0,
                updatedSellTds: userQbsLimitData?.sellTds || "",
                bulkTrader: userQbsLimitData?.bulkTrader || "",



                quickyPayfreeInrLimit: quickyPayLimit?.freeInrLimit?.total || "",
                quickyPaydailyInrLimit: quickyPayLimit?.dailyInrLimit?.total || "",
                quickyPayminAmountInr: quickyPayLimit?.minAmountInr?.total || "",
                quickyPaymaxAmountInr: quickyPayLimit?.maxAmountInr?.total || "",
                quickyPayinrFee: quickyPayLimit?.inrFee?.total || "",
                quickyPayfreeUsdLimit: quickyPayLimit?.freeUsdLimit?.total || "",
                quickyPaydailyUsdLimit: quickyPayLimit?.dailyUsdLimit?.total || "",
                quickyPayminAmountUsd: quickyPayLimit?.minAmountUsd?.total || "",
                quickyPaymaxAmountUsd: quickyPayLimit?.maxAmountUsd?.total || "",
                quickyPayFees: quickyPayLimit?.cryptoFee?.total || "",
                quickyPaytotalOrders: quickyPayLimit?.totalOrders?.total || "",
                quickyPayremark: ""

            });
        }
    }, [depositWithdrawalLimit, userQbsLimitData]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
        setApiResponse("");
    };

    const handleUserDepositWithLimitData = async () => {
        try {
            const result = await AuthService.userLimiDetails(userId);

            if (result.success) {
                setDepositWithdrawalLimit(result?.data);
            } else {
                alertErrorMessage(result?.message || "An error occurred");
            }
        } catch (error) {
            alertErrorMessage(error?.message || "An unexpected error occurred");
        }
    };

    const handleQbsLimitData = async () => {
        try {
            const result = await AuthService.getUserQbsLimits(userId);
            if (result?.success) {
                setUserQbsLimitData(result.data);
            } else {
                alertErrorMessage(result || "Failed to fetch QBS limits.");
            }
        } catch (error) {
            alertErrorMessage(error?.message || "An error occurred while fetching QBS limits.");
        }
    };

    useEffect(() => {
        if (activeTab === 5 && isActiveTab === "Deposit" || isActiveTab === "Withdrawals") {
            handleUserDepositWithLimitData();
        }
    }, [activeTab, isActiveTab])


    const handleUpdateUserQbsLimit = async () => {
        if (!formData?.remarkQbs) {
            alertErrorMessage("Remark is Required")
            return;
        }
        try {
            setIsLoading(true);
            const result = await AuthService.updateUserQbsLimit(formData, userId);
            if (result.success) {
                setIsActiveModelQbs(false);
                handleQbsLimitData();
                setIsLoading(false);
                alertSuccessMessage(result?.message);
            } else {
                setApiResponse(result);
                setIsLoading(false);
            }
        } catch (error) {
            setApiResponse(error);
            setIsLoading(false);
        }
    };

    const handleQuickPayLimitData = async () => {
        try {
            const result = await AuthService.getUserQuickPayLimits(userId);
            if (result?.success) {
                setQuickyPayLimit(result.data);
            } else {
                alertErrorMessage(result?.message || "Failed to fetch QBS limits.");
            }
        } catch (error) {
            alertErrorMessage(error?.message || "An error occurred while fetching QBS limits.");
        }
    };

    const handleUpdateUserQuickPayLimit = async () => {
        try {
            setIsLoading(true);
            const result = await AuthService.updateUserQuickPayLimit(formData, userId);
            if (result.success) {
                setIsActiveModelQuickPay(false);
                handleQuickPayLimitData();
                setIsLoading(false);
            } else {
                setApiResponse(result);
                setIsLoading(false);
            }
        } catch (error) {
            setApiResponse(error);
            setIsLoading(false);
        }
    };

    const handleUpdateOverAllLimit = async (value, key) => {
        setApiResponse("")
        try {
            const result = await AuthService.updateOverAllLimit(value, key, userId);
            if (result.success) {
                alertSuccessMessage(result.message);
            } else {
                setApiResponse(result);
            }
        } catch (error) {
            setApiResponse(error);
        }
    };

    return (

        <div className={`tab-pane ${activeTab === 5 ? "active" : ""}`} >
            <div className="card-body py-4" >
                <div className="profile_data " >
                    <div className="custom-tab-2">
                        <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link cursor-pointer ${isActiveTab === "Deposit" ? "active" : ""}`} type="button" onClick={() => { setIsActiveTab("Deposit"); handleUserDepositWithLimitData() }}>  Deposit  </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link cursor-pointer ${isActiveTab === "Withdrawals" ? "active" : ""}`} type="button" onClick={() => { setIsActiveTab("Withdrawals"); handleUserDepositWithLimitData() }}>  Withdrawals  </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link cursor-pointer ${isActiveTab === "qbs" ? "active" : ""}`} type="button" onClick={() => { setIsActiveTab("qbs"); handleQbsLimitData() }}>  Quick Buy/Sell  </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link cursor-pointer ${isActiveTab === "QuickPay" ? "active" : ""}`} type="button" onClick={() => { setIsActiveTab("QuickPay"); handleQuickPayLimitData() }}>  Quick Pay </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link cursor-pointer ${isActiveTab === "Invest" ? "active" : ""}`} type="button" onClick={() => setIsActiveTab("Invest")}>  Invest </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link cursor-pointer ${isActiveTab === "Merchent" ? "active" : ""}`} type="button" onClick={() => setIsActiveTab("Merchent")}> Merchent </button>
                            </li>

                            <li className="nav-item" role="presentation">
                                <button className={`nav-link cursor-pointer ${isActiveTab === "DirectWithdrawal" ? "active" : ""}`} type="button" onClick={() => setIsActiveTab("DirectWithdrawal")}>  Direct Withdrawal </button>
                            </li>
                        </ul>
                    </div>

                    <div className="tab-content">


                        <div className={`tab-pane ${isActiveTab === "Deposit" ? "active" : ""}`}>
                            <div className="profile_data  ac_body" >
                                <div className="row g-3 mt-0">
                                    <div className="col-lg-12" >
                                        <div className="d-flex justify-content-between" >
                                            <h5> INR Deposit Limits  </h5>
                                            <button className="btn btn-warning btn-sm mb-2" type="button"
                                                onClick={() => setIsActiveModelDeposit(true)}> Update Limits  </button>
                                        </div>

                                        <div className="row g-4" >
                                            <div className=" col-lg-6" >
                                                <div className="profile_data fc_details  ac_body " >
                                                    <div className="d-flex align-items-center justify-content-between mb-4" >
                                                        <h5 className="mb-0" > Overall Deposit Limit (1FY)  </h5>
                                                    </div>
                                                    <div className="row g-4">
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Total</label>
                                                                <p>  {depositWithdrawalLimit?.totalInrDeposit?.total} ₹  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Remaining</label>
                                                                <p>   {depositWithdrawalLimit?.totalInrDeposit?.remaining < 0 ? 0 : depositWithdrawalLimit?.totalInrDeposit?.remaining}  ₹  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Consumed INR</label>
                                                                <p>
                                                                    {String(depositWithdrawalLimit?.totalInrDeposit?.total - depositWithdrawalLimit?.totalInrDeposit?.remaining)} ₹
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Status</label>
                                                                <p>
                                                                    {depositWithdrawalLimit?.totalInrDeposit?.adminDependency === true ? "Default" : "Customized"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-4 mt-0">
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Total Cash</label>
                                                                <p>  {depositWithdrawalLimit?.totalInrCashDeposit?.total} ₹  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Remaining Cash</label>
                                                                <p>   {depositWithdrawalLimit?.totalInrCashDeposit?.remaining < 0 ? 0 : depositWithdrawalLimit?.totalInrCashDeposit?.remaining} ₹  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Consumed Cash INR</label>
                                                                <p>
                                                                    {String(depositWithdrawalLimit?.totalInrCashDeposit?.total - depositWithdrawalLimit?.totalInrCashDeposit?.remaining) || 0} ₹
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Status</label>
                                                                <p>
                                                                    {depositWithdrawalLimit?.totalInrCashDeposit?.adminDependency === true ? "Default" : "Customized"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className=" col-lg-6" >
                                                <div className="profile_data fc_details  ac_body " >
                                                    <div className="d-flex align-items-center justify-content-between mb-4" >
                                                        <h5 className="mb-0" >  Daily Deposit Limit (24H)  </h5>
                                                    </div>
                                                    <div className="row g-4">
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Total</label>
                                                                <p>   {depositWithdrawalLimit?.dailyInrDeposit?.total}  ₹  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Remaining</label>
                                                                <p> {depositWithdrawalLimit?.dailyInrDeposit?.remaining < 0 ? 0 : depositWithdrawalLimit?.dailyInrDeposit?.remaining}  ₹  </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Consumed INR</label>
                                                                <p>
                                                                    {String(depositWithdrawalLimit?.dailyInrDeposit?.total - depositWithdrawalLimit?.dailyInrDeposit?.remaining) || 0} ₹
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Status</label>
                                                                <p>
                                                                    {depositWithdrawalLimit?.dailyInrDeposit?.adminDependency === true ? "Default" : "Customized"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-4 mt-0">
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Total Cash</label>
                                                                <p>  {depositWithdrawalLimit?.dailyInrCashDeposit?.total} ₹  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Remaining Cash</label>
                                                                <p>   {depositWithdrawalLimit?.dailyInrCashDeposit?.remaining} ₹  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Consumed Cash INR</label>
                                                                <p>
                                                                    {String(depositWithdrawalLimit?.dailyInrCashDeposit?.total - depositWithdrawalLimit?.dailyInrCashDeposit?.remaining) || 0} ₹
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Status</label>
                                                                <p>
                                                                    {depositWithdrawalLimit?.dailyInrCashDeposit?.adminDependency === true ? "Default" : "Customized"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="profile_data  ac_body my-4 " >
                                <div className="d-flex justify-content-between" >
                                    <h5> Updated History </h5>
                                </div>
                                <div className="table-responsive m_table">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" className=""> Date/Time </th>
                                                <th scope="col" className=""> Deposit Type </th>
                                                <th scope="col" className=""> Limit Type </th>
                                                <th scope="col" className="">   Prev. Limit  </th>
                                                <th scope="col" className="">   New Limit </th>
                                                <th scope="col" className=""> Remark By </th>
                                                <th scope="col" className=""> Remark </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="">
                                                    <span>------</span>
                                                </td>
                                                <td className="">
                                                    <span>    Total/Cash </span>
                                                </td>
                                                <td className="">
                                                    <span> Daily/Overall  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> Message here......... </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                            </div>
                        </div>


                        <div className={`tab-pane ${isActiveTab === "Withdrawals" ? "active" : ""}`}>
                            <div className="profile_data  ac_body" >
                                <div className="row g-3 mt-0">
                                    <div className="col-lg-6" >
                                        <div className="d-flex justify-content-between" >
                                            <h5> INR Withdrawal Limits  </h5>
                                        </div>

                                        <div className="row g-4" >
                                            <div className=" col-lg-12 " >
                                                <div className="profile_data fc_details  ac_body " >
                                                    <div className="d-flex align-items-center justify-content-between mb-4" >
                                                        <h5 className="mb-0" > Overall Withdrawal Limit (1FY)  </h5>
                                                    </div>
                                                    <div className="row g-4">
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Total</label>
                                                                <p>   {depositWithdrawalLimit?.totalInrWithdrawal?.total || "-----"} ₹  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Remaining</label>
                                                                <p> {depositWithdrawalLimit?.totalInrWithdrawal?.remaining < 0 ? 0 : depositWithdrawalLimit?.totalInrWithdrawal?.remaining} ₹  </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Consumed INR</label>
                                                                <p>
                                                                    {String(depositWithdrawalLimit?.totalInrWithdrawal?.total - depositWithdrawalLimit?.totalInrWithdrawal?.remaining) || 0} ₹
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Status</label>
                                                                <p>
                                                                    {depositWithdrawalLimit?.totalInrWithdrawal?.adminDependency === true ? "Default" : "Customized"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-4 mt-0">
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Total Cash</label>
                                                                <p>{depositWithdrawalLimit?.totalInrCashWithdrawal?.total} ₹</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>   Remaining Cash      </label>
                                                                <p>{depositWithdrawalLimit?.totalInrCashWithdrawal?.remaining} ₹</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Consumed Cash INR</label>
                                                                <p>
                                                                    {String(depositWithdrawalLimit?.totalInrCashWithdrawal?.total - depositWithdrawalLimit?.totalInrCashWithdrawal?.remaining) || 0} ₹
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>   Status     </label>
                                                                <p>
                                                                    {depositWithdrawalLimit?.totalInrCashWithdrawal?.adminDependency === true ? "Default" : "Customized"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className=" col-lg-12" >
                                                <div className="profile_data fc_details  ac_body " >
                                                    <div className="d-flex align-items-center justify-content-between mb-4" >
                                                        <h5 className="mb-0" >  Daily Withdrawal Limit (24H)  </h5>
                                                    </div>
                                                    <div className="row g-4">
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Total</label>
                                                                <p>  {depositWithdrawalLimit?.dailyInrWithdrawal?.total || "----"} ₹  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Remaining</label>
                                                                <p>   {depositWithdrawalLimit?.dailyInrWithdrawal?.remaining < 0 ? 0 : depositWithdrawalLimit?.dailyInrWithdrawal?.remaining || "----"} ₹  </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Consumed INR</label>
                                                                <p>
                                                                    {String(depositWithdrawalLimit?.dailyInrWithdrawal?.total - depositWithdrawalLimit?.dailyInrWithdrawal?.remaining) || 0} ₹
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Status</label>
                                                                <p>
                                                                    {depositWithdrawalLimit?.dailyInrWithdrawal?.adminDependency === true ? "Default" : "Customized"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-4 mt-0">
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Total Cash</label>
                                                                <p>  {depositWithdrawalLimit?.dailyInrCashWithdrawal?.total || "----"} ₹  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Remaining Cash</label>
                                                                <p>   {depositWithdrawalLimit?.dailyInrCashWithdrawal?.remaining || "----"} ₹  </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Consumed Cash INR</label>
                                                                <p>
                                                                    {String(depositWithdrawalLimit?.dailyInrCashWithdrawal?.total - depositWithdrawalLimit?.dailyInrCashWithdrawal?.remaining) || 0} ₹
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Status</label>
                                                                <p>
                                                                    {depositWithdrawalLimit?.dailyInrCashWithdrawal?.adminDependency === true ? "Default" : "Customized"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6" >
                                        <div className="d-flex justify-content-between" >
                                            <h5> Crypto Withdrawal  Limits  </h5>
                                            <button className="btn btn-warning btn-sm mb-2" type="button"
                                                onClick={() => setIsActiveModelWithdrawal(true)}> Update Limits  </button>
                                        </div>
                                        <div className="row g-4" >
                                            <div className=" col-lg-12" >
                                                <div className="profile_data fc_details  ac_body " >
                                                    <div className="d-flex align-items-center justify-content-between mb-4" >
                                                        <h5 className="mb-0" > Overall Withdrawal Limit (1FY)  </h5>
                                                    </div>
                                                    <div className="row g-4">
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Total</label>
                                                                <p>  $  {depositWithdrawalLimit?.totalCryptoWithdrawal?.total || "-----"}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Remaining</label>
                                                                <p>   $ {depositWithdrawalLimit?.totalCryptoWithdrawal?.remaining || "-----"}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Consumed Crypto</label>
                                                                <p>
                                                                    {String(depositWithdrawalLimit?.totalCryptoWithdrawal?.total - depositWithdrawalLimit?.totalCryptoWithdrawal?.remaining) || 0} $
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Status</label>
                                                                <p>
                                                                    {depositWithdrawalLimit?.totalCryptoWithdrawal?.adminDependency === true ? "Default" : "Customized"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-lg-12" >
                                                <div className="profile_data fc_details  ac_body " >
                                                    <div className="d-flex align-items-center justify-content-between mb-4" >
                                                        <h5 className="mb-0" >  Daily Withdrawal Limit (24H)  </h5>
                                                    </div>
                                                    <div className="row g-4">
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Total</label>
                                                                <p>  $ {depositWithdrawalLimit?.dailyCryptoWithdrawal?.total}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Remaining</label>
                                                                <p>  $ {depositWithdrawalLimit?.dailyCryptoWithdrawal?.remaining || "----"}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Consumed Crypto</label>
                                                                <p>
                                                                    {String(depositWithdrawalLimit?.dailyCryptoWithdrawal?.total - depositWithdrawalLimit?.dailyCryptoWithdrawal?.remaining) || 0} $
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Status</label>
                                                                <p>
                                                                    {depositWithdrawalLimit?.dailyCryptoWithdrawal?.adminDependency === true ? "Default" : "Customized"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile_data  ac_body my-4 " >
                                <div className="d-flex justify-content-between" >
                                    <h5> Updated History </h5>
                                </div>
                                <div className="table-responsive m_table">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" className=""> Date/Time </th>
                                                <th scope="col" className=""> Currency Type </th>
                                                <th scope="col" className=""> Withdrawal  Type </th>
                                                <th scope="col" className=""> Limit Type </th>
                                                <th scope="col" className="">   Prev. Limit  </th>
                                                <th scope="col" className="">   New Limit </th>
                                                <th scope="col" className=""> Remark By </th>
                                                <th scope="col" className=""> Remark </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="">
                                                    <span> 11-12-2024, 12:11 AM </span>
                                                </td>
                                                <td className="">
                                                    <span>    INR/Crypto </span>
                                                </td>
                                                <td className="">
                                                    <span>    Total/Cash </span>
                                                </td>
                                                <td className="">
                                                    <span> Daily/Overall  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> Message here......... </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                        <div className={`tab-pane ${isActiveTab === "qbs" ? "active" : ""}`}>
                            <div className="profile_data  ac_body" >
                                <div className="row g-3 mt-0">
                                    <div className="col-lg-12" >
                                        <div className="d-flex justify-content-between" >
                                            <h5> Quick Buy/Sell Limits (INR)  </h5>
                                        </div>
                                        <div className="row g-4" >
                                            <div className=" col-lg-6" >
                                                <div className="profile_data fc_details  ac_body " >
                                                    <div className="d-flex align-items-center justify-content-between mb-4" >
                                                        <h5 className="mb-0" >  Daily Buy Limit (24H)  </h5>
                                                        <button className="btn btn-warning btn-sm" type="button" onClick={() => setIsActiveModelQbs(true)}> Update Limits  </button>
                                                    </div>
                                                    <div className="row g-4">
                                                        <div className="row g-2">
                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Admin Dependency</label>
                                                                    <p>{userQbsLimitData?.adminDependency ? "Default" : "Customized"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Bulk Trader</label>
                                                                    <p>{userQbsLimitData?.bulkTrader ? "Yes" : "No"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Daily Orders</label>
                                                                    <p>{userQbsLimitData?.dailyOrders}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-4 mt-0">
                                                        <div className="row g-2">
                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Daily Buy Limit</label>
                                                                    <p>{userQbsLimitData?.dailyBuyLimit}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Buy Fees</label>
                                                                    <p>{userQbsLimitData?.buyFees}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Buy Tds</label>
                                                                    <p>{userQbsLimitData?.buyTds}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-4 mt-0">
                                                        <div className="row g-2">
                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Daily Cash</label>
                                                                    <p>{userQbsLimitData?.dailyCashLimit}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Sell Fees</label>
                                                                    <p>{userQbsLimitData?.sellFees}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Sell Tds</label>
                                                                    <p>{userQbsLimitData?.sellTds}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-4 mt-0">
                                                        <div className="row g-2">
                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Remaining Daily Buy Limit</label>
                                                                    <p>{userQbsLimitData?.remainingDailyBuyLimit}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Remaining Daily Cash Limit</label>
                                                                    <p>{userQbsLimitData?.remainingDailyCashLimit}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Remaining Daily Orders</label>
                                                                    <p>{userQbsLimitData?.remainingDailyOrders}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Consumed Cash</label>
                                                                    <p> {String(userQbsLimitData?.dailyCashLimit - userQbsLimitData?.remainingDailyCashLimit)?.slice(1) || 0} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Consumed Daily Inr</label>
                                                                    <p> {String(userQbsLimitData?.dailyBuyLimit - userQbsLimitData?.remainingDailyBuyLimit)?.slice(1) || 0} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Consumed Orders</label>
                                                                    <p> {String(userQbsLimitData?.dailyOrders - userQbsLimitData?.remainingDailyOrders)?.slice(1) || 0} </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-4 mt-0">
                                                        <div className="row g-2">
                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Created At</label>
                                                                    <p>{moment(userQbsLimitData?.createdAt).format('Do MMM YYYY, h:mm:ss a')}</p>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-xl-4">
                                                                <div className="label-group">
                                                                    <label>Updated At</label>
                                                                    <p>{moment(userQbsLimitData?.updatedAt).format('Do MMM YYYY, h:mm:ss a')}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="profile_data  ac_body my-4 " >
                                <div className="d-flex justify-content-between" >
                                    <h5> Updated History </h5>
                                </div>
                                <div className="oul_scroll" >
                                    <div className="table-responsive m_table">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Date/Time</th>
                                                    <th scope="col">Limit</th>
                                                    <th scope="col">Admin Name / ID</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userQbsLimitData?.history?.map((item, index) => {
                                                    const limitEntries = item.data
                                                        ? Object.entries(item.data).filter(([key]) => key !== "_id" && key !== "__v" && key !== "createdAt")
                                                        : [];

                                                    return (
                                                        <tr key={item._id || index}>
                                                            <td>{moment(item.timestamp).format('Do MMM YYYY, h:mm:ss a')}</td>
                                                            <td>
                                                                {limitEntries.length > 0 ? (
                                                                    <ul>
                                                                        {limitEntries.map(([indexNew, value]) => (
                                                                            <li key={indexNew}>
                                                                                {indexNew} : {" "}
                                                                                {typeof value === "boolean" ? (value ? "Yes" : "No") : value}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                ) : (
                                                                    "----"
                                                                )}
                                                            </td>
                                                            <td>{item.adminName || "----"} <br />{item.adminId || "----"}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={`tab-pane ${isActiveTab === "QuickPay" ? "active" : ""}`}>
                            <div className="profile_data  ac_body" >
                                <div className="row g-3 mt-0">

                                    <div className="col-lg-6" >
                                        <div className="d-flex justify-content-between" >
                                            <h5> INR Withdrawal Limits  </h5>
                                        </div>

                                        <div className="row g-4" >
                                            <div className=" col-lg-12 " >
                                                <div className="profile_data fc_details  ac_body " >
                                                    <div className="d-flex align-items-center justify-content-between mb-4" >
                                                        <h5 className="mb-0" > Overall Withdrawal Limit (1m)  </h5>
                                                    </div>
                                                    <div className="row g-4">
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>  Total     </label><p>  {quickyPayLimit?.freeInrLimit?.total} ₹  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Remaining      </label><p>    {quickyPayLimit?.freeInrLimit?.remaining} ₹  </p></div>
                                                        </div>

                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Consumed INR</label>
                                                                <p> {String(quickyPayLimit?.freeInrLimit?.total - quickyPayLimit?.freeInrLimit?.remaining) || 0} </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Status     </label>
                                                                <p>
                                                                    {quickyPayLimit?.freeInrLimit?.adminDependency === true ? "Default" : "Customized"} </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className=" col-lg-12" >
                                                <div className="profile_data fc_details  ac_body " >
                                                    <div className="d-flex align-items-center justify-content-between mb-4" >
                                                        <h5 className="mb-0" >  Daily Withdrawal Limit (24H)  </h5>
                                                    </div>
                                                    <div className="row g-4">
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>  Total     </label><p>  {quickyPayLimit?.dailyInrLimit?.total} ₹  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Remaining      </label><p>    {quickyPayLimit?.dailyInrLimit?.remaining} ₹  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Consumed Daily INR</label>
                                                                <p> {String(quickyPayLimit?.dailyInrLimit?.total - quickyPayLimit?.dailyInrLimit?.remaining) || 0} </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Status     </label><p>   {quickyPayLimit?.dailyInrLimit?.adminDependency === true ? "Default" : "Customized"}</p></div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-4 mt-2">
                                                        <div className="col-lg-4 col-xl-6">
                                                            <div className="label-group"><label>  Min Amount     </label><p>  {quickyPayLimit?.minAmountInr?.total}  ₹  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-6">
                                                            <div className="label-group"><label>   Status Min Inr    </label><p>  {quickyPayLimit?.minAmountInr?.adminDependency === true ? "Default" : "Customized"} </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-6">
                                                            <div className="label-group"><label>   Max      </label><p>   {quickyPayLimit?.maxAmountInr?.total}  ₹  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-6">
                                                            <div className="label-group"><label>   Status Max Inr    </label><p>  {quickyPayLimit?.maxAmountInr?.adminDependency === true ? "Default" : "Customized"} </p></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6" >
                                        <div className="d-flex justify-content-between" >
                                            <h5> Crypto Withdrawal  Limits  </h5>
                                            <button className="btn btn-warning btn-sm mb-2" type="button"
                                                onClick={() => setIsActiveModelQuickPay(true)}>
                                                Update Limits
                                            </button>
                                        </div>
                                        <div className="row g-4" >
                                            <div className=" col-lg-12" >
                                                <div className="profile_data fc_details  ac_body " >
                                                    <div className="d-flex align-items-center justify-content-between mb-4" >
                                                        <h5 className="mb-0" > Overall Withdrawal Limit (1M)  </h5>
                                                    </div>
                                                    <div className="row g-4">
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>  Total     </label><p>  $  {quickyPayLimit?.freeUsdLimit?.total}  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Remaining      </label><p>   $  {quickyPayLimit?.freeUsdLimit?.remaining}  </p></div>
                                                        </div>

                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Consumed Crypto</label>
                                                                <p> {String(quickyPayLimit?.freeUsdLimit?.total - quickyPayLimit?.freeUsdLimit?.remaining) || 0} </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Status     </label><p> {quickyPayLimit?.freeUsdLimit?.adminDependency === true ? "Default" : "Customized"} </p></div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className=" col-lg-12" >
                                                <div className="profile_data fc_details  ac_body " >
                                                    <div className="d-flex align-items-center justify-content-between mb-4" >
                                                        <h5 className="mb-0" >  Daily Withdrawal Limit (24H)  </h5>
                                                    </div>
                                                    <div className="row g-4">
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>  Total     </label><p>  $  {quickyPayLimit?.dailyUsdLimit?.total}   </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Remaining      </label><p>  $  {quickyPayLimit?.dailyUsdLimit?.remaining}  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group">
                                                                <label>Consumed Crypto</label>
                                                                <p> {String(quickyPayLimit?.dailyUsdLimit?.total - quickyPayLimit?.dailyUsdLimit?.remaining) || 0} </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Status     </label><p>  {quickyPayLimit?.dailyUsdLimit?.adminDependency === true ? "Default" : "Customized"} </p></div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-4 mt-2">
                                                        <div className="col-lg-6 col-xl-6">
                                                            <div className="label-group"><label>  Min Amount     </label><p>  $  {quickyPayLimit?.minAmountUsd?.total}  </p></div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6">
                                                            <div className="label-group"><label>   Status  Min Crypto   </label><p> {quickyPayLimit?.minAmountUsd?.adminDependency === true ? "Default" : "Customized"}</p></div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6">
                                                            <div className="label-group"><label>   Max      </label><p>   $  {quickyPayLimit?.maxAmountUsd?.total}  </p></div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6">
                                                            <div className="label-group"><label>   Status  Max Crypto   </label><p> {quickyPayLimit?.maxAmountUsd?.adminDependency === true ? "Default" : "Customized"}</p></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-lg-12 mt-4" >

                                    <div className="row g-4" >
                                        <div className=" col-lg-6 " >
                                            <div className="profile_data fc_details  ac_body " >
                                                <div className="d-flex align-items-center justify-content-between mb-4" >
                                                    <h5 className="mb-0" > INR Fess  </h5>
                                                </div>
                                                <div className="row g-4">
                                                    <div className="col-lg-4 col-xl-4">
                                                        <div className="label-group"><p>  {quickyPayLimit?.inrFee?.total} ₹  </p></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className=" col-lg-6" >
                                            <div className="profile_data fc_details  ac_body " >
                                                <div className="d-flex align-items-center justify-content-between mb-4" >
                                                    <h5 className="mb-0" >   Crypto Fess  </h5>
                                                </div>
                                                <div className="row g-4">
                                                    <div className="col-lg-4 col-xl-4">
                                                        <div className="label-group"><p>  {quickyPayLimit?.cryptoFee?.total} ₹  </p></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className=" col-lg-12 mt-3" >
                                    <div className="profile_data fc_details  ac_body " >
                                        <div className="d-flex align-items-center justify-content-between mb-4" >
                                            <h5 className="mb-0" >  Daily Pay Number of Orders </h5>
                                        </div>
                                        <div className="row g-4">
                                            <div className="col-lg-3 col-xl-3">
                                                <div className="label-group"><label>  Total     </label><p>  {quickyPayLimit?.totalOrders?.total}   </p></div>
                                            </div>
                                            <div className="col-lg-3 col-xl-3">
                                                <div className="label-group"><label>   Remaining      </label><p>  {quickyPayLimit?.totalOrders?.remaining}   </p></div>
                                            </div>
                                            <div className="col-lg-3 col-xl-3">
                                                <div className="label-group">
                                                    <label>Consumed </label>
                                                    <p> {String(quickyPayLimit?.totalOrders?.total - quickyPayLimit?.totalOrders?.remaining) || 0} </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-xl-3">
                                                <div className="label-group"><label>   Status     </label><p> {quickyPayLimit?.totalOrders?.adminDependency === true ? "Default" : "Customized"}</p></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div className="profile_data  ac_body my-4 " >
                                <div className="d-flex justify-content-between" >
                                    <h5> Updated History </h5>
                                </div>
                                <div className="table-responsive m_table">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" className=""> Date/Time </th>
                                                <th scope="col" className=""> Limit Type </th>
                                                <th scope="col" className=""> Withdrawal  Type </th>
                                                <th scope="col" className="">   Prev. Total  </th>
                                                <th scope="col" className="">   New Total  </th>
                                                <th scope="col" className="">   Prev. Remaining  </th>
                                                <th scope="col" className="">   New Remaining  </th>
                                                <th scope="col" className="">   Prev. Min   </th>
                                                <th scope="col" className="">   New Min   </th>
                                                <th scope="col" className="">   Prev. Max   </th>
                                                <th scope="col" className="">   New Max   </th>
                                                <th scope="col" className=""> Remark By </th>
                                                <th scope="col" className=""> Remark </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="">
                                                    <span> 11-12-2024, 12:11 AM </span>
                                                </td>
                                                <td className="">
                                                    <span>    INR/Crypto </span>
                                                </td>
                                                <td className="">
                                                    <span>    Daily/Overall </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> Message here......... </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                            </div>

                        </div>


                        <div className={`tab-pane ${isActiveTab === "Invest" ? "active" : ""}`}>
                            <div className="profile_data  ac_body" >
                                <div className="row g-3 mt-0">
                                    <div className="col-lg-6" >
                                        <div className="d-flex justify-content-between" >
                                            <h5> INR Invest Limits  </h5>
                                        </div>
                                        <div className="row g-4" >
                                            <div className=" col-lg-12 " >
                                                <div className="profile_data fc_details  ac_body " >
                                                    <div className="d-flex align-items-center justify-content-between mb-4" >
                                                        <h5 className="mb-0" >   Invest Transaction Limit   </h5>
                                                        <button className="btn btn-warning btn-sm"> Update Limits  </button>
                                                    </div>
                                                    <div className="row g-4">
                                                        {/* editable in modal */}
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>  Min. Order    </label><p>  --------  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Max. Order      </label><p>   --------  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Intrest Rate   </label><p>   --------  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Penalty Rate   </label><p>   --------  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-6">
                                                            <div className="label-group"><label>   Status     </label><p> Default,Customized</p></div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6" >
                                        <div className="d-flex justify-content-between" >
                                            <h5> Crypto Invest  Limits  </h5>
                                        </div>
                                        <div className="row g-4" >
                                            <div className=" col-lg-12 " >
                                                <div className="profile_data fc_details  ac_body " >
                                                    <div className="d-flex align-items-center justify-content-between mb-4" >
                                                        <h5 className="mb-0" >   Invest Transaction Limit   </h5>
                                                        <button className="btn btn-warning btn-sm"> Update Limits  </button>
                                                    </div>
                                                    <div className="row g-4">
                                                        {/* editable in modal */}
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>  Min. Order    </label><p>  --------  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Max. Order      </label><p>   --------  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Intrest Rate   </label><p>   --------  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Penalty Rate   </label><p>   --------  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-6">
                                                            <div className="label-group"><label>   Status     </label><p> Default,Customized</p></div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="profile_data  ac_body my-4 " >
                                <div className="d-flex justify-content-between" >
                                    <h5> Updated History </h5>
                                </div>
                                <div className="table-responsive m_table">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" className=""> Date/Time </th>
                                                <th scope="col" className=""> Limit Type </th>
                                                <th scope="col" className=""> Withdrawal  Type </th>
                                                <th scope="col" className="">   Prev. Min. Order  </th>
                                                <th scope="col" className="">   New Min. Order  </th>
                                                <th scope="col" className="">   Prev. Max. Order  </th>
                                                <th scope="col" className="">   New Max. Order  </th>
                                                <th scope="col" className="">   Prev. Intrest Rate   </th>
                                                <th scope="col" className="">   New Intrest Rate   </th>
                                                <th scope="col" className="">   Prev. Penalty Rate   </th>
                                                <th scope="col" className="">   New Penalty Rate   </th>
                                                <th scope="col" className=""> Remark By </th>
                                                <th scope="col" className=""> Remark </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="">
                                                    <span> 11-12-2024, 12:11 AM </span>
                                                </td>
                                                <td className="">
                                                    <span>    INR/Crypto </span>
                                                </td>
                                                <td className="">
                                                    <span>    Daily/Overall </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> Message here......... </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                            </div>

                        </div>


                        <div className={`tab-pane ${isActiveTab === "Merchent" ? "active" : ""}`}>
                            <div className="profile_data  ac_body" >
                                <div className="row g-3 mt-0">
                                    <div className="col-lg-12" >
                                        <div className="d-flex justify-content-between" >
                                            <h5> Merchent  Transaction  Limits (1Y)  </h5>
                                        </div>
                                        <div className="row g-4" >
                                            <div className=" col-lg-6" >
                                                <div className="profile_data fc_details  ac_body " >
                                                    <div className="d-flex align-items-center justify-content-between mb-4" >
                                                        <h5 className="mb-0" >  Overall Deposit Limit (1FY)  </h5>
                                                        <button className="btn btn-warning btn-sm"> Update Limits  </button>
                                                    </div>
                                                    <div className="row g-4">
                                                        {/* editable in modal */}
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>  Total Limit     </label><p>  -------- </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Remaining      </label><p>   --------  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Status     </label><p> Default,Customized</p></div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className=" col-lg-6" >
                                                <div className="profile_data fc_details  ac_body " >
                                                    <div className="d-flex align-items-center justify-content-between mb-4" >
                                                        <h5 className="mb-0" >  Overall Deposit Limit (24H)  </h5>
                                                        <button className="btn btn-warning btn-sm"> Update Limits  </button>
                                                    </div>
                                                    <div className="row g-4">
                                                        {/* editable in modal */}
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>  Total Limit     </label><p>  -------- </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Remaining      </label><p>   --------  </p></div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="label-group"><label>   Status     </label><p> Default,Customized</p></div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="profile_data  ac_body my-4 " >
                                <div className="d-flex justify-content-between" >
                                    <h5> Updated History </h5>
                                </div>
                                <div className="table-responsive m_table">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" className=""> Date/Time </th>
                                                <th scope="col" className=""> Limit Type </th>
                                                <th scope="col" className="">   Prev. Total  </th>
                                                <th scope="col" className="">   New  Total  </th>
                                                <th scope="col" className="">   Prev. Remaining  </th>
                                                <th scope="col" className="">   New Remaining </th>
                                                <th scope="col" className=""> Remark By </th>
                                                <th scope="col" className=""> Remark </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="">
                                                    <span> 11-12-2024, 12:11 AM </span>
                                                </td>
                                                <td className="">
                                                    <span>   1YR/1M </span>
                                                </td>
                                                <td className="">
                                                    <span>    ----- </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> ----  </span>
                                                </td>
                                                <td className="">
                                                    <span> Message here......... </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                            </div>

                        </div>

                        <DirectWithdrawalLimit isActiveTab={isActiveTab} userId={userId} />

                    </div>
                </div>
            </div>


            {isActiveModelDeposit && (
                <>
                    <div className="modal ccc_modal show d-block" >
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content ">
                                <div className="modal-body p-0">
                                    <h6 className="mb-3" > INR Deposit Limits  </h6>
                                    <div className="row">
                                        <div className="form-group mb-3 col-lg-6">
                                            <label className="small mb-1" htmlFor="totalDepositYear">Total Deposit Limit (1FY)<em>*</em></label>
                                            <div className="otp_box">
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="totalDepositYear"
                                                    name="totalDepositYear"
                                                    type="text"
                                                    value={formData.totalDepositYear}
                                                    onChange={handleChange}
                                                />
                                                <button className="btn btn-warning btn-xs" type="button" disabled={!formData.totalDepositYear}
                                                    onClick={() => handleUpdateOverAllLimit(formData.totalDepositYear, "totalInrDeposit")}> Update  </button>
                                            </div>
                                        </div>
                                        <div className="form-group mb-3 col-lg-6">
                                            <label className="small mb-1" htmlFor="totalCashDepositYear">Total Cash Deposit Limit (1FY)<em>*</em></label>
                                            <div className="otp_box">
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="totalCashDepositYear"
                                                    name="totalCashDepositYear"
                                                    type="text"
                                                    value={formData.totalCashDepositYear}
                                                    onChange={handleChange}
                                                />
                                                <button className="btn btn-warning btn-xs" type="button" disabled={!formData.totalCashDepositYear}
                                                    onClick={() => handleUpdateOverAllLimit(formData.totalCashDepositYear, "totalInrCashDeposit")}> Update  </button>
                                            </div>
                                        </div>
                                        <div className="form-group mb-3 col-lg-6">
                                            <label className="small mb-1" htmlFor="dailyDepositLimit">Daily Deposit Limit (24H)<em>*</em></label>
                                            <div className="otp_box">
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="dailyDepositLimit"
                                                    name="dailyDepositLimit"
                                                    type="text"
                                                    value={formData.dailyDepositLimit}
                                                    onChange={handleChange}
                                                />
                                                <button className="btn btn-warning btn-xs" type="button" disabled={!formData.dailyDepositLimit}
                                                    onClick={() => handleUpdateOverAllLimit(formData.dailyDepositLimit, "dailyInrDeposit")}> Update  </button>
                                            </div>
                                        </div>
                                        <div className="form-group mb-3 col-lg-6">
                                            <label className="small mb-1" htmlFor="dailyCashDepositLimit">Daily Cash Deposit Limit (24H)<em>*</em></label>
                                            <div className="otp_box">
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="dailyCashDepositLimit"
                                                    name="dailyCashDepositLimit"
                                                    type="number"
                                                    value={formData.dailyCashDepositLimit}
                                                    onChange={handleChange}
                                                />
                                                <button className="btn btn-warning btn-xs" type="button" disabled={!formData.dailyCashDepositLimit}
                                                    onClick={() => handleUpdateOverAllLimit(formData.dailyCashDepositLimit, "dailyInrCashDeposit")}> Update  </button>
                                            </div>
                                        </div>
                                        <div className="form-group mb-3 col-lg-6">
                                            <label className="small mb-1" htmlFor="remark">Remark<em>*</em></label>
                                            <textarea
                                                className="form-control py-3"
                                                rows="3"
                                                placeholder="Enter your Remark.."
                                                name="remark"
                                                value={formData.remark}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className=" d-flex justify-content-center gap-3 flex-between w-100 mt-5">
                                    <button className="btn btn-primary btn-block px-5 btn-md " type="button"
                                        onClick={() => handleUpdateUserLimitWithDrawal({ setIsActiveModelDeposit, handleUserDepositWithLimitData, setIsLoading, setApiResponse, userId, formData, setIsActiveModelWithdrawal })}
                                        disabled={isLoading}>
                                        {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                                            <span>Submit Details</span>}
                                    </button>
                                    <button className="btn btn-secondary btn-block px-5  btn-md " type="button"
                                        onClick={() => { setIsActiveModelDeposit(false); setApiResponse("") }}>
                                        Cancel
                                    </button>

                                </div>
                                {apiResponse && <div className="text-danger">{apiResponse}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            )
            }


            {
                isActiveModelWithdrawal && (
                    <>
                        <div className="modal ccc_modal show d-block" >
                            <div className="modal-dialog modal-dialog-centered modal-lg">
                                <div className="modal-content ">
                                    <div className="modal-body p-0">
                                        <h5 className="modal-title" >INR Crypto Withdrawal Limits</h5>
                                    </div>
                                    <div className="row" >

                                        <div className="form-group mb-3 col-lg-6">
                                            <label className="small mb-1" htmlFor="totalWithdrawalYear">Total Withdrawal Limit (1FY) <em>*</em></label>
                                            <div className="otp_box">
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="totalWithdrawalYear"
                                                    name="totalWithdrawalYear"
                                                    type="text"
                                                    value={formData.totalWithdrawalYear}
                                                    onChange={handleChange}
                                                />
                                                <button className="btn btn-warning btn-xs" type="button" disabled={!formData.totalWithdrawalYear}
                                                    onClick={() => handleUpdateOverAllLimit(formData.totalWithdrawalYear, "totalInrWithdrawal")}> Update  </button>
                                            </div>
                                        </div>

                                        <div className="form-group mb-3 col-lg-6">
                                            <label className="small mb-1" htmlFor="totalCashWithdrawalYear">Total Cash Withdrawal Limit (1FY)<em>*</em></label>
                                            <div className="otp_box">
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="totalCashWithdrawalYear"
                                                    name="totalCashWithdrawalYear"
                                                    type="text"
                                                    value={formData.totalCashWithdrawalYear}
                                                    onChange={handleChange}
                                                />
                                                <button className="btn btn-warning btn-xs" type="button" disabled={!formData.totalCashWithdrawalYear}
                                                    onClick={() => handleUpdateOverAllLimit(formData.totalCashWithdrawalYear, "totalInrCashWithdrawal")}> Update  </button>
                                            </div>
                                        </div>

                                        <div className="form-group mb-3 col-lg-6">
                                            <label className="small mb-1" htmlFor="dailyWithdrawalLimit">Daily Withdrawal Limit (24H)<em>*</em></label>
                                            <div className="otp_box">
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="dailyWithdrawalLimit"
                                                    name="dailyWithdrawalLimit"
                                                    type="text"
                                                    value={formData.dailyWithdrawalLimit}
                                                    onChange={handleChange}
                                                />
                                                <button className="btn btn-warning btn-xs" type="button" disabled={!formData.dailyWithdrawalLimit}
                                                    onClick={() => handleUpdateOverAllLimit(formData.dailyWithdrawalLimit, "dailyInrWithdrawal")}> Update  </button>
                                            </div>
                                        </div>

                                        <div className="form-group mb-3 col-lg-6">
                                            <label className="small mb-1" htmlFor="dailyCashWithdrawalLimit">Daily Cash Withdrawal Limit (24H)<em>*</em></label>
                                            <div className="otp_box">
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="dailyCashWithdrawalLimit"
                                                    name="dailyCashWithdrawalLimit"
                                                    type="number"
                                                    value={formData.dailyCashWithdrawalLimit}
                                                    onChange={handleChange}
                                                />
                                                <button className="btn btn-warning btn-xs" type="button" disabled={!formData.dailyCashWithdrawalLimit}
                                                    onClick={() => handleUpdateOverAllLimit(formData.dailyCashWithdrawalLimit, "dailyInrCashWithdrawal")}> Update  </button>
                                            </div>
                                        </div>

                                        <div className="form-group mb-3 col-lg-6">
                                            <label className="small mb-1" htmlFor="totalCryptoWithdrawalLimit">Total Crypto Withdrawal Limit (1FY)<em>*</em></label>
                                            <div className="otp_box">
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="totalCryptoWithdrawalLimit"
                                                    name="totalCryptoWithdrawalLimit"
                                                    type="number"
                                                    value={formData.totalCryptoWithdrawalLimit}
                                                    onChange={handleChange}
                                                />
                                                <button className="btn btn-warning btn-xs" type="button" disabled={!formData.totalCryptoWithdrawalLimit}
                                                    onClick={() => handleUpdateOverAllLimit(formData.totalCryptoWithdrawalLimit, "totalCryptoWithdrawal")}> Update  </button>
                                            </div>
                                        </div>

                                        <div className="form-group mb-3 col-lg-6">
                                            <label className="small mb-1" htmlFor="cryptoDailyCashWithdrawalLimit">Crypto Daily Cash Withdrawal Limit (24H)<em>*</em></label>
                                            <div className="otp_box">
                                                <input
                                                    className="form-control form-control-solid"
                                                    id="cryptoDailyCashWithdrawalLimit"
                                                    name="cryptoDailyCashWithdrawalLimit"
                                                    type="number"
                                                    value={formData.cryptoDailyCashWithdrawalLimit}
                                                    onChange={handleChange}
                                                />
                                                <button className="btn btn-warning btn-xs" type="button" disabled={!formData.cryptoDailyCashWithdrawalLimit}
                                                    onClick={() => handleUpdateOverAllLimit(formData.cryptoDailyCashWithdrawalLimit, "dailyCryptoWithdrawal")}> Update  </button>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <label className="small mb-1" htmlFor="remark">Remark<em>*</em></label>
                                            <textarea
                                                className="form-control py-3"
                                                rows="3"
                                                placeholder="Enter your Remark.."
                                                name="remark"
                                                value={formData.remark}
                                                onChange={handleChange}
                                            />
                                        </div>

                                    </div>
                                    <div className=" d-flex justify-content-center gap-3 flex-between w-100 mt-5">
                                        <button className="btn btn-primary btn-block px-5 btn-md " type="button"
                                            onClick={() => handleUpdateUserLimitWithDrawal({ setIsActiveModelDeposit, handleUserDepositWithLimitData, setIsLoading, setApiResponse, userId, formData, setIsActiveModelWithdrawal })}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                                                <span>Submit Details</span>}
                                        </button>
                                        <button className="btn btn-secondary btn-block px-5  btn-md " type="button"
                                            onClick={() => { setIsActiveModelWithdrawal(false); setApiResponse("") }}>
                                            Cancel
                                        </button>
                                    </div>
                                    {apiResponse && <div className="text-danger">{apiResponse}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </>
                )
            }


            {
                isActiveModelQbs && (
                    <>
                        <div className="modal show d-block" >
                            <div className="modal-dialog modal-dialog-centered modal-lg">
                                <div className="modal-content pb-3">
                                    <div className="modal-header">
                                        <h5 className="modal-title" >Update Quick Buy/Sell Limits</h5>
                                    </div>
                                    <div className="modal-body ">
                                        <div className="row" >
                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">
                                                    <label className="small mb-1" htmlFor="updatedBuyLimit">Buy Limit<em>*</em></label>
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="updatedBuyLimit"
                                                        name="updatedBuyLimit"
                                                        type="text"
                                                        value={formData.updatedBuyLimit}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">
                                                    <label className="small mb-1" htmlFor="updatedCashLimit">Cash Limit<em>*</em></label>
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="updatedCashLimit"
                                                        name="updatedCashLimit"
                                                        type="text"
                                                        value={formData.updatedCashLimit}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">
                                                    <label className="small mb-1" htmlFor="dailyDepositLimit">Daily Buy Limit<em>*</em></label>
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="dailyDepositLimit"
                                                        name="dailyDepositLimit"
                                                        type="text"
                                                        value={formData.dailyDepositLimit}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">
                                                    <label className="small mb-1" htmlFor="updatedOrders">Daily Orders<em>*</em></label>
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="updatedOrders"
                                                        name="updatedOrders"
                                                        type="number"
                                                        value={formData.updatedOrders}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">
                                                    <label className="small mb-1" htmlFor="updatedBuyFees">Buy Fees<em>*</em></label>
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="updatedBuyFees"
                                                        name="updatedBuyFees"
                                                        type="number"
                                                        value={formData.updatedBuyFees}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">
                                                    <label className="small mb-1" htmlFor="updatedBuyTds">Buy Tds<em>*</em></label>
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="updatedBuyTds"
                                                        name="updatedBuyTds"
                                                        type="number"
                                                        value={formData.updatedBuyTds}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">
                                                    <label className="small mb-1" htmlFor="updatedSellFees">Sell Fees<em>*</em></label>
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="updatedSellFees"
                                                        name="updatedSellFees"
                                                        type="number"
                                                        value={formData.updatedSellFees}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">
                                                    <label className="small mb-1" htmlFor="updatedSellTds">Sell TDS<em>*</em></label>
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="updatedSellTds"
                                                        name="updatedSellTds"
                                                        type="number"
                                                        value={formData.updatedSellTds}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">
                                                    <label className="small mb-1" htmlFor="bulkTrader">Bulk Trader<em>*</em></label>

                                                    <div class="checkbox_switch">
                                                        <input class="tgl" id="bulkTrader" type="checkbox"
                                                            checked={formData.bulkTrader}
                                                            onChange={(e) => handleChange({ target: { name: "bulkTrader", value: e.target.checked } })}
                                                        />
                                                        <label class="tgl-btn" for="bulkTrader"></label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="form-group mb-4">
                                                    <label className="small mb-1" htmlFor="remarkQbs">Remark<em>*</em></label>
                                                    <textarea
                                                        className="form-control py-3"
                                                        rows="3"
                                                        placeholder="Enter your Remark.."
                                                        name="remarkQbs"
                                                        value={formData.remarkQbs}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center w-100 mb-4">
                                            <button
                                                className="btn btn-danger btn-block w-100"
                                                type="button"
                                                onClick={handleUpdateUserQbsLimit}
                                            >
                                                Submit Details
                                            </button>
                                            <button className="btn btn-secondary btn-block w-100 mt-1" type="button" onClick={() => { setIsActiveModelQbs(false); setApiResponse("") }}>
                                                Cancel
                                            </button>
                                        </div>
                                        {apiResponse && <div className="text-danger">{apiResponse}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </>
                )
            }


            {
                isActiveModelQuickPay && (
                    <>
                        <div className="modal ccc_modal show d-block" >
                            <div className="modal-dialog modal-dialog-centered modal-lg">
                                <div className="modal-content ">

                                    <div className="modal-body p-0 row">
                                        <div className=" col-lg-5   " >
                                            <h6 className="mb-3" > INR Withdrawal Limits  </h6>
                                            <div className="">
                                                <div className="form-group mb-3">
                                                    <label className="small mb-1" htmlFor="quickyPayfreeInrLimit">Total Withdrawal Limit <em>*</em></label>
                                                    <div className="otp_box">
                                                        <input
                                                            className="form-control form-control-solid"
                                                            id="quickyPayfreeInrLimit"
                                                            name="quickyPayfreeInrLimit"
                                                            type="text"
                                                            placeholder="Enter Amount"
                                                            value={formData.quickyPayfreeInrLimit}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="">
                                                <div className="form-group  mb-3">
                                                    <label className="small mb-1" htmlFor="quickyPaydailyInrLimit">Total Daily Withdrawal Limit<em>*</em></label>
                                                    <div className="otp_box">
                                                        <input
                                                            className="form-control form-control-solid"
                                                            id="quickyPaydailyInrLimit"
                                                            name="quickyPaydailyInrLimit"
                                                            type="text"
                                                            placeholder="Enter Amount"
                                                            value={formData.quickyPaydailyInrLimit}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>





                                            <div className="">
                                                <div className="form-group  mb-3">
                                                    <label className="small mb-1" htmlFor="quickyPayminAmountInr">Daily Withdrawal Min Amount<em>*</em></label>
                                                    <div className="otp_box">
                                                        <input
                                                            className="form-control form-control-solid"
                                                            id="quickyPayminAmountInr"
                                                            name="quickyPayminAmountInr"
                                                            type="text"
                                                            placeholder="Enter Amount"
                                                            value={formData.quickyPayminAmountInr}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="">
                                                <div className="form-group  mb-3">
                                                    <label className="small mb-1" htmlFor="quickyPaymaxAmountInr">Daily Withdrawal Max Amount<em>*</em></label>
                                                    <div className="otp_box">
                                                        <input
                                                            className="form-control form-control-solid"
                                                            id="quickyPaymaxAmountInr"
                                                            name="quickyPaymaxAmountInr"
                                                            type="text"
                                                            placeholder="Enter Amount"
                                                            value={formData.quickyPaymaxAmountInr}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="form-group  mb-3">
                                                <label className="small mb-1" htmlFor="quickyPayinrFee">Fees<em>*</em></label>
                                                <div className="otp_box">
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="quickyPayinrFee"
                                                        name="quickyPayinrFee"
                                                        type="text"
                                                        placeholder="Enter Amount"
                                                        value={formData.quickyPayinrFee}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>


                                        </div>
                                        <div className="col-lg-2 text-center" >
                                            <hr className="dashed_hr hr_long" />
                                        </div>
                                        <div className="col-lg-5 " >
                                            <h6 className="mb-3" >Crypto Withdrawal Limits</h6>

                                            <div className="form-group mb-3">
                                                <label className="small mb-1" htmlFor="quickyPayfreeUsdLimit">Total Withdrawal Limit <em>*</em></label>
                                                <div className="otp_box">
                                                    <input
                                                        className="form-control form-control-solid"
                                                        placeholder="Enter Amount"
                                                        id="quickyPayfreeUsdLimit"
                                                        name="quickyPayfreeUsdLimit"
                                                        type="text"
                                                        value={formData.quickyPayfreeUsdLimit}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>



                                            <div className="form-group  mb-3">
                                                <label className="small mb-1" htmlFor="quickyPaydailyUsdLimit">Total Daily Withdrawal Limit<em>*</em></label>
                                                <div className="otp_box">
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="quickyPaydailyUsdLimit"
                                                        name="quickyPaydailyUsdLimit"
                                                        type="text"
                                                        placeholder="Enter Amount"
                                                        value={formData.quickyPaydailyUsdLimit}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>



                                            <div className="form-group  mb-3">
                                                <label className="small mb-1" htmlFor="quickyPayminAmountUsd">Daily Withdrawal Min Amount<em>*</em></label>
                                                <div className="otp_box">
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="quickyPayminAmountUsd"
                                                        name="quickyPayminAmountUsd"
                                                        type="text"
                                                        placeholder="Enter Amount"
                                                        value={formData.quickyPayminAmountUsd}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>



                                            <div className="form-group  mb-3">
                                                <label className="small mb-1" htmlFor="quickyPaymaxAmountUsd">Daily Withdrawal Max Amount<em>*</em></label>
                                                <div className="otp_box">
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="quickyPaymaxAmountUsd"
                                                        name="quickyPaymaxAmountUsd"
                                                        type="text"
                                                        placeholder="Enter Amount"
                                                        value={formData.quickyPaymaxAmountUsd}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group  mb-3">
                                                <label className="small mb-1" htmlFor="quickyPayFees">Fees<em>*</em></label>
                                                <div className="otp_box">
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="quickyPayFees"
                                                        name="quickyPayFees"
                                                        type="text"
                                                        placeholder="Enter Amount"
                                                        value={formData.quickyPayFees}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="mt-2" />


                                        <div className="row" >
                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">
                                                    <label className="small mb-1" htmlFor="quickyPaytotalOrders">Daily Pay Number of Orders<em>*</em></label>
                                                    <input
                                                        className="form-control form-control-solid"
                                                        id="quickyPaytotalOrders"
                                                        name="quickyPaytotalOrders"
                                                        type="text"
                                                        value={formData.quickyPaytotalOrders}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group mb-4">
                                                    <label className="small mb-1" htmlFor="quickyPayremark">Remark<em>*</em></label>
                                                    <textarea
                                                        className="form-control py-3"
                                                        rows="3"
                                                        placeholder="Enter your Remark.."
                                                        name="quickyPayremark"
                                                        value={formData.quickyPayremark}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" d-flex justify-content-center gap-3 flex-between w-100 mt-5">
                                            <button className="btn btn-primary btn-block px-5 btn-md " type="button" onClick={handleUpdateUserQuickPayLimit}>
                                                {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                                                    <span>   APPLY TO USER</span>}
                                            </button>
                                            <button className="btn btn-secondary btn-block px-5  btn-md " type="button"
                                                onClick={() => { setIsActiveModelQuickPay(false); setApiResponse("") }}>
                                                Cancel
                                            </button>
                                        </div>
                                        {apiResponse && <div className="text-danger">{apiResponse}  </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </>
                )
            }





        </div >
    )
};

export default LimitDetails;