import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../services/ApiIntegration/AuthService";
import { ProfileContext } from "../../CustomComponent/ProfileContext";

const LoginPage = () => {
    const navigate = useNavigate();

    const [loginInfo, setLoginInfo] = useState({ email: '', password: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");
    const [, updateProfileState] = useContext(ProfileContext);
    const [isLoading, setIsLoading] = useState(false);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const validateFields = () => {
        if (!loginInfo.email) {
            setError("Please enter your email.");
            return false;
        } if (!emailRegex.test(loginInfo?.email)) {
            setError("Enter a valid email address.");
            return false;
        }
        if (!loginInfo.password) {
            setError("Please enter your password.");
            return false;
        }
        setError("");
        return true;
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!validateFields()) return;
        setIsLoading(true);
        try {
            const result = await AuthService.login(loginInfo);
            setIsLoading(false);
            if (result.success) {
                sessionStorage.setItem("token", result.data.token);
                sessionStorage.setItem("userType", result.data.admin_type);
                sessionStorage.setItem("userId", result.data.id);
                sessionStorage.setItem("email", result.data.email);
                sessionStorage.setItem("full_name", result.data.full_name);
                sessionStorage.setItem("phone", result.data.phone);
                sessionStorage.setItem("permissions", JSON.stringify(result?.data?.permissions || []));
                updateProfileState(result?.data);
                navigate('/dashboard');
            } else {
                setError(result || "Login failed. Please try again.");
            }
        } catch (error) {
            setError(error?.message || "An unexpected error occurred.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoginInfo({ ...loginInfo, [name]: value });
        setError("");
    };
    
    return (
        <div className="login-card">
            <div className="container-xl px-4">
                <div className="row justify-content-start">
                    <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                        <div className="card">
                            <div className="card-body auth-form">
                                <div className="text-center mb-3">
                                    <img src="/assets/img/logo.svg" className="img-fluid" alt="" width="260" />
                                </div>
                                <h4 className="text-center">Sign in to your account</h4>
                                <div className="text-center small mb-4">Enter your email & password to login</div>
                                <form onSubmit={handleLogin}>
                                    <div className="mb-3">
                                        <label className="text-gray-600 small" htmlFor="emailExample">Email Address</label>
                                        <input
                                            className="form-control"
                                            type="email"
                                            name="email"
                                            placeholder="Enter email address"
                                            aria-label="Email Address"
                                            aria-describedby="emailExample"
                                            value={loginInfo.email}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 mb-4">
                                        <label className="text-gray-600 small" htmlFor="passwordExample">Password</label>
                                        <div className="input-group">
                                            <input
                                                type={showPassword ? 'text' : "password"}
                                                name="password"
                                                id="password"
                                                className="form-control"
                                                placeholder="Password"
                                                required
                                                value={loginInfo.password}
                                                onChange={handleInputChange}
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-dark btn-icon btn_view_pass"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                <i className={`fa-solid fa-eye${showPassword ? "-slash" : ""}`} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4 mt-md-5">
                                        <button
                                            type="submit"
                                            className="btn btn-block w-100 btn-xl btn btn-indigo btn_admin"
                                        >
                                            {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                : <span> Sign In </span>}
                                        </button>
                                    </div>
                                </form>
                                {error && <div className="alert text-danger">{error}</div>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
