import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useContext } from 'react';
import Sidebar from "../../Components/CustomComponent/Sidebar";
import Dashboard from "../../Components/SidebarPages/Dashboard";
import SubAdminList from "../../Components/SidebarPages/Subadmin/SubadminList";
import TradeList from "../../Components/SidebarPages/Traders/TraderList";
import CurrencyManagement from "../../Components/SidebarPages/Currency/CurrencyManagement";
import CurrencypairManagement from "../../Components/SidebarPages/Currency/CurrencyPairManagement";
import CompletedDeposit from "../../Components/SidebarPages/FundsDepositManagement/CompletedDeposit";
import PendingDeposit from "../../Components/SidebarPages/FundsDepositManagement/PendingDeposit";
import CancelledWithdrawal from "../../Components/SidebarPages/FundsWithdrawalManagement/CancelledWithdrawal";
import PendingWithdrawal from "../../Components/SidebarPages/FundsWithdrawalManagement/PendingWithdrawal";
import CompletedWithdrawal from "../../Components/SidebarPages/FundsWithdrawalManagement/CompletedWithdrawal";
import Notification from "../../Components/SidebarPages/Notifications";
import BannerManagement from "../../Components/SidebarPages/BannerManagement";
import OrderBook from "../../Components/SidebarPages/OrderBook";
import LoginPage from "../../Components/AuthComponent/Login";
import ForgetpasswordPage from "../../Components/AuthComponent/ForgetPassword";
import AddSubadmin from "../../Components/SidebarPages/Subadmin/AddSubadmin";
import EditSubadmin from "../../Components/SidebarPages/Subadmin/EditSubadmin";
import { ProfileContext } from "../../Components/CustomComponent/ProfileContext";
import TraderDetails from "../../Components/SidebarPages/Traders/TraderDetails";
import TradingReport from "../../Components/SidebarPages/Trading Report";
import Support from "../../Components/SidebarPages/Support";
import Header from '../../Components/CustomComponent/Header';
import KycDetailsPersonal from '../../Components/SidebarPages/KYCManager/KycDetailsPersonal';
import BuySell from '../../Components/SidebarPages/BuySellPage/BuySell';
import BuySellHistory from '../../Components/SidebarPages/BuySellPage/BuySellHistory';
import WithdrawalMultisig from '../../Components/SidebarPages/WithdrawalMultiSig';
import KycDetailsEntity from '../../Components/SidebarPages/KYCManager/KycDetailsEntity';
import VerificationBank from '../../Components/SidebarPages/BankingManagement/VerificationBank';
import VerificationUpi from '../../Components/SidebarPages/BankingManagement/VerificationUpi';
import UserBankDetails from '../../Components/SidebarPages/BankingManagement/UserBankDetails';
import PersonalKyc from '../../Components/SidebarPages/KYCManager/PersonalKyc';
import EntityKyc from '../../Components/SidebarPages/KYCManager/EntityKyc';
import ViewSubadmin from '../../Components/SidebarPages/Subadmin/ViewSubadmin';
import InvestEarnHistory from '../../Components/SidebarPages/InvestEarn/InvestEarnHistory';
import DepositRequest from '../../Components/SidebarPages/InvestEarn/DepositRequest';
import WithdrawalRequest from '../../Components/SidebarPages/InvestEarn/WithdrawalRequest';
import NewBuysell from '../../Components/SidebarPages/BuySellPage/NewBuysell';



const Routing = () => {

    const [profileState] = useContext(ProfileContext)
    const token = sessionStorage.getItem('token');
    const isAuthenticated = profileState.token || token;

    return (
        <Router>
            {isAuthenticated ? (
                <>
                    <Header />
                    <div id="layoutSidenav" >
                        <Sidebar />
                        <Routes>
                            <Route exact path="/dashboard" element={<Dashboard />} />
                            <Route exact path="/sub_admin_list" element={<SubAdminList />} />
                            <Route exact path="/add_sub_admin" element={<AddSubadmin />} />
                            <Route exact path="/edit_sub_admin" element={<EditSubadmin />} />
                            <Route exact path="/view_sub_admin" element={<ViewSubadmin />} />
                            <Route exact path="/users_list" element={<TradeList />} />
                            <Route exact path="/user_details" element={<TraderDetails />} />

                            <Route exact path="/personal_kyc" element={<PersonalKyc />} />
                            <Route exact path="/kyc_detail_personal" element={<KycDetailsPersonal />} />
                            <Route exact path="/entity_kyc" element={<EntityKyc />} />                         
                            <Route exact path="/kyc_detail_entity" element={<KycDetailsEntity />} />

                            <Route exact path="/withdrwalmultisig" element={<WithdrawalMultisig />} />


                            <Route exact path="/bank_verification" element={<VerificationBank />} />
                            <Route exact path="/user_bank_details" element={<UserBankDetails />} />
                            <Route exact path="/upi_verification" element={<VerificationUpi />} />


                            <Route exact path="/deposit_request" element={<DepositRequest />} />
                            <Route exact path="/withdrawal_request" element={<WithdrawalRequest />} />
                            <Route exact path="/invest_earn_history" element={<InvestEarnHistory />} />


                            <Route exact path="/currency_management" element={<CurrencyManagement />} />
                            <Route exact path="/currencypair" element={<CurrencypairManagement />} />
                            <Route exact path="/complete_deposit" element={<CompletedDeposit />} />
                            <Route exact path="/pending_deposit" element={<PendingDeposit />} />
                            <Route exact path="/completed_withdrawal" element={<CompletedWithdrawal />} />
                            <Route exact path="/pending_withdrawal" element={<PendingWithdrawal />} />
                            <Route exact path="/cancelled_withdrawal" element={<CancelledWithdrawal />} />
                            <Route exact path="/trading_report" element={<TradingReport />} />
                            <Route exact path="/order_book" element={<OrderBook />} />
                            <Route exact path="/notification" element={<Notification />} />
                            <Route exact path="/support" element={<Support />} />
                            <Route exact path="/banner_management" element={<BannerManagement />} />
                            <Route exact path="/buysell" element={<BuySell />} />
                            <Route exact path="/buysell_ad" element={<NewBuysell />} />
                            <Route exact path="/buysell-history" element={<BuySellHistory />} />
                            <Route exact path="*" element={<Navigate to="/dashboard" />} />
                        </Routes>
                    </div>


                </>
            ) : (
                <Routes>
                    <Route exact path="/" element={<LoginPage />} />
                    <Route exact path="/*" element={<LoginPage />} />
                    <Route exact path="/forgotpassword" element={<ForgetpasswordPage />} />
                    <Route exact path="*" element={<Navigate to="/" />} />
                </Routes>
            )}
        </Router>
    );
};

export default Routing;
