import React from "react";

const FeaturesManagement = ({ activeTab, userDetail }) => {
    

    return (
        <div className={`tab-pane ${activeTab === 2 ? "active" : ""}`} >
                <div className="card-body py-4" >

                  <div className="profile_data " >

                    <div className="profile_data  ac_body" >
                      <div className="d-flex justify-content-between" >
                        <h5> Features Management  </h5>
                      </div>
                      <div className="row">
                        <div className="col-lg-6" >
                          <div className="per_col mb-3" >
                            <div className="d-flex align-items-center " >
                              <div className="pr_icon" ><i className="ri-line-chart-fill"></i></div>
                              <div>
                                <h5>  Spot  Trading </h5>
                                <p> Revoke user side  Spot Trading freture. </p>
                              </div>
                            </div>
                            <label className="switch">
                              <input type="checkbox" name="" id="forBuySell" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="per_col mb-3" >
                            <div className="d-flex align-items-center " >
                              <div className="pr_icon" ><i className="ri-line-chart-fill"></i></div>
                              <div>
                                <h5>  Quick Buy Sell </h5>
                                <p> Buy or Sell Your Crypto with INR Directly </p>
                              </div>
                            </div>
                            <label className="switch">
                              <input type="checkbox" name="" id="forBuySell" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="per_col mb-3" >
                            <div className="d-flex align-items-center " >
                              <div className="pr_icon" ><i className="ri-p2p-fill"></i></div>
                              <div>
                                <h5> P2P Trading  </h5>
                                <p> Revoke user side  P2P Trading freture. </p>
                              </div>
                            </div>
                            <label className="switch">
                              <input type="checkbox" name="" id="forBuySell" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="per_col mb-3" >
                            <div className="d-flex align-items-center " >
                              <div className="pr_icon" ><i className="ri-arrow-up-line"></i></div>
                              <div>
                                <h5> Deposit  Funds</h5>
                                <p> Revoke user side Deposit freture. </p>
                              </div>
                            </div>
                            <label className="switch">
                              <input type="checkbox" name="" id="forBuySell" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="per_col mb-3" >
                            <div className="d-flex align-items-center " >
                              <div className="pr_icon" ><i className="ri-arrow-down-line"></i></div>
                              <div>
                                <h5> Withdraw Funds </h5>
                                <p> Revoke user side Withdraw freture. </p>
                              </div>
                            </div>
                            <label className="switch">
                              <input type="checkbox" name="" id="forBuySell" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="profile_data  ac_body my-4 " >
                      <div className="d-flex justify-content-between" >
                        <h5> Management History </h5>
                      </div>
                      <div className="table-responsive m_table">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col" className=""> Date/Time </th>
                              <th scope="col" className=""> Features  Type </th>
                              <th scope="col" className=""> Updated Status </th>
                              <th scope="col" className=""> Remark </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="">
                                <span> 11-12-2024 </span>
                              </td>
                              <td className="">
                                <span> ----  </span>
                              </td>
                              <td className="">
                                <span> ----  </span>
                              </td>
                              <td className="">
                                <span> Message here......... </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>


                    </div>

                  </div>

                </div>
              </div>
    )
};

export default FeaturesManagement;