import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { alertErrorMessage, alertSuccessMessage } from '../../CustomComponent/CustomAlert';
import contractData from './Multisign.json'
import { ethers } from "ethers";


const WithdrawalMultisig = () => {


    const [walletAddress, setWalletAddress] = useState();
    const [id, setID] = useState();
   
    const [formData, setFormData] = useState({
        token: 'USDT',
        amount: '',
        receiverAddress: '',
        followingButtons: '',
    })


    let ethersProvider;
    let signer;

    const connectWallet = async () => {
        if (window.ethereum) {
            try {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                setWalletAddress(accounts[0]);
                alertSuccessMessage(`Connected: ${accounts[0]}`);
            } catch (error) {
                alertErrorMessage('Connection failed');
            }
        } else {
            alertErrorMessage('MetaMask is not installed');
        }
    };

    const disconnectWallet = () => {
        setWalletAddress(null);
        alertSuccessMessage('Disconnected wallet');
    };


    const handleChange = (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        } else {
            setFormData({ ...formData, permissions: e || [] });
        }
    };

    const addRequest = async () => {
        try {
            if (!walletAddress) {
                connectWallet()
            }
            ethersProvider = new ethers.providers.Web3Provider(window.ethereum, "any");
            console.log("formData => ", formData)
            signer = ethersProvider.getSigner();
            const abi = contractData.abi;
            const address = contractData.address;
            const contractInstance = new ethers.Contract(
                address,
                abi,
                signer
            );
            const amount = ethers.utils.parseUnits(formData.amount, 18);
            const recipant = formData.receiverAddress;
            const data = ethers.utils.defaultAbiCoder.encode(
                ["address", "uint256"],
                [recipant, amount]
            );
            await contractInstance.submitTransaction('0x55d398326f99059fF775485246999027B3197955', 0, data);
        } catch (error) {
            alertSuccessMessage('Error adding request');
        }
    }

    const signWithUserOne = async () => {
        try {
            if (!walletAddress) {
                connectWallet()
            }
            ethersProvider = new ethers.providers.Web3Provider(window.ethereum, "any");
            console.log("formData => ", formData)
            signer = ethersProvider.getSigner();
            const abi = contractData.abi;
            const address = contractData.address;
            const contractInstance = new ethers.Contract(
                address,
                abi,
                signer
            );
            await contractInstance.confirmTransaction(id)
        } catch (error) {
            alertSuccessMessage('Error approving request');
        }
    }

    const signWithUserTwo = async () => {
        try {
            if (!walletAddress) {
                connectWallet()
            }
            ethersProvider = new ethers.providers.Web3Provider(window.ethereum, "any");
            console.log("formData => ", formData)
            signer = ethersProvider.getSigner();
            const abi = contractData.abi;
            const address = contractData.address;
            const contractInstance = new ethers.Contract(
                address,
                abi,
                signer
            );
            await contractInstance.confirmTransaction(id)
        } catch (error) {
            alertSuccessMessage('Error approving request');
        }
    }

    const signWithUserThree = async () => {
        try {
            if (!walletAddress) {
                connectWallet()
            }
            ethersProvider = new ethers.providers.Web3Provider(window.ethereum, "any");
            console.log("formData => ", formData)
            signer = ethersProvider.getSigner();
            const abi = contractData.abi;
            const address = contractData.address;
            const contractInstance = new ethers.Contract(
                address,
                abi,
                signer
            );
            await contractInstance.confirmTransaction(id)
        } catch (error) {
            alertSuccessMessage('Error approving request');
        }
    }



    const executeTransaction = async () => {
        try {
            if (!walletAddress) {
                connectWallet()
            }
            ethersProvider = new ethers.providers.Web3Provider(window.ethereum, "any");
            console.log("formData => ", formData)
            signer = ethersProvider.getSigner();
            const abi = contractData.abi;
            const address = contractData.address;
            const contractInstance = new ethers.Contract(
                address,
                abi,
                signer
            );
            await contractInstance.executeTransaction(id)
        } catch (error) {
            alertSuccessMessage('Error executing request');
        }
    }


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header ">
                    <div className="container-xl px-4">
                        <div className="page-header-content">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="far fa-user"></i></div>
                                        Withdrawal MultiSig
                                    </h1>
                                </div>
                                <div className="col-auto">
                                    {walletAddress ? (
                                        <>
                                            <span>Connected: {walletAddress}</span>
                                            <button className="btn btn-secondary me-2" onClick={disconnectWallet}>
                                                Disconnect Wallet
                                            </button>
                                        </>
                                    ) : (
                                        <button className="btn btn-primary" onClick={connectWallet}>
                                            Connect Wallet
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4">
                    <div className="card mb-4">
                        <div className="card-header">Enter Details</div>
                        <div className="card-body">
                            <form>
                                <div class="row gx-3 mb-3">
                                    <div class="col-md-4">
                                        <label class="small mb-1" htmlFor="inputToken">Token <em>*</em></label>
                                        <select name="token" id="inputToken" class="form-select form-select-solid form-control form-control-solid" aria-label="Select example" onChange={handleChange} value={formData.token}>
                                            <option selected value="USTD">USDT</option>
                                        </select>
                                    </div>

                                    <div class="col-md-4">
                                        <label class="small mb-1" htmlFor="inputAmount">Amount <em>*</em></label>
                                        <input class="form-control form-control-solid no-arrows" id="inputAmount" name='amount' type="number" placeholder="Enter Amount" value={formData.amount} onChange={handleChange} />
                                    </div>
                                    <div class="col-md-4">
                                        <label class="small mb-1" htmlFor="inputReceiverAddress">Receiver Address <em>*</em></label>
                                        <textarea name="receiverAddress" id="inputReceiverAddress" class="form-control form-control-solid" placeholder='Enter Receiver Address' value={formData.receiverAddress} onChange={handleChange} />

                                    </div>
                                </div>


                                <div class="row gx-3 mb-3">


                                    <div className="col-md-7">
                                        <label className="small mb-1" htmlFor="inputFollowingButtons">Following Buttons <em>*</em></label>

                                        <div className="d-flex flex-wrap gap-2 align-items-start mt-2">
                                            <button type="button" className="btn btn-outline-primary btn-sm text-dark" onClick={() => addRequest()}>Add Request</button>
                                        </div>

                                    </div>
                                </div>

                                <div className="d-flex justify-content-center w-100 mt-5 mb-4">
                                </div>
                            </form>
                            <input
                                type="number"
                                class="form-control form-control-solid"
                                placeholder="Enter ID"
                                value={id}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => {
                                    setID(e.target.value);
                                }}
                            >
                            </input>
                            <button type="button" className="btn btn-outline-primary btn-sm text-dark" onClick={() => signWithUserOne()}>Sign From Owner One</button>
                            <button type="button" className="btn btn-outline-primary btn-sm text-dark" onClick={() => signWithUserTwo()}>Sign From Owner Two</button>
                            <button type="button" className="btn btn-outline-primary btn-sm text-dark" onClick={() => signWithUserThree}>Sign From Owner Three</button>
                            <button type="button" className="btn btn-outline-primary btn-sm text-dark" onClick={() => executeTransaction()}>Execute Request</button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default WithdrawalMultisig