import React, { useEffect, useState } from "react";
import { alertErrorMessage } from "../../../../../CustomComponent/CustomAlert";
import AuthService from "../../../../../../services/ApiIntegration/AuthService";
import LoaderHelper from "../../../../../CustomComponent/LoaderHelper/LoaderHelper";
import moment from "moment";
import copy from "copy-to-clipboard";

const UserTransferHistory = ({ isActiveTab }) => {

    const [totalDataLength, setTotalDataLength] = useState([]);
    const [data, setData] = useState([]);
    const [transactionData, setTransactionData] = useState("");
    const [activeModel, setActiveModel] = useState(false);
    const [copiedId, setCopiedId] = useState(null);
    const [searchKey, setSearchKey] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [skip, setSkip] = useState(0);
    const limit = 10;

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const parts = dateString.split("-");
        if (parts.length !== 3) return "";
        const [year, month, day] = parts;
        return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
    };

    const handleDateFilterData = (startDate, endDate) => {
        if (!startDate) {
            alertErrorMessage("Please select Start date")
            return;
        }
        if (!endDate) {
            alertErrorMessage("Please select End date")
            return;
        }
        handleGetData();
    };

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchKey(searchTerm);
    };

    const handleFilterReset = () => {
        handleGetData();
        setSearchKey("");
        setStartDate("");
        setEndDate("");
        setSkip(0)
    };

    const handlePagination = (action) => {
        if (action === 'prev') {
            if (skip - limit >= 0) {
                setSkip(skip - limit);
            }
        } else if (action === 'next') {
            if (skip + limit < totalDataLength) {
                setSkip(skip + limit);
            }
        } else if (action === 'first') {
            setSkip(0);
        } else if (action === 'last') {
            const lastPageSkip = Math.floor(totalDataLength);
            const data = lastPageSkip - 10
            setSkip(data);
        }
    };

    const handleGetData = async () => {
        const formattedSearchKey = searchKey || "";
        const formattedStartDate = startDate ? formatDate(startDate) : "";
        const formattedEndDate = endDate ? formatDate(endDate) : "";

        let filters = {};
        filters.order_type = "fundTransferToUser";
        if (skip) {
            filters.skip = skip;
        }

        if (limit) {
            filters.limit = limit;
        }

        if (formattedSearchKey) {
            filters.search = formattedSearchKey;
        }

        if (formattedStartDate) {
            filters.startDate = formattedStartDate;
        }
        if (formattedEndDate) {
            filters.endDate = formattedEndDate;
        }

        const queryString = new URLSearchParams(filters).toString();
        LoaderHelper.loaderStatus(true);
        await AuthService.fetchMasterAdminTransactions(queryString).then(async (result) => {
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                try {
                    setData(result?.data?.transactions);
                    setTotalDataLength(result?.data?.totalCount);
                } catch (error) {
                    setData([]);
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                setData([]);
            }
        });
    };

    useEffect(() => {
        if (isActiveTab === "fundTransferToUser") {
            handleGetData();
        }
    }, [isActiveTab, skip, limit, searchKey]);


    const handleCopy = (id) => {
        copy(id);
        setCopiedId(id);
        setTimeout(() => setCopiedId(null), 1000);
    };

    return (
        <div className="tab-content">
            <div className={`tab-pane ${isActiveTab === "fundTransferToUser" && 'active'}`}>
                <div className=" " >
                    <div className="profile_data  ac_body d-flex align-items-center mb-3 justify-content-between" >
                        <h5 className="mb-0" >  User Transfer History  </h5>
                    </div>
                    <div className="tab-content">
                        <div className='tab-pane active'>
                            <div className="filter_bar search-header px-0 g-2">
                                <form className="row align-items-center w-100 gx-2">
                                    <div className="col-6">
                                    </div>
                                    <div className="col-2">
                                        <input
                                            type="date"
                                            className="form-control form-control-solid"
                                            data-provide="datepicker"
                                            name="startDate"
                                            value={startDate}
                                            max={new Date().toISOString().split("T")[0]}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <input
                                            type="date"
                                            className="form-control form-control-solid"
                                            data-provide="datepicker"
                                            name="endDate"
                                            value={endDate}
                                            max={new Date().toISOString().split("T")[0]}
                                            onChange={(e) => setEndDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <div className="row align-items-center gx-2">
                                            <div className="col">
                                                <button className="btn btn-black btn-block w-100 px-4" type="button" onClick={() => handleDateFilterData(startDate, endDate)}>
                                                    Apply
                                                </button>
                                            </div>
                                            <div className="col me-2">
                                                <button className="btn btn-light btn-block w-100" type="button" onClick={handleFilterReset}>
                                                    <i className="ri-reset-left-line"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <label className="small text-dark mb-1" >  </label>
                                <div className="dd_search">
                                    <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" value={searchKey} onChange={handleSearch} />
                                    <i className="fas fa-search"></i>
                                </div>
                            </div>

                            <div className="table-responsive m_table">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="">Id</th>
                                            <th scope="col" className=""> Date/Time </th>
                                            <th scope="col" className="">Txn. Type </th>
                                            <th scope="col" className="">Order Type </th>
                                            <th scope="col" className="">Currency </th>
                                            <th scope="col" className="">Amount </th>
                                            <th scope="col" className="">Status</th>
                                            <th scope="col" className="">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.length > 0 ? (
                                            data?.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="">
                                                        <span>
                                                            {item?._id ? `${item._id.slice(0, 7)}...` : "----------"}
                                                            <i
                                                                className={`ms-1 link cursor-pointer ${copiedId === item._id ? "ri-check-fill text-success" : "ri-file-copy-fill"}`}
                                                                onClick={() => handleCopy(item?._id)}
                                                            ></i>
                                                        </span>
                                                    </td>
                                                    <td className="">
                                                        <span>
                                                            {moment(item.date_time).format("DD MMM YYYY")}
                                                            <br />
                                                            {moment(item.date_time).format("HH:mm:ss")}
                                                        </span>
                                                    </td>
                                                    <td className="">
                                                        <span> {item?.transaction_type}  </span>
                                                    </td>
                                                    <td className="">
                                                        <span> {item?.order_type}  </span>
                                                    </td>
                                                    <td className="">
                                                        <span> {item?.currency}  </span>
                                                    </td>
                                                    <td className="">
                                                        <span> {item?.amount}  </span>
                                                    </td>
                                                    <td className="">
                                                        <span> {item?.status}  </span>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-dark btn-sm me-2" onClick={() => { setActiveModel(true); setTransactionData(item) }}>View</button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" className="text-center">No Data Available</td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                                {
                                    data?.length > 0 &&
                                    <div className="hVPalX gap-2" >
                                        <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                        <div className="sc-eAKtBH gVtWSU">
                                            <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                </svg>
                                            </button>
                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                </svg>
                                            </button>

                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                </svg>
                                            </button>
                                            <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                activeModel &&
                <>
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content pb-3">
                                <div className="modal-header">
                                    <h5 className="modal-title" >Details</h5>
                                    <button type="button" className="btn-close" onClick={() => setActiveModel(false)}></button>
                                </div>
                                <div className="modal-body ">
                                    <div className="tt_data ">
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Wallet Id : </span>
                                            <span className="tt_normal">{transactionData?.wallet_id}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Wallet Type: </span>
                                            <span className="tt_normal">{transactionData?.wallet_type}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> From Wallet Type : </span>
                                            <span className="tt_normal">{transactionData?.from_wallet_type}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> To Wallet Type: </span>
                                            <span className="tt_normal">{transactionData?.to_wallet_type}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted">Description: </span>
                                            <span className="tt_normal">{transactionData?.description}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Admin Name: </span>
                                            <span className="tt_normal">{transactionData?.admin_name || "-----------"}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Remark: </span>
                                            <span className="tt_normal">{transactionData?.remark || "-----------"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            }

        </div>
    )
};

export default UserTransferHistory;