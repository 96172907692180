const appUrl = "https://dev.api.cryptoindia.in";

export const ApiConfig = {
  // =========EndPoints==========
  login: "login",
  newPassword: "newPassword",
  dashboard: "dashboard",
  getSubAdminList: "all-subadmin",
  single_subadmin: "single-subadmin",
  subadmindelete: "remove-subadmin",
  updateSubadmin: "edit-subadmin",
  AddsubAdmin: "create-subadmin",
  users_list: "exchange-users-list",
  update_user_status: 'update-user-status',
  transferhistory: "user-trade-history",
  updateTicketStatus: "update-ticket-status",
  transactionstatus: "update-withdrawal-status",
  getverifyData: "approve-kyc-user",
  getrejectedData: "rejected-kyc-user",
  kycVerify_remark: "add-remarks",
  getVerify_remark: "get-user-remarks",
  coinlist: "coinList",
  addNotify: "add-notification",
  deleteNotify: "delete-notification",
  notificationList: "notification-list",
  admintransfer: "admintransfer",
  getmasterbal: "getmasterbal",
  setcoinwithdrawal: "edit-currency",
  AddCoinPair: "create-pair",
  createCategory: "create-coin-category",
  getCurrencyPairList: "pair-list",
  completeWithdrawalRequest: "completed-withdrawals",
  PendingWithdrwal: "pending-withdrawals",
  CancelledWithdrwal: "rejected-withdrawals",
  completeDepositRequest: "completed-deposits",
  pendingDeposits: "pending-deposits",
  verifiedtrader: "verifiedtrader",
  addNewCoins: "add-currency",
  Addbanner: "add_banner",
  bannerList: "banner_list",
  updateBanner: "edit_banner",
  bannerdelete: "delete-banner",
  BannerStatus: "set_banner_status",
  deleteCoinPair: "delete-pair",
  getCoinList: "currency-list",
  CoinCategory: "coin-category-list",
  tradingCommission: "trading-commission",
  tradeHistory: 'trade-history',
  OrderBook: 'orderbook',
  tradeById: 'find-trades-by-orderid',
  miscellaneousRequest: 'miscellaneous_withdrawal_request',
  botStatus: 'bot-status',
  cancelOrder: 'cancel-order',
  getAllTickets: 'all-tickets',
  replyTicket: "ticket-admin-reply",
  single_ticket: "single-ticket",
  add_qbs: "add-qbs-settings",
  addedqbsHistoy: "get-qbs-settings",
  deleteQbsList: "delete-admin-qbs-settings",
  update_qbs_settings: "update-qbs-settings",
  update_qbs_settings_status: "update-qbs-settings-status",
  allqbsHistoy: "admin/get-all-qbs",
  update_qbs_status: "admin/update-qbs-order",
  process_refund: "payments/process-refund",
  bank_list: "bank-list",
  upi_list: "get-upi-list",

  bank_details: "all-bank-data",
  verify_bank_details: "verify-bank-details",
  change_bank_status: "change-bank-status",
  user_list: "user-list",
  entity_user_list: "entity-kyc-data",
  step_wise_data: "step-wise-data",
  kyc_status_user: "kyc-status-user",
  aml_details: "aml-details",
  master_kyc_data: "master-kyc-data",

  coin_listing_list: "coin-listing-list",
  feedback_list: "feedback-list",
  partnership_list: "partnership-list",
  qbs_fee_list: "qbs-fee-list",
  spot_fee_list: "spot-fee-list",
  add_qbs_fee: "qbs-fee",
  delete_qbs_fee: "delete-qbs-fee",
  delete_spot_fee: "delete-spot-fee",
  market_maker_list: "market-maker-list",
  job_openings_list: "job-openings-list",
  update_job_opening_status: "remove-job-opening",
  create_job_opening: "create-job-opening",
  all_job_applications: "all-job-applications",
  job_application: "job-application",
  create_support_ticket: "create-support-ticket",
  update_ticket: "update-ticket",
  all_support_tickets: "all-support-tickets",
  support_ticket: "support-ticket",




  add_spot_fee: "spot-fee",
  upgrade_user_list: "upgrade-user-list",
  upgrade_user_step_data: "user-upgrade-detail",
  reject_user_upgrade: "reject-user-upgrade",
  upgrade_kycVerify_remark: "user-upgrade-approve",
  reject_user_kyc: "reject-user-kyc",
  get_remark_upgrade: "user-upgrade-remarks",


  admin_bank_details: "admin-bank-details",
  add_admin_bank_details: "add-admin-bank-details",
  delete_admin_bank_details: "delete-admin-bank-details",
  update_bank_status: "update-bank-status",

  add_admin_upi_details: "add-admin-upi-details",
  get_admin_upi_details: "get-admin-upi-details",
  update_upi_status: "update-upi-status",
  delete_admin_upi_details: "delete-admin-upi-details",


  internal_exchange_limit: "internal/user-limit",
  exchange_limit_session: "exchange-limit-session",
  apply_users_limit: "apply-users-limit",
  user_limit: "user-limit",
  apply_limit_user: "apply-limit-user",

  nominee_details: "nominee-details",


  check_user_overall_limit: "check-user-overall-limit",
  quick_pay_limit: "quick-pay-limit",
  add_payment_option: "add-payment-option",
  payment_options: "payment-options",
  update_payment_option: "update-payment-option",
  delete_payment_option: "delete-payment-option",
  exchange_limit_validity: "exchange-limit-validity",

  get_qbs_limits: "get-limits",
  update_limit: "update-limits",
  update_dependent_limits: "update-dependent-limits",
  update_user_limit: "update-user-limit",
  quick_pay_limit_user: "quick-pay-limit-user",
  user_details: "user-details",
  admin_wallet: "admin/wallets",
  update_balance: "admin/add-deduct-funds",

  user_wallets: "user/wallets",

  user_fund_transfer: "admin/user-fund-transfer",
  hold_unhold_fund_user: "user/hold-unhold-fund",
  hold_unhold_fund_admin: "admin/hold-unhold-fund",


  fetch_transactions: "fetch-transactions",
  payment_option: "payment-option",


  get_withdrawal_limit: "get-withdrawal-limit",
  update_withdrawal_limit: "update-withdrawal-limit",
  user_withdrawal_limit: "user-withdrawal-limit",

  internal_fund_transfer: "admin/internal-fund-transfer",
  // ============URLs================
  baseHelp: `${appUrl}/help/`,
  baseTran: `${appUrl}/v1/transaction/`,
  baseWithdrawal: `${appUrl}/v1/admin/withdrawal/`,
  baseWithdrawal2: `${appUrl}/v1/admin/withdrawl/`,
  baseUrl: `${appUrl}/v1/admin/`,
  baseUser: `${appUrl}/v1/user/`,
  baseUrlInternal: `${appUrl}/v1/admin/internal/`,
  uploadUrl: `${appUrl}/uploads/`,
  basenotify: `${appUrl}/acc/`,
  baseSecure: `${appUrl}/v1/coin/`,
  baseWallet: `${appUrl}/v1/wallet/`,
  baseCoin: `${appUrl}/v1/coin/`,
  baseReport: `${appUrl}/report/`,
  baseExchange: `${appUrl}/v1/exchange/`,
  baseAdminQbs: `${appUrl}/v1/admin/qbs/`,
  baseQbs: `${appUrl}/v1/qbs/`,
  baseuserQbs: `${appUrl}/v1/qbs/user/`,
  uploadcurrency: `${appUrl}/`,
  baseWalletAdmin: `${appUrl}/v1/wallet/admin/`,

  appUrl: `${appUrl}/`,
};
