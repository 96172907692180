import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";
import DataTable from "react-data-table-component";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { ApiConfig } from "../../../../services/ApiIntegration/ApiConfig";
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import LoaderHelper from "../../../CustomComponent/LoaderHelper/LoaderHelper";
import { $ } from "react-jquery-plugin";
import EditModal from "./EditModal";


const TraderDetails = (props) => {

    const [userDetail, setUserDetail] = useState([]);
    const [walletdetails, setwalletdetails] = useState([]);
    const [status, setstatus] = useState();
    const [makerFee, setMakerFee] = useState();
    const [takerFee, setTakerFee] = useState();
    const location = useLocation();
    const userTradeData = location.state?._id

    const [formData, setFormData] = useState([{
        firstName: userTradeData?.firstName,
        lastName: userTradeData?.lastName,
        profilePicture: "",
        status: userTradeData?.status,
        _id: userTradeData?._id,
    }])

    const [modalFields, setModalFields] = useState([]);
    const [modalTitle, setModalTitle] = useState('');

    const handleUserData = async () => {
        try {
            const result = await AuthService.getuserDetails(location.state?._id);
            if (result?.success) {
                setUserDetail(result.data);
            } else {
                alertErrorMessage(result.message);
            }
        } catch (error) {
            alertErrorMessage("An error occurred while fetching user details.");
        }
    };

    const submitEditedDetails = () => {
        // document.getElementById('editModal').classList.remove('show');
        $('#edit_trade_modal').modal('hide');
        console.log('submittedData', formData)

        return

        LoaderHelper.loaderStatus(true);
        try {
            const result = AuthService.api(formData);
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                // $('#edit_trade_modal').modal('hide');
            } else {
                alertErrorMessage(result?.message);
            };
        } catch (error) {
            alertErrorMessage(error?.message);
        }
        finally { LoaderHelper.loaderStatus(false) };
    }

    const PriceFormat = (row) => {
        return row?.fee && parseFloat(row?.fee?.toFixed(5));
    };

    const columns = [
        { name: "Date/Time", selector: row => moment(row?.updatedAt).format("MMM Do YYYY"), },
        { name: "Currency", selector: row => row.currency, },
        { name: "Fee", selector: PriceFormat },
        { name: "Type", selector: row => row.order_type, },
        { name: "Price", selector: row => row.price, },
        { name: "Quantity", selector: row => row.quantity, },
        { name: "Side", selector: row => row.side, },
        { name: "TDS", selector: row => row.tds, },
    ]

    // ******** Wallet History Table ************//
    const walletdetailscolumns = [
        { name: "Assets", selector: row => row.short_name, },
        { name: "Balance", selector: row => row.balance, },
        { name: "Locked Balance", selector: row => row.locked_balance, },
    ]

    const handleTransferhistory = async () => {
        await AuthService.transferhistory(location.state?._id).then(async result => {
            if (result?.success) {
                try {
                    // setuserTradeDetails(result?.data);
                } catch (error) {
                    // console.log('error', `${error}`);
                }
            } else {
                // alertErrorMessage(result.message)
            }
        });
    }
    const userWallet = async () => {
        await AuthService.userWallet(location.state?._id).then(async result => {
            if (result?.success) {
                try {
                    setwalletdetails(result?.data);
                } catch (error) {
                    console.log('error', `${error}`);
                }
            } else {
                // alertErrorMessage(result.message)
            }
        });
    }





    const HandleStatus = (Status) => {
        setstatus(Status);
        if (!Status) {
            setMakerFee(0)
            setTakerFee(0)
        }
    };
    useEffect(() => {
        if (makerFee > 0 || takerFee > 0) {
            setstatus(true)
        } else {
            setMakerFee(0);
            setTakerFee(0);
            setstatus(false)
        }

    }, [makerFee, takerFee]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const openModal = (fields, title) => {
        setModalFields(fields);
        setModalTitle(title);
        $('#editModal').modal('show');
    };

    const personalFields = [
        { label: 'First Name', name: 'firstName', type: 'text', placeholder: 'Enter First Name', id: 'inputFirstName' },
        { label: 'Last Name', name: 'lastName', type: 'text', placeholder: 'Enter Last Name', id: 'inputLastName' },
        { label: 'Email', name: 'email', type: 'email', placeholder: 'Enter Email', id: 'inputEmail' },
        { label: 'Mobile Number', name: 'phone', type: 'text', placeholder: 'Enter Phone', id: 'inputPhone' },
        { label: 'Referral Code', name: 'Referral Code', type: 'text', placeholder: 'Enter Referral Code', id: 'inputReferralCode' },
        { label: 'Status', name: 'status', type: 'text', placeholder: 'Enter Status', id: 'inputStatus' },
        { label: 'Kyc Type', name: 'kycType', type: 'text', placeholder: 'Enter Kyc Type', id: 'inputKycType' },
        { label: 'Date of Birth', name: 'dob', type: 'text', placeholder: 'Enter Date of Birth', id: 'inputDob' },
        { label: 'Address', name: 'address', type: 'text', placeholder: 'Enter Address', id: 'inputAddress' },
        { label: 'City Name', name: 'city', type: 'text', placeholder: 'Enter City Name', id: 'inputCity' },
        { label: 'Zip Code', name: 'zipCode', type: 'text', placeholder: 'Enter Zip Code', id: 'inputZipCode' },
        { label: 'State Name', name: 'state', type: 'text', placeholder: 'Enter State Name', id: 'inputState' },
        { label: 'Country Name', name: 'country', type: 'text', placeholder: 'Enter Country Name', id: 'inputCountry' },
    ];

    const bankAccountFields = [
        { label: 'Bank Name', name: 'bankName', type: 'text', placeholder: 'Enter Bank Name', id: 'inputBankName' },
        { label: 'Branch Name', name: 'branchName', type: 'text', placeholder: 'Enter Branch Name', id: 'inputBranchName' },
        { label: 'Account Holder Name', name: 'accountHolderName', type: 'text', placeholder: 'Enter Account Holder Name', id: 'inputAccountHolderName' },
        { label: 'Account Type', name: 'accountType', type: 'text', placeholder: 'Enter Account Type', id: 'inputAccountType' },
        { label: 'Bank Account Number', name: 'bankAccountNumber', type: 'text', placeholder: 'Enter Bank Account Number', id: 'inputBankAccountNumber' },
        { label: 'IFSC Code', name: 'ifscCode', type: 'text', placeholder: 'Enter IFSC Code', id: 'inputIFSCCode' },
    ];

    const upiAccountFields = [
        { label: 'Name', name: 'upiName', type: 'text', placeholder: 'Enter Name', id: 'inputUPIName' },
        { label: 'UPI VPA ID', name: 'upiVpaId', type: 'text', placeholder: 'Enter UPI VPA ID', id: 'inputUPIVpaId' },
    ];

    const nomineeFields = [
        { label: ' Name', name: 'nomineeName', type: 'text', placeholder: 'Enter Name', id: 'inputNomineeName' },
        { label: 'Relation', name: 'relation', type: 'text', placeholder: 'Enter Relation', id: 'inputRelation' },
        { label: 'Date of Birth', name: 'nomineeDob', type: 'text', placeholder: 'Enter Date of Birth', id: 'inputnomineeDob' },
        { label: 'Aadhaar Number', name: 'nomineeAadhaar', type: 'text', placeholder: 'Enter Aadhaar Number', id: 'inputnomineeAadhaar' },
    ]




    useEffect(() => {
        handleUserData();
    }, [])



    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <form className="form-data" >
                        <header className="page-header ">
                            <div className="container-xl px-4">
                                <div className="page-header-content">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto">
                                            <h1 className="page-header-title">
                                                <Link to="/users_list" className="page-header-icon">
                                                    <i className="fa fa-arrow-left" ></i>
                                                </Link>
                                                <div className="hd_u_info" >
                                                    <img
                                                        src={userDetail?.document_front_image ? `${ApiConfig.appUrl}${userDetail?.document_front_image}` : "assets/img/illustrations/profiles/profile-5.png"}
                                                        alt=""
                                                        className="img-account-profile rounded-circle "
                                                        onError={(e) => e.target.src = "assets/img/illustrations/404-error.svg"}
                                                    />
                                                    <span>{userDetail?.kyc?.first_name || 'Unknown User'} {userDetail?.kyc?.last_name} <small className="text-success" > <i class="ri-verified-badge-fill "></i> Verified</small> </span>
                                                </div>
                                            </h1>
                                        </div>
                                        <div className="col-auto">
                                            <button class="btn btn-danger px-4 btn-md "> Block User</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="container-xl px-4" >
                            <div className="card" >
                                <div className="card-header pb-0">
                                    <ul className="nav nav-tabs nav-tabs-custom" id="cardTab" role="tablist">
                                        <li className="nav-item  ">
                                            <a className="menu-link nav-link  active" id="personal-pill" href="#personalPill" data-bs-toggle="tab" role="tab" aria-controls="personal" aria-selected="true">
                                                <span className="menu-title">  Personal Info </span>
                                            </a>
                                        </li>
                                        <li className="nav-item  ">
                                            <a className="menu-link  nav-link" id="Verification-pill" href="#VerificationPill" data-bs-toggle="tab" role="tab" aria-controls="Verification" aria-selected="false"> <span className="menu-title">  KYC Info</span></a>
                                        </li>
                                        <li className="nav-item ">
                                            <a className="menu-link d-flex nav-link" id="bankaccount-pill" href="#bankaccountPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-title">  Bank Info</span></a>
                                        </li>
                                        <li className="nav-item ">
                                            <a className="menu-link d-flex nav-link" id="upiaccount-pill" href="#upiaccountPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-title">  UPI Info</span></a>
                                        </li>
                                        <li className="nav-item ">
                                            <a className="menu-link d-flex nav-link" id="permission-pill" href="#permissionPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-title">  Permission</span></a>
                                        </li>
                                        <li className="nav-item ">
                                            <a className="menu-link d-flex nav-link" id="twoFactorAuthentication-pill" href="#twoFactorAuthenticationPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-title">  2FA Details</span></a>
                                        </li>
                                        <li className="nav-item ">
                                            <a className="menu-link d-flex nav-link" id="Limits-pill" href="#LimitsPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-title"> Limits  </span></a>
                                        </li>
                                        <li className="nav-item ">
                                            <a className="menu-link d-flex nav-link" id="nominee-pill" href="#nomineePill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-title">  Nominee</span></a>
                                        </li>
                                        <li className="nav-item ">
                                            <a className="menu-link d-flex nav-link" id="Transitions-pill" href="#TransitionsPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-title">  Trade Details</span></a>
                                        </li>
                                        <li className="nav-item ">
                                            <a className="menu-link d-flex nav-link" id="Transitions-pill" href="#walletdetails" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-title">  User Wallet</span></a>
                                        </li>

                                    </ul>
                                    <div className="dropdown">
                                        <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                            <i className="fa fa-download me-3"></i>Export
                                        </button>
                                        <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp" >
                                            {/* <CSVLink  className="dropdown-item">
                                                Export as CSV
                                            </CSVLink> */}
                                            <div className="dropdown-item" >Export as CSV</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content tab-content-card " id="cardPillContent">

                                    {/* personal INformation */}
                                    <div className="tab-pane show active" id="personalPill" role="tabpanel" aria-labelledby="personal-pill">
                                        <div className="card-body py-4" >
                                            <div className="profile_data  ac_body " >
                                                <h5>  Personal Information </h5>
                                                <div className="row g-4">
                                                    <div class="col-lg-6 col-xl-4">
                                                        <div class="label-group"><label>  User Type   </label><p>  {userDetail?.kyc?.sub_kyc_type || '------'} </p></div>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-4">
                                                        <div class="label-group"><label> User Id   </label><p>   {userDetail?.user?.uin || '------'}  </p></div>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-4">
                                                        <div class="label-group"><label>  Account Status   </label><p className="text-success" >   {userDetail?.user?.status || '------'}  </p></div>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-4">
                                                        <div class="label-group"><label>  Referral Code   </label><p>  {userDetail?.user?.referral_code || '------'}   </p></div>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-4">
                                                        <div class="label-group"><label>   Email Address  </label><p>  {userDetail?.kyc?.emailAddress || '------'}  </p></div>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-4">
                                                        <div class="label-group"><label>  Mobile Number  </label><p>  {userDetail?.kyc?.mobileNumber || '------'}  </p></div>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-4">
                                                        <div class="label-group"><label> First Name  </label><p>   {userDetail?.kyc?.first_name || '------'} </p></div>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-4">
                                                        <div class="label-group"><label>  Middle Name  </label><p>  {userDetail?.kyc?.middle || '------'}  </p></div>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-4">
                                                        <div class="label-group"><label>  Last Name  </label><p>  {userDetail?.kyc?.last || '------'}  </p></div>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-4">
                                                        <div class="label-group"><label>  Date of Birth   </label><p>  {userDetail?.kyc?.dob || '------'}  </p></div>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-4">
                                                        <div class="label-group"><label>  Address   </label><p>  {userDetail?.kyc?.address || '------'}  </p></div>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-4">
                                                        <div class="label-group"><label>  City   </label><p>  {userDetail?.kyc?.city || '------'}  </p></div>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-4">
                                                        <div class="label-group"><label>  State   </label><p>  {userDetail?.kyc?.state || '------'} </p></div>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-4">
                                                        <div class="label-group"><label>  Country   </label><p>  {userDetail?.kyc?.country || '------'}  </p></div>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-4">
                                                        <div class="label-group"><label>   Zip Code  </label><p>  {userDetail?.kyc?.zip_code || '------'}  </p></div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* KYC Verification */}
                                    <div className="tab-pane" id="VerificationPill" role="tabpanel" aria-labelledby="Verification-pill">
                                        <div className="card-header">KYC Verification Details
                                            <div className="d-flex justify-content-end" >

                                            </div>
                                        </div>
                                        <div className="card-body" >
                                            <div className="profile_data py-4 px-4" >
                                                <div className="row" >
                                                    <span className="col-3" >Verification Status: </span>

                                                    {userDetail?.user?.kycVerified == 2 ?
                                                        <strong className=" col text-success  ">Verified</strong>
                                                        : userDetail?.user?.kycVerified == 3 ?
                                                            <strong className=" col text-danger">Rejected</strong>
                                                            : <strong className="col text-warning">Pending</strong>
                                                    }

                                                </div>
                                                <hr className="my-3" />
                                                <div className="row" > <span className="col-3" >Submited Date:</span>
                                                    <strong className=" col">{userDetail?.kyc?.createdAt && moment(userDetail?.kyc?.createdAt).format('Do MMMM YYYY')}</strong>
                                                </div>

                                                <hr className="my-3" />
                                                <div className="row" > <span className="col-3" >KYC Type:</span>
                                                    <strong className=" col"> {userDetail?.kyc?.kyc_type} </strong>
                                                </div>
                                                <hr className="my-3" />
                                                <div className="row" > <span className="col-3" >Document Type:</span>
                                                    <strong className=" col"> {userDetail?.kyc?.document_type} ({userDetail?.kyc?.document_number}) </strong>
                                                </div>
                                                <hr className="my-3" />

                                                <div className="row" > <span className="col-3" >Pan Card Number:</span>
                                                    <strong className=" col"> {userDetail?.kyc?.pancard_number} </strong>
                                                </div>
                                                <hr className="my-3" />


                                            </div>
                                        </div>
                                    </div>

                                    {/* Bank Account Details*/}
                                    <div className="tab-pane" id="bankaccountPill" role="tabpanel" aria-labelledby="bankaccount-pill">
                                        <div className="card-body py-4" >


                                            <div className="profile_data  ac_body" >
                                                <h4 className="mb-4" >  Bank Accounts </h4>
                                                <ul class="nav custom-tabs mb-4  ">
                                                    <li class="buysell-tab">
                                                        <a class="active">  Verified Accounts  </a>
                                                    </li>
                                                    <li class="buysell-tab">
                                                        <a class="">  Pending Accounts  </a>
                                                    </li>
                                                    <li class="buysell-tab">
                                                        <a class="">   Inactive Accounts </a>
                                                    </li>
                                                </ul>
                                                <div className="ac_lists" >
                                                    <div className="ac_card" >
                                                        <div className="row g-4 align-items-end">
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  Bank Name   </label><p>  HDFC BANK <span className="badge bg-dark" >Default</span> </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  Account Number   </label><p>  HDFC BANK  </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  IFSC Code </label><p> DFGV2FD31G </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label> Verification Date </label><p>  11 Nov. 2024    </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label> Status </label><p>   <span className="badge bg-success" > Verified </span> </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"> <Link to="/user_bank_details" className="btn btn-sm btn-primary" >View</Link> </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div className="ac_card" >
                                                        <div className="row g-4 align-items-end">
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  Bank Name   </label><p>  HDFC BANK  </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  Account Number   </label><p>  HDFC BANK  </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  IFSC Code </label><p> DFGV2FD31G </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label> Verification Date </label><p>  11 Nov. 2024    </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label> Status </label><p>   <span className="badge bg-success" > Verified </span>    </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"> <Link to="/user_bank_details" className="btn btn-sm btn-primary" >View</Link> </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div className="ac_card" >
                                                        <div className="row g-4 align-items-end">
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  Bank Name   </label><p>  HDFC BANK  </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  Account Number   </label><p>  HDFC BANK  </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  IFSC Code </label><p> DFGV2FD31G </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label> Applied Date </label><p> ----   </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label> Status </label><p>   <span className="badge bg-warning" > Pending </span>    </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"> <Link to="/user_bank_details" className="btn btn-sm btn-primary" >View</Link> </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div className="ac_card" >
                                                        <div className="row g-4 align-items-end">
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  Bank Name   </label><p>  HDFC BANK  </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  Account Number   </label><p>  HDFC BANK  </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  IFSC Code </label><p> DFGV2FD31G </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label> Deleted Date </label><p> ----   </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label> Status </label><p>   <span className="badge bg-danger" > Deleted </span>    </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"> <Link to="/user_bank_details" className="btn btn-sm btn-primary" >View</Link> </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                    {/* UPI Account Details */}
                                    <div className="tab-pane" id="upiaccountPill" role="tabpanel" aria-labelledby="upiaccount-pill">
                                        <div className="card-body py-4" >


                                            <div className="profile_data  ac_body" >
                                                <h4 className="mb-4" >  UPI ID </h4>
                                                <ul class="nav custom-tabs mb-4  ">
                                                    <li class="buysell-tab">
                                                        <a class="active">  Verified UPI's  </a>
                                                    </li>
                                                    <li class="buysell-tab">
                                                        <a class="">   Deleted UPI's </a>
                                                    </li>
                                                </ul>
                                                <div className="ac_lists" >
                                                    <div className="ac_card" >
                                                        <div className="row g-4 align-items-end">
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  KYC User Name     </label><p>  Unknown username  </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  UPI ID   </label><p>  asdasd@ybl <span className="badge bg-dark" >Default</span> </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>    UPI ID Status  </label><p className="text-success" > <small><i class="ri-shield-check-line"></i>  Valid</small>   </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  UPI Name </label><p> asdasdsad </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label> Name Match Status </label><p>   <span className="badge bg-success" > Verified </span> </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"> <Link to="/user_bank_details" className="btn btn-sm btn-danger" >Delete</Link> </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div className="ac_card" >
                                                        <div className="row g-4 align-items-end">
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  KYC User Name     </label><p>  Unknown username  </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  UPI ID   </label><p>  asdasd@ybl <span className="badge bg-dark" >Default</span> </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>    UPI ID Status  </label><p className="text-danger" > <small><i class="ri-spam-2-line"></i>  Invalid </small>   </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label>  UPI Name </label><p> asdasdsad </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"><label> Name Match Status </label><p>   <span className="badge bg-success" > Verified </span> </p></div>
                                                            </div>
                                                            <div class="col-lg-6 col-xl-2">
                                                                <div class="label-group"> <Link to="/user_bank_details" className="btn btn-sm btn-danger" >Delete</Link> </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    {/* Permission Details */}
                                    <div className="tab-pane" id="permissionPill" role="tabpanel" aria-labelledby="permission-pill">
                                        <div className="card-body py-4" >
                                            <div className="profile_data " >
                                                <h4 className="mb-4" >  User Permissions </h4>

                                                <div className="  row" >

                                                    <div className="col-lg-6" >
                                                        <div className="per_col mb-3" >
                                                            <div className="d-flex align-items-center " >
                                                                <div className="pr_icon" ><i class="ri-arrow-up-down-line"></i></div>
                                                                <div>
                                                                    <h5>Buy & Sell</h5>
                                                                    <p> Revoke user side buy sell access. </p>
                                                                </div>
                                                            </div>
                                                            <label className="switch">
                                                                <input type="checkbox" name="" id="forBuySell" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6" >
                                                        <div className="per_col mb-3" >
                                                            <div className="d-flex align-items-center " >
                                                                <div className="pr_icon" ><i class="ri-line-chart-fill"></i></div>
                                                                <div>
                                                                    <h5>  Spot  Trading </h5>
                                                                    <p> Revoke user side  Spot Trading freture. </p>
                                                                </div>
                                                            </div>
                                                            <label className="switch">
                                                                <input type="checkbox" name="" id="forBuySell" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6" >
                                                        <div className="per_col mb-3" >
                                                            <div className="d-flex align-items-center " >
                                                                <div className="pr_icon" ><i class="ri-p2p-fill"></i></div>
                                                                <div>
                                                                    <h5> P2P Trading  </h5>
                                                                    <p> Revoke user side  P2P Trading freture. </p>
                                                                </div>
                                                            </div>
                                                            <label className="switch">
                                                                <input type="checkbox" name="" id="forBuySell" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6" >
                                                        <div className="per_col mb-3" >
                                                            <div className="d-flex align-items-center " >
                                                                <div className="pr_icon" ><i class="ri-arrow-up-line"></i></div>
                                                                <div>
                                                                    <h5> Deposit  Funds</h5>
                                                                    <p> Revoke user side Deposit freture. </p>
                                                                </div>
                                                            </div>
                                                            <label className="switch">
                                                                <input type="checkbox" name="" id="forBuySell" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6" >
                                                        <div className="per_col mb-3" >
                                                            <div className="d-flex align-items-center " >
                                                                <div className="pr_icon" ><i class="ri-arrow-down-line"></i></div>
                                                                <div>
                                                                    <h5> Withdraw Funds </h5>
                                                                    <p> Revoke user side Withdraw freture. </p>
                                                                </div>
                                                            </div>
                                                            <label className="switch">
                                                                <input type="checkbox" name="" id="forBuySell" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>


                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    {/* Two Factor Authentication Details */}

                                    <div className="tab-pane" id="twoFactorAuthenticationPill" role="tabpanel" aria-labelledby="twoFactorAuthentication-pill">

                                        <div className="card-body py-4" >

                                            <div className="profile_data " >
                                                <h4 className="mb-4" >  Two Factor Authentication   </h4>

                                                <div className="  row justify-content-center" >

                                                    <div className="col-lg-7" >
                                                        <div className="per_col mb-3" >
                                                            <div className="d-flex align-items-center " >
                                                                <div className="pr_icon" ><i class="ri-arrow-up-down-line"></i></div>
                                                                <div>
                                                                    <h5>Authenticator App :</h5>
                                                                </div>
                                                            </div>
                                                            <label className="switch">
                                                                <input type="checkbox" name="" id="forBuySell" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7" >
                                                        <div className="per_col mb-3" >
                                                            <div className="d-flex align-items-center " >
                                                                <div className="pr_icon" ><i class="ri-line-chart-fill"></i></div>
                                                                <div>
                                                                    <h5>  Email OTP Verification </h5>
                                                                </div>
                                                            </div>
                                                            <label className="switch">
                                                                <input type="checkbox" name="" id="forBuySell" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7" >
                                                        <div className="per_col mb-3" >
                                                            <div className="d-flex align-items-center " >
                                                                <div className="pr_icon" ><i class="ri-p2p-fill"></i></div>
                                                                <div>
                                                                    <h5>   Mobile OTP Verification  </h5>
                                                                </div>
                                                            </div>
                                                            <label className="switch">
                                                                <input type="checkbox" name="" id="forBuySell" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7" >
                                                        <div className="per_col mb-3" >
                                                            <div className="d-flex align-items-center " >
                                                                <div className="pr_icon" ><i class="ri-arrow-up-line"></i></div>
                                                                <div>
                                                                    <h5> None  </h5>
                                                                </div>
                                                            </div>
                                                            <label className="switch">
                                                                <input type="checkbox" name="" id="forBuySell" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>


                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    {/* Two Factor Authentication Details */}
                                    <div className="tab-pane" id="LimitsPill" role="tabpanel" aria-labelledby="Limits-pill">

                                        <div className="card-body py-4" >

                                            <div className="profile_data " >

                                                <div className="  row " >

                                                    <div className="col-lg-4" >
                                                        <div className="per_col mb-3" >
                                                            <div className="l_form w-100" >
                                                                <h5 className="mb-4" > Buy Sell Limits </h5>
                                                                <div className="form-group mb-3" >
                                                                    <label className="small mb-1">
                                                                        Maximum Buying Limit
                                                                    </label>
                                                                    <input type="number" className="form-control  " value="10000" />
                                                                </div>
                                                                <div className="form-group mb-3" >
                                                                    <label className="small mb-1">
                                                                        Maximum Orders
                                                                    </label>
                                                                    <input type="number" className="form-control  " value="5" />
                                                                </div>
                                                                <div className="form-group">
                                                                    <button className="btn btn-warning btn-block w-100"> Submit </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    {/* Nominee Details */}
                                    <div className="tab-pane" id="nomineePill" role="tabpanel" aria-labelledby="nominee-pill">

                                        <div className="card-body py-4" >
                                            <div className="profile_data  ac_body  " >
                                                <div className="d-flex justify-content-between" >
                                                    <h5>  Nominee Information </h5>
                                                    <button
                                                        type="button" className="btn btn-dark px-4 btn-md py-2 " onClick={() => openModal(nomineeFields, 'Edit Nominee Details')}  >
                                                        Edit
                                                    </button>
                                                </div>
                                                <div className="row g-4" >

                                                    <div class="col-lg-6 col-xl-4"><div class="label-group"><label>  Name     </label>
                                                        <p>  {userDetail?.nominee_details?.bank_name || "----"} </p> </div></div>
                                                    <div class="col-lg-6 col-xl-4"><div class="label-group"><label>  Relation     </label>
                                                        <p>   {userDetail?.nominee_details?.branch_name || "----"} </p> </div></div>
                                                    <div class="col-lg-6 col-xl-4"><div class="label-group"><label>  Date of Birth:     </label>
                                                        <p>  {userDetail?.nominee_details?.account_holder_name || "----"}</p> </div></div>
                                                    <div class="col-lg-6 col-xl-4"><div class="label-group"><label>  Date of Birth:     </label>
                                                        <p>  {userDetail?.nominee_details?.bank_name || "----"}</p> </div></div>
                                                    <div class="col-lg-6 col-xl-4"><div class="label-group"><label>  Aadhaar Number:     </label>
                                                        <p>  {userDetail?.nominee_details?.account_type || "----"}</p> </div></div>

                                                </div>

                                            </div>
                                        </div>



                                    </div>


                                    {/* User Trade Details */}
                                    <div className="tab-pane" id="TransitionsPill" role="tabpanel" aria-labelledby="Transitions-pill">

                                        <div className="card-body py-4" >
                                            <div className="profile_data  ac_body  " >
                                                <div className="d-flex justify-content-between" >
                                                    <h5>  User Trade Information </h5>
                                                    <button
                                                        type="button" className="btn btn-dark px-4 btn-md py-2 " onClick={handleChange}  >
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>
                                            <form className="row">
                                                <div className="col-12" >
                                                    <table className="" width="100%" >
                                                        <DataTable columns={walletdetailscolumns} data={walletdetails} />
                                                    </table>
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    {/* walletdetails*/}
                                    <div className="tab-pane" id="walletdetails" role="tabpanel" aria-labelledby="walletdetails">
                                        <div className="card-body py-4" >

                                            <div className="profile_data  ac_body  " >
                                                <div className="d-flex justify-content-between" >
                                                    <h5> User Wallet Details </h5>
                                                    <button
                                                        type="button" className="btn btn-dark px-4 btn-md py-2 " onClick={handleChange}  >
                                                        Edit
                                                    </button>
                                                    {
                                                        walletdetails?.length === 0 ? "" :
                                                        <div className="dropdown">
                                                            <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>
                                                            <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                                                <CSVLink data={walletdetails} className="dropdown-item">Export as CSV</CSVLink>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>



                                            <form className="row">
                                                <div className="col-12" >
                                                    <table className="" width="100%" >
                                                        <DataTable columns={walletdetailscolumns} data={walletdetails} />
                                                    </table>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    {/* Master Admin */}
                                    <div className="tab-pane" id="masterAdmin" role="tabpanel" aria-labelledby="bankaccount-pill">
                                        <div className="card-header">Master Trader</div>

                                        <div className="profile_data py-5 px-4" >
                                            <div className="row" >
                                                <label className="small mb-1" htmlFor="Maker">Maker Fee </label>
                                                <input type="number" className="form-control  form-control-solid" id="Maker" placeholder="Maker Fee" name="firstName" value={makerFee} onChange={(e) => setMakerFee(e.target.value)} onWheel={(e) => e.target.Blur()} />
                                            </div>
                                            <hr className="my-3" />
                                            <div className="row" >
                                                <label className="small mb-1" htmlFor="taker">Taker Fee </label>
                                                <input type="number" className="form-control  form-control-solid" id="taker" placeholder="Taker Fee " name="firstName" value={takerFee} onChange={(e) => setTakerFee(e.target.value)} onWheel={(e) => e.target.Blur()} /></div>
                                            <hr className="my-3" />
                                            <div className="row" > <label className="small mb-1" htmlFor="taker">Status </label><div>
                                                <button type="button" className={`btn  btn-sm  me-2 ${status ? 'btn-success' : 'btn-outline-success'}`} onClick={() => HandleStatus(true)} >Active</button>
                                                <button type="button" className={`btn  btn-sm  me-2 ${status ? 'btn-outline-danger' : 'btn-danger'}`} onClick={() => HandleStatus(false)}  >Inactive</button></div>  </div>
                                            <hr className="my-3" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </main>
            </div>





            {/* Edit Information Model Box */}

            <EditModal
                title={modalTitle}
                fields={modalFields}
                formData={formData}
                handleChange={handleChange}
                handleSubmit={submitEditedDetails}
                modalId="editModal"
            />


        </>

    )


}

export default TraderDetails;