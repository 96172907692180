import { alertErrorMessage, alertSuccessMessage } from "../../../Components/CustomComponent/CustomAlert";
import AuthService from "../../ApiIntegration/AuthService";



export const handleUpdateValue = async (value, key, handleGetUpdatedValue) => {
  await AuthService.updatedValueNewVar(value, key).then(async (result) => {
    if (result.success) {
      try {
        handleGetUpdatedValue();
        alertSuccessMessage(result?.message);
      } catch (error) {
        alertErrorMessage(error);
        handleGetUpdatedValue();
      }
    } else {
      alertErrorMessage(result);
      handleGetUpdatedValue();
    }
  });
};


export const handleUpdateUserLimitWithDrawal = async ({ setIsActiveModelDeposit, handleUserDepositWithLimitData, setIsLoading, setApiResponse, userId, formData,setIsActiveModelWithdrawal }) => {
  try {
    setIsLoading(true);
    const result = await AuthService.updateUserDepositWithdrawalLimit(formData, userId);
    if (result.success) {
      setIsActiveModelDeposit(false);
      setIsActiveModelWithdrawal(false);
      handleUserDepositWithLimitData();
      setIsLoading(false);
    } else {
      setApiResponse(result);
      setIsLoading(false);
    }
  } catch (error) {
    setApiResponse(error);
    setIsLoading(false);
  }
};